import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Box,
  Button,
  IconButton,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { api } from "../../../../axios/api";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material";
import ViewNotes from "./ViewNNotes";
const AddNote = ({ selectedLead }) => {
  const theme = useTheme();
  const [viewMode, setViewMode] = useState(false);
  const [note, setNote] = useState("");
  const [allNotes, setAllNotes] = useState([]);
  const [happinessRatingOptions, setHappinessRatingOptions] = useState([]);
  const [happinessRating, setHappinessRating] = useState(0);
  const [noteError, setNoteError] = useState("");
  useEffect(() => {
    if (note?.length > 0) setNoteError("");
    if (note?.length < 0) setNoteError("Note field can't be empty");
  }, [note]);
  const user = useSelector((state) => state.user);
  useEffect(() => {
    api.get("/organization").then((res) => {
      console.log(res?.data);
      let org = res?.data;
      if (org?.leads_happiness_rating_choices) {
        setHappinessRatingOptions(org?.leads_happiness_rating_choices);
      }
    });
  }, []);
  useEffect(() => {
    api
      .get("/leadsNotes/" + selectedLead?.lead_serial_number)
      .then((res) => {
        console.log("res", res?.data);
        setAllNotes(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);
  const submitNote = () => {
    setNoteError("");
    if (note?.length < 1) {
      return setNoteError("Note field can't be empty");
      // return toast.error(`Note field can’t be empty`);
    }
    if (!happinessRating || happinessRating < 1 || happinessRating > 5) {
      return toast.error(`select happiness rating`);
    }
    let obj = {
      prospect_id: selectedLead?.lead_serial_number,
      uploaded_by: user?.id,
      note,
      happiness_rating: happinessRating,
    };
    api
      .post("/addLeadNote", obj)
      .then((res) => {
        console.log("response", res?.data);
        api.post("/addActivity", {
          prospect_id: selectedLead?.lead_serial_number,
          done_by: user?.id,
          action: "Note Added!",
          note: `Total Notes: ${allNotes?.length + 1}`,
        });
        setNote("");
        setHappinessRating(0);
        toast.success("note added successfully");
      })
      .catch((err) => toast.error("unable to add note"));
  };
  return (
    <Box>
      <ToastContainer />
      {viewMode ? (
        <ViewNotes setViewMode={setViewMode} selectedLead={selectedLead} />
      ) : (
        <Box
          sx={{ mt: 2, background: theme.palette.selectedMenuBack.main, p: 2 }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6" sx={{ color: theme.palette.primary.main }}>
              Notes
            </Typography>
            <Button variant="contained" onClick={() => setViewMode(!viewMode)}>
              {viewMode ? "Add a Note" : "View Notes"}
            </Button>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column", mt: 1 }}>
            <Typography variant="body2" sx={{ ml: 1, fontWeight: "700" }}>
              Enter Note
            </Typography>
            <Box sx={{ display: "flex" }}>
              <TextField
                placeholder="enter here..."
                sx={{
                  width: "100%",
                  padding: "10px",
                  fontFamily: "inherit",
                }}
                error={noteError}
                helperText={noteError && noteError}
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
            </Box>
          </Box>
          <Box sx={{ mt: 3, backround: "#fff", p: 2 }}>
            <Typography variant="h6" sx={{ color: theme.palette.primary.main }}>
              Happiness Rating
            </Typography>
            {user?.profile_type == "admin" && (
              <Typography
                variant="p"
                sx={{ color: theme.palette.para.main, fontSize: "0.8rem" }}
              >
                As an Admin, you can edit happiness rating choices from
                organization's general settings.
              </Typography>
            )}

            <TableContainer component={Paper} sx={{ mt: 2 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        width: "80px",
                        // color: theme.palette.colortwo.main,
                        fontSize: "0.9rem",
                      }}
                    ></TableCell>
                    <TableCell
                      sx={{
                        // color: theme.palette.colortwo.main,
                        fontSize: "0.9rem",
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          color: theme.palette.primary.main,
                          fontWeight: "600",
                        }}
                      >
                        How happy you are with this Lead?
                      </Typography>
                    </TableCell>
                    {/* <TableCell>Customer ID</TableCell> */}
                    <TableCell
                      sx={{
                        // color: theme.palette.colortwo.main,
                        fontSize: "0.9rem",
                      }}
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {happinessRatingOptions?.map((m, i) => (
                    <TableRow>
                      <TableCell sx={{ padding: "4px 20px" }}>
                        {i + 1}
                      </TableCell>
                      <TableCell sx={{ padding: "4px 20px" }}>
                        <Typography variant="p">{m}</Typography>
                      </TableCell>
                      <TableCell sx={{ padding: "4px 20px" }}>
                        <Button
                          color="primary"
                          onClick={() => setHappinessRating(i + 1)}
                          size="small"
                          sx={{
                            background:
                              // happinessRating == i
                              //   ? theme.palette.primary.main
                              //   :
                              theme.palette.selectedMenuBack.main,
                            p: 0.5,
                          }}
                        >
                          <Typography variant="p">
                            {" "}
                            {happinessRating == i + 1 ? "Selected" : "Select"}
                          </Typography>
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Button
            sx={{ width: "max-content", ml: 1, mt: 1 }}
            variant="contained"
            onClick={() => submitNote()}
          >
            Submit
          </Button>
        </Box>
      )}
    </Box>
    // <Box
    //   sx={{
    //     background: "#fff",
    //     p: 3,
    //     display: "flex",
    //     flexDirection: "column",
    //     gap: "10px",
    //     mt: 3,
    //   }}
    // >
    //   <ToastContainer />
    //   <Typography variant="body2">Enter Note</Typography>
    //   <Box sx={{ display: "flex" }}>
    //     <TextField
    //       placeholder="enter here..."
    //       sx={{
    //         width: "100%",
    //         padding: "10px",
    //         fontFamily: "inherit",
    //       }}
    //       value={note}
    //       onChange={(e) => setNote(e.target.value)}
    //     />
    //   </Box>
    //   <Box sx={{ mt: 3, p: 2, my: 1 }}>
    //     <Typography
    //       sx={{
    //         width: "100%",
    //         textAlign: "center",
    //         color: "#fff",
    //         background: theme.palette.primary.main,
    //         p: 0.8,
    //       }}
    //     >
    //       How happy are you with this lead today?
    //     </Typography>
    //     <Box>
    //       <Button
    //         color="primary"
    //         size="small"
    //         sx={{
    //           background:
    //             happinessRating == 1
    //               ? theme.palette.whiteFont.main
    //               : theme.palette.selectedMenuBack.main,
    //           mt: 2,
    //           width: "100%",
    //         }}
    //         onClick={() => setHappinessRating(1)}
    //       >
    //         <Typography variant="body2" sx={{ p: 1 }}>
    //           1. This is a low potential lead; there is very little to hope for.
    //           My spirit is crushed.
    //         </Typography>
    //       </Button>
    //       <Button
    //         color="primary"
    //         size="small"
    //         sx={{
    //           background:
    //             happinessRating == 2
    //               ? theme.palette.whiteFont.main
    //               : theme.palette.selectedMenuBack.main,
    //           mt: 2,
    //           width: "100%",
    //         }}
    //         onClick={() => setHappinessRating(2)}
    //       >
    //         <Typography variant="body2" sx={{ p: 1 }}>
    //           2. Definitely not where I want to be; needs a lot more effort.
    //           This one calls for patience.
    //         </Typography>
    //       </Button>
    //       <Button
    //         color="primary"
    //         size="small"
    //         sx={{
    //           background:
    //             happinessRating == 3
    //               ? theme.palette.whiteFont.main
    //               : theme.palette.selectedMenuBack.main,
    //           mt: 2,
    //           width: "100%",
    //         }}
    //         onClick={() => setHappinessRating(3)}
    //       >
    //         <Typography variant="body2" sx={{ p: 1 }}>
    //           3. The client fully understands what to expect from our services;
    //           there are no obvious signs that the client will chose us. This
    //           lead is borderline.
    //         </Typography>
    //       </Button>
    //       <Button
    //         color="primary"
    //         size="small"
    //         sx={{
    //           background:
    //             happinessRating == 4
    //               ? theme.palette.whiteFont.main
    //               : theme.palette.selectedMenuBack.main,
    //           mt: 2,
    //           width: "100%",
    //         }}
    //         onClick={() => setHappinessRating(4)}
    //       >
    //         <Typography variant="body2" sx={{ p: 1 }}>
    //           4. On a speed lane; a little bit more followups and I close the
    //           deal. Today I can smile.
    //         </Typography>
    //       </Button>
    //       <Button
    //         color="primary"
    //         size="small"
    //         sx={{
    //           background:
    //             happinessRating == 5
    //               ? theme.palette.whiteFont.main
    //               : theme.palette.selectedMenuBack.main,
    //           mt: 2,
    //           width: "100%",
    //         }}
    //         onClick={() => setHappinessRating(5)}
    //       >
    //         <Typography variant="body2" sx={{ p: 1 }}>
    //           5. Am over the moon; I am winning this lead any time now. My heart
    //           is throbbing.
    //         </Typography>
    //       </Button>
    //     </Box>
    //   </Box>

    //   <Button
    //     sx={{ width: "max-content" }}
    //     variant="contained"
    //     onClick={() => submitNote()}
    //   >
    //     Submit
    //   </Button>
    // </Box>
  );
};

export default AddNote;
