import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
  IconButton,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { api } from "../../../../axios/api";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material";
import { ThemeContext } from "@emotion/react";
import { Star } from "react-feather";

const Milestones = ({ selectedLead, setMilestoneLevel }) => {
  const theme = useTheme();
  const user = useSelector((state) => state.user);
  const [editMode, setEditMode] = useState(false);
  const [milestones, setMilestones] = useState([]);
  const [currentMilestone, setCurrentMilestone] = useState({});
  useEffect(() => {
    console.log("selected lead", selectedLead);
    console.log("user", user);
    setCurrentMilestone(
      milestones?.filter((m) => m?.level == selectedLead?.milestone_level)[0]
    );
  }, [milestones]);
  useEffect(() => {
    api
      .get("/organization")
      .then((res) => {
        console.log(res.data);
        let org = res?.data;
        if (org?.leads_milestones) {
          setMilestones(
            org?.leads_milestones?.map((l, i) => {
              return { level: i + 1, description: l };
            })
          );
        }
      })
      .catch((err) => console.log);
  }, []);

  const upgradeTo = (milestone) => {
    console.log("to be upgraded to", milestone);
    console.log("current milestone", currentMilestone);
    api
      .post("/updateMilestone", {
        prospect_id: selectedLead?.lead_serial_number,
        milestone_level: milestone?.level,
      })
      .then((res) => {
        setMilestoneLevel(milestone?.level);
        console.log(res?.data);
        toast.success("Milestone Updated Successfully!");
        api.post("/addActivity", {
          prospect_id: selectedLead?.lead_serial_number,
          done_by: user?.id,
          action: "Milestone Updated!",
          note: `Milestone: ${milestone?.level}`,
        });
        setCurrentMilestone(milestone);
      })
      .catch((err) => toast.error("unable to update milestone"));
  };

  return (
    <Box>
      {editMode ? (
        <EditBox />
      ) : (
        <Box
          sx={{ mt: 2, background: theme.palette.selectedMenuBack.main, p: 2 }}
        >
          <Typography variant="h6" sx={{ color: theme.palette.primary.main }}>
            Current Milestone
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Box
              sx={{
                flex: "0.2",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  border: "1px solid gold",
                  borderRadius: "50%",
                  width: "40px",
                  height: "40px",
                  textAlign: "center",
                }}
              >
                {currentMilestone?.level}
              </Typography>
            </Box>
            <Box sx={{ flex: 1, p: 3 }}>
              <Typography>{currentMilestone?.description}</Typography>
            </Box>
          </Box>
          <Box sx={{ mt: 3, backround: "#fff", p: 2 }}>
            <Box sx={{}}>
              {" "}
              <Typography
                variant="h6"
                sx={{ color: theme.palette.primary.main }}
              >
                Upgrade to
              </Typography>
              {user?.profile_type == "admin" && (
                <Typography
                  variant="p"
                  sx={{ fontSize: "0.8rem", color: theme.palette.para.main }}
                >
                  As an Admin, you can edit milestones information for the
                  organization in general settings.
                </Typography>
              )}
            </Box>

            <TableContainer component={Paper} sx={{ mt: 2 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        width: "80px",
                        // color: theme.palette.colortwo.main,
                        fontSize: "0.9rem",
                      }}
                    ></TableCell>
                    <TableCell
                      sx={{
                        // color: theme.palette.colortwo.main,
                        fontSize: "0.9rem",
                      }}
                    >
                      <Typography variant="body2">MILESTONES</Typography>
                    </TableCell>
                    {/* <TableCell>Customer ID</TableCell> */}
                    <TableCell
                      sx={{
                        // color: theme.palette.colortwo.main,
                        fontSize: "0.9rem",
                      }}
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {milestones
                    ?.filter((m) => m?.level != currentMilestone?.level)
                    ?.map((m) => (
                      <TableRow>
                        <TableCell sx={{ padding: "4px 20px" }}>
                          {m?.level}
                        </TableCell>
                        <TableCell sx={{ padding: "4px 20px" }}>
                          <Typography variant="p">{m?.description}</Typography>
                        </TableCell>
                        <TableCell sx={{ padding: "4px 20px" }}>
                          <Button
                            color="primary"
                            onClick={() => upgradeTo(m)}
                            size="small"
                            sx={{
                              background: theme.palette.selectedMenuBack.main,
                              p: 0.5,
                            }}
                          >
                            <Typography variant="p">
                              {" "}
                              {m?.level < currentMilestone?.level
                                ? "Downgrade"
                                : "Upgrade"}
                            </Typography>
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Milestones;

const EditBox = ({ editMode, selectedLead, milestones }) => {
  return (
    <Box>
      <Typography>Edit Milestones</Typography>
    </Box>
  );
};
