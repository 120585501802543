import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  IconButton,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { useTheme } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { api } from "../../../axios/api";
// // import { Scrollbars } from "react-custom-scrollbars-2";
import DeleteIcon from "@mui/icons-material/Delete";

const InfoColumn = ({
  type,
  organizationInformation,
  setOrganizationInformation,
  toast,
}) => {
  const theme = useTheme();
  const [showInput, setShowInput] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState("");

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    setError("");
  };

  const handleSubmit = () => {
    if (inputValue.length < 3 || inputValue.length > 15) {
      setError("Input length should be between 3 and 15 characters.");
    } else if (
      organizationInformation[`${type}`] &&
      organizationInformation[`${type}`]?.includes(
        inputValue?.toLocaleLowerCase()
      )
    ) {
      setError("Already exist");
    } else {
      console.log("Submitted value:", inputValue);
      console.log("organization information", organizationInformation);
      console.log(
        "organizationInformation[`${type}`]",
        organizationInformation[`${type}`]
      );
      let array = organizationInformation[`${type}`]
        ? organizationInformation[`${type}`]
        : [];
      array?.push(inputValue?.toLocaleLowerCase());
      console.log("array", array);
      console.log("pushobj", {
        [type]: array,
      });

      api
        .post("/organization/edit/" + organizationInformation?.id, {
          [type]: array,
        })
        .then((res) => {
          console.log("res?.data", res?.data);
          setOrganizationInformation(res?.data);
          setShowInput(false);
          setInputValue("");
          toast.success("added successfully!");
        })
        .catch((err) => {
          console.log(err);
          toast.error("unable to add !");
        });
    }
  };

  const handleCancel = () => {
    setInputValue("");
    setError("");
    setShowInput(false);
  };

  const handleDelete = (value) => {
    console.log(value);
    let array = organizationInformation[`${type}`]
      ? organizationInformation[`${type}`]
      : [];
    array = array?.filter((a) => a !== value?.toLocaleLowerCase());
    console.log("array", array);
    console.log("pushobj", {
      [type]: array,
    });

    api
      .post("/organization/edit/" + organizationInformation?.id, {
        [type]: array,
      })
      .then((res) => {
        console.log("res?.data", res?.data);
        setOrganizationInformation(res?.data);
        setShowInput(false);
        setInputValue("");
        toast.success("deleted successfully!");
      })
      .catch((err) => {
        console.log(err);
        toast.error("unable to delete!");
      });
  };
  return (
    <Grid
      item
      xs={12}
      sm={3.6}
      sx={{
        background: theme.palette.background.main,
        borderRadius: "4px",
        padding: "20px 10px",
        maxHeight: "100vh",
        overflowY: "auto",
      }}
    >
      {/* <Scrollbars> */}
      <>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Typography varinat="h6" sx={{ color: theme.palette.heading.main }}>
              {type == "lead_sources"
                ? "Lead Sources"
                : type == "industries"
                ? "Industries"
                : "Services"}
            </Typography>

            <Tooltip
              title={
                type == "services"
                  ? "Which services do you offer? e.g. Tax advice, litigation, graphic design e.t.c"
                  : type == "industries"
                  ? "Which industries do you serve? e.g. Manufacturing Financial services, Nonprofits e.t.c"
                  : "Where do you plan to get sales leads from? e.g. referrals, facebook, advertisements e.t.c"
              }
              placement="top-start"
              sx={{ ml: 2, color: theme.palette.para.main }}
            >
              <InfoIcon />
            </Tooltip>
          </Box>
          <IconButton
            sx={{ color: theme.palette.colortwo.main }}
            onClick={() => setShowInput(true)}
          >
            <AddCircleIcon />
          </IconButton>
        </Box>
        {showInput && (
          <Box>
            <TextField
              label={
                type == "lead_sources"
                  ? "Add Lead Source"
                  : type == "industries"
                  ? "Add Industry"
                  : "Add Service"
              }
              placeholder="Enter Here"
              value={inputValue}
              onChange={handleInputChange}
              error={!!error}
              helperText={error}
              sx={{ mt: 1, width: "100%" }}
            />
            <Box sx={{ mt: 1, mb: 1, display: "flex", gap: "5px" }}>
              <Button variant="contained" size="small" onClick={handleSubmit}>
                Submit
              </Button>
              <Button variant="contained" size="small" onClick={handleCancel}>
                Cancel
              </Button>
            </Box>
          </Box>
        )}

        {organizationInformation[`${type}`]?.length > 0 ? (
          <TableContainer component={Paper} sx={{ marginTop: "20px" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      width: "50px",
                      color: theme.palette.colortwo.main,
                    }}
                  >
                    ID
                  </TableCell>
                  {/* <TableCell>Customer ID</TableCell> */}
                  <TableCell
                    sx={{
                      color: theme.palette.colortwo.main,
                    }}
                  >
                    {type == "lead_sources"
                      ? "Lead Sources"
                      : type == "industries"
                      ? "Industries"
                      : "Services"}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {organizationInformation[`${type}`]?.map((value, i) => (
                  <TableRow
                    key={8}
                    style={{
                      backgroundColor:
                        i % 2 == 0
                          ? theme.palette.colorthree.main
                          : "transparent",
                    }}
                  >
                    <TableCell sx={{ width: "50px" }}>{i + 1}</TableCell>
                    <TableCell>{value}</TableCell>
                    <TableCell
                      sx={{
                        color: theme.palette.colortwo.main,
                      }}
                    >
                      <IconButton onClick={() => handleDelete(value)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography
            varinant="body2"
            sx={{ color: theme.palette.colortwo?.main, mt: 2 }}
          >
            No entries
          </Typography>
        )}
      </>
      {/* </Scrollbars> */}
    </Grid>
  );
};

export default InfoColumn;
