import * as React from "react";
import { useState, useEffect } from "react";
import TokenIcon from "@mui/icons-material/Token";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Avatar, CircularProgress, Modal } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Drawer from "@mui/material/Drawer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import logo from "../utils/images/edge.jpg";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";

import Logout from "@mui/icons-material/Logout";

import { Hash, Search, UserPlus } from "react-feather";
import { InputBase } from "@mui/material";
import {
  AlignLeft,
  Home,
  Package,
  Bell,
  Settings,
  BarChart2,
  Users,
  LogOut,
  Truck,
  ShoppingCart,
  ArrowDownCircle,
  ArrowUpCircle,
  Upload,
  Download,
  User,
  BookOpen,
  Server,
  Clipboard,
  Filter,
  users,
  Sunrise,
  Monitor,
  Radio,
} from "react-feather";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setUser, setToken } from "../actions/userActions";
import { useNavigate } from "react-router-dom";

// import SearchInput from "../components/SearchInput";
// import SearchComponent from "../components/SearchInput";
import { api, setAuthToken } from "../axios/api";
import SearchInput from "../components/CRM/SearchInput";
// import CompanyFormModal from "../components/CompanyFormModal";
// import LanguageDropdown from "../components/Dropdowns/LanguageDropdown";
import { Scrollbars } from "react-custom-scrollbars-2";
import logo from "../utils/images/edge.jpg";
import Notifications from "./Notifications";

const StyledListItem = styled(ListItem)(({ theme }) => ({
  fontSize: "15px !important",
  color: theme.palette.menuItem.main,
  "&:hover": {
    backgroundColor: theme.palette.selectedMenuBack.main,
  },
  "&:active": {
    color: theme.palette.selectedMenuFont.main,
    backgroundColor: theme.palette.selectedMenuBack.main,
  },
}));

const drawerWidth = 260;

function CRMLayout(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isCompanyDataExist, setIsCompanyDataExist] = useState({});
  const user = useSelector((state) => state.user);
  console.log("user", user);
  const selecteDashboardTabFromStore = useSelector(
    (state) => state?.dashboard_selected_tab
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    function fetchUserData() {
      api
        .get("/getUserData")
        .then((res) => {
          console.log("response from getting user ", res.data);
          if (
            selecteDashboardTabFromStore == "subscriptions" &&
            res?.data?.profile_type == "admin"
          ) {
            dispatch({
              type: "SET_Dashboard_Tab",
              payload: "",
            });
            dispatch(setUser(res.data));
            return setSelectedItem("subscriptions");
          }
          dispatch(setUser(res.data));
          setSelectedItem(
            res?.data?.email_address == "admin@edge360.co"
              ? "adminAnalytics"
              : res?.data?.profile_type == "admin"
              ? "analytics"
              : res?.data?.profile_type == "agent"
              ? "agentAnalytics"
              : res?.data?.authorized_to_company_details
              ? "analytics"
              : "accountSettings"
          );
          setIsCompanyDataExist(res?.data?.company_id ? true : false);
        })
        .catch((err) => console.log("err from getting user", err));
    }
    fetchUserData();
    setTimeout(() => {
      fetchUserData();
    }, 5000);
  }, []);
  useEffect(() => {
    function fetchUserSubscriptionData() {
      api
        .get("/user/subscription")
        .then((res) => {
          console.log("response from getting user subscriptio", res.data);
          dispatch({
            type: "SET_Subscription_Data",
            payload: res?.data,
          });
        })
        .catch((err) => console.log("err from getting user", err));
    }
    fetchUserSubscriptionData();
    setTimeout(() => {
      fetchUserSubscriptionData();
    }, 5000);
  }, [user]);

  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  // user?.profile_type == "admin"
  //   ? "dashboard"
  //   : user?.authorized_to_company_details
  //   ? "dashboard"
  //   : "accountSettings"
  useEffect(() => {
    selectedItem && mobileOpen && setMobileOpen(false);
  }, [selectedItem]);

  const [showProfileSubmenu, setShowProfileSubmenu] = useState(false);
  const [showOrganizationSubmenu, setShowOrganizationSubmenu] = useState(false);
  const [showReportsSubMenu, setShowReportsSubMenu] = useState(false);
  const [showAgentsSubMenu, setShowAgentsSubMenu] = useState(false);
  const agentMenuItems = [
    {
      label: "Analytics",
      value: "agentAnalytics",
      icon: (
        <BarChart2
          size={17}
          //   color={theme.palette.menuHeading.main}
          strokeWidth={1.5}
        />
      ),
    },
  ];
  const adminMenuItems = [
    {
      label: "Analytics",
      value: "adminAnalytics",
      icon: (
        <BarChart2
          size={17}
          //   color={theme.palette.menuHeading.main}
          strokeWidth={1.5}
        />
      ),
    },
    {
      label: "Billboard",
      value: "adminBillboard",
      icon: (
        <Clipboard
          size={17}
          //   color={theme.palette.menuHeading.main}
          strokeWidth={1.5}
        />
      ),
    },
    {
      label: "Stories",
      value: "adminStories",
      icon: (
        <Sunrise
          size={17}
          //   color={theme.palette.menuHeading.main}
          strokeWidth={1.5}
        />
      ),
    },
    {
      label: "Coupons",
      value: "coupons",
      icon: (
        <TokenIcon
          size={17}
          //   color={theme.palette.menuHeading.main}
          strokeWidth={1.5}
        />
      ),
    },
    {
      label: "Ads",
      value: "manageAds",
      icon: (
        <Monitor
          size={17}
          //   color={theme.palette.menuHeading.main}
          strokeWidth={1.5}
        />
      ),
    },
    {
      label: "Tickets",
      value: "adminSupportTickets",
      icon: (
        <Hash
          size={17}
          //   color={theme.palette.menuHeading.main}
          strokeWidth={1.5}
        />
      ),
    },
    {
      label: "Agents",
      value: "agents",
      icon: (
        <Radio
          size={17}
          //   color={theme.palette.menuHeading.main}
          strokeWidth={1.5}
        />
      ),
    },
  ];
  const menuItems = [
    {
      label: "Dashboard",
      value: "analytics",
      icon: (
        <BarChart2
          size={17}
          //   color={theme.palette.menuHeading.main}
          strokeWidth={1.5}
        />
      ),
    },
    {
      label: "Profile",
      value: "profile",
      icon: (
        <User
          size={17}
          //   color={theme.palette.menuHeading.main}
          strokeWidth={1.5}
        />
      ),
    },
    {
      label: "Organization",
      value: "organization",
      icon: (
        <Server
          size={17}
          //   color={theme.palette.menuHeading.main}
          strokeWidth={1.5}
        />
      ),
    },
    {
      label: "Leads",
      value: "prospects",
      icon: (
        <Users
          size={17}
          //   color={theme.palette.menuHeading.main}
          strokeWidth={1.5}
        />
      ),
    },
    {
      label: "Leads Pipeline",
      value: "leadsPipeline",
      icon: (
        <Filter
          size={17}
          //   color={theme.palette.menuHeading.main}
          strokeWidth={1.5}
        />
      ),
    },
    {
      label: "Network",
      value: "relationships",
      icon: (
        <Users
          size={17}
          //   color={theme.palette.menuHeading.main}
          strokeWidth={1.5}
        />
      ),
    },

    {
      label: "Billboard",
      value: "billboard",
      icon: (
        <Clipboard
          size={17}
          //   color={theme.palette.menuHeading.main}
          strokeWidth={1.5}
        />
      ),
    },
    {
      label: "Reports",
      value: "reports",
      icon: (
        <BookOpen
          size={17}
          //   color={theme.palette.menuHeading.main}
          strokeWidth={1.5}
        />
      ),
    },
  ];

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleItemClick = (item) => {
    console.log("item", item);
    setSelectedItem(item?.value);
  };
  const logout = async () => {
    await api.post("/users/toggle-login/" + user?.id, { loggedIn: false });
    dispatch(setUser({}));
    dispatch(setToken(""));
    navigate("/auth/login");
    setAuthToken("");
    localStorage.setItem("user", null);
    localStorage.setItem("token", null);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openProfileDropdown = Boolean(anchorEl);
  const handleProfileDropdownClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleProfileDropdownClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    console.log("show Agent submeni", showAgentsSubMenu);
  }, [showAgentsSubMenu]);
  const drawer = (
    <Scrollbars>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
          paddingBottom: "100px",

          // borderRight: "1px solid lightgray",
        }}
      >
        <Toolbar
          sx={{
            // background: theme.palette.colortwo.main,
            // outlineRight: "2px solid white",
            position: "fixed",
            width: `${drawerWidth}px`,
            zIndex: 10,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: `${drawerWidth}px`,
              pt: 3,
              gap: "5px",
              background: "#fff",
              // backgroundColor: theme.palette.colortwo.main,
              cursor: "pointer",
              zIndex: 1,
            }}
            onClick={() => {
              navigate("/");
            }}
          >
            {" "}
            {/* <Typography
              sx={{
                color: theme.palette.whiteFont.main,
                fontWeight: "600",
                fontSize: "1.5rem",
                paddingLeft: "10px",
                zIndex: 1,
              }}
            >
              Edge360
            </Typography> */}
            <Box
              sx={{ flex: "0.3", cursor: "pointer" }}
              onClick={() => navigate("/")}
            >
              <img style={{ height: "50px" }} src={logo} />
            </Box>
            {/* <Box
            sx={{
              margin: "10px 0",
              display: "flex",
              justifyContent: "center",
              width: "100%",
              background: theme.palette.colortwo.main,
            }}
          >
            <img src={logo} style={{ width: "110px" }} />
          </Box> */}
          </Box>
        </Toolbar>
        {/* <Divider /> */}

        {/* <Typography
        variant="h6"
        sx={{
          color: theme.palette.menuHeading.main,
          paddingLeft: "26px",
          fontWeight: "600",
          fontSize: "0.8rem",
          marginTop: "30px",
          marginBottom: "12px",
          wordSpacing: "2.5px",
        }}
      >
        MAIN MENU
      </Typography> */}

        <List sx={{ padding: "0 18px", marginTop: "100px" }}>
          {/* <Scrollbars style={{}}> */}
          {user?.profile_type == "agent" &&
            agentMenuItems?.map((item) => (
              <StyledListItem
                key={item?.value}
                disablePadding
                sx={{ display: "block", marginBottom: "15px" }}
                selected={selectedItem === item?.value}
                onClick={() => handleItemClick(item)}
              >
                <ListItemButton
                  sx={{
                    minHeight: 30,
                    justifyContent: "initial",
                    px: 1.5,
                    py: 1.3,
                    backgroundColor:
                      selectedItem === item?.value
                        ? theme.palette.selectedMenuBack.main
                        : "transparent",
                    color:
                      selectedItem === item?.value &&
                      theme.palette.selectedMenuFont.main,
                    "&:hover": {
                      backgroundColor: theme.palette.selectedMenuBack.main,
                    },

                    borderRadius: "3px",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: 1.7,
                      zIndex: -1,
                      justifyContent: "center",
                      color:
                        selectedItem === item?.value
                          ? theme.palette.selectedMenuFont.main
                          : theme.palette.menuHeading.main,
                    }}
                  >
                    {item?.icon}
                  </ListItemIcon>

                  <Typography
                    style={{
                      opacity: 1,
                      zIndex: -1,
                      fontWeight: selectedItem === item?.value ? 400 : 400,
                      fontSize: selectedItem === item?.value ? "14px" : "14px",
                      color:
                        selectedItem === item?.value
                          ? theme.palette.selectedMenuFont.main
                          : theme.palette.menuItem.main,
                    }}
                  >
                    {" "}
                    {item?.label}
                  </Typography>
                </ListItemButton>
              </StyledListItem>
            ))}
          {user?.email_address == "admin@edge360.co" &&
            adminMenuItems?.map((item, index) => (
              <Box>
                {item?.value == "agents" ? (
                  <>
                    <StyledListItem
                      key={item?.value}
                      disablePadding
                      sx={{ display: "block", marginBottom: "15px" }}
                      selected={selectedItem === item?.value}
                      onClick={() => {
                        // handleItemClick(item);
                        setShowAgentsSubMenu(!showAgentsSubMenu);
                        console.log("hurrrraahhhh");
                      }}
                    >
                      <ListItemButton
                        sx={{
                          minHeight: 30,
                          justifyContent: "initial",
                          px: 1.5,
                          py: 1.3,
                          backgroundColor:
                            selectedItem === item?.value
                              ? theme.palette.selectedMenuBack.main
                              : "transparent",
                          color:
                            selectedItem === item?.value &&
                            theme.palette.selectedMenuFont.main,
                          "&:hover": {
                            backgroundColor:
                              theme.palette.selectedMenuBack.main,
                          },

                          borderRadius: "3px",
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: 1.7,
                            zIndex: -1,
                            justifyContent: "center",
                            color:
                              selectedItem === item?.value
                                ? theme.palette.selectedMenuFont.main
                                : theme.palette.menuHeading.main,
                          }}
                        >
                          {item?.icon}
                        </ListItemIcon>

                        <Typography
                          style={{
                            opacity: 1,
                            zIndex: -1,
                            fontWeight:
                              selectedItem === item?.value ? 400 : 400,
                            fontSize:
                              selectedItem === item?.value ? "14px" : "14px",
                            color:
                              selectedItem === item?.value
                                ? theme.palette.selectedMenuFont.main
                                : theme.palette.menuItem.main,
                          }}
                        >
                          {" "}
                          {item?.label}
                        </Typography>
                      </ListItemButton>
                    </StyledListItem>
                    {showAgentsSubMenu && (
                      <Box sx={{ marginLeft: "25px", marginBottom: "16px" }}>
                        <StyledListItem
                          key={"createNewAgent"}
                          disablePadding
                          sx={{ display: "block", marginBottom: "0px" }}
                          selected={selectedItem === "createNewAgent"}
                          onClick={() =>
                            handleItemClick({
                              value: "createNewAgent",
                            })
                          }
                        >
                          <ListItemButton>
                            <Typography
                              style={{
                                zIndex: -1,
                                opacity: 1,
                                fontWeight:
                                  selectedItem === "createNewAgent" ? 400 : 400,
                                fontSize: "13px",
                                color:
                                  selectedItem === "createNewAgent"
                                    ? theme.palette.selectedMenuFont.main
                                    : theme.palette.menuItem.main,
                              }}
                            >
                              Create Agent
                            </Typography>
                          </ListItemButton>
                        </StyledListItem>{" "}
                        <StyledListItem
                          key={"listOfAgents"}
                          disablePadding
                          sx={{ display: "block", marginBottom: "0px" }}
                          selected={selectedItem === "listOfAgents"}
                          onClick={() =>
                            handleItemClick({
                              value: "listOfAgents",
                            })
                          }
                        >
                          <ListItemButton>
                            <Typography
                              style={{
                                zIndex: -1,
                                opacity: 1,
                                fontWeight:
                                  selectedItem === "listOfAgents" ? 400 : 400,
                                fontSize: "13px",
                                color:
                                  selectedItem === "listOfAgents"
                                    ? theme.palette.selectedMenuFont.main
                                    : theme.palette.menuItem.main,
                              }}
                            >
                              List of Agents
                            </Typography>
                          </ListItemButton>
                        </StyledListItem>{" "}
                        <StyledListItem
                          key={"agentPrice"}
                          disablePadding
                          sx={{ display: "block", marginBottom: "0px" }}
                          selected={selectedItem === "agentPrice"}
                          onClick={() =>
                            handleItemClick({
                              value: "agentPrice",
                            })
                          }
                        >
                          <ListItemButton>
                            <Typography
                              style={{
                                zIndex: -1,
                                opacity: 1,
                                fontWeight:
                                  selectedItem === "agentPrice" ? 400 : 400,
                                fontSize: "13px",
                                color:
                                  selectedItem === "agentPrice"
                                    ? theme.palette.selectedMenuFont.main
                                    : theme.palette.menuItem.main,
                              }}
                            >
                              Agent Price
                            </Typography>
                          </ListItemButton>
                        </StyledListItem>{" "}
                      </Box>
                    )}
                  </>
                ) : (
                  <>
                    <StyledListItem
                      key={item?.value}
                      disablePadding
                      sx={{ display: "block", marginBottom: "15px" }}
                      selected={selectedItem === item?.value}
                      onClick={() => handleItemClick(item)}
                    >
                      <ListItemButton
                        sx={{
                          minHeight: 30,
                          justifyContent: "initial",
                          px: 1.5,
                          py: 1.3,
                          backgroundColor:
                            selectedItem === item?.value
                              ? theme.palette.selectedMenuBack.main
                              : "transparent",
                          color:
                            selectedItem === item?.value &&
                            theme.palette.selectedMenuFont.main,
                          "&:hover": {
                            backgroundColor:
                              theme.palette.selectedMenuBack.main,
                          },

                          borderRadius: "3px",
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: 1.7,
                            zIndex: -1,
                            justifyContent: "center",
                            color:
                              selectedItem === item?.value
                                ? theme.palette.selectedMenuFont.main
                                : theme.palette.menuHeading.main,
                          }}
                        >
                          {item?.icon}
                        </ListItemIcon>

                        <Typography
                          style={{
                            opacity: 1,
                            zIndex: -1,
                            fontWeight:
                              selectedItem === item?.value ? 400 : 400,
                            fontSize:
                              selectedItem === item?.value ? "14px" : "14px",
                            color:
                              selectedItem === item?.value
                                ? theme.palette.selectedMenuFont.main
                                : theme.palette.menuItem.main,
                          }}
                        >
                          {" "}
                          {item?.label}
                        </Typography>
                      </ListItemButton>
                    </StyledListItem>
                  </>
                )}
              </Box>
            ))}
          {user?.email_address != "admin@edge360.co" &&
            user?.profile_type != "agent" &&
            menuItems?.map((item, index) => (
              <Box>
                {item?.value == "profile" ? (
                  <>
                    {" "}
                    <ListItemButton
                      onClick={() => setShowProfileSubmenu(!showProfileSubmenu)}
                      sx={{
                        minHeight: 30,
                        justifyContent: "initial",
                        px: 1.5,
                        py: 1.3,
                        marginBottom: showProfileSubmenu ? "0px" : "12px",
                        backgroundColor:
                          selectedItem === item?.value
                            ? theme.palette.selectedMenuBack.main
                            : "transparent",
                        color:
                          selectedItem === item?.value &&
                          theme.palette.selectedMenuFont.main,
                        "&:hover": {
                          backgroundColor: theme.palette.selectedMenuBack.main,
                        },

                        borderRadius: "3px",
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: 1.7,
                          justifyContent: "center",
                          color:
                            selectedItem === item?.value
                              ? theme.palette.selectedMenuFont.main
                              : theme.palette.menuHeading.main,
                          zIndex: -1,
                        }}
                      >
                        {item?.icon}
                      </ListItemIcon>

                      <Typography
                        style={{
                          opacity: 1,
                          fontWeight: selectedItem === item?.value ? 400 : 400,
                          fontSize:
                            selectedItem === item?.value ? "14px" : "14px",
                          color:
                            selectedItem === item?.value
                              ? theme.palette.selectedMenuFont.main
                              : theme.palette.menuItem.main,
                          zIndex: -1,
                        }}
                      >
                        {" "}
                        {item?.label}
                      </Typography>
                    </ListItemButton>
                    {showProfileSubmenu && (
                      <Box sx={{ marginLeft: "25px", marginBottom: "16px" }}>
                        <StyledListItem
                          key={"accountSettings"}
                          disablePadding
                          sx={{ display: "block", marginBottom: "0px" }}
                          selected={selectedItem === "accountSettings"}
                          onClick={() =>
                            handleItemClick({ value: "accountSettings" })
                          }
                        >
                          <ListItemButton>
                            <Typography
                              style={{
                                zIndex: -1,
                                opacity: 1,
                                fontWeight:
                                  selectedItem === "accountSettings"
                                    ? 400
                                    : 400,
                                fontSize: "13px",
                                color:
                                  selectedItem === "accountSettings"
                                    ? theme.palette.selectedMenuFont.main
                                    : theme.palette.menuItem.main,
                              }}
                            >
                              Account Settings
                            </Typography>
                          </ListItemButton>
                        </StyledListItem>
                        {user?.profile_type != "employee" && (
                          <StyledListItem
                            key={"subscriptions"}
                            disablePadding
                            sx={{ display: "block", marginBottom: "0px" }}
                            selected={selectedItem === "subscriptions"}
                            onClick={() =>
                              handleItemClick({ value: "subscriptions" })
                            }
                          >
                            <ListItemButton>
                              <Typography
                                style={{
                                  zIndex: -1,
                                  opacity: 1,
                                  fontWeight:
                                    selectedItem === "faqs" ? 400 : 400,
                                  fontSize: "13px",
                                  color:
                                    selectedItem === "subscriptions"
                                      ? theme.palette.selectedMenuFont.main
                                      : theme.palette.menuItem.main,
                                }}
                              >
                                Subscriptions
                              </Typography>
                            </ListItemButton>
                          </StyledListItem>
                        )}

                        <StyledListItem
                          key={"faqs"}
                          disablePadding
                          sx={{ display: "block", marginBottom: "0px" }}
                          selected={selectedItem === "faqs"}
                          onClick={() => handleItemClick({ value: "faqs" })}
                        >
                          <ListItemButton>
                            <Typography
                              style={{
                                zIndex: -1,
                                opacity: 1,
                                fontWeight: selectedItem === "faqs" ? 400 : 400,
                                fontSize: "13px",
                                color:
                                  selectedItem === "faqs"
                                    ? theme.palette.selectedMenuFont.main
                                    : theme.palette.menuItem.main,
                              }}
                            >
                              FAQs
                            </Typography>
                          </ListItemButton>
                        </StyledListItem>
                        <StyledListItem
                          key={"contactSupport"}
                          disablePadding
                          sx={{ display: "block", marginBottom: "0px" }}
                          selected={selectedItem === "contactSupport"}
                          onClick={() =>
                            handleItemClick({ value: "contactSupport" })
                          }
                        >
                          <ListItemButton>
                            {" "}
                            <Typography
                              style={{
                                zIndex: -1,
                                opacity: 1,
                                fontWeight:
                                  selectedItem === "contactSupport" ? 400 : 400,
                                fontSize: "13px",
                                color:
                                  selectedItem === "contactSupport"
                                    ? theme.palette.selectedMenuFont.main
                                    : theme.palette.menuItem.main,
                              }}
                            >
                              Contact Support
                            </Typography>
                          </ListItemButton>
                        </StyledListItem>
                        <StyledListItem
                          key="logout"
                          disablePadding
                          sx={{ display: "block", marginBottom: "0px" }}
                          selected={selectedItem === item?.value}
                          onClick={() => logout()}
                        >
                          <ListItemButton>
                            {" "}
                            <Typography
                              style={{
                                zIndex: -1,
                                opacity: 1,
                                fontWeight:
                                  selectedItem === item?.value ? 400 : 400,
                                fontSize: "13px",
                              }}
                            >
                              Logout
                            </Typography>
                          </ListItemButton>
                        </StyledListItem>
                      </Box>
                    )}
                  </>
                ) : item?.value == "organization" ? (
                  <>
                    {user?.profile_type == "admin" && (
                      <>
                        {" "}
                        <ListItemButton
                          onClick={() =>
                            setShowOrganizationSubmenu(!showOrganizationSubmenu)
                          }
                          sx={{
                            minHeight: 30,
                            justifyContent: "initial",
                            px: 1.5,
                            py: 1.3,
                            marginBottom: showProfileSubmenu ? "0px" : "12px",
                            backgroundColor:
                              selectedItem === item?.value
                                ? theme.palette.selectedMenuBack.main
                                : "transparent",
                            color:
                              selectedItem === item?.value &&
                              theme.palette.selectedMenuFont.main,
                            "&:hover": {
                              backgroundColor:
                                theme.palette.selectedMenuBack.main,
                            },

                            borderRadius: "3px",
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              zIndex: -1,
                              minWidth: 0,
                              mr: 1.7,
                              justifyContent: "center",
                              color:
                                selectedItem === item?.value
                                  ? theme.palette.selectedMenuFont.main
                                  : theme.palette.menuHeading.main,
                            }}
                          >
                            {item?.icon}
                          </ListItemIcon>

                          <Typography
                            style={{
                              zIndex: -1,
                              opacity: 1,
                              fontWeight:
                                selectedItem === item?.value ? 400 : 400,
                              fontSize:
                                selectedItem === item?.value ? "14px" : "14px",
                              color:
                                selectedItem === item?.value
                                  ? theme.palette.selectedMenuFont.main
                                  : theme.palette.menuItem.main,
                            }}
                          >
                            {" "}
                            {item?.label}
                          </Typography>
                        </ListItemButton>
                        {showOrganizationSubmenu && (
                          <Box
                            sx={{ marginLeft: "25px", marginBottom: "16px" }}
                          >
                            <StyledListItem
                              key={"organizationGeneralSettings"}
                              disablePadding
                              sx={{ display: "block", marginBottom: "0px" }}
                              selected={
                                selectedItem === "organizationGeneralSettings"
                              }
                              onClick={() =>
                                handleItemClick({
                                  value: "organizationGeneralSettings",
                                })
                              }
                            >
                              <ListItemButton>
                                <Typography
                                  style={{
                                    zIndex: -1,
                                    opacity: 1,
                                    fontWeight:
                                      selectedItem ===
                                      "organizationGeneralSettings"
                                        ? 400
                                        : 400,
                                    fontSize: "13px",
                                    color:
                                      selectedItem ===
                                      "organizationGeneralSettings"
                                        ? theme.palette.selectedMenuFont.main
                                        : theme.palette.menuItem.main,
                                  }}
                                >
                                  General Settings
                                </Typography>
                              </ListItemButton>
                            </StyledListItem>

                            <StyledListItem
                              key={"addUsers"}
                              disablePadding
                              sx={{ display: "block", marginBottom: "0px" }}
                              selected={selectedItem === "addUsers"}
                              onClick={() =>
                                handleItemClick({ value: "addUsers" })
                              }
                            >
                              <ListItemButton>
                                <Typography
                                  style={{
                                    opacity: 1,
                                    zIndex: -1,
                                    fontWeight:
                                      selectedItem === "addUsers" ? 400 : 400,
                                    fontSize: "13px",
                                    color:
                                      selectedItem === "addUsers"
                                        ? theme.palette.selectedMenuFont.main
                                        : theme.palette.menuItem.main,
                                  }}
                                >
                                  Team members
                                </Typography>
                              </ListItemButton>
                            </StyledListItem>
                            <StyledListItem
                              key={"setTargetsForPartners"}
                              disablePadding
                              sx={{ display: "block", marginBottom: "0px" }}
                              selected={
                                selectedItem === "setTargetsForPartners"
                              }
                              onClick={() =>
                                handleItemClick({
                                  value: "setTargetsForPartners",
                                })
                              }
                            >
                              <ListItemButton>
                                <Typography
                                  style={{
                                    zIndex: -1,
                                    opacity: 1,
                                    fontWeight:
                                      selectedItem === "setTargetsForPartners"
                                        ? 400
                                        : 400,
                                    fontSize: "13px",
                                    color:
                                      selectedItem === "setTargetsForPartners"
                                        ? theme.palette.selectedMenuFont.main
                                        : theme.palette.menuItem.main,
                                  }}
                                >
                                  Set Targets
                                </Typography>
                              </ListItemButton>
                            </StyledListItem>

                            {/* <StyledListItem
                              key={"faqs"}
                              disablePadding
                              sx={{ display: "block", marginBottom: "0px" }}
                              selected={selectedItem === "faqs"}
                              onClick={() => handleItemClick({ value: "faqs" })}
                            >
                              <ListItemButton>
                                <Typography
                                  style={{
                                    opacity: 1,
                                    zIndex: -1,
                                    fontWeight:
                                      selectedItem === "faqs" ? 400 : 400,
                                    fontSize: "13px",
                                    color:
                                      selectedItem === "faqs"
                                        ? theme.palette.selectedMenuFont.main
                                        : theme.palette.menuItem.main,
                                  }}
                                >
                                  FAQs
                                </Typography>
                              </ListItemButton>
                            </StyledListItem> */}
                          </Box>
                        )}
                      </>
                    )}
                  </>
                ) : item?.value == "reports" ? (
                  <>
                    {user?.profile_type == "admin" && (
                      <>
                        {" "}
                        <ListItemButton
                          onClick={() =>
                            setShowReportsSubMenu(!showReportsSubMenu)
                          }
                          sx={{
                            minHeight: 30,
                            justifyContent: "initial",
                            px: 1.5,
                            py: 1.3,
                            marginBottom: showProfileSubmenu ? "0px" : "12px",
                            backgroundColor:
                              selectedItem === item?.value
                                ? theme.palette.selectedMenuBack.main
                                : "transparent",
                            color:
                              selectedItem === item?.value &&
                              theme.palette.selectedMenuFont.main,
                            "&:hover": {
                              backgroundColor:
                                theme.palette.selectedMenuBack.main,
                            },

                            borderRadius: "3px",
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              zIndex: -1,
                              minWidth: 0,
                              mr: 1.7,
                              justifyContent: "center",
                              color:
                                selectedItem === item?.value
                                  ? theme.palette.selectedMenuFont.main
                                  : theme.palette.menuHeading.main,
                            }}
                          >
                            {item?.icon}
                          </ListItemIcon>

                          <Typography
                            style={{
                              zIndex: -1,
                              opacity: 1,
                              fontWeight:
                                selectedItem === item?.value ? 400 : 400,
                              fontSize:
                                selectedItem === item?.value ? "14px" : "14px",
                              color:
                                selectedItem === item?.value
                                  ? theme.palette.selectedMenuFont.main
                                  : theme.palette.menuItem.main,
                            }}
                          >
                            {" "}
                            {item?.label}
                          </Typography>
                        </ListItemButton>
                        {showReportsSubMenu && (
                          <Box
                            sx={{ marginLeft: "25px", marginBottom: "16px" }}
                          >
                            <StyledListItem
                              key={"viewReports"}
                              disablePadding
                              sx={{ display: "block", marginBottom: "0px" }}
                              selected={selectedItem === "viewReports"}
                              onClick={() =>
                                handleItemClick({ value: "viewReports" })
                              }
                            >
                              <ListItemButton>
                                <Typography
                                  style={{
                                    opacity: 1,
                                    zIndex: -1,
                                    fontWeight:
                                      selectedItem === "viewReports"
                                        ? 400
                                        : 400,
                                    fontSize: "13px",
                                    color:
                                      selectedItem === "viewReports"
                                        ? theme.palette.selectedMenuFont.main
                                        : theme.palette.menuItem.main,
                                  }}
                                >
                                  View Reports
                                </Typography>
                              </ListItemButton>
                            </StyledListItem>
                          </Box>
                        )}
                      </>
                    )}
                  </>
                ) : item?.value == "analytics" &&
                  user?.profile_type != "admin" &&
                  !user?.authorized_to_company_details ? (
                  <></>
                ) : (
                  <>
                    {" "}
                    <StyledListItem
                      key={item?.value}
                      disablePadding
                      sx={{ display: "block", marginBottom: "15px" }}
                      selected={selectedItem === item?.value}
                      onClick={() => handleItemClick(item)}
                    >
                      <ListItemButton
                        sx={{
                          minHeight: 30,
                          justifyContent: "initial",
                          px: 1.5,
                          py: 1.3,
                          backgroundColor:
                            selectedItem === item?.value
                              ? theme.palette.selectedMenuBack.main
                              : "transparent",
                          color:
                            selectedItem === item?.value &&
                            theme.palette.selectedMenuFont.main,
                          "&:hover": {
                            backgroundColor:
                              theme.palette.selectedMenuBack.main,
                          },

                          borderRadius: "3px",
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: 1.7,
                            zIndex: -1,
                            justifyContent: "center",
                            color:
                              selectedItem === item?.value
                                ? theme.palette.selectedMenuFont.main
                                : theme.palette.menuHeading.main,
                          }}
                        >
                          {item?.icon}
                        </ListItemIcon>

                        <Typography
                          style={{
                            opacity: 1,
                            zIndex: -1,
                            fontWeight:
                              selectedItem === item?.value ? 400 : 400,
                            fontSize:
                              selectedItem === item?.value ? "14px" : "14px",
                            color:
                              selectedItem === item?.value
                                ? theme.palette.selectedMenuFont.main
                                : theme.palette.menuItem.main,
                          }}
                        >
                          {" "}
                          {item?.label}
                        </Typography>
                      </ListItemButton>
                    </StyledListItem>
                  </>
                )}
              </Box>
            ))}
          {/* </Scrollbars> */}
        </List>

        <Box
          sx={{
            position: "fixed",
            bottom: "0",
            width: `${drawerWidth}px`,
          }}
        ></Box>
      </Box>
    </Scrollbars>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;
  const [dashboardIsLoading, setDashboardIsLoading] = React.useState(true);
  const handleDashboardLoadingOpen = () => setDashboardIsLoading(true);
  const handleDashboardLoadingClose = () => setDashboardIsLoading(false);
  useEffect(() => {
    setTimeout(() => setDashboardIsLoading(false), 5000);
  }, []);
  useEffect(() => {
    navigate("/dashboard/" + selectedItem);
  }, [selectedItem]);
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Modal
        open={dashboardIsLoading}
        // onClose={handleDashboardLoadingClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            height: "100%",
            bgcolor: "background.paper",
            // border: "2px solid #000",
            // boxShadow: 24,
            p: 4,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            outline: "0",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <Box sx={{ cursor: "pointer" }} onClick={() => navigate("/")}>
              <img style={{ height: "50px" }} src={logo} />
            </Box>
            <Typography sx={{ mt: 3 }}>Customized Dashboard Loading</Typography>
            <CircularProgress />
          </Box>
        </Box>
      </Modal>
      {/* <CompanyFormModal
        showModal={user?.id && !isCompanyDataExist ? true : false}
      /> */}
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)`, xs: "100vw" },
          ml: { sm: `${drawerWidth}px`, xs: "0px" },
          // backgroundColor: theme.palette.background.main,
          // backgroundColor: theme.palette.colortwo.main,
          boxShadow: " rgba(33, 35, 38, 0.1) 0px 10px 10px -10px",
          // zIndex: "10000",
          // border: "2px solid red",
        }}
      >
        <Toolbar sx={{ background: "#fff" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            {/* <MenuIcon /> */}
            <AlignLeft
              size={24}
              color={theme.palette.primary.main}
              strokeWidth={0.7}
            />
          </IconButton>
          {/* <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <SearchInput />
          </Box> */}

          <Box
            sx={{
              flex: "1",
              margin: "0 10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                marginLeft: "auto",
                width: "max-content",

                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "2px",
              }}
            >
              {/* <SearchComponent /> */}
              <Notifications />

              {/* <LanguageDropdown /> */}
              {/* <IconButton onClick={() => setSelectedItem("accountSettings")}>
                <Settings
                  size={22}
                  color={theme.palette.iconColor.main}
                  strokeWidth={2}
                />
              </IconButton> */}
              <Typography
                sx={{
                  color: theme.palette.heading.main,
                  textTransform: "capitalize",
                  fontWeight: "500",
                }}
              >
                {user?.name + " "}(
                {user?.profile_type == "employee"
                  ? "Team member"
                  : user?.profile_type}
                )
              </Typography>
              {/* <Typography
                sx={{
                  color: theme.palette.primary.main,
                  textTransform: "capitalize",
                  fontWeight: "500",
                }}
              >
                (
                {user?.profile_type == "employee"
                  ? "partner"
                  : user?.profile_type}
                )
              </Typography> */}

              <Tooltip title="Account settings">
                <IconButton
                  onClick={handleProfileDropdownClick}
                  size="small"
                  sx={{ ml: 1 }}
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  {/* {user?.profile_image ? (
                    <Box>
                      <Avatar
                        src={user?.profile_image}
                        sx={{
                          width: 32,
                          height: 32,
                          backgroundColor: theme.palette.colortwo.main,
                        }}
                      />
                    </Box>
                  ) : ( */}
                  <Box>
                    {" "}
                    {/* <Avatar
                      sx={{
                        width: 32,
                        height: 32,
                        backgroundColor: theme.palette.background.main,
                        color: theme.palette.colortwo.main,
                      }}
                    >
                      {user?.name && user?.name[0]?.toUpperCase()}
                    </Avatar> */}
                    {user?.profile_image ? (
                      <Avatar size="small">
                        <img
                          src={user?.profile_image}
                          alt="User Avatar"
                          style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "50%",
                            objectFit: "cover",
                          }}
                          referrerpolicy="no-referrer"
                        />
                      </Avatar>
                    ) : (
                      <Avatar size="small">
                        {user?.name && user?.name?.charAt(0)?.toUpperCase()}
                      </Avatar>
                    )}
                  </Box>
                  {/* )} */}
                </IconButton>
              </Tooltip>
            </Box>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={openProfileDropdown}
              onClose={handleProfileDropdownClose}
              onClick={handleProfileDropdownClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: "0",
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem
                onClick={() => {
                  setSelectedItem("accountSettings");
                  handleProfileDropdownClose();
                }}
              >
                {/* <Avatar
                  sx={{ height: "10px", width: "10px", fontSize: "15px" }}
                />{" "} */}
                <ListItemIcon>
                  <User size="15px" />
                </ListItemIcon>

                <Typography
                  variant="body2"
                  color={theme.palette.para.main}
                  sx={{ marginLeft: "-10px" }}
                >
                  {" "}
                  My Profile
                </Typography>
              </MenuItem>
              {/* <MenuItem onClick={handleProfileDropdownClose}>
                <Avatar /> My account
              </MenuItem> */}
              <Divider />

              <MenuItem
                onClick={() => {
                  setSelectedItem("organizationGeneralSettings");
                  handleProfileDropdownClose();
                }}
              >
                <ListItemIcon>
                  <Settings size="15px" />
                </ListItemIcon>
                <Typography variant="body2" color={theme.palette.para.main}>
                  Settings
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  logout();
                  handleProfileDropdownClose();
                }}
              >
                <ListItemIcon>
                  <LogOut size="15px" />
                </ListItemIcon>
                <Typography variant="body2" color={theme.palette.para.main}>
                  Logout
                </Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
          borderRight: "none",
          // border: "2px solid green",
        }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        {/********* sidebar in mobile view */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              borderWidth: "0",
              borderRight: "none",
            },
          }}
        >
          {drawer}
        </Drawer>
        {/******   sidebar in desktop view */}
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              borderWidth: "0",
              borderRight: "none",
              // border: "3px solid pink",
              zIndex: "0",
            },
          }}
          open
        >
          {drawer}
        </Drawer>{" "}
      </Box>
      {/**********************content container(other than sidebar and topbar of crm view) ///////////////*/}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 0,
          minHeight: "100vh",
          height: "maxContent",
          // backgroundColor: theme.palette.secondary.main,
          flexGrow: 1,
          // p: { xs: 1, sm: 3 },
          width: { sm: `calc(100% - ${drawerWidth}px)`, xs: "100vw" },
          borderRadius: "10px",
          // border: "3px solid yellow",
        }}
      >
        <Toolbar />
        {/* <DrawerHeader /> */}
        {/* {props?.children(selectedItem)} */}
        {props?.children()}
      </Box>
    </Box>
  );
}

export default CRMLayout;
