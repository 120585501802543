export const setUser = (user) => (dispatch) => {
  dispatch({
    type: "SET_USER",
    payload: user,
  });
  localStorage.setItem("user", JSON.stringify(user));
};
export const setToken = (token) => (dispatch) => {
  dispatch({
    type: "SET_Token",
    payload: token,
  });
  localStorage.setItem("token", JSON.stringify(token));
};
