import React from "react";
import { Box, IconButton } from "@mui/material";
import { Search } from "react-feather";
import { useTheme } from "@mui/material";

const SearchInput = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        backgroundColor: "rgba(255, 255, 255, 0.5)", // set a semi-transparent white background color
        backdropFilter: "blur(6px)", // add a 5px blur filter
        zIndex: "10000",
        display: "flex",
        alignItems: "center",
        padding: "3px 7px",
        gap: "10px",
        borderRadius: "2px",
        border: "1px solid lightgray",
      }}
    >
      <Box
        sx={{
          //   background: theme.palette.colortwo.main,
          padding: "2px 1px",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <Search strokeWidth={1.5} width={16} />
      </Box>
      <input
        className="dashboard_header_search_input"
        style={{
          //   border: "1px solid lightgray",
          background: "none",
          fontSize: "0.8rem",
          height: "20px",
          color: theme.palette.heading.main,
          width: "200px",
        }}
        placeholder="Search..."
      />
    </Box>
  );
};

export default SearchInput;
