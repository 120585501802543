import React, { useEffect, useState } from "react";
import {
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
  Button,
  Grid,
  Box,
} from "@mui/material";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { api } from "../../../axios/api";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AES from "crypto-js/aes";

const CreateAgents = ({ editOptions, setShowAddForm }) => {
  const theme = useTheme();
  const user = useSelector((state) => state.user);

  const [formValues, setFormValues] = useState({
    name: editOptions?.name || "",
    email: editOptions?.email_address || "",
    password: editOptions?.password || "",
    phone_number: editOptions?.phone_number || "",
    profile_type: "agent",
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    password: "",
    phone_number: "",
  });

  useEffect(() => {
    console.log("user at add agent", user);
  }, [user]);

  useEffect(() => {
    console.log("edit options at add agent form", editOptions);
  }, [editOptions]);

  const validateForm = () => {
    console.log("form values at form", formValues);
    const newErrors = {};

    // Perform your custom validation here
    if (!formValues.name) {
      newErrors.name = "Required";
    }
    if (!formValues.email) {
      newErrors.email = "Required";
    }
    if (!formValues.password && !editOptions?.id) {
      newErrors.password = "Required";
    }
    if (!formValues.phone_number) {
      newErrors.phone_number = "Required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const [loading, setLoading] = useState(false);

  const onSubmit = () => {
    if (validateForm()) {
      setLoading(true);
      // Perform submission logic here

      var encPass = AES.encrypt(
        formValues.password,
        process.env.REACT_APP_CRYPTO_JS_KEY
      ).toString();

      if (editOptions?.id) {
        let body = {
          name: formValues.name,
          email_address: formValues.email,
          phone_number: formValues.phone_number,
        };
        api
          .put("/updateAgentByAdmin/" + editOptions?.id, body)
          .then((res) => {
            console.log("response from updating agent", res?.data);
            toast.success("User updated successfully!");
            setShowAddForm(false);
            setFormValues({
              name: "",
              email: "",
              password: "",
              phone_number: "",
              profile_type: "agent",
            });
            setLoading(false);
            // Optionally, you can reset the formValues state here
          })
          .catch((err) => {
            console.log("err", err);
            toast.error("Error in updating agent");
            setLoading(false);
          });
      } else {
        let body = {
          name: formValues.name,
          email_address: formValues.email,
          password: encPass,
          phone_number: formValues.phone_number,
          profile_type: formValues.profile_type,
        };
        api
          .post("/addAgentByAdmin", body)
          .then((res) => {
            console.log("response from adding agent", res?.data);
            toast.success("Agent added successfully!");
            setFormValues({
              name: "",
              email: "",
              password: "",
              phone_number: "",
              profile_type: "agent",
            });
            setLoading(false);
            // Optionally, you can reset the formValues state here
          })
          .catch((err) => {
            console.log("err", err);
            toast.error("Error in adding agent");
            setLoading(false);
          });
      }
    } else {
      console.log("Form validation failed");
    }
  };

  const cancelForm = () => {
    setShowAddForm(false);
  };

  return (
    <Box sx={{ background: "#fff", p: 2 }}>
      <Typography variant="h6" sx={{ color: theme.palette.heading.main }}>
        Create New Agent
      </Typography>
      <Box sx={{ mt: 2 }}>
        <Box>
          <ToastContainer />
          <Box
            sx={{
              backgroundColor: theme.palette.background.main,
              padding: "20px",
              borderRadius: "5px",
              marginTop: "20px",
              maxWidth: "700px",
            }}
          >
            <Typography
              variant="body2"
              sx={{ margin: "10px 0 30px 0", color: theme.palette.para.main }}
            >
              Fill up the form with agent data.
            </Typography>
            <form>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    name="name"
                    label="Name"
                    fullWidth
                    value={formValues.name}
                    onChange={(e) =>
                      setFormValues({ ...formValues, name: e.target.value })
                    }
                    error={Boolean(errors.name)}
                    helperText={errors.name}
                  />
                </Grid>

                <Grid item xs={12} sm={editOptions?.id ? 12 : 6}>
                  <TextField
                    disabled={editOptions?.id}
                    name="email"
                    label="Email"
                    fullWidth
                    value={formValues.email}
                    onChange={(e) =>
                      setFormValues({ ...formValues, email: e.target.value })
                    }
                    error={Boolean(errors.email)}
                    helperText={errors.email}
                  />
                </Grid>
                {!editOptions?.id && (
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="password"
                      label="Password"
                      fullWidth
                      value={formValues.password}
                      onChange={(e) =>
                        setFormValues({
                          ...formValues,
                          password: e.target.value,
                        })
                      }
                      error={Boolean(errors.password)}
                      helperText={errors.password}
                    />
                  </Grid>
                )}

                <Grid item xs={12}>
                  <TextField
                    name="phone_number"
                    label="Phone Number"
                    fullWidth
                    value={formValues.phone_number}
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        phone_number: e.target.value,
                      })
                    }
                    error={Boolean(errors.phone_number)}
                    helperText={errors.phone_number}
                  />
                </Grid>

                <Grid item xs={12} sm={4}></Grid>
                <Grid item xs={12} sm={4}></Grid>

                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: theme.palette.colortwo.main,
                      color: theme.palette.whiteFont.main,
                    }}
                    onClick={onSubmit}
                    size="small"
                    disabled={loading}
                  >
                    {editOptions?.id ? "Update" : "Submit"}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={cancelForm}
                    sx={{
                      marginLeft: "10px",
                      backgroundColor: theme.palette.colortwo.main,
                      color: theme.palette.whiteFont.main,
                    }}
                    size="small"
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CreateAgents;
