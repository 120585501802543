import React, { useState, useEffect, useRef } from "react";
import {
  Typography,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
  IconButton,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  TextField,
} from "@mui/material";
import { Avatar } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { api } from "../../axios/api";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material";
import SearchInput from "../CRM/SearchInput2";
import theme from "../../theme";
import SendIcon from "@mui/icons-material/Send";
import { formatDistanceToNow } from "date-fns";
import { io } from "socket.io-client";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const ChatBox = ({
  selectedPartnerForConversation,
  isGeneralChat,
  selectedLead,
  socket,
  setShowConversation,
  setShowChat,
  isRelationship,
}) => {
  const user = useSelector((state) => state.user);
  const [messageText, setMessageText] = useState("");
  const [messages, setMessages] = useState([]);
  const [arrivalMessage, setArrivalMessage] = useState({});
  const scrollRef = useRef();
  socket.current = io(process.env.REACT_APP_BACKEND_URL_FOR_SOCKET, {
    path: "/api/socket.io/",
  });

  const fetchMessages = () => {
    let body;
    if (isGeneralChat) {
      if (isRelationship) {
        body = {
          relationship_id: selectedLead?.info_reference_number,
          is_general: true,
          isRelationship,
        };
      } else {
        body = {
          prospect_id: selectedLead?.lead_serial_number,
          is_general: true,
          isRelationship,
        };
      }
    } else {
      if (isRelationship) {
        body = {
          relationship_id: selectedLead?.info_reference_number,
          is_general: false,
          other_user_id: selectedPartnerForConversation?.id,
          isRelationship,
        };
      } else {
        body = {
          prospect_id: selectedLead?.lead_serial_number,
          is_general: false,
          other_user_id: selectedPartnerForConversation?.id,
          isRelationship,
        };
      }
    }

    api
      .post("/message/get", body)
      .then((res) => {
        console.log("body", body);
        console.log("res?.data messages", res?.data);
        setMessages(res?.data);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behaviour: "smooth" });
  }, [messages, selectedPartnerForConversation, isGeneralChat]);

  useEffect(() => {
    // Fetch messages immediately on component mount
    if (isGeneralChat || selectedPartnerForConversation) {
      fetchMessages();

      // Fetch messages every 3 seconds (3000 milliseconds)
      // const interval = setInterval(() => {
      //   fetchMessages();
      // }, 3000);

      // // Clean up the interval when the component is unmounted
      // return () => {
      //   clearInterval(interval);
      // };
    }
  }, [selectedLead, isGeneralChat, selectedPartnerForConversation]);
  useEffect(() => {
    console.log(
      "running use effect owiiiiiiiiiiiiiiiiii",
      process.env.REACT_APP_BACKEND_URL_FOR_SOCKET
    );
    // socket.current = io(process.env.REACT_APP_BACKEND_URL_FOR_SOCKET);
    console.log("socket.current att adding user", socket.current);
    console.log("socket.current emit", socket.current.emit);
    if (isRelationship) {
      socket.current.emit("addUser", {
        userId: user?.id,
        isRelationship,
        relationshipId: selectedLead?.info_reference_number,
      });
    } else {
      socket.current.emit("addUser", {
        userId: user?.id,
        isRelationship,
        prospectId: selectedLead?.lead_serial_number,
      });
    }
  }, [user, selectedPartnerForConversation, isGeneralChat]);
  useEffect(() => {
    // const socket = io(process.env.REACT_APP_BACKEND_URL_FOR_SOCKET);
    // socket.current = io(process.env.REACT_APP_BACKEND_URL_FOR_SOCKET);
    console.log("soccet current on get message", socket.current);
    socket.current.on("getMessage", (data) => {
      console.log("getting message", data);
      if (data?.isRelationship) {
        setArrivalMessage({
          relationship_id: data.relationship_id,
          text: data.text,
          sender_id: data.sender_id,
          receiver_id: data.receiver_id,
          is_general: data.is_general,
          sent_on: data?.sent_on,
        });
      } else {
        setArrivalMessage({
          prospect_id: data.prospect_id,
          text: data.text,
          sender_id: data.sender_id,
          receiver_id: data.receiver_id,
          is_general: data.is_general,
          sent_on: data?.sent_on,
        });
      }

      //setRefresher(Math.floor(Math.random() * 56));
    });
  }, [user, selectedPartnerForConversation, isGeneralChat]);
  useEffect(() => {
    if (arrivalMessage?.prospect_id == selectedLead?.lead_serial_number) {
      if (arrivalMessage?.is_general) {
        if (isGeneralChat) setMessages((prev) => [...prev, arrivalMessage]);
      } else {
        arrivalMessage?.sender_id == selectedPartnerForConversation?.id &&
          setMessages((prev) => [...prev, arrivalMessage]);
      }
    }
  }, [arrivalMessage, selectedPartnerForConversation]);

  const sendMessage = () => {
    if (messageText?.trim()?.length < 1) {
      return;
    }

    let body;
    if (isGeneralChat) {
      if (isRelationship) {
        body = {
          relationship_id: selectedLead?.info_reference_number,
          text: messageText,
          is_general: true,
          isRelationship,
        };
        api.post("/addRelationshipActivity", {
          relationship_id: selectedLead?.info_reference_number,
          done_by: user?.id,
          action: "Chat about it!",
          note: `in general`,
        });
      } else {
        body = {
          prospect_id: selectedLead?.lead_serial_number,
          text: messageText,
          is_general: true,
          isRelationship,
        };
        api.post("/addActivity", {
          prospect_id: selectedLead?.lead_serial_number,
          done_by: user?.id,
          action: "Chat about it",
          note: `in general`,
        });
      }
    } else {
      if (isRelationship) {
        body = {
          relationship_id: selectedLead?.info_reference_number,
          text: messageText,
          is_general: false,
          receiver_id: selectedPartnerForConversation?.id,
          isRelationship,
        };
        api.post("/addRelationshipActivity", {
          relationship_id: selectedLead?.info_reference_number,
          done_by: user?.id,
          action: "Chat about it!",
          note: `with: ${selectedPartnerForConversation?.name}!`,
        });
      } else {
        body = {
          prospect_id: selectedLead?.lead_serial_number,
          text: messageText,
          is_general: false,
          receiver_id: selectedPartnerForConversation?.id,
          isRelationship,
        };
        api.post("/addActivity", {
          prospect_id: selectedLead?.lead_serial_number,
          done_by: user?.id,
          action: "Chat about it",
          note: `with ${selectedPartnerForConversation?.name}!`,
        });
      }
    }
    api
      .post("/message/add", body)
      .then((res) => {
        setMessageText("");
        setMessages([...messages, res?.data]);
        /**********socket context */
        // const socket = io(process.env.REACT_APP_BACKEND_URL_FOR_SOCKET);
        // socket.current = io(process.env.REACT_APP_BACKEND_URL_FOR_SOCKET);
        console.log(
          "process.env.REACT_APP_BACKEND_URL_FOR_SOCKET",
          process.env.REACT_APP_BACKEND_URL_FOR_SOCKET
        );
        if (isRelationship) {
          console.log(
            "socket current at send message relation",
            socket?.current
          );
          socket.current.emit("sendMessage", {
            relationship_id: selectedLead?.info_reference_number,
            text: messageText,
            is_general: isGeneralChat,
            isRelationship,
            receiver_id: isGeneralChat
              ? ""
              : selectedPartnerForConversation?.id,
            sender_id: user?.id,
          });
        } else {
          console.log(
            "socket current at send message prospects",
            socket?.current
          );
          socket.current.emit("sendMessage", {
            prospect_id: selectedLead?.lead_serial_number,
            text: messageText,
            isRelationship,
            is_general: isGeneralChat,
            receiver_id: isGeneralChat
              ? ""
              : selectedPartnerForConversation?.id,
            sender_id: user?.id,
          });
        }
      })
      .catch((err) => {
        console.log("error while sending message", err);
      });
  };
  return (
    <Grid
      item
      xs={12}
      sm={8.6}
      sx={{
        background: "#fff",
        p: 1,
        display: "flex",
        flexDirection: "column",
        maxHeight: "100%",
      }}
    >
      {!isGeneralChat && !selectedPartnerForConversation?.name ? (
        <Box></Box>
      ) : (
        <>
          {" "}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              p: 1,
              // border: "1px solid lightgray",
            }}
          >
            <Box sx={{ display: "flex", gap: "20px", alignItems: "center" }}>
              <IconButton
                sx={{ display: { xs: "none", sm: "block" } }}
                onClick={() => {
                  setShowConversation(true);
                  setShowChat(false);
                }}
              >
                <ArrowBackIosIcon />
              </IconButton>
              {!isGeneralChat && (
                <Avatar
                  sx={{
                    height: "30px",
                    width: "30px",
                    background: theme.palette.primary.main,
                  }}
                >
                  {selectedPartnerForConversation?.name?.length > 0 &&
                    selectedPartnerForConversation?.name[0]?.toUpperCase()}
                </Avatar>
              )}

              <Box>
                <Typography
                  variat="body2"
                  sx={{ fontWeight: "600", fontSize: "1rem" }}
                >
                  {isGeneralChat
                    ? `#General Chat`
                    : selectedPartnerForConversation?.name}
                </Typography>
                {!isGeneralChat && (
                  <Typography variant="body2" sx={{ fontSize: "0.8rem" }}>
                    online
                  </Typography>
                )}
              </Box>
            </Box>
            {/* <SearchInput /> */}
          </Box>
          <Box sx={{ borderTop: "1px solid lightgray", p: 1 }}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item xs={10}>
                <input
                  fullWidth
                  placeholder="Enter Message"
                  style={{ padding: "10px 15px", width: "100%" }}
                  value={messageText}
                  onChange={(e) => setMessageText(e.target.value)}
                  // Add other TextField props as needed
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  endIcon={<SendIcon />}
                  size="small"
                  onClick={() => sendMessage()}

                  // Add other Button props as needed
                >
                  Send
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              flex: "1",
              p: 2,
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              maxHeight: "100%",
              overflowY: "auto",
            }}
          >
            {messages?.length < 1 ? (
              <Typography></Typography>
            ) : (
              <>
                {messages?.map((m, i) => (
                  <Message message={m} index={i} scrollRef={scrollRef} />
                ))}
              </>
            )}
          </Box>
        </>
      )}
    </Grid>
  );
};

export default ChatBox;

const Message = ({ message, index, scrollRef }) => {
  const [isSelf, setIsSelf] = useState(false);
  const [sender, setSender] = useState({});
  const user = useSelector((state) => state.user);
  const formatTimeDifference = (date) => {
    return formatDistanceToNow(new Date(date), { addSuffix: true });
  };
  useEffect(() => {
    console.log("message", message);
    if (message?.sender_id == user?.id) {
      setIsSelf(true);
      setSender(user);
    } else {
      setIsSelf(false);
      api
        .get("/getUserById/" + message?.sender_id)
        .then((res) => setSender(res?.data))
        .catch((err) => console.log(err));
      api
        .put("/message/markRead/" + message?.id)
        .then((res) => {
          console.log("res?.data", res?.data);
        })
        .catch((err) => console.log(err));
    }
  }, [message]);
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "10px",
        alignItems: "center",
        flexDirection: isSelf ? "row" : "row-reverse",
        // justifyContent: "center",
        width: "100%",
      }}
      key={index}
      ref={scrollRef}
    >
      <Avatar
        sx={{
          width: "30px",
          height: "30px",
        }}
      >
        {sender?.name?.length > 0 && sender?.name[0]?.toUpperCase()}
      </Avatar>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          // flexDirection: isSelf ? "row" : "row-reverse",
          alignItems: isSelf ? "flex-start" : "flex-end",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            width: "100%",
            flexDirection: isSelf ? "row" : "row-reverse",
          }}
        >
          {" "}
          <Typography
            variant="body2"
            sx={{
              p: 1,
              color: isSelf ? "#fff" : theme.palette.heading.main,
              background: isSelf ? theme.palette.primary.main : "whiteSmoke",
              maxWidth: "60%",
              borderRadius: "5px",
              width: "max-content",
            }}
          >
            {message?.text}
          </Typography>{" "}
          {message?.sent_on && (
            <Typography
              variant="body2"
              sx={{ color: theme.palette.para.main, fontSize: "0.8rem" }}
            >
              {formatTimeDifference(message?.sent_on)}
            </Typography>
          )}
          {/*  */}
        </Box>

        <Typography variant="body2" sx={{ color: theme.palette.para.main }}>
          {sender?.name}
        </Typography>
      </Box>
    </Box>
  );
};
