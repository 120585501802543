import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  IconButton,
  TextField,
  Tooltip,
  Card,
  CardContent,
  CardActions,
} from "@mui/material";
import { useTheme } from "@mui/material";
import { api } from "../../../axios/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const FAQs = () => {
  // Define an array of FAQ items
  const [faqs, setFAQs] = useState(faqArray);
  // State to keep track of which FAQ item is open
  const [openIndex, setOpenIndex] = useState(null);
  const theme = useTheme();

  // Function to toggle the FAQ item open/closed
  const handleToggleFAQ = (index) => {
    if (index === openIndex) {
      // If the same FAQ item is clicked again, close it
      setOpenIndex(null);
    } else {
      // Otherwise, open the clicked FAQ item
      setOpenIndex(index);
    }
  };

  return (
    <Box sx={{ p: 3, background: theme.palette.background.main }}>
      <Typography sx={{ mb: 3 }} variant="h6" gutterBottom>
        Frequently Asked Questions
      </Typography>
      {faqs.map((faq, index) => (
        <Card key={index} style={{ marginBottom: 16 }}>
          <CardContent sx={{ background: theme.palette.colorthree.main }}>
            <Typography
              variant="body2"
              sx={{
                fontWeight: "600",
                color: theme.palette.primary.main,
                fontSize: "1rem",
              }}
            >{`${index + 1}. ${faq.question}`}</Typography>
            {openIndex === index && (
              <Typography
                variant="p"
                sx={{
                  fontWeight: "400",
                  color: theme.palette.para.main,
                  fontSize: "0.9rem",
                }}
                style={{ marginTop: 8 }}
              >
                {faq.answer}
              </Typography>
            )}
          </CardContent>
          <CardActions>
            <Button
              size="small"
              color="primary"
              onClick={() => handleToggleFAQ(index)}
            >
              {openIndex === index ? "Close" : "Read More"}
            </Button>
          </CardActions>
        </Card>
      ))}
    </Box>
  );
};

export default FAQs;

const faqArray = [
  {
    question: "What is edge360 capable of?",
    answer:
      "Edge360 is a comprehensive solution designed to streamline your sales processes and enhance team collaboration. Edge360 allows you to easily manage your sales pipeline, build meaningful relationships with customers, collaborate with your team members, find more sales leads and convert more effectively.",
  },
  {
    question: "How do I create a sales lead in edge360?",
    answer:
      "Creating a sales lead is easy! Simply navigate to the dashboard/prospects, click on the 'Create New' button, and fill in the relevant details such as contact information, company details, and any additional notes. Once saved, the lead will be added to your database.",
  },
  {
    question: "How can I maintain a sales funnel in edge360?",
    answer:
      "Maintaining a sales funnel helps you track your leads' progress through the sales process. Edge360 allows you to categorize leads into different milestone stages such as 'Discovery,' 'Nurturing,' 'Negotiation,' 'Validation,' and 'Conversion.' You can easily upgrade, downgrade, transfer or drop leads between these stages as they advance in the sales journey.",
  },
  {
    question: "How does the lead scoring feature work?",
    answer:
      "Lead scoring is a powerful tool that helps you prioritize your efforts on leads most likely to convert. Edge360 uses a combination of engagement history and milestone level to assign a numerical score to each lead. This score indicates the lead's potential to become a customer, allowing you to focus your attention on high-value prospects.",
  },
  {
    question: "Can I collaborate with my team members within edge360?",
    answer:
      "Edge360 fosters seamless collaboration among team members. You can assign leads to specific team members, transfer a lead from one team member to another, share notes, and even engage in real-time chat discussions within the platform. This ensures that everyone is on the same page and working together to drive conversions.",
  },
  {
    question: "Can I generate performance reports in edge360?",
    answer:
      "Absolutely! Edge360 provides robust reporting capabilities. You can generate various performance reports, including lead conversion rates, leads by rating score, team productivity, and more. These reports offer valuable insights into your sales operations, helping you make informed decisions and optimize your strategies.",
  },
  {
    question: "Is my data safe and secure with edge360?",
    answer:
      "Security is a top priority for us. Edge360 employs industry-standard encryption protocols to ensure that your data remains secure and protected. Regular security audits and updates are performed to maintain the integrity of the system and safeguard your information.",
  },
  {
    question: "Can I customize edge360 to suit my business needs?",
    answer:
      "Yes, edge360 is designed to be flexible and customizable. You can configure lead stages, user permissions, and more to align with your specific business requirements. This ensures that edge360 adapts to your unique sales processes and workflows.",
  },
  {
    question: "Can I integrate edge360 with my emails?",
    answer:
      "We understand the importance of integration. Edge360 supports integration with email applications. This allows you to centralize your sales activities and data, making your workflow even more efficient.",
  },
  {
    question: "Is there customer support available for edge360?",
    answer:
      "Absolutely. Our customer support team is dedicated to assisting you with any inquiries or issues you might encounter while using edge360. You can reach out to us through our support portal, and we'll be more than happy to help you out.",
  },
  {
    question: "Can edge360 automate tasks?",
    answer:
      "Yes, edge360 comes with task automation capabilities. You can set up workflows to automate routine tasks such as sending follow-up emails, assigning leads based on criteria, and triggering notifications for important events.",
  },
  {
    question: "Does edge360 offer mobile access?",
    answer:
      "Certainly. Edge360 is mobile-responsive, allowing you to access and manage your leads, tasks, and collaboration efforts from anywhere using your smartphone or tablet.",
  },
  {
    question: "How does the edge360's email integration work?",
    answer:
      "Edge360 integrates with popular email clients, enabling you to send and receive emails directly within the platform. This ensures that all your communication history with leads is centralized and easily accessible.",
  },
  {
    question: "Can I import existing leads into edge360?",
    answer:
      "Yes, you can import leads in bulk using compatible formats. This helps you seamlessly transition from your previous systems while keeping all your data intact.",
  },
  {
    question: "Does edge360 provide analytics for team performance?",
    answer:
      "Absolutely. Edge360's analytics feature not only tracks individual performance but also provides insights into team-wide activities. This helps you identify top-performing team members and areas that might require improvement.",
  },
  {
    question: "Can I set up automated lead nurturing tasks?",
    answer:
      "Yes, edge360 supports automated lead nurturing. You can create and schedule tasks to engage leads over time, providing them with valuable information and increasing the chances of conversion.",
  },
  {
    question: "How does edge360 handle duplicate leads?",
    answer:
      "The CRM includes a duplicate lead detection feature. When you add a new lead, the system checks for existing entries with similar details, helping you avoid duplicates and maintain a clean database.",
  },
  {
    question: "Is training available for using edge360 effectively?",
    answer:
      "Absolutely. We offer comprehensive training resources, including video tutorials, user guides, and webinars to ensure you and your team can harness the CRM's full potential.",
  },
  {
    question: "Can I customize edge360's dashboard?",
    answer:
      "Yes, you can personalize your dashboard to display the metrics, reports, and insights that matter most to you. This way, you can have a quick overview of your performance at a glance.",
  },
  {
    question: "What is the pricing structure for edge360?",
    answer:
      "Our pricing is tailored to accommodate businesses of all sizes. We offer various plans with different feature sets, allowing you to choose the one that best suits your needs and budget. You can find detailed pricing information on our website or contact our sales team for a customized quote.",
  },
];
