import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
  IconButton,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
} from "@mui/material";
import { api } from "../../../axios/api";
import { useTheme } from "@mui/material/styles";
// import Image1 from "../../../utils/images/features/f1.png";
import Image1 from "../../../utils/images/features/noOfDaysOpen.png";
import Image2 from "../../../utils/images/features/f2.jpg";
import Image3 from "../../../utils/images/features/f3.jpg";
import Image4 from "../../../utils/images/features/f4.png";
// import Image5 from "../../../utils/images/features/f5.jpg";
import Image5 from "../../../utils/images/features/lastAction.png";
import Image6 from "../../../utils/images/features/f6.jpg";
// import Image7 from "../../../utils/images/features/f7.jpg";
import Image7 from "../../../utils/images/features/schedule.png";
import Image8 from "../../../utils/images/features/transferIt.png";
// import Image8 from "../../../utils/images/features/f8.jpg";
import Image9 from "../../../utils/images/features/f9.jpg";
// import Image10 from "../../../utils/images/features/f10.png";
import Image10 from "../../../utils/images/features/addANote.png";
import Image11 from "../../../utils/images/features/f11.png";
import Image12 from "../../../utils/images/features/f12.png";
import Feature from "./Feature";
import Image13 from "../../../utils/images/features/activity.png";

import StarImage1 from "../../../utils/images/stars/star1.jpg";
import StarImage2 from "../../../utils/images/stars/star2.jpeg";
import StarImage3 from "../../../utils/images/stars/star3.jpeg";
import StarImage4 from "../../../utils/images/stars/star4.png";
import StarImage5 from "../../../utils/images/stars/star5.png";
import { useSelector } from "react-redux";

const IndividualLeadFeatures = ({
  selectedLead,
  setShowIndividualLeadFeature,
}) => {
  const theme = useTheme();
  const [showFeature, setShowFeature] = useState(false);
  const [selectedFeature, setSelectedFeature] = useState("");
  const [leadHappinessRating, setLeadHappinessRating] = useState(0);
  const [totalOpenDays, setTotalOpenDays] = useState(0);
  const [lastActionDays, setLastActionDays] = useState(0);
  const [notesCount, setNotesCount] = useState(0);
  const [totalActivities, setTotalActivities] = useState(0);

  useEffect(() => {
    console.log("selected lead", selectedLead);

    //total open days
    let startDate = new Date(selectedLead?.date_created);
    let totalDays = 0;
    let totalHours = 0;
    let totalMinutes = 0;
    let endDate;
    if (
      selectedLead?.end_date &&
      (selectedLead?.isDropped || selectedLead?.Won)
    ) {
      endDate = new Date(selectedLead?.end_date);
    } else {
      endDate = new Date(Date.now());
    }
    console.log("start date", startDate);
    console.log("end date", endDate);
    let timeDifference = endDate.getTime() - startDate.getTime(); // Difference in milliseconds
    totalDays = Math.floor(timeDifference / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
    totalHours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24); // Convert milliseconds to hours and calculate the remaining hours
    totalMinutes = Math.floor((timeDifference / (1000 * 60)) % 60); // Convert milliseconds to minutes and calculate the remaining minutes
    setTotalOpenDays(
      totalDays + " days "
      // + totalHours + " hours " + totalMinutes + " minutes"
    );
    //lastactiondays
    let startDate1 = new Date(selectedLead?.date_created);
    let totalDays1 = 0;
    let totalHours1 = 0;
    let totalMinutes1 = 0;
    let endDate1;

    if (
      selectedLead?.end_date &&
      (selectedLead?.isDropped || selectedLead?.Won)
    ) {
      endDate1 = new Date(selectedLead?.end_date);
    } else {
      endDate1 = new Date(Date.now());
    }
    let timeDifference1 = endDate1.getTime() - startDate1.getTime(); // Difference in milliseconds
    totalDays1 = Math.floor(timeDifference1 / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
    totalHours1 = Math.floor((timeDifference1 / (1000 * 60 * 60)) % 24); // Convert milliseconds to hours and calculate the remaining hours
    totalMinutes1 = Math.floor((timeDifference1 / (1000 * 60)) % 60); // Convert milliseconds to minutes and calculate the remaining minutes
    setLastActionDays(
      totalDays1 + " days "
      // +
      // totalHours1 +
      // " hours " +
      // totalMinutes1 +
      // " minutes"
    );

    api
      .get("/relationshipsNotes/" + selectedLead?.info_reference_number)
      .then((res) => {
        let notes = res?.data;
        if (notes?.length > 0) {
          console.log("notes", notes);
          setNotesCount(res?.data?.length);

          let latestUploadDate = null;

          if (notes.length > 0) {
            latestUploadDate = notes.reduce((maxDate, note) => {
              const noteDate = new Date(note.date_of_upload);
              return noteDate > maxDate ? noteDate : maxDate;
            }, new Date(notes[0].date_of_upload));
          }

          console.log("latestUploadDate", latestUploadDate); // Output: 2023-07-19T19:48:04.891Z

          let timeDifference2 =
            new Date().getTime() - latestUploadDate.getTime(); // Difference in milliseconds
          totalDays = Math.floor(timeDifference2 / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
          totalHours = Math.floor((timeDifference2 / (1000 * 60 * 60)) % 24); // Convert milliseconds to hours and calculate the remaining hours
          totalMinutes = Math.floor((timeDifference2 / (1000 * 60)) % 60); // Convert milliseconds to minutes and calculate the remaining minutes
          setLastActionDays(
            totalDays + " days "
            //  +
            // totalHours +
            // " hours " +
            // totalMinutes +
            // " minutes"
          );
        }
      })
      .catch((err) => console.log(err));
    api
      .get("/relationshipsActivities/" + selectedLead?.info_reference_number)
      .then((res) => {
        console.log("res", res?.data);
        if (res?.data?.length > 0) {
          setTotalActivities(res?.data?.length);
        }
      })
      .catch((err) => console.log(err));

    console.log("selectedFeature:", selectedFeature);
    console.log("showFeature:", showFeature);
    console.log("selectedLead:", selectedLead);
  }, [selectedFeature, showFeature, selectedLead]);

  return (
    <Box>
      <Typography
        onClick={() => {
          if (showFeature) {
            setShowFeature(false);
          } else {
            setShowFeature(false);
            setShowIndividualLeadFeature(false);
          }
        }}
        variant="p"
        sx={{
          mb: 2,
          cursor: "pointer",
          fontSize: "0.8rem",
          backgroundColor: theme.palette.primary.main,
          color: "#fff",
          p: 1.5,
          py: 0.5,
          // "&:hover": {
          //   color: theme.palette.primary.main,
          // },
        }}
      >
        {`BACK`}
      </Typography>
      <br />
      {/* <Box sx={{ mb: 0, mt: 1 }}>
        <Typography
          onClick={() => {
            setShowFeature(false);
            setShowIndividualLeadFeature(false);
          }}
          variant="p"
          sx={{
            cursor: "pointer",
            fontSize: "0.8rem",
            "&:hover": {
              color: theme.palette.primary.main,
            },
          }}
        >
          {` Leads pipeline >`}
        </Typography>
        <Typography
          variant="p"
          sx={{
            fontSize: "0.8rem",
            cursor: "pointer",
            "&:hover": {
              color: showFeature && theme.palette.primary.main,
            },
          }}
          onClick={() => {
            showFeature && setShowFeature(false);
          }}
        >
          {" "}
          Individual lead features
        </Typography>
        {showFeature && (
          <Typography variant="p" sx={{ fontSize: "0.8rem" }}>
            {" "}
            {`> ${selectedFeature}`}
          </Typography>
        )}
      </Box> */}
      {showFeature ? (
        <Feature
          selectedFeature={selectedFeature}
          setSelectedFeature={setSelectedFeature}
          selectedLead={selectedLead}
          setShowIndividualLeadFeature={setShowIndividualLeadFeature}
        />
      ) : (
        <>
          {" "}
          <Typography variant="h6"> Individual Lead Features</Typography>
          {selectedLead?.is_dropped && (
            <Typography
              variant="p"
              sx={{ my: "2", color: theme.palette.primary.main }}
            >
              This lead has been dropped.
            </Typography>
          )}
          {selectedLead?.is_won && (
            <Typography
              variant="p"
              sx={{ my: "2", color: theme.palette.primary.main }}
            >
              This lead has been won.
            </Typography>
          )}
          <Grid container sx={{ backgroundColor: "#fff", mt: 2 }}>
            {/**info table */}
            <Grid
              item
              xs={12}
              sm={4}
              sx={{
                minHeight: "300px",
                padding: "30px 10px",
                // border: "1px solid red",
                // background: theme.palette.colorthree.main,
              }}
            >
              <Grid
                container
                sx={{
                  color: theme.palette.whiteFont.main,
                  gap: "0px",
                  mb: 2,
                }}
              >
                <Grid
                  item
                  xs={5}
                  sx={{
                    background: theme.palette.primary.main,
                    p: 1,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: "0.8rem",
                      fontWeight: "800",
                    }}
                  >
                    {" "}
                    INFO REF. NO. {selectedLead?.info_reference_number}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6.5}
                  sx={{
                    background: theme.palette.primary.main,
                    p: 1,
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body2" sx={{ fontSize: "0.8rem" }}>
                    Details
                  </Typography>
                  {leadHappinessRating > 0 && leadHappinessRating < 6 && (
                    <img
                      src={
                        leadHappinessRating == 1
                          ? StarImage1
                          : leadHappinessRating == 2
                          ? StarImage2
                          : leadHappinessRating == 3
                          ? StarImage3
                          : leadHappinessRating == 4
                          ? StarImage4
                          : leadHappinessRating == 5
                          ? StarImage5
                          : ""
                      }
                      style={{ width: "30px", height: "auto" }}
                    />
                  )}
                </Grid>
              </Grid>
              <InfoRow
                label="Date created"
                value={new Date(selectedLead?.date_created)?.toDateString()}
              />
              <InfoRow
                label="Contact Person"
                value={selectedLead?.contact_person}
              />
              <InfoRow
                label="Primary Phone "
                value={selectedLead?.primary_phone_number}
              />
              <InfoRow
                label="Organization"
                value={selectedLead?.organization_name}
              />

              {/* <InfoRow
                label="Other Phone "
                value={selectedLead?.other_phone_number}
              /> */}
              <InfoRow label="Email Address" value={selectedLead?.email} />
              <InfoRow
                label="Social Media Links"
                value={selectedLead?.social_media_links}
              />
              <InfoRow
                label="What do I know about this person?"
                value={selectedLead?.what_i_know}
              />
              <InfoRow
                label="Why are they important to the business?"
                value={selectedLead?.why_they_important}
              />
              <InfoRow
                label="What is important to this person? What do they value?"
                value={selectedLead?.what_they_value}
              />
            </Grid>
            {/*** option features grid */}
            <Grid
              item
              xs={12}
              sm={8}
              sx={{ minHeight: "300px", padding: "10px 30px" }}
            >
              <Grid container sx={{ p: 3, columnGap: 1, rowGap: 1 }}>
                <FeatureOption
                  image={Image1}
                  label="Total open days"
                  setShowFeature={setShowFeature}
                  setSelectedFeature={setSelectedFeature}
                  totalOpenDays={totalOpenDays}
                />

                <FeatureOption
                  image={Image2}
                  label="Chat about it"
                  setShowFeature={setShowFeature}
                  setSelectedFeature={setSelectedFeature}
                />

                <FeatureOption
                  image={Image3}
                  label="Email the lead"
                  setShowFeature={setShowFeature}
                  setSelectedFeature={setSelectedFeature}
                  selectedLead={selectedLead}
                />

                {!selectedLead?.is_dropped && !selectedLead?.is_won && (
                  <FeatureOption
                    image={Image5}
                    label="Last action days"
                    setShowFeature={setShowFeature}
                    setSelectedFeature={setSelectedFeature}
                    lastActionDays={lastActionDays}
                  />
                )}

                {!selectedLead?.is_dropped && !selectedLead?.is_won && (
                  <FeatureOption
                    image={Image7}
                    label="Schedule a task"
                    setShowFeature={setShowFeature}
                    setSelectedFeature={setSelectedFeature}
                  />
                )}

                {!selectedLead?.is_dropped && !selectedLead?.is_won && (
                  <FeatureOption
                    image={Image8}
                    label="Transfer it"
                    setShowFeature={setShowFeature}
                    setSelectedFeature={setSelectedFeature}
                  />
                )}
                {!selectedLead?.is_dropped && !selectedLead?.is_won && (
                  <FeatureOption
                    image={Image13}
                    label="Activities"
                    setShowFeature={setShowFeature}
                    setSelectedFeature={setSelectedFeature}
                    totalActivities={totalActivities}
                  />
                )}

                {/* <FeatureOption
                  image={Image9}
                  label="View Notes"
                  setShowFeature={setShowFeature}
                  setSelectedFeature={setSelectedFeature}
                  notesCount={notesCount}
                /> */}
                {!selectedLead?.is_dropped && !selectedLead?.is_won && (
                  <FeatureOption
                    image={Image10}
                    label="Add a note"
                    setShowFeature={setShowFeature}
                    setSelectedFeature={setSelectedFeature}
                    notesCount={notesCount}
                  />
                )}

                {!selectedLead?.is_dropped && !selectedLead?.is_won && (
                  <FeatureOption
                    image={Image12}
                    label="Drop it!"
                    setShowFeature={setShowFeature}
                    setSelectedFeature={setSelectedFeature}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};

const InfoRow = ({ label, value }) => {
  const theme = useTheme();
  useEffect(() => {
    console.log(label, value);
  }, []);
  return (
    <Grid
      container
      sx={{
        gap: "0px",
        mt: 0.3,
      }}
    >
      <Grid
        item
        xs={5}
        sx={{
          overflow: "hidden",
          background: theme.palette.colorthree.main,
          py: 0.85,
          px: 1,
        }}
      >
        <Typography
          variant="body2"
          sx={{
            fontSize: "0.75rem",
            fontWeight: "700",
          }}
        >
          {label}
        </Typography>
      </Grid>
      <Grid
        item
        xs={6.5}
        sx={{
          background: theme.palette.colorthree.main,
          py: 0.85,
          px: 1,
          overflow: "hidden",
        }}
      >
        {label == "Social Media Links" ? (
          <>
            {value?.length < 1 ? (
              <Typography variant="body2" sx={{ fontSize: "0.8rem" }}>
                N/A
              </Typography>
            ) : (
              value?.map((v) => (
                <Typography variant="body2" sx={{ fontSize: "0.8rem" }}>
                  <a href={v?.accountLink} target="_blank">
                    {v?.socialMedia}
                  </a>

                  {/* /{v?.accountLink} */}
                </Typography>
              ))
            )}
          </>
        ) : (
          <Typography variant="body2" sx={{ fontSize: "0.8rem" }}>
            {value}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

const FeatureOption = ({
  image,
  label,
  setShowFeature,
  setSelectedFeature,
  selectedLead,
  notesCount,
  totalPoints,
  lastActionDays,
  totalOpenDays,
  milestoneLevel,
  totalActivities,
}) => {
  const theme = useTheme();
  const user = useSelector((state) => state.user);
  return (
    <Grid item md={3.3} sm={3.3} xs={5.8}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          // justifyContent: "space-between",
          alignItems: "center",
          gap: "6px",
          height: "100%",
          p: 1,
          cursor: "pointer",
          "&:hover": { backgroundColor: theme.palette.colorthree.main },
        }}
        onClick={() => {
          if (label !== "Email the lead") {
            setSelectedFeature(label);
            setShowFeature(true);
          } else {
            api.post("/addRelationshipActivity", {
              relationship_id: selectedLead?.info_reference_number,
              done_by: user?.id,
              action: "Email",
              note: "Email icon clicked!",
            });
          }
        }}
      >
        <img
          src={image}
          style={{ width: "auto", height: "55px", minWidth: "45px" }}
        />
        <Typography
          variant="p"
          sx={{
            textAlign: "center",
            fontSize: "0.8rem",
            mt: 0.3,
            cursor: "pointer",
            "&:hover": { color: theme.palette.primary.main },
          }}
          // onClick={() => {
          //   if (label !== "Email the lead") {
          //     setSelectedFeature(label);
          //     setShowFeature(true);
          //   }
          // }}
        >
          {label == "Email the lead" ? (
            <a
              style={{ textDecoration: "none", color: "#000e" }}
              href={`mailto:${selectedLead?.email}`}
            >
              {label}
            </a>
          ) : (
            label
          )}
        </Typography>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            mt: 0.2,
            gap: "5px",
          }}
        >
          {(totalOpenDays ||
            lastActionDays ||
            milestoneLevel ||
            totalPoints ||
            totalActivities ||
            totalActivities == 0 ||
            notesCount ||
            notesCount == 0) && (
            <Typography
              variant="p"
              sx={{
                textAlign: "center",
                fontSize: "0.8rem",
                cursor: "pointer",
                color: theme.palette.primary.main,
                backgroundColor: theme.palette.colorthree.main,
                width: "100%",
                padding: "3px 10px",

                mt: 0,
              }}
            >
              {totalOpenDays && totalOpenDays}
              {lastActionDays && lastActionDays}
              {milestoneLevel && `Level ${milestoneLevel}`}
              {totalActivities && `Activities ${totalActivities}`}
              {totalPoints && `Points: ${totalPoints}`}
              {(notesCount || notesCount == 0) && `Total notes: ${notesCount}`}
            </Typography>
          )}
        </Box>
      </Box>
    </Grid>
  );
};

export default IndividualLeadFeatures;
