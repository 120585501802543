import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Input,
  Typography,
  useTheme,
  CircularProgress,
  Grid,
  IconButton,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import * as filestack from "filestack-js";
import { api } from "../../axios/api";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";

const ManageAds = () => {
  const theme = useTheme();
  const [changeInterval, setChangeInterval] = useState(2);
  const [mediaFiles, setMediaFiles] = useState([]);
  const [showMediaForm, setShowMediaForm] = useState(false);
  const [detailUrl, setDetailUrl] = useState("");
  const [detailUrlError, setDetailUrlError] = useState("");
  const [inputFile, setInputFile] = useState("");

  const [isImageLoading, setIsImageLoading] = useState(false);
  const [inputFileUrl, setInputFileUrl] = useState("");
  const [currentMedia, setCurrentMedia] = useState({});

  useEffect(() => {
    // Fetch change interval data
    const fetchData = async () => {
      try {
        const response = await api.get("/ads/fetch-ad-data/" + 1);
        const data = await response.data;
        console.log(data);
        setChangeInterval(
          data.data.change_interval ? data.data.change_interval : 2
        );
        setMediaFiles(
          data?.data?.media_files?.length > 0 ? data?.data?.media_files : []
        );
      } catch (error) {
        console.error("Error fetching change interval:", error);
      }
    };

    fetchData();
  }, []); // Run once on component mount

  const handleIntervalChange = (e) => {
    setChangeInterval(e.target.value);
  };

  const updateInterval = async () => {
    try {
      await api.put(`/ads/update-change-interval/${1}`, {
        changeInterval,
      });
      toast.success("Media change interval updated!");
    } catch (error) {
      console.error("Error updating change interval:", error);
      toast.error("Failed to update media change interval. Please try again.");
    }
  };

  const uploadFileToBackend = async (file, isJSON) => {
    const formData = new FormData();
    isJSON
      ? formData.append(
          "files",
          new Blob([file], { type: "application/json" }),
          "data.json"
        )
      : formData.append("files", file);

    try {
      const response = await api.post("/files/upload/space", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("url", response?.data?.url);
      return response.data.url; // Assuming the response contains the S3 URL
    } catch (error) {
      // console.error(error);
      setIsImageLoading(false);
      // toast.error("unable to cntinue at the moment!");
      return null;
    }
  };
  useEffect(() => {
    const updateCurrentMedia = async () => {
      if (!inputFile) return;
      const selectedFile = inputFile;

      // Check if the selected file is an image or video
      const isImage = selectedFile.type.startsWith("image");
      const isVideo = selectedFile.type.startsWith("video");

      if (!isImage && !isVideo) {
        toast.error("Invalid file type. Please select an image or video.");
        return;
      }

      // Additional check for video file type
      if (isVideo && selectedFile.type !== "video/mp4") {
        toast.error("Invalid video format. Please select an MP4 video.");
        return;
      }

      setIsImageLoading(true);

      // const client = filestack.init(process.env.REACT_APP_FILESTACK_API);

      try {
        // const res = await client.upload(selectedFile, {});
        const url = await uploadFileToBackend(selectedFile);
        console.log("Uploaded successfully", url);
        setIsImageLoading(false);

        // Update the state based on the file type
        if (isImage) {
          // If it's an image, you might want to store the URL in the mediaFiles array
          setCurrentMedia({ ...currentMedia, type: "image", url: url });
          // setInputFileUrl(res?.url);
        } else if (isVideo) {
          // If it's a video, handle it accordingly
          // Update the state, save the URL or perform any other actions
          setCurrentMedia({ ...currentMedia, type: "video", url: url });
          // setInputFileUrl(res?.url);
        }
      } catch (error) {
        console.error("Error uploading file: ", error);
        setIsImageLoading(false);
        toast.error("Error uploading file. Please try again.");
      }
    };
    updateCurrentMedia();
  }, [inputFile]);
  useEffect(() => {
    // if (!detailUrl) return;
    setCurrentMedia({ ...currentMedia, detail_url: detailUrl });
  }, [detailUrl]);

  // const (e)=>setInputFile(e.target.files[0]) = async (e) => {
  //   const selectedFile = e.target.files[0];

  //   // Check if the selected file is an image or video
  //   const isImage = selectedFile.type.startsWith("image");
  //   const isVideo = selectedFile.type.startsWith("video");

  //   if (!isImage && !isVideo) {
  //     toast.error("Invalid file type. Please select an image or video.");
  //     return;
  //   }

  //   // Additional check for video file type
  //   if (isVideo && selectedFile.type !== "video/mp4") {
  //     toast.error("Invalid video format. Please select an MP4 video.");
  //     return;
  //   }

  //   setIsImageLoading(true);

  //   const client = filestack.init(process.env.REACT_APP_FILESTACK_API);

  //   try {
  //     const res = await client.upload(selectedFile, {});
  //     console.log("Uploaded successfully", res);
  //     setIsImageLoading(false);

  //     // Update the state based on the file type
  //     if (isImage) {
  //       // If it's an image, you might want to store the URL in the mediaFiles array
  //       setMediaFiles([
  //         ...mediaFiles,
  //         { type: "image", url: res?.url, detail_url: detailUrl },
  //       ]);
  //       setInputFileUrl(res?.url);
  //     } else if (isVideo) {
  //       // If it's a video, handle it accordingly
  //       // Update the state, save the URL or perform any other actions
  //       setMediaFiles([
  //         ...mediaFiles,
  //         { type: "video", url: res?.url, detail_url: detailUrl },
  //       ]);
  //       setInputFileUrl(res?.url);
  //     }
  //   } catch (error) {
  //     console.error("Error uploading file: ", error);
  //     setIsImageLoading(false);
  //     toast.error("Error uploading file. Please try again.");
  //   }
  // };
  const handleFileSubmit = async () => {
    console.log("current media at handleFileSubmit", handleFileSubmit);
    if (
      currentMedia?.type &&
      currentMedia?.url &&
      currentMedia?.detail_url &&
      !detailUrlError
    ) {
      let medialFilesSuper = [...mediaFiles, currentMedia];
      setMediaFiles(medialFilesSuper);
      try {
        await api.put(`/ads/update-media-files/${1}`, {
          mediaFiles: medialFilesSuper,
        });
        toast.success("Media change interval updated!");
        setInputFileUrl("");
        setDetailUrl("");
        setCurrentMedia({});
        setShowMediaForm(false);
      } catch (error) {
        console.error("Error updating change interval:", error);
        toast.error(
          "Failed to update media change interval. Please try again."
        );
      }
    } else {
      toast.error("fill up all the details!");
    }
  };
  useEffect(() => {
    setDetailUrlError("");
    if (!detailUrl) {
      setDetailUrlError("Required");
    } else {
      setDetailUrlError("");
      // // Validate the detail URL format
      // const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
      // const isValidUrl = urlPattern.test(detailUrl);

      // if (!isValidUrl) {
      //   setDetailUrlError("Invalid URL format");
      // } else {
      //   setDetailUrlError("");
      // }
    }
  }, [detailUrl]);

  const deleteMedia = async (media) => {
    if (!media) return;
    let Filter = mediaFiles?.filter((m) => m != media);
    if (Filter?.length == mediaFiles?.length) {
      toast.error("unable to delete!");
    }

    try {
      await api.put(`/ads/update-media-files/${1}`, {
        mediaFiles: Filter,
      });
      toast.success("Delete Successfully!");
      setMediaFiles(Filter);
    } catch (error) {
      console.error("Error updating change interval:", error);
      toast.error("Failed to update media change interval. Please try again.");
    }
  };

  const moveMediaUp = async (index) => {
    if (index > 0) {
      const updatedMediaFiles = [...mediaFiles];
      [updatedMediaFiles[index - 1], updatedMediaFiles[index]] = [
        updatedMediaFiles[index],
        updatedMediaFiles[index - 1],
      ];
      setMediaFiles(updatedMediaFiles);
      await api.put(`/ads/update-media-files/${1}`, {
        mediaFiles: updatedMediaFiles,
      });
    }
  };

  const moveMediaDown = async (index) => {
    if (index < mediaFiles.length - 1) {
      const updatedMediaFiles = [...mediaFiles];
      [updatedMediaFiles[index], updatedMediaFiles[index + 1]] = [
        updatedMediaFiles[index + 1],
        updatedMediaFiles[index],
      ];
      setMediaFiles(updatedMediaFiles);
      await api.put(`/ads/update-media-files/${1}`, {
        mediaFiles: updatedMediaFiles,
      });
    }
  };

  useEffect(() => {
    console.log("current");
  }, [currentMedia]);
  return (
    <Box>
      <ToastContainer />
      <Typography variant="h6">Ads</Typography>
      <Box sx={{ my: 3 }}>
        <Typography
          variant="body2"
          sx={{ fontWeight: "600", color: theme.palette.heading.main }}
        >
          Change Interval(in seconds)
        </Typography>
        <Input
          type="number"
          value={changeInterval}
          onChange={handleIntervalChange}
        />
        <Button
          size="small"
          variant="contained"
          sx={{ ml: 2 }}
          onClick={() => updateInterval()}
        >
          Update
        </Button>
      </Box>
      <Box sx={{ my: 4 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Typography
            variant="body2"
            sx={{ fontWeight: "600", color: theme.palette.heading.main }}
          >
            Media Files (shown as ad)
          </Typography>
          <Button
            variant="outlined"
            onClick={() => setShowMediaForm(!showMediaForm)}
          >
            + Add Media
          </Button>
        </Box>
        {showMediaForm && (
          <Box sx={{ my: 2, background: "white", p: 3 }}>
            <Typography>Select image or reel</Typography>
            <Box>
              <Input
                type="file"
                sx={{ mt: 2, mb: 1 }}
                onChange={(e) => setInputFile(e.target.files[0])}
              />
              {isImageLoading && <CircularProgress />}
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography>Add redirection url</Typography>

              <Input
                placeholder="https://example.com"
                type="text"
                value={detailUrl}
                sx={{ mt: 1, mb: 1 }}
                onChange={(e) => setDetailUrl(e.target.value)}
              />
              <Typography sx={{ color: "red", fontSize: "0.8rem" }}>
                {detailUrlError}
              </Typography>
            </Box>
            <Box>
              <Button
                variant="contained"
                size="small"
                disabled={!currentMedia?.url || !currentMedia?.detail_url}
                onClick={handleFileSubmit}
                sx={{ mt: 2 }}
              >
                Submit
              </Button>
            </Box>
          </Box>
        )}

        <Grid
          container
          gap={4}
          rowGap={12}
          sx={{ background: "#fff", p: 3, mt: 1, borderRadius: "4px", pt: 5 }}
        >
          {mediaFiles?.length < 1 ? (
            <Typography>No media added.</Typography>
          ) : (
            mediaFiles?.map((m, index) => (
              <Grid
                item
                xs={3}
                sx={{
                  maxHeight: "400px",
                  boxShadow:
                    "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                  position: "relative",
                }}
              >
                {m?.type == "image" ? (
                  <img
                    src={m?.url}
                    height="100%"
                    style={{ maxHeight: "100%", maxWidth: "100%" }}
                  />
                ) : (
                  m?.type == "video" && (
                    // <video
                    //   src={m?.url}
                    //   // style={{ height: "100%", width: "auto" }}
                    //   type="video/mp4"
                    //   style={{ maxHeight: "100%", maxWidth: "100%" }}
                    // />
                    <video
                      controls
                      width="400"
                      height="300"
                      style={{ maxHeight: "100%", maxWidth: "100%" }}
                    >
                      <source src={m?.url} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  )
                )}
                <Box
                  sx={{
                    position: "absolute",
                    top: "-36px",
                    left: "0px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "20px",
                    width: "100%",
                  }}
                >
                  <Typography variant="body2" sx={{ fontWeight: "600" }}>
                    {" "}
                    Priority: {index + 1}
                  </Typography>
                  <Box>
                    <IconButton size="small" onClick={() => moveMediaUp(index)}>
                      <ArrowCircleUpIcon
                        sx={{
                          color:
                            index < 1 ? "gray" : theme.palette.primary.main,
                        }}
                      />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => moveMediaDown(index)}
                    >
                      <ArrowCircleDownIcon
                        sx={{
                          color:
                            index == mediaFiles?.length - 1
                              ? "gray"
                              : theme.palette.primary.main,
                        }}
                      />
                    </IconButton>
                  </Box>
                </Box>
                <Box
                  sx={{ position: "absolute", bottom: "20px", right: "20px" }}
                >
                  <Button
                    variant="contained"
                    size="small"
                    sx={{ mr: 1 }}
                    onClick={() => deleteMedia(m)}
                  >
                    delete
                  </Button>
                  <a href={m?.detail_url} target="_blank">
                    <Button
                      variant="outlined"
                      size="small"
                      sx={{ background: "#fff" }}
                    >
                      Click for details
                    </Button>
                  </a>
                </Box>
              </Grid>
            ))
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default ManageAds;
