import React, { useEffect, useState } from "react";
import { api } from "../../axios/api";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  useTheme,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
  Modal,
} from "@mui/material";
import * as filestack from "filestack-js";
import * as XLSX from "xlsx";
import UserDetailsCard from "../Agent/UserDetails";

const Analytics = () => {
  const [analyticsOption, setAnalyticsOption] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [downloadFileUrl, setDownloadFileUrl] = useState("");
  const [showDownloadButton, setShowDownloadButton] = useState("");
  const [accounts, setAccounts] = useState([]);
  const [accountsByActivities, setAccountByActivities] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [selectedFilter2, setSelectedFilter2] = useState("leads created");
  const theme = useTheme();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  useEffect(() => {
    // Set default values when the component mounts
    const defaultStartDate = new Date();
    defaultStartDate.setMonth(defaultStartDate.getMonth() - 1); // One month behind
    setStartDate(defaultStartDate.toISOString().split("T")[0]); // Format as YYYY-MM-DD

    const defaultEndDate = new Date();
    defaultEndDate.setDate(defaultEndDate.getDate() + 1); // Tomorrow
    setEndDate(defaultEndDate.toISOString().split("T")[0]); // Format as YYYY-MM-DD
  }, []); // Empty dependency array ensures this effect runs only once when the component mounts

  useEffect(() => {
    api
      .get(`/users/${selectedFilter}`)
      .then((res) => {
        console.log("users", res?.data);
        setAccounts(
          res?.data?.filter((d) => d?.email_address !== "admin@edge360.co")
        );
      })
      .catch((err) => console.log(err));
  }, [selectedFilter, analyticsOption]);
  useEffect(() => {
    console.log("all accounts");

    const fetchAccountActivities = async () => {
      const accountActivities = await Promise.all(
        accounts?.map(async (acc) => {
          console.log("start date", startDate);
          console.log("end date", endDate);
          let totalLeadsCreated = 0;
          let totalLeadsAddedToNoticeBoard = 0;
          let totalNetworksCreated = 0;

          try {
            const leadsCreatedResponse = await api.get(
              "/prospects/byOwnerId/" + acc?.id
            );
            console.log("response prospects", leadsCreatedResponse?.data);
            const filteredLeads = await leadsCreatedResponse?.data?.filter(
              (lead) =>
                (!startDate || new Date(lead.date) >= new Date(startDate)) &&
                (!endDate || new Date(lead.date) <= new Date(endDate))
            );
            console.log("filteredLeads", filteredLeads);
            totalLeadsCreated = filteredLeads?.length || 0;
            console.log("totalLeadsCreated", totalLeadsCreated);
          } catch (error) {
            console.log(error);
          }

          try {
            const leadsAddedResponse = await api.get(
              "/noticeBoardLeads/byUploaderId/" + acc?.id
            );
            console.log(
              "response notice board leads",
              leadsAddedResponse?.data
            );
            const filteredLeads = await leadsAddedResponse?.data?.filter(
              (lead) =>
                (!startDate ||
                  new Date(lead.date_posted) >= new Date(startDate)) &&
                (!endDate || new Date(lead.date_posted) <= new Date(endDate))
            );
            console.log("filteredLeads", filteredLeads);
            totalLeadsAddedToNoticeBoard = filteredLeads?.length || 0;
            console.log(
              "totalLeadsAddedToNoticeBoard",
              totalLeadsAddedToNoticeBoard
            );
          } catch (error) {
            console.log(error);
          }

          try {
            const networkAddedResponse = await api.get(
              "/relationships/byOwnerId/" + acc?.id
            );
            console.log("response relationships", networkAddedResponse?.data);
            const filteredLeads = await networkAddedResponse?.data?.filter(
              (lead) =>
                (!startDate ||
                  new Date(lead.date_created) >= new Date(startDate)) &&
                (!endDate || new Date(lead.date_created) <= new Date(endDate))
            );
            console.log("filteredLeads", filteredLeads);
            totalNetworksCreated = filteredLeads?.length || 0;
            console.log("totalNetworksCreated", totalNetworksCreated);
          } catch (error) {
            console.log(error);
          }
          let obj = {
            id: acc?.id,
            email: acc?.email_address,
            profileType: acc?.profile_type,
            accountType: acc?.account_type,
            organizationName: acc?.organization_name,
            leadsCreated: totalLeadsCreated,
            leadsAddedToNoticeBoard: totalLeadsAddedToNoticeBoard,
            networksAdded: totalNetworksCreated,
          };
          console.log("resultant obj", obj);
          return {
            id: acc?.id,
            email: acc?.email_address,
            profileType: acc?.profile_type,
            accountType: acc?.account_type,
            organizationName: acc?.organization_name,
            leadsCreated: totalLeadsCreated,
            leadsAddedToNoticeBoard: totalLeadsAddedToNoticeBoard,
            networksAdded: totalNetworksCreated,
          };
        })
      );

      setAccountByActivities(accountActivities);
    };

    fetchAccountActivities();
  }, [selectedFilter2, accounts, startDate, endDate]);
  useEffect(() => {
    console.log("accounts by activitie", accountsByActivities);
  }, [accountsByActivities]);

  const getNormalCase = (str) => {
    return str.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
      return str.toUpperCase();
    });
  };
  const uploadFileToBackend = async (file, isJSON) => {
    const formData = new FormData();
    isJSON
      ? formData.append(
          "files",
          new Blob([file], { type: "application/json" }),
          "data.json"
        )
      : formData.append("files", file);

    try {
      const response = await api.post("/files/upload/space", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("url", response?.data?.url);
      return response.data.url; // Assuming the response contains the S3 URL
    } catch (error) {
      // console.error(error);
      setIsLoading(false);
      // toast.error("unable to cntinue at the moment!");
      return null;
    }
  };
  const downloadReport = async () => {
    setIsLoading(true);
    const fileName =
      analyticsOption == 1
        ? `${selectedFilter.replaceAll(" ", "_")}_Report.xlsx`
        : `${selectedFilter2.replaceAll(" ", "_")}_Report.xlsx`;

    // Create a new workbook and add a worksheet
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(
      analyticsOption == 1 ? accounts : accountsByActivities
    );

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Generate an XLSX file
    const wbout = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array",
    });

    // const client = filestack.init(process.env.REACT_APP_FILESTACK_API);
    const file = new File([wbout], fileName, {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    try {
      const url = await uploadFileToBackend(file);
      setIsLoading(false);
      setShowDownloadButton(true);
      setDownloadFileUrl(url);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
    // client
    //   .upload(file, {})
    //   .then((res) => {
    //     console.log("Uploaded successfully", res);
    //     // window.open(res.url, "_blank");

    //     setIsLoading(false);
    //     setShowDownloadButton(true);
    //     setDownloadFileUrl(res?.url);
    //   })
    //   .catch((error) => {
    //     console.error("Error uploading file: ", error);
    //     setIsLoading(false);
    //   });

    if (analyticsOption == 1) {
      //for accounts reports
    }

    if (analyticsOption == 2) {
      //for activities reports
    }
  };

  const [accountToView, setAccountToView] = useState({});

  const [showAccountDetails, setShowAccountDetails] = useState(false);

  const handleOpenAccountDetails = () => setShowAccountDetails(true);
  const handleCloseAccountDetails = () => setShowAccountDetails(false);

  return (
    <Box>
      <Box
        sx={{
          borderBottom: "1px solid lightgray",
          my: 2,
          display: "flex",
        }}
      >
        <Modal
          open={showAccountDetails}
          onClose={handleCloseAccountDetails}
          sx={{ zIndex: "1000000000" }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              minWidth: 400,
              maxHeight: "90vh",
              overflow: "auto",
              bgcolor: "background.paper",

              boxShadow: 24,
              p: 4,
            }}
          >
            <UserDetailsCard user={accountToView} />
          </Box>
        </Modal>
        <Typography
          sx={{
            // border: "1px solid lightgray",
            borderTopLeftRadius: "5px",
            p: 1,
            cursor: "pointer",
            background:
              analyticsOption == 1 ? theme.palette.primary.main : " #fff",
            color: analyticsOption == 1 ? "#fff" : theme.palette.primary.main,
          }}
          onClick={() => setAnalyticsOption(1)}
        >
          All Accounts
        </Typography>
        <Typography
          sx={{
            // border: "1px solid lightgray",
            p: 1,
            cursor: "pointer",
            borderTopRightRadius: "5px",
            background:
              analyticsOption == 2 ? theme.palette.primary.main : " #fff",
            color: analyticsOption == 2 ? "#fff" : theme.palette.primary.main,
          }}
          onClick={() => setAnalyticsOption(2)}
        >
          Activities
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Box>
          {showDownloadButton ? (
            <Box sx={{ display: "flex" }}>
              <a href={downloadFileUrl} target="_blank">
                <Typography>View File</Typography>
              </a>
              <Button
                size="small"
                sx={{ ml: 2 }}
                onClick={() => setShowDownloadButton(false)}
                variant="contained"
              >
                Download again
              </Button>
            </Box>
          ) : (
            <Button
              size="small"
              variant="contained"
              onClick={() => downloadReport()}
            >
              Download Report
            </Button>
          )}
        </Box>
      </Box>

      <Box>
        {analyticsOption == "1" ? (
          <Box>
            {" "}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                mb: 3,
                alignItems: "center",
              }}
            >
              <Typography variant="h6">
                {getNormalCase(`${selectedFilter} accounts`)}
              </Typography>
              <Box sx={{ mt: 2 }}>
                <FormControl>
                  {/* <InputLabel id="filter-label">Filter</InputLabel> */}
                  <Select
                    labelId="filter-label"
                    id="filter"
                    value={selectedFilter}
                    onChange={(e) => setSelectedFilter(e.target.value)}
                  >
                    <MenuItem value="all">All Accounts</MenuItem>
                    <MenuItem value="free">Free Accounts</MenuItem>
                    <MenuItem value="paid">Paid Accounts</MenuItem>
                    <MenuItem value="employee">Employee Accounts</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow sx={{}}>
                    {Object.keys(accounts[0] || {})
                      ?.filter((s) => s !== "profile_image")
                      .map((key, index) => (
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            color: theme.palette.primary.main,
                          }}
                          key={index}
                        >
                          {getNormalCase(key)}
                        </TableCell>
                      ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {accounts
                    ?.map(({ lastActionTimeInMilliSeconds, ...rest }) => rest)
                    ?.map((row, index) => (
                      <TableRow
                        sx={{
                          cursor: "pointer",
                          "&:hover": {
                            background: theme.palette.selectedMenuBack.main,
                          },
                        }}
                        key={index}
                        onClick={() => {
                          setAccountToView(row);
                          setShowAccountDetails(true);
                        }}
                      >
                        {Object.keys(row)
                          ?.filter((s) => s !== "profile_image")
                          .map((key, index) => (
                            <TableCell
                              sx={{ whiteSpace: "nowrap" }}
                              key={index}
                            >
                              {typeof row[key] === "boolean"
                                ? row[key]
                                  ? "true"
                                  : "false"
                                : key === "created_on"
                                ? new Date(row[key])?.toDateString()
                                : row[key]}
                            </TableCell>
                          ))}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        ) : (
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                mb: 3,
                alignItems: "center",
                mt: 3,
              }}
            >
              <Box sx={{ display: "flex", gap: "30px", alignItems: "center" }}>
                <Typography variant="h6">
                  {/* {getNormalCase(`${selectedFilter} accounts`)} */}
                  Accounts activities
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: "15px",
                    alignItems: "center",
                    my: 1,
                  }}
                >
                  <Typography>From</Typography>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "0.8rem",
                        color: theme.palette.primary.main,
                      }}
                    >
                      Start Date
                    </Typography>
                    <Input
                      type="date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </Box>
                  <Typography>To</Typography>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "0.8rem",
                        color: theme.palette.primary.main,
                      }}
                    >
                      End Date
                    </Typography>
                    <Input
                      type="date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </Box>
                </Box>
              </Box>

              <Box sx={{ mt: 2 }}>
                <FormControl>
                  {/* <InputLabel id="filter-label">Filter</InputLabel> */}
                  <Select
                    labelId="filter-label"
                    id="filter"
                    value={selectedFilter2}
                    onChange={(e) => setSelectedFilter2(e.target.value)}
                  >
                    <MenuItem value="leads created">Leads Created</MenuItem>
                    <MenuItem value="notice board leads added">
                      Notice Board Leads Added
                    </MenuItem>
                    <MenuItem value="networks added">Networks Created</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>

            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow sx={{}}>
                    {Object.keys(accountsByActivities[0] || {})
                      ?.filter((s) => s !== "profile_image")
                      ?.filter((s) =>
                        selectedFilter2 == "leads created"
                          ? s != "leadsAddedToNoticeBoard" &&
                            s != "networksAdded"
                          : selectedFilter2 == "networks added"
                          ? s != "leadsCreated" &&
                            s != "leadsAddedToNoticeBoard"
                          : s != "leadsCreated" && s != "networksAdded"
                      )
                      .map((key, index) => (
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            color: theme.palette.colortwo.main,
                          }}
                          key={index}
                        >
                          {getNormalCase(key)}
                        </TableCell>
                      ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {accountsByActivities
                    ?.map(({ lastActionTimeInMilliSeconds, ...rest }) => rest)
                    ?.map((row, index) => (
                      <TableRow key={index}>
                        {Object.keys(row)
                          ?.filter((s) => s !== "profile_image")
                          ?.filter((s) =>
                            selectedFilter2 == "leads created"
                              ? s != "leadsAddedToNoticeBoard" &&
                                s != "networksAdded"
                              : selectedFilter2 == "networks added"
                              ? s != "leadsCreated" &&
                                s != "leadsAddedToNoticeBoard"
                              : s != "leadsCreated" && s != "networksAdded"
                          )
                          .map((key, index) => (
                            <TableCell
                              sx={{ whiteSpace: "nowrap" }}
                              key={index}
                            >
                              {typeof row[key] === "boolean"
                                ? row[key]
                                  ? "true"
                                  : "false"
                                : key === "created_on"
                                ? new Date(row[key])?.toDateString()
                                : row[key]}
                            </TableCell>
                          ))}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </Box>
      {/******************  */}
    </Box>
  );
};

export default Analytics;
