import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
  IconButton,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { api } from "../../../axios/api";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import { Edit, Trash2 } from "react-feather";
import { ToastContainer, toast } from "react-toastify";
import AddTargetForm from "./AddTargetForm";

const SetTragets = () => {
  const theme = useTheme();
  const [users, setUsers] = useState([]);
  const user = useSelector((state) => state.user);
  const [showAddForm, setShowAddForm] = useState(false);
  const [editOptions, setEditOptions] = useState({});

  const [organizationInformation, setOrganizationInformation] = useState();

  useEffect(() => {
    api
      .get("/organization")
      .then((res) => {
        console.log("res?.data", res?.data);
        setOrganizationInformation(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    console.log("user at show contact", user);
  }, [user]);
  useEffect(() => {
    setUsers([]);
    api
      .get("/getAllUsersByAdmin")
      .then((res) => {
        console.log("response from getting users", res?.data);
        let arr = [...res?.data]; // Create a shallow copy of the existing array
        // arr.unshift(user); // Insert user at index 1

        setUsers(arr);
      })
      .catch((err) => console.log("err", err));
    if (!showAddForm) {
      setEditOptions({});
    }
  }, [user, showAddForm]);
  return (
    <Box sx={{ padding: "20px 30px" }}>
      <ToastContainer />
      {!organizationInformation?.id ? (
        <Box sx={{ display: "flex", alignItems: "center", mt: 2, gap: "10px" }}>
          {/* <InfoIcon /> */}
          <Typography sx={{}}>Update your organization details</Typography>
        </Box>
      ) : users?.length < 1 ? (
        <Box sx={{ display: "flex", alignItems: "center", mt: 2, gap: "10px" }}>
          <InfoIcon />
          <Typography sx={{}}>
            {" "}
            Add team members to your organization for setting targets.
          </Typography>
        </Box>
      ) : (
        <>
          <Box sx={{}}>
            <Typography
              variant="h6"
              sx={{
                color: theme.palette.heading.main,
                fontSize: "1.3rem",
                fontWeight: "600",
              }}
            >
              {"Targets"}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: theme.palette.heading.main,
              }}
            >
              {
                "Set Daily targets for your team members for fixed amount of time"
              }
            </Typography>
          </Box>

          {showAddForm ? (
            <AddTargetForm
              editOptions={editOptions}
              setShowAddForm={setShowAddForm}
              organizationInformation={organizationInformation}
            />
          ) : (
            <>
              <Box sx={{ marginTop: "20px" }}>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={12}>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell
                              sx={{
                                width: "50px",
                                color: theme.palette.colortwo.main,
                                whiteSpace: "nowrap",
                              }}
                            >
                              ID
                            </TableCell>
                            {/* <TableCell>Customer ID</TableCell> */}
                            <TableCell
                              sx={{
                                color: theme.palette.colortwo.main,
                                whiteSpace: "nowrap",
                              }}
                            >
                              Team member
                            </TableCell>
                            <TableCell
                              sx={{
                                color: theme.palette.colortwo.main,
                                whiteSpace: "nowrap",
                              }}
                            >
                              Daily Target
                            </TableCell>
                            <TableCell
                              sx={{
                                color: theme.palette.colortwo.main,
                                whiteSpace: "nowrap",
                              }}
                            >
                              Start Date
                            </TableCell>
                            <TableCell
                              sx={{
                                color: theme.palette.colortwo.main,
                                whiteSpace: "nowrap",
                              }}
                            >
                              End Date
                            </TableCell>
                            <TableCell
                              sx={{
                                color: theme.palette.colortwo.main,
                                whiteSpace: "nowrap",
                              }}
                            >
                              Actions
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {users?.map((contact, i) => (
                            <NormalUser
                              contact={contact}
                              i={i}
                              users={users}
                              setUsers={setUsers}
                              setShowAddForm={setShowAddForm}
                              setEditOptions={setEditOptions}
                              organizationInformation={organizationInformation}
                            />
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
        </>
      )}
    </Box>
  );
};
const NormalUser = ({
  contact,
  i,
  users,
  setUsers,
  setEditOptions,
  setShowAddForm,
  organizationInformation,
}) => {
  const theme = useTheme();
  const [isBlocked, setIsBlocked] = useState(false);
  const [target, setTarget] = useState({});
  useEffect(() => {
    api.get("/target/" + contact?.id).then((res) => setTarget(res?.data));
  }, [contact]);

  const deleteCustomer = () => {
    console.log("contact id", contact?.id);
    api
      .delete("/deleteUserByAdmin/" + contact?.id)
      .then((res) => {
        console.log("delete user response", res?.data);
        toast.success("Deleted Successfully!");
        // setUsers(users?.filter((c) => c?.id !== contact?.id));
        setIsBlocked(true);
      })
      .catch((err) => {
        console.log("error in deleting user", err);
        toast.error("unable to delete!");
      });
  };

  const updateTarget = () => {
    setEditOptions(contact);
    setShowAddForm(true);
  };
  return (
    <TableRow
      key={contact?.id}
      style={{
        backgroundColor:
          i % 2 == 0 ? theme.palette.colorthree.main : "transparent",
      }}
    >
      <TableCell
        sx={{ width: "50px", padding: "8px 20px", whiteSpace: "nowrap" }}
      >
        {contact?.id}
      </TableCell>
      {/* <TableCell>{contact?.customer_id}</TableCell> */}
      <TableCell sx={{ padding: "8px 20px", whiteSpace: "nowrap" }}>
        {contact?.name}({contact?.email_address})
      </TableCell>
      <TableCell sx={{ padding: "8px 20px", whiteSpace: "nowrap" }}>
        {target?.id
          ? `${target?.daily_target} (${organizationInformation?.organization_currency})`
          : "not added."}
      </TableCell>
      <TableCell sx={{ padding: "8px 20px", whiteSpace: "nowrap" }}>
        {target?.id
          ? new Date(target?.start_date)?.toDateString()
          : "not added."}
      </TableCell>
      <TableCell sx={{ padding: "8px 20px", whiteSpace: "nowrap" }}>
        {target?.id ? new Date(target?.end_date)?.toDateString() : "not added"}
      </TableCell>
      <TableCell sx={{ padding: "8px 20px", whiteSpace: "nowrap" }}>
        <Box sx={{ display: "flex", gap: "5px", flexDirection: "row" }}>
          <Button
            onClick={() => updateTarget()}
            sx={{ background: theme.palette.colorthree.main }}
          >
            Update Target
          </Button>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default SetTragets;
