// import React from "react";
// import { Box, Typography, Button, Grid } from "@mui/material";
// import { useTheme } from "@mui/material";

// const ViewNoticeBoardLeadDetails = ({ leadData, fromHome }) => {
//   const theme = useTheme();
//   const handleAttachmentDownload = () => {
//     window.open(leadData.attachment, "_blank");
//   };

//   return (
//     <Box
//       sx={{
//         background: theme.palette.selectedMenuBack.main,
//         p: 2,
//       }}
//     >
//       <Typography variant="h6">Lead Details</Typography>
//       <Box
//         sx={{ mt: "3", p: 3, background: "white", mt: 3, overflowX: "hidden" }}
//       >
//         <Grid container spacing={2}>
//           <Grid item xs={12} sm={6}>
//             <Typography variant="body2" sx={{ mt: 1 }}>
//               Lead Reference Number: {leadData.lead_ref_number}
//             </Typography>
//             <Typography variant="body2" sx={{ mt: 1 }}>
//               Lead Category: {leadData.lead_category}
//             </Typography>
//             <Typography variant="body2" sx={{ mt: 1 }}>
//               Date Posted: {leadData.date_posted}
//             </Typography>
//             <Typography variant="body2" sx={{ mt: 1 }}>
//               Valid Up To: {leadData.valid_up_to}
//             </Typography>
//             <Typography variant="body2" sx={{ mt: 1 }}>
//               Referral Industry: {leadData.referral_industry}
//             </Typography>
//             <Typography variant="body2" sx={{ mt: 1 }}>
//               Referral Type: {leadData.referral_type}
//             </Typography>
//           </Grid>
//           <Grid item xs={12} sm={6}>
//             <Typography variant="body2" sx={{ mt: 1 }}>
//               Referral Title: {leadData.referral_title}
//             </Typography>
//             <Typography variant="body2" sx={{ mt: 1 }}>
//               Referral Details: {leadData.referral_details}
//             </Typography>
//             <Typography variant="body2" sx={{ mt: 1 }}>
//               Country: {leadData.country}
//             </Typography>
//             <Typography variant="body2" sx={{ mt: 1 }}>
//               City: {leadData.city}
//             </Typography>
//             <Typography variant="body2" sx={{ mt: 1 }}>
//               Estimated Value: {leadData.estimated_value}
//             </Typography>
//           </Grid>
//           <Grid item xs={12} sm={12}>
//             {/* Continue adding other fields in a similar fashion */}
//             <Typography variant="body2" sx={{ mt: 1 }}>
//               Attachment:
//             </Typography>
//             {leadData.attachment ? (
//               <Button
//                 variant="outlined"
//                 onClick={handleAttachmentDownload}
//                 sx={{ mt: 1 }}
//               >
//                 View Attachment
//               </Button>
//             ) : (
//               <Typography>no attachment.</Typography>
//             )}
//           </Grid>
//         </Grid>
//       </Box>
//     </Box>
//   );
// };

// export default ViewNoticeBoardLeadDetails;

import React from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  Modal,
  Card,
  CardContent,
  CardHeader,
  Avatar,
  Chip,
  useTheme,
} from "@mui/material";

const ViewNoticeBoardLeadDetails = ({
  leadData,
  fromHome,
  open,
  handleClose,
  fromAdmin,
}) => {
  const theme = useTheme();

  const handleAttachmentDownload = () => {
    window.open(leadData.attachment, "_blank");
  };
  console.log("lead_ data", leadData);
  return (
    <Modal open={open} onClose={handleClose} sx={{ zIndex: "10000000000" }}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          maxWidth: "90vw",
          maxHeight: "90vh",
          overflow: "auto",
          bgcolor: "white",
          boxShadow: 24,
          p: 4,
          // borderRadius: 8,
        }}
      >
        <Card
          square={true}
          sx={{ background: theme.palette.selectedMenuBack.main }}
        >
          <CardHeader
            title="Lead Details"
            sx={{ color: theme.palette.primary.main }}
          />
          <CardContent>
            <Grid container spacing={2}>
              {fromAdmin && !leadData?.posted_by_admin && (
                <Grid item xs={12} sm={12}>
                  <Box sx={{ background: "whiteSmoke", p: 2, borderRadius: 8 }}>
                    <Typography variant="subtitle1 " sx={{ fontWeight: 600 }}>
                      Information (specific for Super Admin)
                    </Typography>
                    {leadData?.know_value == "YES" && (
                      <>
                        <Typography
                          variant="body2"
                          sx={{ mt: 1, whiteSpace: "pre-line" }}
                        >
                          Estimated Value: {leadData.estimated_value}{" "}
                          {leadData?.value_currency}
                        </Typography>
                      </>
                    )}
                    {leadData?.know_finder_fee == "YES" && (
                      <>
                        <Typography
                          variant="body2"
                          sx={{ mt: 1, whiteSpace: "pre-line" }}
                        >
                          Finder's Fee: {leadData.finder_fee}{" "}
                          {leadData?.finder_fee_currency}
                        </Typography>
                      </>
                    )}

                    <Typography
                      variant="body2"
                      sx={{ mt: 1, whiteSpace: "pre-line" }}
                    >
                      Uploader Name: {leadData.your_name}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ mt: 1, whiteSpace: "pre-line" }}
                    >
                      Uploader Email: {leadData.your_email}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ mt: 1, whiteSpace: "pre-line" }}
                    >
                      Uploader Number: {leadData.your_phone_number}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ mt: 1, whiteSpace: "pre-line" }}
                    >
                      Note by uploader: {leadData.your_note}
                    </Typography>
                  </Box>
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <Box sx={{ background: "whiteSmoke", p: 2, borderRadius: 8 }}>
                  <Typography variant="subtitle1 " sx={{ fontWeight: 600 }}>
                    Lead Information
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ mt: 1, whiteSpace: "pre-line" }}
                  >
                    Reference Number: {leadData.lead_ref_number}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ mt: 1, whiteSpace: "pre-line" }}
                  >
                    Category: {leadData.lead_category}
                  </Typography>

                  <Typography
                    variant="body2"
                    sx={{ mt: 1, whiteSpace: "pre-line" }}
                  >
                    Date Posted: {leadData.date_posted}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ mt: 1, whiteSpace: "pre-line" }}
                  >
                    Valid Up To: {leadData.valid_up_to}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ mt: 1, whiteSpace: "pre-line" }}
                  >
                    Referral Industry: {leadData.referral_industry}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ mt: 1, whiteSpace: "pre-line" }}
                  >
                    Referral Type: {leadData.referral_type}
                  </Typography>
                  {leadData.phone && (
                    <Typography
                      variant="body2"
                      sx={{ mt: 1, whiteSpace: "pre-line" }}
                    >
                      Office Telephone No. : {leadData?.phone}
                    </Typography>
                  )}
                  {leadData.website_link && (
                    <Typography
                      variant="body2"
                      sx={{ mt: 1, whiteSpace: "pre-line" }}
                    >
                      {" "}
                      Website :
                      <a href={leadData?.website_link} target="_blank">
                        {leadData?.website_link}
                      </a>
                    </Typography>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box sx={{ background: "whiteSmoke", p: 2, borderRadius: 8 }}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                    Location and Value
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ mt: 1, whiteSpace: "pre-line" }}
                  >
                    Title: {leadData.referral_title}
                  </Typography>

                  <Typography
                    variant="body2"
                    sx={{ mt: 1, whiteSpace: "pre-line" }}
                  >
                    Country: {leadData.country}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ mt: 1, whiteSpace: "pre-line" }}
                  >
                    City: {leadData.city}
                  </Typography>
                  {/* <Typography
                    variant="body2"
                    sx={{ mt: 1, whiteSpace: "pre-line" }}
                  >
                    Estimated Value: {leadData.estimated_value}
                  </Typography> */}
                </Box>
              </Grid>
              <Grid item xs={12}>
                {" "}
                <Box sx={{ p: 2 }}>
                  <Typography> Details:</Typography>
                  <Typography
                    variant="body2"
                    sx={{ mt: 1, whiteSpace: "pre-line" }}
                  >
                    {leadData.referral_details
                      ? leadData?.referral_details
                      : "N/A"}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ background: "whiteSmoke", p: 2, borderRadius: 8 }}>
                  <Typography variant="subtitle1">Attachment</Typography>
                  <Typography
                    variant="body2"
                    sx={{ mt: 1, whiteSpace: "pre-line" }}
                  >
                    Attachment:
                  </Typography>
                  {leadData.attachment ? (
                    <Button
                      variant="outlined"
                      onClick={handleAttachmentDownload}
                      sx={{ mt: 1 }}
                    >
                      Download Attachment
                    </Button>
                  ) : (
                    <Typography>no attachment.</Typography>
                  )}
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Modal>
  );
};

export default ViewNoticeBoardLeadDetails;
