import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  IconButton,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
} from "@mui/material";
import { useTheme } from "@mui/material";
import { api } from "../../../../axios/api";
import TransferHistory from "./TransferHistory";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";

const ScheduleATask = ({ selectedLead }) => {
  const user = useSelector((state) => state.user);
  const theme = useTheme();
  const [tasks, setTasks] = useState([]);
  const [showAddTaskForm, setShowAddTaskForm] = useState(false);
  const [heading, setHeading] = useState("");
  const [description, setDescription] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [descriptionFieldError, setDescriptionFieldError] = useState("");
  const [headingFieldError, setHeadingFieldError] = useState("");
  const [dueDateFieldError, setDueDateFieldError] = useState("");
  const submitTask = () => {
    // Reset error messages before checking
    setDescriptionFieldError("");
    setDueDateFieldError("");

    // Error checks
    let hasErrors = false;
    if (heading.trim() === "") {
      setHeadingFieldError("Heading cannot be empty.");
      hasErrors = true;
    } else if (heading.length < 10 || heading.length > 500) {
      setHeadingFieldError(
        "Heading length should be between 10 and 500 characters."
      );
      hasErrors = true;
    }
    if (description.trim() === "") {
      setDescriptionFieldError("Description cannot be empty.");
      hasErrors = true;
    } else if (description.length < 10 || description.length > 500) {
      setDescriptionFieldError(
        "Description length should be between 10 and 500 characters."
      );
      hasErrors = true;
    }

    if (dueDate.trim() === "") {
      setDueDateFieldError("Due date cannot be empty.");
      hasErrors = true;
    } else {
      const currentDate = new Date();
      const selectedDueDate = new Date(dueDate);
      if (selectedDueDate < currentDate) {
        setDueDateFieldError("Due date cannot be earlier than today.");
        hasErrors = true;
      }
    }

    // If errors are found, return without submitting the data
    if (hasErrors) {
      return;
    }

    // If no errors, you can perform the submission or logging of the data here
    // For example, you can log the data:

    let obj = {
      relationship_id: selectedLead?.info_reference_number,
      created_by: user?.id,
      due_date: dueDate,
      description,
      heading,
    };
    console.log("obj", obj);
    api
      .post("/addRelationshipTask", obj)
      .then((res) => {
        console.log("res?.data", res?.data);
        api.post("/addRelationshipActivity", {
          relationship_id: selectedLead?.info_reference_number,
          done_by: user?.id,
          action: "Task added!",
          note: `Heading: ${heading}!`,
        });
        setDescription("");
        setDueDate("");
        setHeading("");
        setHeadingFieldError("");
        setDescriptionFieldError("");
        setDueDateFieldError("");
        setShowAddTaskForm(false);
        toast.success("task added successfully!");
      })
      .catch((err) => toast?.error("ubable to add the task!"));

    // Clear the form after successful submission
  };

  useEffect(() => {
    api
      .get("/relationshipTasks/" + selectedLead?.info_reference_number)
      .then((res) => {
        console.log("all notes", res?.data);
        setTasks(res?.data);
      })
      .catch((err) => console.log(err));
  }, [showAddTaskForm]);

  return (
    <Box>
      <Box sx={{ my: 2, display: "flex", justifyContent: "flex-end" }}>
        <Button
          color="primary"
          sx={{ background: theme.palette.selectedMenuBack.main }}
          onClick={() => setShowAddTaskForm(showAddTaskForm ? false : true)}
        >
          {showAddTaskForm ? "Show Tasks" : "Create New Task"}
        </Button>
      </Box>
      <Box>
        {showAddTaskForm ? (
          <Box
            sx={{
              background: "#fff",
              p: 2,
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            {/***Add Task Form */}
            <Typography variant="body2"> Task Heading</Typography>
            <TextField
              type="text"
              placeholder="task heading"
              value={heading}
              onChange={(e) => setHeading(e.target.value)}
            />
            {headingFieldError?.length > 0 && (
              <Typography sx={{ color: "red" }}>{headingFieldError}</Typography>
            )}
            <Typography variant="body2">Description</Typography>
            <TextField
              type="text"
              placeholder="task description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            {descriptionFieldError?.length > 0 && (
              <Typography sx={{ color: "red" }}>
                {descriptionFieldError}
              </Typography>
            )}

            <Typography variant="body2" sx={{ mt: 2 }}>
              Due Date (can't be less than today)
            </Typography>
            <TextField
              sx={{ width: "max-content" }}
              type="date"
              value={dueDate}
              onChange={(e) => setDueDate(e.target.value)}
            />
            {dueDateFieldError?.length > 0 && (
              <Typography sx={{ color: "red" }}>{dueDateFieldError}</Typography>
            )}

            <Button
              sx={{ width: "max-content", mt: 2 }}
              color="primary"
              variant="contained"
              onClick={() => submitTask()}
            >
              Submit
            </Button>
          </Box>
        ) : (
          <Box sx={{ background: "#fff", p: 2 }}>
            {/***Add Tasks***/}
            <Typography
              variant="h5"
              sx={{ color: theme.palette.heading.main, mb: 3 }}
            >
              Pending Tasks
            </Typography>
            {tasks?.length > 0 ? (
              <Box>
                {tasks?.map((t, i) => (
                  <Task
                    task={t}
                    i={i}
                    selectedLead={selectedLead}
                    user={user}
                    setTasks={setTasks}
                    toast={toast}
                    tasks={tasks}
                  />
                ))}
              </Box>
            ) : (
              <>{"N/A"}</>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ScheduleATask;

const Task = ({ task, i, selectedLead, user, setTasks, toast, tasks }) => {
  const theme = useTheme();
  const [from, setFrom] = useState({});
  const [isOverdue, setIsOverDue] = useState(false);
  useEffect(() => {
    api
      .get("/getUserById/" + task?.created_by)
      .then((res) => setFrom(res?.data))
      .catch((err) => console.log(err));

    if (new Date(task?.due_date) < new Date()) {
      setIsOverDue(true);
    }
  }, []);
  const deleteTask = () => {
    api
      .delete("/relationshipTask/" + task?.id)
      .then((res) => {
        console.log(res?.data);
        toast.success("deleted successfully");
        api.post("/addRelationshipActivity", {
          relationship_id: selectedLead?.info_reference_number,
          done_by: user?.id,
          action: "Task deleted!",
          note: `Heading: ${task?.heading}!`,
        });
        setTasks(tasks?.filter((t) => t?.id !== task?.id));
      })
      .catch((err) => {
        console.log(err);
        toast.error("unable to delete");
      });
  };
  const updateStatus = () => {
    api
      .put("/updateRelationshipTaskStatus", {
        task_id: task?.id,
        status: "complete",
      })
      .then((res) => {
        console.log(res?.data);
        toast.success("marked as completed");
        api.post("/addRelationshipActivity", {
          relationship_id: selectedLead?.info_reference_number,
          done_by: user?.id,
          action: "Task completed!",
          note: `Heading: ${task?.heading}!`,
        });
        setTasks(tasks?.filter((t) => t?.id !== task?.id));
      })
      .catch((err) => {
        console.log(err);
        toast.error("unable to mark as complete");
      });
  };
  return (
    <Box
      sx={{
        background: theme.palette.selectedMenuBack.main,
        p: 2,
        maxWidth: "70%",
        mt: 2,
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "right" }}>
        {isOverdue ? (
          <Typography
            variant="body2"
            sx={{ color: theme.palette.primary.main }}
          >
            Overdue
          </Typography>
        ) : (
          <Typography variant="body2" sx={{ color: theme.palette.para.main }}>
            Due on: {new Date(task?.due_date)?.toDateString()},
          </Typography>
        )}
      </Box>
      <Typography sx={{ fontWeight: "600" }}>{task?.heading}</Typography>
      <Typography>{task?.description}</Typography>
      <Typography
        variant="body2"
        sx={{ color: theme.palette.para.main, mt: 1 }}
      >
        Added By: {from?.id == user?.id ? "You" : from?.name}
      </Typography>
      <Box sx={{ mt: 2, display: "flex", gap: "10px" }}>
        <Button
          color="primary"
          size="small"
          sx={{ fontSize: "0.8rem" }}
          onClick={() => deleteTask()}
        >
          Remove
        </Button>
        <Button
          color="primary"
          variant="contained"
          sx={{ fontSize: "0.8rem" }}
          size="small"
          onClick={() => updateStatus()}
        >
          Marks as Complete
        </Button>
      </Box>
    </Box>
  );
};
