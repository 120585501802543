import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  useTheme,
  Paper,
  CircularProgress,
} from "@mui/material";
import ProcessedForm from "./ProcessedForm";

const ImportProspects = ({
  organizationInformation,
  prospects,
  setShowImportForm,
}) => {
  const theme = useTheme();
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileError, setSelectedFileError] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [showProcessedForm, setShowProcessedForm] = useState(false);
  console.log("organziation info at import", organizationInformation);
  const handleFileChange = (e) => {
    setSelectedFileError("");
    const file = e.target.files[0];
    if (file && file.name.endsWith(".xlsx")) {
      setSelectedFile(file);
    } else {
      setSelectedFile(null);
      setSelectedFileError("Please select a valid XLSX file.");
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setSelectedFileError("");
    const file = e.dataTransfer.files[0];
    if (file && file.name.endsWith(".xlsx")) {
      setSelectedFile(file);
    } else {
      setSelectedFile(null);
      setSelectedFileError("Please drop a valid XLSX file.");
    }
  };

  const processImportedFile = () => {
    setShowProcessedForm(true);
  };

  return (
    <Box sx={{ mt: 2, p: 2, background: theme.palette.background.main }}>
      <Typography variant="h6">Import prospects using excel file.</Typography>
      {showProcessedForm ? (
        <ProcessedForm
          organizationInformation={organizationInformation}
          selectedFile={selectedFile}
          setShowProcessedForm={setShowProcessedForm}
          setShowImportForm={setShowImportForm}
          alreadyExisitingProspects={prospects}
        />
      ) : (
        <>
          {" "}
          <Box sx={{ my: 2 }}>
            <Typography>
              Here is sample excel. Headings(first row) should be exact as
              sample.{" "}
            </Typography>
            <a
              href="https://files.edge360crm.com/edge360_import_prospects_sample_file%20(2).xlsx"
              // "https://res.cloudinary.com/dosfquxpc/raw/upload/v1693392828/edge360_import_prospects_sample_file.xlsx"
            >
              <Button size="small" variant="contained" sx={{ mt: 1 }}>
                Download
              </Button>
            </a>
          </Box>
          {/* <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          //   alignItems: "center",
          padding: theme.spacing(3),
        }}
      > */}
          <Typography variant="h6" sx={{ mt: 3, mb: 2 }}>
            Import file
          </Typography>
          <Paper
            sx={{
              border: "2px dashed #ccc",
              padding: theme.spacing(2),
              textAlign: "center",
              cursor: "pointer",
            }}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            <input
              accept=".xlsx"
              style={{ display: "none" }}
              id="contained-button-file"
              type="file"
              onChange={handleFileChange}
            />
            <label htmlFor="contained-button-file">
              <Button variant="contained" color="primary" component="span">
                Choose File
              </Button>
            </label>
            <Typography sx={{ mt: 1 }}>OR</Typography>
            {selectedFile ? (
              <Box>
                <p>Selected File: {selectedFile.name}</p>
              </Box>
            ) : (
              <Typography>
                Drag and drop an XLSX file here, or click to select one.
              </Typography>
            )}
            {selectedFileError && (
              <Typography sx={{ color: "red" }}>{selectedFileError}</Typography>
            )}
          </Paper>
          {/* </Box> */}
          {selectedFile && !selectedFileError && (
            <Button
              variant="contained"
              sx={{ my: 2 }}
              onClick={() => processImportedFile()}
            >
              Process Imported File
            </Button>
          )}
        </>
      )}
    </Box>
  );
};

export default ImportProspects;
