import React, { useState, useEffect } from "react";
import {
  ThemeProvider,
  createTheme,
  Typography,
  Boxider,
  IconButton,
  TextField,
  Button,
  Box,
} from "@mui/material";
import { LockOutlined, Phone, Person } from "@mui/icons-material";
import AES from "crypto-js/aes";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { api, setAuthToken } from "../../../axios/api";
import { useTheme } from "@mui/material/styles";
import { ToastContainer, toast } from "react-toastify";
import { setUser, setToken } from "../../../actions/userActions";
import AuthPhoneInput from "../../InputFields/AuthPhoneInput";
import axios from "axios";

const AccountDetails = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showResetForm, setShowResetForm] = useState(true);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [currentPhoneNumber, setCurrentPhoneNumber] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [profileFile, setProfileFile] = useState();
  const [showProfileUploadForm, setShowProfileUploadForm] = useState(false);
  const [showFormType, setShowFormType] = useState("");
  const user = useSelector((state) => state.user);
  useEffect(() => {
    console.log("user", user);
    setCurrentPhoneNumber(user?.phone_number);
    setProfileImage(user?.profile_image);
  }, [user]);
  useEffect(() => {
    console.log("profile image", profileImage);
  }, [profileImage]);

  const logout = () => {
    dispatch(setUser({}));
    dispatch(setToken(""));
    navigate("/auth/login");
    setAuthToken("");
    localStorage.setItem("user", null);
    localStorage.setItem("token", null);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("Current Password: ", currentPassword);
    console.log("New Password: ", newPassword);
    console.log("Confirm Password: ", confirmPassword);
    var currPassEnc = AES.encrypt(
      currentPassword,
      process.env.REACT_APP_CRYPTO_JS_KEY
    ).toString();
    var newPassEnc = AES.encrypt(
      newPassword,
      process.env.REACT_APP_CRYPTO_JS_KEY
    ).toString();
    let body = {
      email: user?.email_address,
      currentPassword: currPassEnc,
      newPassword: newPassEnc,
    };
    console.log("body", body);
    await api
      .post("/resetpassword", body)
      .then((res) => {
        console.log("response from resetting password", res.data);
        toast.success("Password updated successfully!");
        logout();
      })
      .catch((err) => {
        console.log("error while resetting password", err);
        toast.error("unable to reset password");
      });
    setConfirmPassword("");
    setNewPassword("");
    setCurrentPassword("");

    setShowResetForm(false);
  };

  const handleResetClick = () => {
    setShowResetForm(true);
  };

  const handleCancelClick = () => {
    setShowResetForm(false);
  };

  const validateForm = () => {
    return (
      currentPassword.length >= 8 &&
      newPassword.length >= 8 &&
      confirmPassword === newPassword
    );
  };
  /********phone */
  const validatePhoneNumber = (phoneNumber) => {
    const phoneRegex = /^\+?[0-9]{8,}$/; // matches phone numbers with at least 8 digits

    if (!phoneNumber) {
      setPhoneNumberError("Phone Number is required");
      return false;
    }
    if (!phoneRegex.test(phoneNumber)) {
      setPhoneNumberError("Enter valid phone number");
      return false;
    }
    setPhoneNumberError("");
    return true;
  };
  const handlePhoneNumberChange = (event) => {
    console.log("event", event);
    setPhoneNumber(event.target.value);
    setPhoneNumberError("");
  };
  const submitPhoneNumber = () => {
    const isPhoneValid = validatePhoneNumber(phoneNumber);
    if (isPhoneValid) {
      api
        .post("/updateUserPhone", { phone_number: phoneNumber })
        .then((res) => {
          console.log("res", res?.data);
          toast.success("phone number updated successfully!");
          setCurrentPhoneNumber(phoneNumber);
          setPhoneNumber("");
        })
        .catch((err) => {
          console.log("error", err);
          toast.error("unable to update your phone number.");
        });
    } else {
      toast.error("Enter valid phone number!");
    }
  };
  /*************profile image */
  const handleProfileChange = (e) => {
    console.log("e", e.target.files);
    setProfileFile(e.target.files[0]);
  };

  const uploadFileToBackend = async (file, isJSON) => {
    const formData = new FormData();
    isJSON
      ? formData.append(
          "files",
          new Blob([file], { type: "application/json" }),
          "data.json"
        )
      : formData.append("files", file);

    try {
      const response = await api.post("/files/upload/space", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("url", response?.data?.url);
      return response.data.url; // Assuming the response contains the S3 URL
    } catch (error) {
      // console.error(error);
      // setIsLoading(false);
      toast.error("unable to cntinue at the moment!");
      return null;
    }
  };
  const submitProfileImage = async () => {
    console.log("profileFile", profileFile);

    const url = await uploadFileToBackend(profileFile);
    console.log("url", url);

    try {
      // const res = await fetch(
      //   "https://api.cloudinary.com/v1_1/dqmbkvawu/image/upload",

      //   {
      //     method: "POST",
      //     body: dataa,
      //   }
      // );
      // const file = await res.json();
      // console.log("response from cloudinary>>>", file);
      // const url = file?.secure_url;

      api
        .post("/updateUserProfileImage", { profile_image: url })
        .then((res) => {
          console.log("response from updating profile", res?.data);
          toast.success("profile image uploaded successfully");
          setProfileImage(url);
          // Update the 'prfile_image' key in local storage
          const user = JSON.parse(localStorage.getItem("user"));
          user.profile_image = url;
          localStorage.setItem("user", JSON.stringify(user));

          setShowProfileUploadForm(false);
        })
        .catch((err) => {
          console.log("err", err);
          toast.error("unable to upload profile_image");
        });
    } catch (err) {
      toast.error("unable to upload image");
    }
  };

  return (
    <Box style={{ padding: "1rem" }}>
      <ToastContainer />
      <Typography
        variant="h6"
        fontWeight="bold"
        color={theme.palette.heading.main}
      >
        Account Settings
      </Typography>
      <divider orientation="vertical" flexItem />
      {/* {showResetForm ? ( */}
      <Box
        style={{
          marginTop: "2rem",
          background: theme.palette.background.main,
          padding: "30px",
        }}
      >
        <Box
          mt={2}
          sx={{
            display: "flex",
            // alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "30px",
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            <Box
              mr={1}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: { sm: "max-content", xs: "100%" },
                gap: "10px",
                justifyContent: { xs: "center", sm: "flex-start" },
              }}
            >
              <img
                referrerpolicy="no-referrer"
                src={profileImage}
                style={{
                  width: "100px",
                  height: "100px",
                  borderRadius: "50%",
                  background: theme.palette.colorthree.main,
                }}
              />
            </Box>
            <Box>
              <Typography
                variant="body2"
                sx={{
                  fontSize: "1.1rem",
                  fontWeight: "600",
                  //   marginBottom: "10px",
                }}
              >
                {user?.name}
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: theme.palette.para.main, marginBottom: "10px" }}
              >
                {user?.profile_type == "admin" ? "admin" : "team member"}
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontSize: "0.9rem", color: theme.palette.heading.main }}
              >
                {user?.email_address}
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontSize: "0.9rem", color: theme.palette.heading.main }}
              >
                {user?.phone_number}
              </Typography>
              <Typography
                variant="body2"
                onClick={() => setShowFormType("profile_image")}
                sx={{
                  color: theme.palette.colortwo.main,
                  fontSize: "0.8rem",
                  fontWeight: "600",
                  cursor: "pointer",
                  marginTop: "15px",
                }}
              >
                Change profile image?
              </Typography>
              {user?.profile_type == "admin" && (
                <>
                  <Typography
                    variant="body2"
                    sx={{
                      color: theme.palette.colortwo.main,
                      fontSize: "0.8rem",
                      fontWeight: "600",

                      cursor: "pointer",
                    }}
                    onClick={() => setShowFormType("password")}
                  >
                    Change password?
                  </Typography>{" "}
                  <Typography
                    variant="body2"
                    onClick={() => setShowFormType("phone_number")}
                    sx={{
                      color: theme.palette.colortwo.main,
                      fontSize: "0.8rem",
                      fontWeight: "600",
                      cursor: "pointer",
                    }}
                  >
                    Change phone number?
                  </Typography>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      {showFormType == "profile_image" && (
        <Box
          style={{
            marginTop: "2rem",
            background: theme.palette.background.main,
            padding: "30px",
          }}
        >
          <Box
            mt={2}
            sx={{
              display: "flex",
              // alignItems: "center",
              //   flexDirection: "column",
            }}
          >
            <Box mr={1}>
              <IconButton
                // onClick={handleResetClick}
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  padding: "7px",
                }}
              >
                <Person
                  sx={{
                    width: "25px",
                    height: "25px",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",

                    color: "white",
                    boxShadow: `0px 4px 10px -6px
                  rgba(0,0,0,0.75)`,
                  }}
                />
              </IconButton>
            </Box>
            <Typography>Change Profile</Typography>
          </Box>
          <Box sx={{ marginTop: "20px" }}>
            <input
              type="file"
              style={{
                border: "1px solid lightgray",
                marginRight: "20px",
                padding: "10px",
              }}
              onChange={handleProfileChange}
            />
            <Box sx={{ marginTop: "20px" }}>
              {" "}
              <Button
                variant="contained"
                size="small"
                onClick={submitProfileImage}
              >
                Upload
              </Button>
              <Button
                size="small"
                sx={{ marginLeft: "10px" }}
                onClick={() => setShowFormType("")}
              >
                Cancel
              </Button>
            </Box>
          </Box>

          {/* </Box> */}
        </Box>
      )}
      {showFormType == "password" && (
        <Box
          style={{
            marginTop: "2rem",
            background: theme.palette.background.main,
            padding: "30px",
          }}
        >
          <Box mt={2} display="flex" alignItems="center">
            <Box mr={1}>
              <IconButton
                // onClick={handleResetClick}
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  padding: "7px",
                }}
              >
                <LockOutlined
                  sx={{
                    width: "25px",
                    height: "25px",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",

                    color: "white",
                    boxShadow: `0px 4px 10px -6px
                  rgba(0,0,0,0.75)`,
                  }}
                />
              </IconButton>
            </Box>
            <Typography>Reset Password</Typography>
          </Box>
          <form onSubmit={handleSubmit} style={{ maxWidth: "300px" }}>
            <TextField
              label="Current Password"
              type="password"
              variant="outlined"
              fullWidth
              margin="normal"
              value={currentPassword}
              onChange={(event) => setCurrentPassword(event.target.value)}
              required
            />
            <TextField
              label="New Password"
              type="password"
              variant="outlined"
              fullWidth
              margin="normal"
              value={newPassword}
              onChange={(event) => setNewPassword(event.target.value)}
              required
            />
            <TextField
              label="Confirm Password"
              type="password"
              variant="outlined"
              fullWidth
              margin="normal"
              value={confirmPassword}
              onChange={(event) => setConfirmPassword(event.target.value)}
              required
            />
            <Button
              variant="contained"
              type="submit"
              disabled={!validateForm()}
              sx={{ marginRight: "1rem" }}
            >
              Save
            </Button>
            <Button
              size="small"
              sx={{ marginLeft: "10px" }}
              onClick={() => setShowFormType("")}
            >
              Cancel
            </Button>
            {/* <Button variant="outlined" onClick={handleCancelClick}>
            Cancel
          </Button> */}
          </form>
          {/* ) : ( */}
        </Box>
      )}
      {showFormType == "phone_number" && (
        <Box
          style={{
            marginTop: "2rem",
            background: theme.palette.background.main,
            padding: "30px",
          }}
        >
          <Box mt={2} display="flex" alignItems="center">
            <Box mr={1}>
              <IconButton
                // onClick={handleResetClick}
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  padding: "7px",
                }}
              >
                <Phone
                  sx={{
                    width: "25px",
                    height: "25px",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",

                    color: "white",
                    boxShadow: `0px 4px 10px -6px
                  rgba(0,0,0,0.75)`,
                  }}
                />
              </IconButton>
            </Box>
            <Typography>Change Phone</Typography>
          </Box>
          <Typography variant="body2" sx={{ margin: "15px 0" }}>
            Current Phone No:{" "}
            {/* {user?.phone_number ? user?.phone_number : "not updated"} */}
            {currentPhoneNumber ? currentPhoneNumber : "not updated"}
          </Typography>
          <Box style={{ maxWidth: "300px", margin: "15px 0 " }}>
            {/* <TextField
            label="New Name"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            value={currentPassword}
            onChange={(event) => setCurrentPassword(event.target.value)}
            required
          /> */}
            <Box sx={{ margin: "15px 0" }}>
              <AuthPhoneInput
                isPassword={false}
                label="Phone Number"
                placeholder="phone number"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                setPhoneNumber={setPhoneNumber}
                error={phoneNumberError ? true : false}
                helperText={phoneNumberError}
              />
            </Box>

            <Button
              variant="contained"
              type="submit"
              // disabled={!validateForm()}
              sx={{ marginRight: "1rem" }}
              onClick={() => submitPhoneNumber()}
            >
              Save
            </Button>
            <Button
              size="small"
              sx={{ marginLeft: "10px" }}
              onClick={() => setShowFormType("")}
            >
              Cancel
            </Button>
            {/* <Button variant="outlined" onClick={handleCancelClick}>
            Cancel
          </Button> */}
          </Box>
          {/* ) : ( */}
        </Box>
      )}

      {/* )} */}
    </Box>
  );
};

export default AccountDetails;
