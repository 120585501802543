// import {
//   Box,
//   Typography,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   TextField,
//   Button,
//   TextareaAutosize,
// } from "@mui/material";
// import React, { useEffect, useState } from "react";
// import { api } from "../../axios/api";
// import storyOneImg from "../../utils/images/story1.jpg";
// import storyTwoImg from "../../utils/images/story2.jpg";
// import storyThreeImg from "../../utils/images/story3.jpg";
// import storyFourImg from "../../utils/images/story4.jpg";
// import storyFiveImg from "../../utils/images/story5.jpg";
// import storySixImg from "../../utils/images/story6.jpg";
// import { ToastContainer, toast } from "react-toastify";

// const ManageStories = () => {
//   const [thisWeekStories, setThisWeekStories] = useState([]);

//   const [stories, setStories] = useState(thisWeekStories);
//   const [showAddStoryForm, setShowAddStoryForm] = useState(false);
//   const [title, setTitle] = useState("");
//   const [description, setDescription] = useState("");
//   const [coverPhoto, setCoverPhoto] = useState("");

//   const fetchStories = () => {
//     api
//       .get("/stories")
//       .then((res) => {
//         console?.log("response stories ", res?.data);
//         setStories(res?.data);
//       })
//       .catch((err) => console.log(err));
//   };

//   const handleFormSubmit = async (e) => {
//     e.preventDefault();
//     // Call API to add story here
//     console.log("form data", title, description, coverPhoto);

//     const dataa = new FormData();
//     dataa.append("file", coverPhoto);
//     dataa.append("upload_preset", "bpyoqbty");
//     console.log("dataa", dataa);
//     try {
//       const res = await fetch(
//         "https://api.cloudinary.com/v1_1/dqmbkvawu/image/upload",

//         {
//           method: "POST",
//           body: dataa,
//         }
//       );
//       const file = await res.json();
//       console.log("response from cloudinary>>>", file);
//       const url = file?.secure_url;
//       if (url) {
//         api
//           .post("/stories/add", { title, description, cover_photo: url })
//           .then((res) => {
//             toast?.success("Story added successfully");
//             fetchStories();
//             // Reset form values
//             setTitle("");
//             setDescription("");
//             setCoverPhoto("");
//             setShowAddStoryForm(false);
//           })
//           .catch((err) => toast.error("unable to upload story"));
//       }
//     } catch (err) {
//       toast.error("unable to upload story");
//     }
//   };

//   useEffect(() => {
//     fetchStories();
//   }, []);

//   const deleteStory = (story) => {
//     console.log("story", story);

//     api
//       .delete("/stories/" + story?.id)
//       .then((res) => {
//         setStories(stories?.filter((s) => s?.id != story?.id));
//         toast.success("deleted successfully!");
//       })
//       .catch((err) => toast.error("unable to delete"));
//   };

//   return (
//     <Box>
//       <ToastContainer />
//       <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
//         <Typography variant="h6" gutterBottom>
//           Stories
//         </Typography>
//         <Button
//           onClick={() => setShowAddStoryForm(true)}
//           variant="contained"
//           color="primary"
//         >
//           Add Story
//         </Button>
//       </Box>
//       {showAddStoryForm ? (
//         <Box
//           sx={{
//             display: "flex",
//             flexDirection: "column",
//             gap: "5px",
//             maxWidth: "350px",
//           }}
//           component="form"
//           onSubmit={handleFormSubmit}
//         >
//           <Typography variant="body2" sx={{ fontWeight: 500, mt: 2 }}>
//             Title
//           </Typography>
//           <TextField
//             // label="Title"
//             value={title}
//             onChange={(e) => setTitle(e.target.value)}
//             required
//           />
//           <Typography variant="body2" sx={{ fontWeight: 500, mt: 2 }}>
//             Story
//           </Typography>
//           <TextareaAutosize
//             placeholder="Write your story here..."
//             style={{ height: "250px", padding: "10px" }}
//             value={description}
//             onChange={(e) => setDescription(e.target.value)}
//             required
//           />
//           <Typography variant="body2" sx={{ fontWeight: 500, mt: 2 }}>
//             Upload Cover Photo
//           </Typography>
//           <TextField
//             // label="Cover Photo"
//             type="file"
//             value={coverPhoto?.filename}
//             onChange={(e) => setCoverPhoto(e.target.files[0])}
//             required
//           />
//           <Button
//             sx={{ width: "max-content" }}
//             type="submit"
//             variant="contained"
//             color="primary"
//           >
//             Submit
//           </Button>
//         </Box>
//       ) : (
//         <Box>
//           {" "}
//           {stories.length > 0 && (
//             <TableContainer component={Paper}>
//               <Table>
//                 <TableHead>
//                   <TableRow>
//                     <TableCell>Id</TableCell>
//                     <TableCell>Cover Photo</TableCell>
//                     <TableCell>Story</TableCell>
//                     <TableCell>Actions</TableCell>
//                   </TableRow>
//                 </TableHead>
//                 <TableBody>
//                   {stories.map((story, i) => (
//                     <TableRow key={i + 1}>
//                       <TableCell>{i + 1}</TableCell>
//                       <TableCell>
//                         <img
//                           src={story.cover_photo}
//                           alt="Cover"
//                           style={{
//                             width: 100,
//                             height: 100,
//                             objectFit: "cover",
//                           }}
//                         />
//                       </TableCell>
//                       <TableCell>
//                         <Typography variant="body2" sx={{ fontWeight: "700" }}>
//                           {" "}
//                           {story.title}
//                         </Typography>
//                         <Typography
//                           sx={{
//                             maxWidth: 300,
//                             overflow: "hidden",
//                             textOverflow: "ellipsis",
//                             whiteSpace: "nowrap",
//                           }}
//                         >
//                           {story.description}
//                         </Typography>
//                       </TableCell>
//                       <TableCell>
//                         <Button
//                           variant="outlined"
//                           size="small"
//                           onClick={() => deleteStory(story)}
//                         >
//                           Delete
//                         </Button>
//                       </TableCell>
//                     </TableRow>
//                   ))}
//                 </TableBody>
//               </Table>
//             </TableContainer>
//           )}
//         </Box>
//       )}
//     </Box>
//   );
// };

// export default ManageStories;

import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  TextareaAutosize,
} from "@mui/material";
import { api } from "../../axios/api";
import { ToastContainer, toast } from "react-toastify";
import { useTheme } from "@mui/material";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import parse from "html-react-parser"; // Import html-react-parser

const TextEditor = ({ storyContent, setStoryContent }) => {
  const handleChange = (html) => {
    setStoryContent(html);
  };

  return (
    <div>
      <ReactQuill theme="snow" value={storyContent} onChange={handleChange} />
      <div>
        {/* <h2>Preview</h2> */}
        {/* Use html-react-parser to parse HTML content */}
        {/* <div>{parse(editorHtml)}</div> */}
      </div>
    </div>
  );
};

// export default TextEditor;

const ManageStories = () => {
  const [stories, setStories] = useState([]);
  const [showAddStoryForm, setShowAddStoryForm] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [coverPhoto, setCoverPhoto] = useState("");
  const [storyContent, setStoryContent] = useState("");
  const [editStory, setEditStory] = useState(null);
  const [fileUrlAtEdit, setFileUrlAtEdit] = useState("");
  const theme = useTheme();

  const fetchStories = () => {
    api
      .get("/stories")
      .then((res) => {
        console.log("response stories ", res?.data);
        setStories(res?.data);
      })
      .catch((err) => console.log(err));
  };
  const uploadFileToBackend = async (file, isJSON) => {
    const formData = new FormData();
    isJSON
      ? formData.append(
          "files",
          new Blob([file], { type: "application/json" }),
          "data.json"
        )
      : formData.append("files", file);

    try {
      const response = await api.post("/files/upload/space", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("url", response?.data?.url);
      return response.data.url; // Assuming the response contains the S3 URL
    } catch (error) {
      // console.error(error);
      // setIsImageLoading(false);
      // toast.error("unable to cntinue at the moment!");
      return null;
    }
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Call API to add story here
    console.log("form data", title, description, coverPhoto);

    // const dataa = new FormData();
    // dataa.append("file", coverPhoto);
    // dataa.append("upload_preset", "bpyoqbty");
    // console.log("dataa", dataa);

    try {
      // const res = await fetch(
      //   "https://api.cloudinary.com/v1_1/dqmbkvawu/image/upload",
      //   {
      //     method: "POST",
      //     body: dataa,
      //   }
      // );
      // const file = await res.json();
      // console.log("response from cloudinary>>>", file);
      // const url = file?.secure_url;
      const url = await uploadFileToBackend(coverPhoto);
      if (url) {
        api
          .post("/stories/add", {
            title,
            description,
            story_content: storyContent,
            cover_photo: url,
          })
          .then((res) => {
            toast?.success("Story added successfully");
            fetchStories();
            // Reset form values
            setTitle("");
            setDescription("");
            setStoryContent("");
            setCoverPhoto("");
            setShowAddStoryForm(false);
          })
          .catch((err) => toast.error("unable to upload story"));
      }
    } catch (err) {
      toast.error("unable to upload story");
    }
  };

  const openEditForm = (story) => {
    setEditStory(story);
    // Set other state variables if needed
    // Open your edit form
    setTitle(story?.title);
    setDescription(story?.description);
    setFileUrlAtEdit(story?.cover_photo);
    setStoryContent(story?.story_content);
    setShowAddStoryForm(true);
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    console.log("running handle edit submit");
    // Make a PUT request to update the story
    try {
      let url;
      if (coverPhoto) {
        console.log("form data", title, description, coverPhoto);

        const dataa = new FormData();
        dataa.append("file", coverPhoto);
        dataa.append("upload_preset", "bpyoqbty");
        console.log("dataa", dataa);

        const res = await fetch(
          "https://api.cloudinary.com/v1_1/dqmbkvawu/image/upload",
          {
            method: "POST",
            body: dataa,
          }
        );
        const file = await res.json();
        console.log("response from cloudinary>>>", file);
        url = file?.secure_url;
      } else {
        url = fileUrlAtEdit;
      }
      console.log("url", url);
      if (url) {
        console.log("running put api");
        api
          .put("/stories/update/" + editStory?.id, {
            title,
            description,
            story_content: storyContent,
            cover_photo: url,
          })
          .then((res) => {
            console.log("res data", res?.data);
            toast?.success("Story updated successfully");
            fetchStories();
            // Reset form values
            setTitle("");
            setDescription("");
            setStoryContent("");
            setCoverPhoto("");
            setShowAddStoryForm(false);

            setEditStory(null);
          })
          .catch((err) => {
            toast.error("unable to update story");
            console.log(err);
          });
      }
    } catch (error) {
      console.error("Error updating story:", error);
      // Handle error
    }
  };

  const deleteStory = (story) => {
    // Make a DELETE request to delete the story
    api
      .delete("/stories/" + story.id)
      .then((res) => {
        setStories((prevStories) =>
          prevStories.filter((s) => s.id !== story.id)
        );
        toast.success("Story deleted successfully!");
      })
      .catch((err) => toast.error("Unable to delete story"));
  };

  useEffect(() => {
    fetchStories();
  }, []);
  const setTopStory = (story) => {
    api
      .put("/stories/set-top-story/" + story.id)
      .then((res) => {
        toast.success("Story set as Top story successfully!");
        fetchStories();
      })
      .catch((err) => toast.error("Unable to update"));
  };

  return (
    <Box>
      <ToastContainer />
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
        <Typography variant="h6" gutterBottom>
          Stories
        </Typography>
        {showAddStoryForm ? (
          <Button
            onClick={() => setShowAddStoryForm(false)}
            variant="contained"
            color="primary"
          >
            Close Form
          </Button>
        ) : (
          <Button
            onClick={() => setShowAddStoryForm(true)}
            variant="contained"
            color="primary"
          >
            Add Story
          </Button>
        )}
      </Box>
      {showAddStoryForm ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            width: "100%",
          }}
        >
          {/* ... existing code for add story form */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              maxWidth: "600px",
              background: "#fff",
              py: 3,
              px: 1,
              // border: "1px solid red",
            }}
            component="form"
            onSubmit={handleFormSubmit}
          >
            <Typography variant="body2" sx={{ fontWeight: 500, mt: 2 }}>
              Title
            </Typography>
            <TextField
              // label="Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
            <Typography variant="body2" sx={{ fontWeight: 500, mt: 2 }}>
              Thumbnail description (optional)
            </Typography>
            <TextField
              // label="Title"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
            <Typography variant="body2" sx={{ fontWeight: 500, mt: 2 }}>
              Story
            </Typography>
            <TextEditor
              storyContent={storyContent}
              setStoryContent={setStoryContent}
            />
            {/* <TextareaAutosize
              placeholder="Write your story here..."
              style={{ height: "250px", padding: "10px" }}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            /> */}

            <Typography variant="body2" sx={{ fontWeight: 500, mt: 2 }}>
              Upload Cover Photo
            </Typography>
            <TextField
              // label="Cover Photo"
              type="file"
              value={coverPhoto?.filename}
              onChange={(e) => setCoverPhoto(e.target.files[0])}
              required={editStory ? false : true}
            />
            <Button
              sx={{ width: "max-content", mt: 2 }}
              disabled={!storyContent || !title || !coverPhoto}
              onClick={(e) => {
                editStory ? handleEditSubmit(e) : handleFormSubmit(e);
              }}
              variant="contained"
              color="primary"
            >
              Submit
            </Button>
          </Box>
        </Box>
      ) : (
        <Box>
          {stories.length > 0 && (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Id</TableCell>
                    <TableCell>Cover Photo</TableCell>
                    <TableCell>Story</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stories
                    .sort((a, b) => a.id - b.id)
                    .map((story, i) => (
                      <TableRow key={i + 1}>
                        {/* ... existing columns */}
                        <TableCell>{i + 1}</TableCell>
                        <TableCell>
                          <img
                            src={story.cover_photo}
                            alt="Cover"
                            style={{
                              width: 100,
                              height: 100,
                              objectFit: "cover",
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="body2"
                            sx={{ fontWeight: "700", mb: 0.5 }}
                          >
                            {" "}
                            {story.title}{" "}
                            <Typography
                              variant="p"
                              sx={{
                                color: theme.palette.primary.main,
                                background:
                                  theme.palette.selectedMenuBack?.main,
                                width: "max-content",
                                p: 0.5,
                              }}
                            >
                              {story?.is_top_story && "Top Story"}
                            </Typography>
                          </Typography>
                          <Typography
                            sx={{
                              maxWidth: 300,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {story.description}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {story?.is_top_story ? (
                            <Typography sx={{ mr: 2 }}></Typography>
                          ) : (
                            <Button
                              size="small"
                              onClick={() => setTopStory(story)}
                              sx={{
                                mr: 2,
                                background: theme.palette.selectedMenuBack.main,
                              }}
                            >
                              Set as Top Story
                            </Button>
                          )}

                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() => openEditForm(story)}
                          >
                            Edit
                          </Button>
                          <Button
                            variant="contained"
                            size="small"
                            onClick={() => deleteStory(story)}
                            sx={{ ml: 1 }}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      )}
    </Box>
  );
};

export default ManageStories;
