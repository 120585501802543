import React, { useEffect } from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { CoPresentOutlined } from "@mui/icons-material";

const AuthInput = ({
  label,
  placeholder,
  value,
  onChange,
  icon,
  isPassword,
  error,
  helperText,
}) => {
  const theme = useTheme();
  useEffect(() => {
    console.log("error in", label, error, helperText);
  }, [error, helperText]);
  return (
    <Box
      //   fullWidth
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        margin: "15px 0 3px 0",
      }}
    >
      {/* <Typography
        variant="h5"
        sx={{
          fontWeight: "500",
          color: theme.palette.heading.main,
          fontSize: "14px",
          marginBottom: "2px",
        }}
      >
        {label}
      </Typography> */}
      <Box
        // fullWidth
        sx={{
          display: "flex",
          // border: "1px solid lightgray",
          borderRadius: "4px",
        }}
      >
        <OutlinedInput
          autoComplete="off"
          sx={{
            flex: "1",
            height: "45px !important",
            boxSizing: "border-box",
            overflow: "hidden",
            fontSize: "14px",
          }}
          placeholder={placeholder}
          value={value}
          type={isPassword ? "password" : "text"}
          onChange={onChange}
          error={error}
          //   helperText={helperText}
          startAdornment={
            icon && (
              <InputAdornment position="start" sx={{ marginRight: "12px" }}>
                {/* <PersonIcon /> */}
                {icon}
              </InputAdornment>
            )
          }
          //   labelWidth={0}
        />
      </Box>
      {helperText && (
        <Typography varaint="body2" sx={{ color: "red", fontSize: "0.8rem" }}>
          {helperText}
        </Typography>
      )}
    </Box>
  );
};

export default AuthInput;
