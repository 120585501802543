import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
  IconButton,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useTheme } from "@emotion/react";
import { api } from "../../../../axios/api";
import Modal from "@mui/material/Modal";
import { ToastContainer, toast } from "react-toastify";

const DropIt = ({ selectedLead, setShowIndividualLeadFeature }) => {
  const theme = useTheme();
  const [note, setNote] = useState("");
  const user = useSelector((state) => state.user);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const cancelDropping = () => {
    setNote("");
    handleClose();
  };
  const confirmDropping = () => {
    console.log("note", note);

    if (note?.length < 1) {
      return toast.error(`field can't be empty`);
    }
    let obj = {
      relationship_id: selectedLead?.info_reference_number,
      uploaded_by: user?.id,
      note: note + " (Dropped-note)",
    };
    api
      .post("/addRelationshipNote", obj)
      .then((res) => {
        console.log("response", res?.data);
        api
          .put("/dropTheRelationship/" + selectedLead?.info_reference_number)
          .then((res) => {
            console.log(res?.data);
            let body = {
              relationship_id: selectedLead?.info_reference_number,
              text: note + " (Dropped-note)",
              is_general: true,
              isRelationship: true,
            };
            api
              .post("/message/add", body)
              .then((res) => {
                setNote("");
                toast.success("lead dropped successfully");
                setShowIndividualLeadFeature(false);
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => toast.error("unable to drop the lead at moment"));
      })
      .catch((err) => {
        toast.error("unable to add note");
      });
  };
  return (
    <Box>
      <ToastContainer />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ minHeight: "300px", zIndex: "1000000" }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "80vw", sm: 400 },
            bgcolor: "background.paper",
            //   border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ mb: 2 }}
          >
            Explain why the lead has to be dropped
          </Typography>
          <textarea
            style={{
              padding: "10px",
              width: "100%",
              minHeight: { sm: "200px", xs: "30vh" },
            }}
            placeholder="enter here"
            onChange={(e) => setNote(e.target.value)}
          />
          <Box sx={{ mt: 3, display: "flex", gap: "10px" }}>
            <Button
              color="primary"
              size="small"
              onClick={() => cancelDropping()}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="samll"
              color="primary"
              onClick={() => confirmDropping()}
            >
              Drop
            </Button>
          </Box>
        </Box>
      </Modal>
      <Button
        color="primary"
        variant="contained"
        onClick={() => handleOpen()}
        sx={{ mt: 2 }}
      >
        Drop the lead
      </Button>
    </Box>
  );
};

export default DropIt;
