import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 680, // <-- set sm breakpoint to 680px
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: "#D21312",
    },
    secondary: {
      main: "#f3f4f7",
    },
    colortwo: {
      main: "#ED2B2A",
    },
    colorthree: {
      main: "#F15A5910",
    },
    colorfour: {
      main: "#070A52",
    },
    colorfive: {
      main: "#d5e3cf", //green layer 1
    },
    colorsix: {
      main: "#ebf1e9", //green layer 2
    },
    colorseven: {
      main: "#bfd7b2", //green layer 3
    },
    coloreight: {
      main: "#ff7784",
    },
    fontcolor: {
      main: "#fff",
    },
    iconColor: {
      main: "#ED2B2A",
    },
    heading: {
      main: "#4b4b5a",
    },
    para: {
      main: "#6c757d",
    },

    menuHeading: {
      main: "#9299b8",
    },
    menuItem: {
      main: "#272b41",
    },
    black: {
      main: "#000",
    },

    background: {
      main: "#fff",
    },
    whiteFont: {
      main: "#fff",
    },
    selectedMenuFont: {
      // main: "#5f63f2",
      main: "#ED2B2A",
    },
    selectedMenuBack: {
      // main: "#5f63f205",
      main: "#F15A5910",
    },
  },
  typography: {
    fontFamily: "IBM Plex Sans, sans-serif",
    //fontFamily: "Inter, sans-serif",
  },
});

export default theme;
