import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  useMediaQuery,
  MenuItem,
  Avatar,
} from "@mui/material";
import logo from "../utils/images/edge.jpg";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../theme";

import styled from "@mui/material/styles/styled";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import LoginContainer from "./Login/LoginContainer";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setUser, setToken } from "../actions/userActions";
import { api, setAuthToken } from "../axios/api";

const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
  "&:hover": {
    backgroundColor: "transparent",
    "& > .MuiTypography-root": {
      color: theme.palette.primary.main,
    },
  },
}));

const CustomMenuTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

function MyCustomMenuItem(props) {
  const theme = useTheme();

  return (
    <CustomMenuItem {...props}>
      <CustomMenuTypography sx={{ color: theme.palette.text.primary }}>
        {props.children}
      </CustomMenuTypography>
    </CustomMenuItem>
  );
}

const CustomButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.colortwo.main,
  color: theme.palette.common.white,
  textTransform: "capitalize",
  borderRadius: "999px",
  fontSize: "1rem",
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
  },
}));

const Navbar = () => {
  const user = useSelector((state) => state.user);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    function fetchUserSubscriptionData() {
      api
        .get("/user/subscription")
        .then((res) => {
          console.log("response from getting user subscription>>>>", res.data);
          dispatch({
            type: "SET_Subscription_Data",
            payload: res?.data,
          });
        })
        .catch((err) => console.log("err from getting user", err));
    }
    fetchUserSubscriptionData();
    setTimeout(() => {
      fetchUserSubscriptionData();
    }, 5000);
  }, [user]);

  useEffect(() => {
    !isMobile && setShowMobileMenu(false);
  }, [isMobile]);
  useEffect(() => {
    api
      .get("/getUserData")
      .then((res) => {
        console.log("response from getting user", res.data);
        dispatch(setUser(res.data));
        // setIsCompanyDataExist(res?.data?.company_id ? true : false);
      })
      .catch((err) => console.log("err", err));
  }, []);
  const logout = async (e) => {
    // e.preventDefault();
    await api.post("/users/toggle-login/" + user?.id, { loggedIn: false });
    dispatch(setUser({}));
    dispatch(setToken(""));
    navigate("/auth/login");
    setAuthToken("");
    localStorage.setItem("user", null);
    localStorage.setItem("token", null);
  };

  return (
    <Container>
      {/* <ThemeProvider theme={theme}>
        <LoginContainer />
      </ThemeProvider> */}
      <Box
        sx={{
          backgroundColor: theme.palette.background.main,
          height: "70px",
          display: "flex",
          alignItems: "center",
          // padding: { sx: "0 20px ", sm: "0 40px" },
          // position: showMobileMenu ? "fixed" : "relative",
          position: "relative",
          top: "0px",
          // left: "0px",
          // width: showMobileMenu ? "93%" : "auto",
          zIndex: "10000",
        }}
      >
        <Box
          sx={{ flex: "0.3", cursor: "pointer" }}
          onClick={() => navigate("/")}
        >
          <img style={{ height: "50px" }} src={logo} />
        </Box>
        {isMobile ? (
          <Box
            sx={{
              flex: "1",
              display: "flex",
              gap: { md: "20px", xs: "10px" },
              justifyContent: "flex-end",
            }}
          >
            <IconButton
              size="medium"
              edge="start"
              color="inherit"
              aria-label="open menu"
              sx={{ mr: 2 }}
              onClick={() => setShowMobileMenu(!showMobileMenu)}
            >
              <MenuIcon />
            </IconButton>
          </Box>
        ) : (
          <>
            <Box
              sx={{
                flex: "1",
                display: "flex",
                gap: { md: "20px", xs: "3px" },
                justifyContent: "center",
              }}
            >
              <MyCustomMenuItem onClick={() => navigate("/")}>
                Home
              </MyCustomMenuItem>
              <MyCustomMenuItem onClick={() => navigate("/billboard")}>
                Billboard
              </MyCustomMenuItem>
              <MyCustomMenuItem onClick={() => navigate("/pricing")}>
                Pricing
              </MyCustomMenuItem>
              <MyCustomMenuItem onClick={() => navigate("/stories")}>
                Stories
              </MyCustomMenuItem>
              {user?.id && (
                <MyCustomMenuItem onClick={() => navigate("/dashboard")}>
                  {user?.email_address == "admin@edge360.co"
                    ? "Admin Dashboard"
                    : "CRM"}
                </MyCustomMenuItem>
              )}
            </Box>

            <Box
              sx={{
                flex: "0.3",
                display: "flex",
                alignItems: "center",
                gap: "10px",
                justifyContent: "flex-end",
              }}
            >
              {user?.id ? (
                <>
                  {" "}
                  <Typography
                    sx={{
                      color: theme.palette.primary.main,
                      cursor: "pointer",
                    }}
                    onClick={() => logout()}
                  >
                    Logout
                  </Typography>
                  {/* <CustomButton
                    sx={{ width: "120px", height: "40px", padding: "8px" }}
                    onClick={() => navigate("/auth/login")}
                  >
                    Login

                  </CustomButton> */}
                  {user?.profile_image ? (
                    <Avatar size="small">
                      <img
                        src={user?.profile_image}
                        alt="User Avatar"
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "50%",
                          objectFit: "cover",
                        }}
                        referrerpolicy="no-referrer"
                      />
                    </Avatar>
                  ) : (
                    <Avatar size="small">{user?.name[0]?.toUpperCase()}</Avatar>
                  )}
                </>
              ) : (
                <>
                  {" "}
                  <Typography
                    sx={{
                      color: theme.palette.primary.main,
                      cursor: "pointer",
                    }}
                    onClick={() => navigate("/auth/signup")}
                  >
                    Register
                  </Typography>
                  <CustomButton
                    sx={{ width: "120px", height: "40px", padding: "8px" }}
                    onClick={() => navigate("/auth/login")}
                  >
                    Login
                  </CustomButton>
                </>
              )}
            </Box>
          </>
        )}
        {true && (
          <Box
            sx={{
              backgroundColor: theme.palette.background.main,
              backgroundColor: "rgba(255, 255, 255, 0.5)", // set a semi-transparent white background color
              backdropFilter: "blur(6px)", // add a 5px blur filter
              zIndex: "10000",
              height: "calc(100vh - 70px)", // set the height to 100vh - 70px
              // position: "absolute",
              // top: "100%",
              position: "fixed",
              top: "70px",
              left: 0,
              width: "100vw",
              transform:
                showMobileMenu && isMobile
                  ? "translateX(0%)"
                  : "translateX(-100%)",
              transition: "all 0.3s ease",
              overflowY: "auto",
              paddingBottom: "20px",

              borderTop: "1px solid lightgray",
            }}
          >
            <Box sx={{ marginTop: "20px" }}>
              <MyCustomMenuItem onClick={() => navigate("/")}>
                Home
              </MyCustomMenuItem>
              <MyCustomMenuItem onClick={() => navigate("/billboard")}>
                Billboard
              </MyCustomMenuItem>
              <MyCustomMenuItem onClick={() => navigate("/pricing")}>
                Pricing
              </MyCustomMenuItem>
              <MyCustomMenuItem onClick={() => navigate("/stories")}>
                Stories
              </MyCustomMenuItem>
              {user?.id && (
                <MyCustomMenuItem onClick={() => navigate("/dashboard")}>
                  {user?.email_address == "admin@edge360.co"
                    ? "Admin Dashboard"
                    : "CRM"}
                </MyCustomMenuItem>
              )}
            </Box>
            <Box
              sx={{
                flex: "0.3",
                display: "flex",
                alignItems: "center",
                gap: "10px",
                marginTop: "30px",
                paddingLeft: "20px",
              }}
            >
              {user?.id ? (
                <>
                  <Typography
                    sx={{
                      color: theme.palette.primary.main,
                      cursor: "pointer",
                    }}
                    onClick={() => logout()}
                  >
                    Logout
                  </Typography>
                  {/* <CustomButton
                    sx={{ width: "120px", height: "40px", padding: "8px" }}
                    onClick={() => navigate("/auth/login")}
                  >
                    Login

                  </CustomButton> */}
                  {user?.profile_image ? (
                    <Avatar size="small">
                      <img
                        src={user?.profile_image}
                        alt="User Avatar"
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "50%",
                          objectFit: "cover",
                        }}
                        referrerpolicy="no-referrer"
                      />
                    </Avatar>
                  ) : (
                    <Avatar size="small">{user?.name[0]?.toUpperCase()}</Avatar>
                  )}
                </>
              ) : (
                <>
                  <Typography
                    sx={{
                      color: theme.palette.primary.main,
                      cursor: "pointer",
                    }}
                    onClick={() => navigate("/auth/signup")}
                  >
                    Register
                  </Typography>
                  <CustomButton
                    sx={{ width: "120px", height: "40px", padding: "8px" }}
                    onClick={() => navigate("/auth/login")}
                  >
                    Login
                  </CustomButton>
                </>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default Navbar;
