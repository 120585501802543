import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
  IconButton,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  useMediaQuery,
  TextField,
} from "@mui/material";
import { api } from "../../axios/api";
import { useTheme } from "@mui/material/styles";
import { Globe, Award, Eye } from "react-feather";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddLeadToNoticeBoardForm from "../CRM/NoticeBoard/AddLeadToNoticeBoardForm";
import ViewNoticeBoardLeadDetails from "../CRM/NoticeBoard/ViewNoticeBoardLeadDetails";

const ManageNoticeBoard = () => {
  const theme = useTheme();
  const [data, setData] = useState([]);
  const [showLeadAddingForm, setShowLeadAddingForm] = useState(false);
  const [selectedLeadForDetails, setSelectedLeadForDetails] = useState({});
  const [showDetailsOfLead, setShowDetailsOfLead] = useState(false);
  const handleShowDetailsClosed = () => setShowDetailsOfLead(false);
  const [tableType, setTableType] = useState("noticeBoard"); //requests or noticeBoard
  const [noticeBoardLeads, setNoticeBoardLeads] = useState([]);
  const [editOptions, setEditOptions] = useState({});

  useEffect(() => {
    api
      .get("/noticeBoardLeads")
      .then((res) => {
        console.log("response from gettoing noticebborad leads", res?.data);
        setNoticeBoardLeads(res?.data?.filter((s) => s?.authorized_by_admin));
        let authorized_data = res?.data?.filter((s) => !s?.authorized_by_admin);
        setData(authorized_data);
      })
      .catch((err) => console.log(err));
  }, [showLeadAddingForm, tableType, showDetailsOfLead]);

  const acceptRequest = (leadData) => {
    // alert("accept request");
    api
      .put("/noticeboardLeads/authorization", {
        leadRefNo: leadData?.lead_ref_number,
        authorizedOption: true,
      })
      .then((res) => {
        console.log("res", res?.data);
        setData(
          data?.filter((d) => d?.lead_ref_number !== leadData?.lead_ref_number)
        );
        toast.success("successfully accepted!");
      })
      .catch((err) => {
        console.log("error", err);
        toast.error("unable to accept at the moment");
      });
  };
  const deleteRequest = (leadData) => {
    api
      .delete("/noticeBoardLeads/" + leadData?.lead_ref_number)
      .then((res) => {
        console.log("response", res?.data);
        setData(
          data?.filter((d) => d?.lead_ref_number !== leadData?.lead_ref_number)
        );
        toast.success("successfully deleted");
      })
      .catch((err) => {
        console.log(err);
        toast.error("unable to delete ");
      });
  };

  useEffect(() => {
    console.log("show details of lead", showDetailsOfLead);
  }, [showDetailsOfLead]);
  return (
    <Box>
      <ToastContainer />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 3,
        }}
      >
        <Box>
          <Typography variant="h6">Manage Billboard</Typography>
          <Typography variant="body2">
            Accept or remove requests from guest users to add leads on notice
            board.
          </Typography>
        </Box>
        <Box>
          <Button
            sx={{ mr: 2, my: 2 }}
            variant="outlined"
            onClick={() => {
              setShowLeadAddingForm(false);
              setShowDetailsOfLead(false);
              tableType == "noticeBoard"
                ? setTableType("requests")
                : setTableType("noticeBoard");
            }}
          >
            {tableType == "noticeBoard" ? "See Requests" : " See Billboard"}
          </Button>
          <Button
            sx={{ ml: "2", my: 2 }}
            variant="contained"
            onClick={() =>
              showLeadAddingForm
                ? setShowLeadAddingForm(false)
                : showDetailsOfLead
                ? setShowDetailsOfLead(false)
                : setShowLeadAddingForm(true)
            }
          >
            {showDetailsOfLead
              ? "Show Billboard"
              : showLeadAddingForm
              ? "show Leads"
              : " Add to Billboard"}
          </Button>
        </Box>
      </Box>
      {showDetailsOfLead && (
        <ViewNoticeBoardLeadDetails
          leadData={selectedLeadForDetails}
          handleClose={handleShowDetailsClosed}
          open={showDetailsOfLead}
          fromAdmin={true}
        />
      )}
      {showLeadAddingForm ? (
        <AddLeadToNoticeBoardForm
          setShowLeadAddingForm={setShowLeadAddingForm}
          isSuperAdmin={true}
          editOptions={editOptions}
        />
      ) : tableType == "requests" ? (
        <Box sx={{ width: "100%", overflowX: "scroll", mt: 4 }}>
          <TableContainer
            component={Paper}
            style={{
              marginBottom: theme.spacing(3),
              border: "none",
              outline: "none",
              boxShadow: "none",
              overflowX: "initial",
            }}
          >
            <Table sx={{ border: "none", outline: "none" }} stickyHeader>
              <TableHead>
                <TableRow
                // sx={{ backgroundColor: theme.palette.secondary.main }}
                >
                  {/* <TableCell
                  style={{
                    whiteSpace: "nowrap",
                    borderBottom: "none",
                    color: theme.palette.primary.main,
                    fontWeight: "600",
                    fontSize: "0.8rem",
                  }}
                >
                  Access
                </TableCell> */}
                  <TableCell
                    style={{
                      whiteSpace: "nowrap",
                      borderBottom: "none",
                      color: theme.palette.primary.main,
                      fontWeight: "600",
                      fontSize: "0.8rem",
                    }}
                  >
                    Ref
                  </TableCell>
                  <TableCell
                    style={{
                      whiteSpace: "nowrap",
                      borderBottom: "none",
                      color: theme.palette.primary.main,
                      fontWeight: "600",
                      fontSize: "0.8rem",
                    }}
                  >
                    Organisation
                  </TableCell>
                  <TableCell
                    style={{
                      whiteSpace: "nowrap",
                      borderBottom: "none",
                      color: theme.palette.primary.main,
                      fontWeight: "600",
                      fontSize: "0.8rem",
                    }}
                  >
                    Service line
                  </TableCell>
                  <TableCell
                    style={{
                      whiteSpace: "nowrap",
                      borderBottom: "none",
                      color: theme.palette.primary.main,
                      fontWeight: "600",
                      fontSize: "0.8rem",
                    }}
                  >
                    Opportunity In
                  </TableCell>
                  <TableCell
                    style={{
                      whiteSpace: "nowrap",
                      borderBottom: "none",
                      color: theme.palette.primary.main,
                      fontWeight: "600",
                      fontSize: "0.8rem",
                    }}
                  >
                    Estimated Value
                  </TableCell>
                  <TableCell
                    style={{
                      whiteSpace: "nowrap",
                      borderBottom: "none",
                      color: theme.palette.primary.main,
                      fontWeight: "600",
                      fontSize: "0.8rem",
                    }}
                  >
                    Location
                  </TableCell>
                  <TableCell
                    style={{
                      whiteSpace: "nowrap",
                      borderBottom: "none",
                      color: theme.palette.primary.main,
                      fontWeight: "600",
                      fontSize: "0.8rem",
                    }}
                  >
                    Actions
                  </TableCell>
                  {/* <TableCell
                          style={{
                             whiteSpace: 'nowrap',
                            borderBottom: "none",
                            color: theme.palette.primary.main,
                            fontWeight: "600",
                            fontSize: "0.8rem",
                          }}
                        >
                          Location
                        </TableCell> */}

                  {/* <TableCell
                  style={{
                    whiteSpace: "nowrap",
                    borderBottom: "none",
                    color: theme.palette.primary.main,
                    fontWeight: "600",
                    fontSize: "0.95rem",
                  }}
                >
                  Actions
                </TableCell> */}
                </TableRow>
              </TableHead>
              {/* <TableBody> */}
              {data
                ?.sort((a, b) => b.lead_ref_number - a.lead_ref_number)
                ?.map((row, index) => (
                  <TableRow
                    key={index}
                    style={{
                      backgroundColor:
                        index % 2 === 0 && theme.palette.colorthree.main,
                      // cursor: "pointer",
                      // "&:hover": {
                      //   boxShadow:
                      //     "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                      // },
                    }}
                    // onClick={() => {
                    //   setShowDetailsOfLead(true);
                    //   setSelectedLeadForDetails(row);
                    // }}
                  >
                    {/* <TableCell
                  style={{
                    borderBottom: "none",
                    whiteSpace: "nowrap",
                  }}
                >
                  <Box sx={{}}>
                    {row?.post_type == "free" ? (
                      <Globe size={24} strokeWidth={1} color="gray" />
                    ) : (
                      <Award size={24} strokeWidth={2} color="#ffc107" />
                    )}
                  </Box>
                </TableCell> */}
                    <TableCell
                      style={{
                        borderBottom: "none",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <Box sx={{}}>{row?.lead_ref_number}</Box>
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: "none",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {row?.organisation}
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: "none",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {row?.referral_industry}
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: "none",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {row?.referral_type}
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: "none",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {row?.estimated_value}
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: "none",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {row?.city},{row?.country}
                    </TableCell>

                    <TableCell
                      style={{
                        borderBottom: "none",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <IconButton
                        onClick={() => {
                          // alert("clck");
                          setShowDetailsOfLead(true);
                          setSelectedLeadForDetails(row);
                        }}
                      >
                        <Eye
                          strokeWidth={1.5}
                          size={20}
                          color={theme.palette.primary.main}
                          style={{ cursor: "pointer" }}
                        />
                      </IconButton>
                      <Button
                        size="small"
                        variant="contained"
                        sx={{ ml: 2 }}
                        onClick={() => acceptRequest(row)}
                      >
                        Accept
                      </Button>
                      <Button
                        size="small"
                        variant="contained"
                        onClick={() => deleteRequest(row)}
                        sx={{ ml: 2 }}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              {/* </TableBody> */}
            </Table>
          </TableContainer>
        </Box>
      ) : (
        <TableContainer
          component={Paper}
          style={{
            marginBottom: theme.spacing(3),
            border: "none",
            outline: "none",
            boxShadow: "none",
            overflowX: "initial",
          }}
        >
          <Table sx={{ border: "none", outline: "none" }} stickyHeader>
            <TableHead>
              <TableRow
              // sx={{ backgroundColor: theme.palette.secondary.main }}
              >
                <TableCell
                  style={{
                    whiteSpace: "nowrap",
                    padding: "3px 5px",
                    borderBottom: "none",
                    color: theme.palette.primary.main,
                    fontWeight: "600",
                    fontSize: "0.8rem",
                  }}
                >
                  Access
                </TableCell>
                <TableCell
                  style={{
                    whiteSpace: "nowrap",
                    padding: "3px 5px",
                    borderBottom: "none",
                    color: theme.palette.primary.main,
                    fontWeight: "600",
                    fontSize: "0.8rem",
                  }}
                >
                  Ref
                </TableCell>
                <TableCell
                  style={{
                    whiteSpace: "nowrap",
                    padding: "3px 5px",
                    borderBottom: "none",
                    color: theme.palette.primary.main,
                    fontWeight: "600",
                    fontSize: "0.8rem",
                  }}
                >
                  Organisation
                </TableCell>
                <TableCell
                  style={{
                    whiteSpace: "nowrap",
                    padding: "3px 5px",
                    borderBottom: "none",
                    color: theme.palette.primary.main,
                    fontWeight: "600",
                    fontSize: "0.8rem",
                  }}
                >
                  Service line
                </TableCell>
                <TableCell
                  style={{
                    whiteSpace: "nowrap",
                    padding: "3px 5px",
                    borderBottom: "none",
                    color: theme.palette.primary.main,
                    fontWeight: "600",
                    fontSize: "0.8rem",
                  }}
                >
                  Opportunity In
                </TableCell>
                <TableCell
                  style={{
                    whiteSpace: "nowrap",
                    padding: "3px 5px",
                    borderBottom: "none",
                    color: theme.palette.primary.main,
                    fontWeight: "600",
                    fontSize: "0.8rem",
                  }}
                >
                  Headline
                </TableCell>
                <TableCell
                  style={{
                    whiteSpace: "nowrap",
                    padding: "3px 5px",
                    borderBottom: "none",
                    color: theme.palette.primary.main,
                    fontWeight: "600",
                    fontSize: "0.8rem",
                  }}
                >
                  Actions
                </TableCell>
                {/* <TableCell
                            style={{
                              whiteSpace: "nowrap",
                              padding:'3px 5px',
                              borderBottom: "none",
                              color: theme.palette.primary.main,
                              fontWeight: "600",
                              fontSize: "0.8rem",
                            }}
                          >
                            Location
                          </TableCell> */}
                {/* <TableCell
                           style={{
                              whiteSpace: 'nowrap',
                             borderBottom: "none",
                             color: theme.palette.primary.main,
                             fontWeight: "600",
                             fontSize: "0.8rem",
                           }}
                         >
                           Location
                         </TableCell> */}
                {/* <TableCell
                            style={{
                              whiteSpace: "nowrap",
                              padding:'3px 5px',
                              borderBottom: "none",
                              color: theme.palette.primary.main,
                              fontWeight: "600",
                              fontSize: "0.95rem",
                            }}
                          >
                            Details
                          </TableCell> */}
              </TableRow>
            </TableHead>
            {/* <TableBody> */}
            {noticeBoardLeads
              ?.sort((a, b) => b.lead_ref_number - a.lead_ref_number)
              ?.map((row, index) => (
                <Lead_row
                  index={index}
                  row={row}
                  setShowDetailsOfLead={setShowDetailsOfLead}
                  setSelectedLeadForDetails={setSelectedLeadForDetails}
                  setNoticeBoardLeads={setNoticeBoardLeads}
                  noticeBoardLeads={noticeBoardLeads}
                  setShowLeadAddingForm={setShowLeadAddingForm}
                  setEditOptions={setEditOptions}
                />
              ))}
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default ManageNoticeBoard;
const Lead_row = ({
  index,
  row,
  setShowDetailsOfLead,
  setSelectedLeadForDetails,
  setNoticeBoardLeads,
  noticeBoardLeads,
  setShowLeadAddingForm,
  setEditOptions,
}) => {
  const theme = useTheme();
  const [isHide, setIsHide] = useState(row?.hidden);
  const hiddenToggle = () => {
    api
      .put(
        `/noticeBoardLeads/hidden-toggle/${row?.lead_ref_number}/${
          isHide ? "unhide" : "hide"
        }`
      )
      .then((res) => {
        console.log(res?.data);
        isHide
          ? toast.success("Unhidden successfully!")
          : toast.success("Hidden successfully!");

        setIsHide(isHide ? false : true);
      })
      .catch((err) => console.log(err));
  };
  const deleteRow = () => {
    api
      .delete(`/noticeBoardLeads/${row?.lead_ref_number}`)
      .then((res) => {
        console.log(res?.data);
        setNoticeBoardLeads(
          noticeBoardLeads?.filter(
            (l) => l?.lead_ref_number != row?.lead_ref_number
          )
        );
        toast.success("deleted successfully!");
      })
      .catch((err) => console.log(err));
  };
  return (
    <TableRow
      key={index}
      sx={{
        padding: "0px",
        margin: "0px",
        backgroundColor: index % 2 === 0 && theme.palette.colorthree.main,
        // cursor: "pointer",
        // "&:hover": {
        //   boxShadow:
        //     "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
        // },
      }}
      // onClick={() => {
      //   setShowDetailsOfLead(true);
      //   setSelectedLeadForDetails(row);
      // }}
    >
      <TableCell
        style={{
          borderBottom: "none",
          padding: "3px 5px",
          whiteSpace: "nowrap",
        }}
      >
        <Box sx={{}}>
          {row?.lead_category == "open" ? (
            <Globe size={24} strokeWidth={1} color="gray" />
          ) : (
            <Award size={24} strokeWidth={2} color="#ffc107" />
          )}
        </Box>
      </TableCell>
      <TableCell
        style={{
          borderBottom: "none",
          padding: "0px",
          whiteSpace: "nowrap",
        }}
      >
        <Box sx={{}}>{row?.lead_ref_number}</Box>
      </TableCell>
      <TableCell
        style={{
          borderBottom: "none",
          padding: "0px",
          whiteSpace: "nowrap",
        }}
      >
        {row?.organisation}
      </TableCell>
      <TableCell
        style={{
          borderBottom: "none",
          padding: "0px",
          whiteSpace: "nowrap",
        }}
      >
        {row?.referral_industry}
      </TableCell>
      <TableCell
        style={{
          borderBottom: "none",
          padding: "0px",
          whiteSpace: "nowrap",
        }}
      >
        {row?.referral_type}
      </TableCell>
      <TableCell
        style={{
          borderBottom: "none",
          padding: "0px",
          whiteSpace: "nowrap",
        }}
      >
        {row?.referral_title}
      </TableCell>
      <TableCell
        style={{
          borderBottom: "none",
          adding: "0px",
          whiteSpace: "nowrap",
          display: "flex",
          gap: "5px",
        }}
      >
        <IconButton
          onClick={() => {
            console.log("setSHow details of lead", setShowDetailsOfLead);
            setShowDetailsOfLead(true);
            setSelectedLeadForDetails(row);
          }}
        >
          <Eye
            strokeWidth={1.5}
            size={20}
            color={theme.palette.primary.main}
            style={{ cursor: "pointer" }}
          />
        </IconButton>
        <Button
          size="small"
          variant="outlined"
          onClick={() => {
            setShowLeadAddingForm(true);
            setEditOptions(row);
          }}
        >
          Edit
        </Button>
        <Button size="small" variant="outlined" onClick={() => hiddenToggle()}>
          {isHide ? "UnHide" : "Hide"}
        </Button>
        <Button size="small" variant="contained" onClick={() => deleteRow()}>
          delete
        </Button>
      </TableCell>
      {/* 
      <TableCell
        style={{
          borderBottom: "none",
          adding: "0px",
          whiteSpace: "nowrap",
        }}
      >
       
      </TableCell> */}
    </TableRow>
  );
};
