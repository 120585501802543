import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
  IconButton,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { api } from "../../../../axios/api";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material";

const LastActionDays = ({ selectedLead }) => {
  const [notes, setNotes] = useState([]);
  const [lastActionTime, setLastActionTime] = useState(0);
  const theme = useTheme();

  useEffect(() => {
    console.log("runnibg useefeect");

    let startDate = new Date(selectedLead?.date);
    let totalDays = 0;
    let totalHours = 0;
    let totalMinutes = 0;
    let endDate;

    if (
      selectedLead?.end_date &&
      (selectedLead?.isDropped || selectedLead?.Won)
    ) {
      endDate = new Date(selectedLead?.end_date);
    } else {
      endDate = new Date(Date.now());
    }
    console.log("start date", startDate);
    console.log("end date", endDate);
    let timeDifference = endDate.getTime() - startDate.getTime(); // Difference in milliseconds
    totalDays = Math.floor(timeDifference / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
    totalHours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24); // Convert milliseconds to hours and calculate the remaining hours
    totalMinutes = Math.floor((timeDifference / (1000 * 60)) % 60); // Convert milliseconds to minutes and calculate the remaining minutes
    setLastActionTime(
      totalDays + " days " + totalHours + " hours " + totalMinutes + " minutes"
    );
    console.log("total time", totalDays + " days " + totalHours + " hours");

    api
      .get("/leadsNotes/" + selectedLead?.lead_serial_number)
      .then((res) => {
        let notes = res?.data;
        if (notes?.length > 0) {
          console.log("notes", notes);

          let latestUploadDate = null;

          if (notes.length > 0) {
            latestUploadDate = notes.reduce((maxDate, note) => {
              const noteDate = new Date(note.date_of_upload);
              return noteDate > maxDate ? noteDate : maxDate;
            }, new Date(notes[0].date_of_upload));
          }

          console.log("latestUploadDate", latestUploadDate); // Output: 2023-07-19T19:48:04.891Z

          let timeDifference2 =
            new Date().getTime() - latestUploadDate.getTime(); // Difference in milliseconds
          totalDays = Math.floor(timeDifference2 / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
          totalHours = Math.floor((timeDifference2 / (1000 * 60 * 60)) % 24); // Convert milliseconds to hours and calculate the remaining hours
          totalMinutes = Math.floor((timeDifference2 / (1000 * 60)) % 60); // Convert milliseconds to minutes and calculate the remaining minutes
          setLastActionTime(
            totalDays +
              " days " +
              totalHours +
              " hours " +
              totalMinutes +
              " minutes"
          );
          console.log(
            "lastAction Time",
            totalDays +
              " days " +
              totalHours +
              " hours " +
              totalMinutes +
              " minutes"
          );
        }
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <Box sx={{ mt: 2, background: "white", p: 3 }}>
      <Typography variant="p">Time till last actions</Typography>
      <Typography variant="h6" sx={{ color: theme.palette.primary.main }}>
        {lastActionTime}
      </Typography>
    </Box>
  );
};

export default LastActionDays;
