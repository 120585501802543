import {
  Box,
  Button,
  Input,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { api } from "../../../axios/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AgentPrice = () => {
  const theme = useTheme();
  const [agentOffers, setAgentOffers] = useState([]);
  const [newPrice, setNewPrice] = useState(0);
  const fetchAgentsOffers = () => {
    api
      .get("/subscriptions/agent")
      .then((res) => {
        console.log("agent subs", res?.data);
        setAgentOffers(res?.data);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    fetchAgentsOffers();
  }, []);
  const [offerToEdit, setOfferToEdit] = useState({});
  const editAmountOf = (offer) => {
    console.log("offer to be edited", offer);
    setOfferToEdit(offer);
    handleOpen();
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setOfferToEdit({});
  };

  const updateAmount = () => {
    console.log("offer", offerToEdit);
    console.log("amount to update", newPrice);
    api
      .put("/subscriptions/update-amount/agent/" + offerToEdit?.title, {
        newPrice,
      })
      .then((res) => {
        console.log("update response", res?.data);
        handleClose();
        fetchAgentsOffers();
        toast.success("updated successfully");
      })
      .catch((err) => {
        console.log(err);
        toast.error("unable to update at the moment");
      });
  };
  return (
    <Box sx={{ background: "#fff", p: 2 }}>
      <ToastContainer />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",

            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {offerToEdit?.title}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mb: 2 }}>
            Update price of the package
          </Typography>
          <Box>
            <Typography>Price (KES)</Typography>

            <Input
              type="number"
              defaultValue={offerToEdit?.price}
              onChange={(e) => setNewPrice(e.target.value)}
            />
          </Box>

          <Button
            variant="contained"
            sx={{ mt: 3 }}
            onClick={() => updateAmount()}
          >
            update
          </Button>
        </Box>
      </Modal>
      <Typography variant="h6" sx={{ color: theme.palette.heading.main }}>
        Agent Price
      </Typography>
      <Typography sx={{ color: theme.palette.para.main }}>
        Set prices of subscription in case of agent.
      </Typography>
      <Box sx={{ mt: 2 }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Subscription Plan</TableCell>
                <TableCell>Subscription Amount (KES)</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {agentOffers?.length > 0 &&
                agentOffers?.map((of) => (
                  <TableRow>
                    <TableCell>{of?.title}</TableCell>
                    <TableCell>{of?.price}</TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        onClick={() => editAmountOf(of)}
                      >
                        Edit Amount
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default AgentPrice;
