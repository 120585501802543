import React, { useEffect, useState } from "react";
import { Formik, Field, Form, FormikConsumer } from "formik";
import * as Yup from "yup";
import {
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
  Button,
  Grid,
  Box,
} from "@mui/material";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { api } from "../../../axios/api";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AES from "crypto-js/aes";

const validationSchema = Yup.object().shape({
  dailyTarget: Yup.string().required("Required"),
  startDate: Yup.string().required("Required"),
  endDate: Yup.string().required("Required"),
  // phone_number: Yup.string().required("Required"),
});

const AddTargetForm = ({
  editOptions,
  setShowAddForm,
  organizationInformation,
}) => {
  const theme = useTheme();
  const user = useSelector((state) => state.user);
  const [authorizedToCompany, setAuthorizedToCompany] = useState();
  const [authorizedToCompanyError, setAuthorizedToCompanyError] = useState("");
  console.log("edit options at target form", editOptions);
  console.log(
    "organization information at target form",
    organizationInformation
  );
  const [initialValues, setInitialValues] = useState({
    dailyTarget: 0,
    startDate: "",
    endDate: "",
  });

  useEffect(() => {
    console.log("user at add contact", user);
  }, [user]);
  useEffect(() => {
    console.log("edit options at add supplier form", editOptions);
    setAuthorizedToCompany(editOptions?.authorized_to_company_details);
  }, [editOptions]);
  useEffect(() => {
    setAuthorizedToCompanyError("");
  }, [authorizedToCompany]);
  useEffect(() => {
    console.log("authorizedToCompanyError", authorizedToCompanyError);
  }, [authorizedToCompanyError]);

  const onSubmit = (values, { resetForm }) => {
    console.log("values at on submit", values);
    console.log("edit options", editOptions);

    let body = {
      partner_id: editOptions?.id,
      daily_target: values.dailyTarget,
      start_date: values.startDate,
      end_date: values.endDate,
    };
    api
      .post("/target", body)
      .then((res) => {
        console.log("response from adding target", res?.data);
        toast.success("target added successfully!");
        resetForm();
        setTimeout(() => {
          setShowAddForm(false);
        }, 2000);
      })
      .catch((err) => {
        console.log("err", err);
        toast.error("error in adding contact");
      });
  };

  const cancelForm = () => {
    setShowAddForm(false);
  };
  return (
    <Box sx={{ padding: "30px 0px" }}>
      <ToastContainer />
      {/* <Typography
        variant="h6"
        sx={{
          color: theme.palette.heading.main,
          fontSize: "1.3rem",
          fontWeight: "600",
        }}
      >
        Add Contact
      </Typography> */}
      <Box
        sx={{
          backgroundColor: theme.palette.background.main,
          padding: "20px",
          borderRadius: "5px",
          marginTop: "20px",
          maxWidth: "700px",
        }}
      >
        <Typography
          variant="body2"
          sx={{ margin: "10px 0 30px 0", color: theme.palette.para.main }}
        >
          Fill up the form with user data.
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ errors, touched }) => (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Field
                    name="dailyTarget"
                    as={TextField}
                    type="number"
                    label={`Daily target (${organizationInformation?.organization_currency})`}
                    fullWidth
                    error={touched.dailyTargtet && Boolean(errors.dailyTargtet)}
                    helperText={touched.dailyTargtet && errors.dailyTargtet}
                  />
                </Grid>

                <Grid item xs={12} sm={editOptions?.id ? 12 : 6}>
                  <Typography
                    variant="body2"
                    sx={{ color: theme.palette.heading.main, mb: 1 }}
                  >
                    Start Date
                  </Typography>
                  <Field
                    name="startDate"
                    as={TextField}
                    type="date"
                    // label="Start date"
                    fullWidth
                    error={touched.startDate && Boolean(errors.startDate)}
                    helperText={touched.startDate && errors.startDate}
                    sx={{ marginBottom: "20px" }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    variant="body2"
                    sx={{ color: theme.palette.heading.main, mb: 1 }}
                  >
                    End Date
                  </Typography>
                  <Field
                    variant="outlined"
                    name="endDate"
                    as={TextField}
                    type="date"
                    // label="End Date"
                    fullWidth
                    error={touched.endDate && Boolean(errors.endDate)}
                    helperText={touched.endDate && errors.endDate}
                    sx={{ marginBottom: "20px" }}
                  />
                </Grid>

                <Grid item xs={12} sm={4}></Grid>
                <Grid item xs={12} sm={4}></Grid>

                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    // color={theme.palette.colortwo.main}
                    sx={{
                      backgroundColor: theme.palette.colortwo.main,
                      color: theme.palette.whiteFont.main,
                    }}
                    type="submit"
                    size="small"
                  >
                    {editOptions?.id ? "Update" : "Submit"}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => cancelForm()}
                    sx={{
                      marginLeft: "10px",
                      backgroundColor: theme.palette.colortwo.main,
                      color: theme.palette.whiteFont.main,
                    }}
                    size="small"
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default AddTargetForm;
