import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
  IconButton,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

import { api } from "../../../axios/api";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import AddAgentForm from "./CreateAgents";
import { Edit, Trash2 } from "react-feather";
import { ToastContainer, toast } from "react-toastify";

const ListOfAgents = (props) => {
  const theme = useTheme();
  const [users, setUsers] = useState([]);
  const user = useSelector((state) => state.user);
  const [showAddForm, setShowAddForm] = useState(false);
  const [editOptions, setEditOptions] = useState({});

  useEffect(() => {
    console.log("user at show contact", user);
  }, [user]);
  useEffect(() => {
    setUsers([]);
    api
      .get("/users/agent")
      .then((res) => {
        console.log("response from getting users", res?.data);
        setUsers(res?.data);
      })
      .catch((err) => console.log("err", err));
    if (!showAddForm) {
      setEditOptions({});
    }
  }, [user, showAddForm]);

  return (
    <Box sx={{ padding: "20px 30px" }}>
      <ToastContainer />

      <>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            variant="h6"
            sx={{
              color: theme.palette.heading.main,
              fontSize: "1.3rem",
              fontWeight: "600",
            }}
          >
            {"Agents"}
          </Typography>
          {/* <Button
            variant="contained"
            onClick={() => setShowAddForm(!showAddForm)}
            sx={{
              backgroundColor: theme.palette.colortwo.main,
              color: "#fff",
            }}
            size="small"
          >
            {showAddForm ? "View Team Members" : "Add Team Members"}
          </Button> */}
        </Box>

        {showAddForm ? (
          <AddAgentForm
            editOptions={editOptions}
            setShowAddForm={setShowAddForm}
          />
        ) : users?.length < 1 ? (
          <Box
            sx={{ display: "flex", alignItems: "center", mt: 2, gap: "10px" }}
          >
            <InfoIcon />
            <Typography sx={{}}> No member added.</Typography>
          </Box>
        ) : (
          <>
            <Box sx={{ marginTop: "20px" }}>
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={{
                              width: "50px",
                              color: theme.palette.colortwo.main,
                            }}
                          >
                            ID
                          </TableCell>
                          {/* <TableCell>Customer ID</TableCell> */}
                          <TableCell
                            sx={{
                              color: theme.palette.colortwo.main,
                            }}
                          >
                            Name
                          </TableCell>
                          <TableCell
                            sx={{
                              color: theme.palette.colortwo.main,
                            }}
                          >
                            Email
                          </TableCell>
                          <TableCell
                            sx={{
                              color: theme.palette.colortwo.main,
                            }}
                          >
                            Phone
                          </TableCell>

                          <TableCell
                            sx={{
                              color: theme.palette.colortwo.main,
                            }}
                          >
                            Actions
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {users?.map((contact, i) => (
                          <NormalUser
                            contact={contact}
                            i={i}
                            users={users}
                            setUsers={setUsers}
                            setShowAddForm={setShowAddForm}
                            setEditOptions={setEditOptions}
                          />
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      </>
    </Box>
  );
};

const NormalUser = ({
  contact,
  i,
  users,
  setUsers,
  setEditOptions,
  setShowAddForm,
}) => {
  const theme = useTheme();
  const [isBlocked, setIsBlocked] = useState(
    contact?.is_agent_suspended ? true : false
  );

  const deleteCustomer = () => {
    console.log("contact id", contact?.id);
    api
      .put("/updateAgentByAdmin/" + contact?.id, { is_agent_suspended: true })
      .then((res) => {
        console.log("suspend user response", res?.data);
        toast.success("Suspended Successfully!");
        // setUsers(users?.filter((c) => c?.id !== contact?.id));
        setIsBlocked(true);
      })
      .catch((err) => {
        console.log("error in deleting user", err);
        toast.error("unable to suspend!");
      });
  };
  const activateCustomer = () => {
    console.log("contact id", contact?.id);
    api
      .put("/updateAgentByAdmin/" + contact?.id, {
        is_agent_suspended: false,
      })
      .then((res) => {
        console.log("activate user response", res?.data);
        toast.success("activated Successfully!");
        // setUsers(users?.filter((c) => c?.id !== contact?.id));
        setIsBlocked(false);
      })
      .catch((err) => {
        console.log("error in activating user", err);
        toast.error("unable to activate!");
      });
  };

  const editSupplier = () => {
    setEditOptions(contact);
    setShowAddForm(true);
  };
  return (
    <TableRow
      key={contact?.id}
      style={{
        backgroundColor:
          i % 2 == 0 ? theme.palette.colorthree.main : "transparent",
      }}
    >
      <TableCell sx={{ width: "50px", padding: "8px 20px" }}>
        {contact?.id}
      </TableCell>
      {/* <TableCell>{contact?.customer_id}</TableCell> */}
      <TableCell sx={{ padding: "8px 20px" }}>{contact?.name}</TableCell>
      <TableCell sx={{ padding: "8px 20px" }}>
        {contact?.email_address}
      </TableCell>
      <TableCell sx={{ padding: "8px 20px" }}>
        {contact?.phone_number}
      </TableCell>

      <TableCell sx={{ padding: "8px 20px" }}>
        {contact?.profile_type == "admin" ? (
          "Admin Account"
        ) : (
          <Box sx={{ display: "flex", gap: "5px", flexDirection: "row" }}>
            {isBlocked ? (
              <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <Typography> Suspended.</Typography>{" "}
                <Button
                  onClick={() => activateCustomer()}
                  sx={{ background: theme.palette.colorthree.main }}
                >
                  Activate
                </Button>
              </Box>
            ) : (
              <>
                {" "}
                {/* <IconButton onClick={() => editSupplier()}>
                <Edit size="18" color={theme.palette.colortwo.main} />
              </IconButton> */}
                <Button
                  onClick={() => editSupplier()}
                  sx={{ background: theme.palette.colorthree.main }}
                >
                  Edit
                </Button>
                {/* <IconButton
                onClick={() => {
                  deleteCustomer();
                }}
              >
                <Trash2 size="18" color={theme.palette.colortwo.main} />
              </IconButton> */}
                <Button
                  onClick={() => {
                    deleteCustomer();
                  }}
                  variant="contained"
                >
                  Suspend
                </Button>
              </>
            )}
          </Box>
        )}
      </TableCell>
    </TableRow>
  );
};

export default ListOfAgents;
