import React from "react";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  useMediaQuery,
} from "@mui/material";
import video from "../utils/reels/edge360crm.mp4";
import Navbar from "../components/Navbar";
import HowToUse from "../components/home/HowToUse";
import Footer from "../components/Footer";
import SystemFeatures from "../components/home/SystemFeatures";
import GetStarted from "../components/home/GetStarted";
import { WhatsAppWidget } from "react-whatsapp-widget";

import "react-whatsapp-widget/dist/index.css";

const Home = () => {
  const theme = useTheme();
  return (
    <Box>
      {/* Home */}
      <Navbar />
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: "100vh",

          // minHeight: "860px",
          maxHeight: "900px",
          overflow: "hidden",
          // marginTop: "100px",
          "& video": {
            width: "100%",
            height: "100%",
            // objectFit: "cover",
            objectFit: { xs: "cover", sm: "cover" },
            position: "absolute",
            top: 0,
            left: 0,
          },
          "& .overlay": {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 200, 200, 0.25)",
          },
        }}
      >
        <video autoPlay muted playsInline loop playbackRate={0.5}>
          <source
            src="https://files.edge360crm.com/edge360crm%20(1).mp4"
            // "https://sfo3.digitaloceanspaces.com/edge360crm.com/edge360crm%20%281%29.mp4"
            // "https://cdn.filestackcontent.com/deqYbwFwQZO4v4slkF8u"
            // {video}
            type="video/mp4"
            loading="lazy"
          />
          Your browser does not support the video tag.
        </video>
        <Box className="overlay" />
        <Container>
          <Box
            sx={{
              zIndex: "0",
              height: "100%",
              position: "relative",
              display: "flex",
              //  padding: { sx: "30px 20px", sm: "30px 40px" },
              padding: { sx: "30px 0px", sm: "30px 0px" },
              // alignItems: "center",
              // border: "1px solid black",
            }}
          >
            <Box
              sx={{
                flex: { md: "0.5", xs: "1" },
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: { xs: "center", md: "flex-start" },
                //   justifyContent: "flex-start",
                transform: "translateY(-70px)",
                //   border: "1px solid black",
                //   paddingTop: "70px",
                paddingTop: { sm: "18vh", xs: "23vh", xl: "10vh" },
                gap: "30px",
              }}
            >
              <Typography
                variant="h1"
                sx={{
                  // color: "#fff",
                  color: theme.palette.colortwo.main,
                  fontWeight: "700",
                  fontSize: { sm: "4.4rem", xs: "3.5rem" },
                  textAlign: { sm: "left", xs: "center" },
                }}
              >
                Accelerate Your Sales Growth with Edge360
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: theme.palette.colortwo.main,
                  // color: "#fff",
                  color: theme.palette.heading.main,
                  fontSize: { sm: "1.2rem", xs: "1.3rem" },
                  textAlign: { sm: "left", xs: "center" },
                  padding: { sm: "0", xs: "10px" },
                  fontWeight: "400",
                }}
              >
                Growing sales is a challenging job. Edge360 makes it easy.
                Edge360 is a CRM that streamlines leads generation, tracks
                follow-ups, and helps your team make smart decisions that
                grow your sales
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
      {/*********************** */}
      <HowToUse />
      <GetStarted />
      <SystemFeatures />
      <Footer />
      {/* WhatsApp floating icon */}{" "}
      <WhatsAppWidget phoneNumber={process.env.REACT_APP_WHATSAPP_NUMBER} />
    </Box>
  );
};

export default Home;
