import React, { useState, useEffect } from "react";

import {
  TextField,
  Button,
  Grid,
  Typography,
  Box,
  FormControl,
  Select,
  MenuItem,
  Input,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useTheme } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import * as filestack from "filestack-js";
import { api } from "../../../axios/api";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import PhoneInput from "react-phone-input-2";

const industries = [
  "Lawyers",
  "Management consultants",
  "Engineers",
  "Surveyors",
  "Marketing & PR agencies",
  "IT firms",
  "Architects",
  "Insurance agencies",
  "Real estate agencies",
  "Travel agencies",
  "Accountants",
  "Various Goods & Services",
];

const AddLeadToNoticeBoardForm = ({
  editOptions,
  setShowLeadAddingForm,
  prospects,
  isSuperAdmin,
}) => {
  const user = useSelector((state) => state.user);
  useEffect(() => {
    console.log("user at add prospect form", user);
  }, [user]);

  const theme = useTheme();
  const [errors, setErrors] = useState([]);

  // Modified state and fields
  const [leadRefNumber, setLeadRefNumber] = useState(
    "Auto generated by the system"
  );
  const [datePosted, setDatePosted] = useState(new Date().toLocaleDateString());
  const [validUpTo, setValidUpTo] = useState(""); // Calendar date
  const [referralIndustry, setReferralIndustry] = useState("");
  const [referralType, setReferralType] = useState("");
  const [referralTitle, setReferralTitle] = useState("");
  const [referralDetails, setReferralDetails] = useState("");
  const [websiteLink, setWebsiteLink] = useState("");
  const [attachment, setAttachment] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [estimatedValue, setEstimatedValue] = useState(0);
  const [knowValue, setKnowValue] = useState("NO"); // Default to NO
  const [valueCurrency, setValueCurrency] = useState("");
  const [organisation, setOrganisation] = useState("Available on request");
  const [finderFee, setFinderFee] = useState("");
  const [knowFinderFee, setKnowFinderFee] = useState("NO");
  const [finderFeeCurrency, setFinderFeeCurrency] = useState("");
  const [commission, setCommission] = useState("to be discussed"); // Default
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [leadCategory, setLeadCategory] = useState("open"); //open or premium
  const [showDetailsOptions, setShowDetailsOptions] = useState(false);
  const [selectedShowDetailOption, setSlectedShowDetailOption] =
    useState("manual"); // manual or  file
  const [yourName, setYourName] = useState("");
  const [yourEmail, setYourEmail] = useState("");
  const [yourPhoneNumber, setYourPhoneNumber] = useState("");
  const [yourNote, setYourNote] = useState("");

  console.log("edit options", editOptions);
  useEffect(() => {
    if (editOptions?.lead_ref_number) {
      setLeadRefNumber(
        editOptions.lead_ref_number || "Auto generated by the system"
      );
      setDatePosted(editOptions.date_posted || new Date().toLocaleDateString());
      setValidUpTo(editOptions.valid_up_to || "");
      setReferralIndustry(editOptions.referral_industry || "");
      setReferralType(editOptions.referral_type || "");
      setWebsiteLink(editOptions.website_link || "");
      setReferralTitle(editOptions.referral_title || "");
      setReferralDetails(editOptions.referral_details || "");
      setAttachment(editOptions.attachment || "");
      setCountry(editOptions.country || "");
      setCity(editOptions.city || "");
      setEstimatedValue(editOptions.estimated_value || "");
      setKnowValue(editOptions.know_value || "NO");
      setValueCurrency(editOptions.value_currency || "");
      setFinderFee(editOptions.finder_fee || "");
      setKnowFinderFee(editOptions.know_finder_fee || "NO");
      setFinderFeeCurrency(editOptions.finder_fee_currency || "");
      setOrganisation(editOptions.organisation || "Available on request");

      setCommission(editOptions.commission || "to be discussed");
      setEmail(editOptions.email || "");
      setPhone(editOptions.phone || "");
      setLeadCategory(editOptions.lead_category || "");
      // Other state values go here

      // Assuming that showDetailsOptions and selectedShowDetailOption
      // are not part of the editOptions
      setShowDetailsOptions(false);
      setSlectedShowDetailOption("manual");
      setYourEmail(editOptions?.your_email || "");
      setYourName(editOptions?.your_name || "");
      setYourNote(editOptions?.your_note || "");
      setYourPhoneNumber(editOptions?.your_phone_number || "");
    }
  }, [editOptions]);
  const uploadFileToBackend = async (file, isJSON) => {
    const formData = new FormData();
    isJSON
      ? formData.append(
          "files",
          new Blob([file], { type: "application/json" }),
          "data.json"
        )
      : formData.append("files", file);

    try {
      const response = await api.post("/files/upload/space", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("url", response?.data?.url);
      return response.data.url; // Assuming the response contains the S3 URL
    } catch (error) {
      // console.error(error);
      // setIsLoading(false);
      // toast.error("unable to cntinue at the moment!");
      return null;
    }
  };
  const uploadAttachment = async (files) => {
    console.log("files", files);
    // const client = filestack.init(process.env.REACT_APP_FILESTACK_API);

    try {
      // const response = await client.upload(files[0]);
      // // Handle the response from Filestack
      // console.log("Filestack response:", response?.url);
      // setAttachment(response?.url);
      // You can save the response URL to your state or perform any other necessary actions.
      const url = await uploadFileToBackend(files[0]);
      setAttachment(url);
    } catch (error) {
      // Handle errors here
      console.error("Filestack upload error:", error);
    }
  };
  useEffect(() => {
    console.log("errors", errors);
  }, [errors]);
  let requiredFields = [
    { name: "leadRefNumber", value: leadRefNumber },
    { name: "datePosted", value: datePosted },
    { name: "validUpTo", value: validUpTo },
    { name: "referralIndustry", value: referralIndustry },
    { name: "referralType", value: referralType },
    { name: "referralTitle", value: referralTitle },
    // { name: "referralDetails", value: referralDetails },
    // { name: "attachment", value: attachment },
    { name: "country", value: country },
    { name: "city", value: city },
    { name: "estimatedValue", value: estimatedValue },
    { name: "knowValue", value: knowValue },
    { name: "valueCurrency", value: valueCurrency },
    { name: "organisation", value: organisation },
    { name: "finderFee", value: finderFee },
    { name: "knowFinderFee", value: knowFinderFee },
    { name: "finderFeeCurrency", value: finderFeeCurrency },
    { name: "commission", value: commission },
    { name: "email", value: email },
    { name: "yourName", value: yourName },
    { name: "yourEmail", value: yourEmail },
    { name: "yourPhoneNumber", value: yourPhoneNumber },
    { name: "yourNote", value: yourNote },
    // { name: "phone", value: phone },
    { name: "leadCategory", value: leadCategory },
  ];

  useEffect(() => {
    const newErrors = {};
    console.log("knowValue", knowValue);
    requiredFields.forEach((field) => {
      console.log("fileds", field);
      if (knowValue == "NO" || isSuperAdmin) {
        console.log("intaa 1");
        if (field.name == "valueCurrency" || field.name == "estimatedValue") {
          console.log("intaa 2");
          newErrors[field.name] = "";
          return;
        }
      }
      if (knowFinderFee == "NO" || isSuperAdmin) {
        console.log("intaa 3");
        if (field.name == "finderFee" || field.name == "finderFeeCurrency") {
          console.log("intaa 4");
          newErrors[field.name] = "";
          return;
        }
      }
      if (isSuperAdmin) {
        if (
          field.name == "yourName" ||
          field.name == "yourEmail" ||
          field.name == "yourPhoneNumber" ||
          field.name == "yourNote"
        ) {
          newErrors[field.name] = "";
          return;
        }
      }
      console.log("intaa 5");
      if (!field.value) {
        newErrors[field.name] = "Required";
      } else if (field.name == "yourNote" && field?.value?.length > 250) {
        newErrors[field.name] = "max 250 charachters allowed";
      } else {
        newErrors[field.name] = "";
      }
    });
    // if (knowValue == "NO") {
    //   console.log("running first");
    //   requiredFields.forEach((field) => {
    //     console.log("field", field);
    //     if (
    //       field.name !== "finderFee" &&
    //       field.name !== "currency" &&
    //       field.name !== "estimatedValue"
    //     )
    //       if (!field.value) {
    //         newErrors[field.name] = "Required";
    //       } else {
    //         newErrors[field.name] = "";
    //       }
    //   });
    // } else {
    //   console.log("running second");
    //   requiredFields.forEach((field) => {
    //     if (!field.value) {
    //       newErrors[field.name] = "Required";
    //     } else {
    //       newErrors[field.name] = "";
    //     }
    //   });
    // }
    // Check and update errors for each required field

    // Update errors in the state
    setErrors(newErrors);
  }, [
    leadRefNumber,
    datePosted,
    validUpTo,
    referralIndustry,
    referralType,
    referralTitle,
    // referralDetails,
    // attachment,
    country,
    city,
    estimatedValue,
    knowValue,
    valueCurrency,
    organisation,
    finderFee,
    knowFinderFee,
    finderFeeCurrency,
    yourEmail,
    yourName,
    yourPhoneNumber,
    yourNote,
    // commission,
    email,
    // phone,
    leadCategory,
  ]);

  useEffect(() => {
    console.log("know value", knowValue);

    setErrors({ ...errors, finderFee: "", currency: "" });
  }, [knowValue]);
  useEffect(() => {
    console.log("know finer fee value", knowFinderFee);

    setErrors({ ...errors, finderFee: "", finderFeeCurrency: "" });
  }, [knowFinderFee]);

  const onSubmit = () => {
    setErrors({});
    // Check if all required fields are filled

    const newErrors = {};
    console?.log("know value", knowValue);
    requiredFields.forEach((field) => {
      console.log("fileds", field);
      if (knowValue == "NO" || isSuperAdmin) {
        console.log("intaa 1");
        if (field.name == "valueCurrency" || field.name == "estimatedValue") {
          console.log("intaa 2");
          newErrors[field.name] = "";
          return;
        }
      }
      if (knowFinderFee == "NO" || isSuperAdmin) {
        console.log("intaa 3");
        if (field.name == "finderFee" || field.name == "finderFeeCurrency") {
          console.log("intaa 4");
          newErrors[field.name] = "";
          return;
        }
      }
      if (isSuperAdmin) {
        if (
          field.name == "yourName" ||
          field.name == "yourEmail" ||
          field.name == "yourPhoneNumber" ||
          field.name == "yourNote"
        ) {
          newErrors[field.name] = "";
          return;
        }
      }
      console.log("intaa 5");
      if (!field.value) {
        newErrors[field.name] = "Required";
      } else if (field.name == "yourNote" && field?.value?.length > 250) {
        newErrors[field.name] = "max 250 charachters allowed";
      } else {
        newErrors[field.name] = "";
      }
    });
    // if (knowValue == "NO") {
    //   console.log("running first");
    //   requiredFields.forEach((field) => {
    //     if (
    //       field.name !== "finderFee" &&
    //       field.name !== "currency" &&
    //       field.name !== "estimatedValue"
    //     )
    //       if (!field.value) {
    //         newErrors[field.name] = "Required";
    //       } else {
    //         newErrors[field.name] = "";
    //       }
    //   });
    // } else {
    //   console.log("running second");
    //   requiredFields.forEach((field) => {
    //     if (!field.value) {
    //       newErrors[field.name] = "Required";
    //     } else {
    //       newErrors[field.name] = "";
    //     }
    //   });
    // }

    setErrors(newErrors);

    // const isAllFieldsFilled = requiredFields.every(
    //   (field) => field.value !== ""
    // );
    console.log("new errors", newErrors);
    console.log("required fields", requiredFields);
    const isAllFieldsFilled = Object.values(newErrors).every(
      (value) => value === ""
    );

    // const isAllFieldsFilled = requiredFields.every((field) => field !== "");

    if (isAllFieldsFilled) {
      // Convert keys to snake case
      const snakeCaseData = {
        lead_ref_number: leadRefNumber,
        date_posted: datePosted,
        valid_up_to: validUpTo,
        referral_industry: referralIndustry,
        referral_type: referralType,
        referral_title: referralTitle,
        referral_details: referralDetails,
        website_link: websiteLink,
        attachment: attachment,
        country: country,
        city: city,
        estimated_value: estimatedValue,
        know_value: knowValue,
        value_currency: valueCurrency,
        finder_fee: finderFee,
        finder_fee_currency: finderFeeCurrency,
        know_finder_fee: knowFinderFee,
        lead_category: leadCategory,
        commission: commission,
        email: email,
        phone: phone,
        uploader_id: user?.id ? user?.id : "",
        post_type: user?.id ? "premium" : "free",
        authorized_by_admin: user?.id ? true : false,
        posted_by_admin: isSuperAdmin ? true : false,
        organisation,
        your_name: yourName,
        your_email: yourEmail,
        your_phone_number: yourPhoneNumber,
        your_note: yourNote,
      };
      console.log("snake case data", snakeCaseData);
      // return;

      // Log the object to the console
      console.log("data to be submitted", snakeCaseData);
      if (editOptions?.lead_ref_number) {
        api
          .put("/noticeBoardLeads/update", snakeCaseData)
          .then((res) => {
            console.log("response from uploading notice board lead", res?.data);
            toast.success("Data updated successfully!");
            setTimeout(() => {
              setShowLeadAddingForm(false);
            }, 2000);
          })
          .catch((err) => console.log("error ", err));
      } else {
        api
          .post("/noticeBoardLeads", snakeCaseData)
          .then((res) => {
            console.log("response from uploading notice board lead", res?.data);
            toast.success("Data added successfully!");
            setTimeout(() => {
              setShowLeadAddingForm(false);
            }, 2000);
          })
          .catch((err) => console.log("error ", err));
      }
    } else {
      // Notify the user to fill all required fields
      toast.error("Please fill all the required fields.");
    }
  };

  const referralTypeOptions = [
    "Expression of Interest",
    "Request for Bids",
    "Supplier Prequalification",
    "Request for Quotation",
    "Invitation to Tender",
    "Sale of Business",
  ];

  return (
    <Box
      // onSubmit={handleSubmit(onSubmit)}
      style={{
        background: theme.palette.background.main,
        marginTop: "20px",
        padding: "20px",
        maxWidth: "700px",
      }}
    >
      <ToastContainer />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography variant="h6" sx={{ color: theme.palette.heading.main }}>
          Enter prospect details
        </Typography>
        <Typography variant="p" sx={{ color: theme.palette.para.main }}>
          Date: {new Date(Date.now()).toDateString()}
        </Typography>
      </Box>
      <Typography
        sx={{ mb: 2, fontSize: "0.8rem", color: theme.palette.para.main }}
      >
        Note: The information contained in the form below will be visible to
        Edge360 users. By filing the form below, you agree to be contacted. If
        you donot wish to be contacted, please leave this page.
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography
            variant="body2"
            sx={{ color: theme.palette.heading.main, mb: 1 }}
          >
            Referral number (automatic)
          </Typography>
          <TextField disabled fullWidth value={leadRefNumber} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            variant="body2"
            sx={{ color: theme.palette.heading.main, mb: 1 }}
          >
            Posting date (automatic)
          </Typography>
          <TextField disabled fullWidth value={datePosted} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            variant="body2"
            sx={{ color: theme.palette.heading.main, mb: 1 }}
          >
            Referral Industry
          </Typography>
          <Select
            MenuProps={{
              style: { zIndex: 1000001 },
            }}
            fullWidth
            value={referralIndustry}
            onChange={(e) => setReferralIndustry(e.target.value)}
          >
            {industries.map((industry, index) => (
              <MenuItem key={index} value={industry}>
                {industry}
              </MenuItem>
            ))}
            {/* Add more industry options */}
          </Select>
          {errors.referralIndustry && (
            <Typography
              variant="body2"
              sx={{
                color: "red",
                mt: 0.4,
                fontSize: "0.8rem",
                fontWeight: "300",
              }}
            >
              Required
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography
            variant="body2"
            sx={{ color: theme.palette.heading.main, mb: 1 }}
          >
            Referral is Valid Up To
          </Typography>
          {/* Integrate a date picker here */}
          <TextField
            fullWidth
            type="date"
            value={validUpTo}
            onChange={(e) => setValidUpTo(e.target.value)}
          />
          {errors.validUpTo && (
            <Typography
              variant="body2"
              sx={{
                color: "red",
                mt: 0.4,
                fontSize: "0.8rem",
                fontWeight: "300",
              }}
            >
              Required
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            variant="body2"
            sx={{ color: theme.palette.heading.main, mb: 1 }}
          >
            Referral Type
          </Typography>
          <Select
            MenuProps={{
              style: { zIndex: 1000001 },
            }}
            fullWidth
            value={referralType}
            onChange={(e) => setReferralType(e.target.value)}
          >
            <MenuItem value="">Select a type</MenuItem>
            {referralTypeOptions.map((option, index) => (
              <MenuItem key={index} value={option}>
                {option}
              </MenuItem>
            ))}
            {/* Add more type options */}
          </Select>
          {errors.referralType && (
            <Typography
              variant="body2"
              sx={{
                color: "red",
                mt: 0.4,
                fontSize: "0.8rem",
                fontWeight: "300",
              }}
            >
              Required
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            variant="body2"
            sx={{ color: theme.palette.heading.main, mb: 1 }}
          >
            Lead Category
          </Typography>
          <Select
            MenuProps={{
              style: { zIndex: 1000001 },
            }}
            fullWidth
            value={leadCategory}
            onChange={(e) => setLeadCategory(e.target.value)}
          >
            <MenuItem key={"open"} value={"open"}>
              Open Lead
            </MenuItem>
            <MenuItem key={"premium"} value={"premium"}>
              Premium Lead
            </MenuItem>
            {/* Add more industry options */}
          </Select>
          {errors.leadCategory && (
            <Typography
              variant="body2"
              sx={{
                color: "red",
                mt: 0.4,
                fontSize: "0.8rem",
                fontWeight: "300",
              }}
            >
              Required
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography
            variant="body2"
            sx={{ color: theme.palette.heading.main, mb: 1 }}
          >
            Organization name (optional)
          </Typography>
          <TextField
            fullWidth
            value={organisation}
            onChange={(e) => setOrganisation(e.target.value)}
          />
          {errors.organisation && (
            <Typography
              variant="body2"
              sx={{
                color: "red",
                mt: 0.4,
                fontSize: "0.8rem",
                fontWeight: "300",
              }}
            >
              Required
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            variant="body2"
            sx={{ color: theme.palette.heading.main, mb: 1 }}
          >
            Email Address
          </Typography>
          <TextField
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {errors.email && (
            <Typography
              variant="body2"
              sx={{
                color: "red",
                mt: 0.4,
                fontSize: "0.8rem",
                fontWeight: "300",
              }}
            >
              Required
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            variant="body2"
            sx={{ color: theme.palette.heading.main, mb: 1 }}
          >
            City
          </Typography>
          <TextField
            fullWidth
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
          {errors.city && (
            <Typography
              variant="body2"
              sx={{
                color: "red",
                mt: 0.4,
                fontSize: "0.8rem",
                fontWeight: "300",
              }}
            >
              Required
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            variant="body2"
            sx={{ color: theme.palette.heading.main, mb: 1 }}
          >
            Website (optional)
          </Typography>
          <TextField
            fullWidth
            value={websiteLink}
            onChange={(e) => setWebsiteLink(e.target.value)}
          />
          {errors.websiteLink && (
            <Typography
              variant="body2"
              sx={{
                color: "red",
                mt: 0.4,
                fontSize: "0.8rem",
                fontWeight: "300",
              }}
            >
              Required
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            variant="body2"
            sx={{ color: theme.palette.heading.main, mb: 1 }}
          >
            Country
          </Typography>
          <TextField
            fullWidth
            value={country}
            onChange={(e) => setCountry(e.target.value)}
          />
          {errors.country && (
            <Typography
              variant="body2"
              sx={{
                color: "red",
                mt: 0.4,
                fontSize: "0.8rem",
                fontWeight: "300",
              }}
            >
              Required
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            variant="body2"
            sx={{ color: theme.palette.heading.main, mb: 1 }}
          >
            Office Telephone Number(optional)
          </Typography>
          {/* <TextField
            fullWidth
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          /> */}
          <PhoneInput
            // // disabled={editOptions?.lead_serial_number ? true : false}
            // {...register("primary_phone_number", {
            //   required: "Primary phone number is required",
            // })}
            value={phone}
            onChange={(value) => setPhone(value)}
            country={"ke"}
            inputProps={
              {
                // name: "primary_phone_number",
                // required: "Primary phone number is required",
              }
            }
            fullWidth
            inputStyle={{
              border: "none",
              outline: "none",
              width: "98%",
              paddingLeft: "47px",
              fontWeight: "300",
              fontFamily: "inherit",
            }}
            searchStyle={{
              border: "1px solid black",
              fontWeight: "300",
              fontFamily: "inherit",
            }}
            containerStyle={{
              flex: "1",
              border: "1px solid lightgray",
              borderRadius: "4px",
              padding: "9px 2px",
            }}
            buttonStyle={{
              // height: "45px",
              borderRadius: "4px",

              padding: "0 5px",
              backgroundColor: "white",
              border: "none",
              outline: "none",
            }}
            dropdownStyle={{
              marginTop: "0",
              borderRadius: "4px",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              border: "1px solid #ced4da",
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography
            variant="body2"
            sx={{ color: theme.palette.heading.main, mb: 1 }}
          >
            Referral Title
          </Typography>
          <TextField
            fullWidth
            value={referralTitle}
            onChange={(e) => setReferralTitle(e.target.value)}
          />
          {errors.referralTitle && (
            <Typography
              variant="body2"
              sx={{
                color: "red",
                mt: 0.4,
                fontSize: "0.8rem",
                fontWeight: "300",
              }}
            >
              Required
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body2"
            sx={{ color: theme.palette.heading.main, mb: 1 }}
          >
            Attachment
          </Typography>
          <Input
            type="file"
            onChange={(e) => uploadAttachment(e.target.files)}
          />
          {/* <TextField
            fullWidth
            value={attachment}
            onChange={(e) => setAttachment(e.target.value)}
          /> */}
        </Grid>

        {showDetailsOptions && (
          <Grid item xs={12} sm={12}>
            <Typography variant="subtitle1">
              Select Option to insert details (this option is only for super
              admin)
            </Typography>
            <RadioGroup
              aria-label="input-option"
              name="input-option"
              value={selectedShowDetailOption}
              onChange={(e) => {
                setSlectedShowDetailOption(e.target.value);
                setShowDetailsOptions(false);
              }}
            >
              <FormControlLabel
                value="manual"
                control={<Radio />}
                label="Manual Input"
              />
              <FormControlLabel
                value="file"
                control={<Radio />}
                label="File Input"
              />
            </RadioGroup>
          </Grid>
        )}

        <Grid item xs={12}>
          <Typography
            variant="body2"
            sx={{ color: theme.palette.heading.main, mb: 1 }}
          >
            Referral Details
          </Typography>
          <TextField
            id="outlined-multiline-static"
            // label="Multiline"
            multiline
            rows={20}
            variant="outlined"
            fullWidth
            value={referralDetails}
            onChange={(e) => setReferralDetails(e.target.value)}
          />
          {errors.referralDetails && (
            <Typography
              variant="body2"
              sx={{
                color: "red",
                mt: 0.4,
                fontSize: "0.8rem",
                fontWeight: "300",
              }}
            >
              Required
            </Typography>
          )}
        </Grid>
      </Grid>
      {(!isSuperAdmin ||
        (editOptions?.id && !editOptions?.posted_by_admin)) && (
        <>
          {" "}
          <Typography
            sx={{
              mb: 2,
              mt: 3,
              fontSize: "0.8rem",
              color: theme.palette.para.main,
            }}
          >
            The Information below will not be visible to edge360 users. The
            information below will be used by edge360 to contact you about the
            referral above. If you donot wish to be contacted, please leave this
            page. Edge360 does not charge you a commission on finder’s fee.
          </Typography>
          <Grid container spacing={2}>
            {(!isSuperAdmin || selectedShowDetailOption == "manual") && (
              <>
                <Grid item xs={12} sm={12}>
                  <Typography
                    variant="body2"
                    sx={{ color: theme.palette.heading.main, mb: 1 }}
                  >
                    Do you know the value of business in the above referral?
                  </Typography>
                  <Select
                    MenuProps={{
                      style: { zIndex: 1000001 },
                    }}
                    fullWidth
                    value={knowValue}
                    onChange={(e) => setKnowValue(e.target.value)}
                  >
                    <MenuItem value="YES">YES</MenuItem>
                    <MenuItem value="NO">NO</MenuItem>
                  </Select>
                  {errors.knowValue && (
                    <Typography
                      variant="body2"
                      sx={{
                        color: "red",
                        mt: 0.4,
                        fontSize: "0.8rem",
                        fontWeight: "300",
                      }}
                    >
                      Required
                    </Typography>
                  )}
                </Grid>
                {knowValue === "YES" && (
                  <Grid item xs={12} sm={6}>
                    <Typography
                      variant="body2"
                      sx={{ color: theme.palette.heading.main, mb: 1 }}
                    >
                      Currency
                    </Typography>
                    <Select
                      MenuProps={{
                        style: { zIndex: 1000001 },
                      }}
                      fullWidth
                      value={valueCurrency}
                      onChange={(e) => setValueCurrency(e.target.value)}
                    >
                      <MenuItem value="USD">USD</MenuItem>
                      <MenuItem value="EUR">EUR</MenuItem>
                      <MenuItem value="KSh">KSh</MenuItem>
                      {/* Add more currency options */}
                    </Select>
                    {errors.valueCurrency && (
                      <Typography
                        variant="body2"
                        sx={{
                          color: "red",
                          mt: 0.4,
                          fontSize: "0.8rem",
                          fontWeight: "300",
                        }}
                      >
                        Required
                      </Typography>
                    )}
                  </Grid>
                )}
                {knowValue == "YES" && (
                  <Grid item xs={12} sm={6}>
                    <Typography
                      variant="body2"
                      sx={{ color: theme.palette.heading.main, mb: 1 }}
                    >
                      Estimated Value of Business
                    </Typography>
                    <TextField
                      type="number"
                      fullWidth
                      value={estimatedValue}
                      onChange={(e) => setEstimatedValue(e.target.value)}
                    />
                    {errors.estimatedValue && (
                      <Typography
                        variant="body2"
                        sx={{
                          color: "red",
                          mt: 0.4,
                          fontSize: "0.8rem",
                          fontWeight: "300",
                        }}
                      >
                        Required
                      </Typography>
                    )}
                  </Grid>
                )}
                <Grid item xs={12} sm={12}>
                  <Typography
                    variant="body2"
                    sx={{ color: theme.palette.heading.main, mb: 1 }}
                  >
                    Do you want to be paid a finders fee?
                  </Typography>
                  <Select
                    MenuProps={{
                      style: { zIndex: 1000001 },
                    }}
                    fullWidth
                    value={knowFinderFee}
                    onChange={(e) => setKnowFinderFee(e.target.value)}
                  >
                    <MenuItem value="YES">YES</MenuItem>
                    <MenuItem value="NO">NO</MenuItem>
                  </Select>
                  {errors.knowFinderFee && (
                    <Typography
                      variant="body2"
                      sx={{
                        color: "red",
                        mt: 0.4,
                        fontSize: "0.8rem",
                        fontWeight: "300",
                      }}
                    >
                      Required
                    </Typography>
                  )}
                </Grid>
                {knowFinderFee === "YES" && (
                  <Grid item xs={12} sm={6}>
                    <Typography
                      variant="body2"
                      sx={{ color: theme.palette.heading.main, mb: 1 }}
                    >
                      Currency
                    </Typography>
                    <Select
                      MenuProps={{
                        style: { zIndex: 1000001 },
                      }}
                      fullWidth
                      value={finderFeeCurrency}
                      onChange={(e) => setFinderFeeCurrency(e.target.value)}
                    >
                      <MenuItem value="USD">USD</MenuItem>
                      <MenuItem value="EUR">EUR</MenuItem>
                      <MenuItem value="KSh">KSh</MenuItem>
                      {/* Add more currency options */}
                    </Select>
                    {errors.finderFeeCurrency && (
                      <Typography
                        variant="body2"
                        sx={{
                          color: "red",
                          mt: 0.4,
                          fontSize: "0.8rem",
                          fontWeight: "300",
                        }}
                      >
                        Required
                      </Typography>
                    )}
                  </Grid>
                )}
                {knowFinderFee === "YES" && (
                  <Grid item xs={12} sm={6}>
                    <Typography
                      variant="body2"
                      sx={{ color: theme.palette.heading.main, mb: 1 }}
                    >
                      Finder's Fee
                    </Typography>
                    <TextField
                      type="number"
                      fullWidth
                      value={finderFee}
                      onChange={(e) => setFinderFee(e.target.value)}
                    />
                    {errors.finderFee && (
                      <Typography
                        variant="body2"
                        sx={{
                          color: "red",
                          mt: 0.4,
                          fontSize: "0.8rem",
                          fontWeight: "300",
                        }}
                      >
                        Required
                      </Typography>
                    )}
                  </Grid>
                )}

                {/* {knowValue === "NO" && (
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="body2"
                  sx={{ color: theme.palette.heading.main, mb: 1 }}
                >
                  Commission
                </Typography>
                <TextField disabled fullWidth value={commission} />
              </Grid>
            )} */}
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="body2"
                    sx={{ color: theme.palette.heading.main, mb: 1 }}
                  >
                    Your name
                  </Typography>
                  <TextField
                    fullWidth
                    value={yourName}
                    onChange={(e) => setYourName(e.target.value)}
                  />
                  {errors.yourName && (
                    <Typography
                      variant="body2"
                      sx={{
                        color: "red",
                        mt: 0.4,
                        fontSize: "0.8rem",
                        fontWeight: "300",
                      }}
                    >
                      Required
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="body2"
                    sx={{ color: theme.palette.heading.main, mb: 1 }}
                  >
                    Your phone number
                  </Typography>
                  {/* <TextField
                    value={yourPhoneNumber}
                    onChange={(e) => setYourPhoneNumber(e.target.value)}
                  /> */}
                  <PhoneInput
                    // disabled={editOptions?.lead_serial_number ? true : false}
                    // {...register("primary_phone_number", {
                    //   required: "Primary phone number is required",
                    // })}
                    value={yourPhoneNumber}
                    onChange={(value) => setYourPhoneNumber(value)}
                    country={"ke"}
                    inputProps={
                      {
                        // name: "primary_phone_number",
                        // required: "Primary phone number is required",
                      }
                    }
                    fullWidth
                    inputStyle={{
                      border: "none",
                      outline: "none",
                      width: "98%",
                      paddingLeft: "47px",
                      fontWeight: "300",
                      fontFamily: "inherit",
                    }}
                    searchStyle={{
                      border: "1px solid black",
                      fontWeight: "300",
                      fontFamily: "inherit",
                    }}
                    containerStyle={{
                      flex: "1",
                      border: "1px solid lightgray",
                      borderRadius: "4px",
                      padding: "9px 2px",
                    }}
                    buttonStyle={{
                      // height: "45px",
                      borderRadius: "4px",

                      padding: "0 5px",
                      backgroundColor: "white",
                      border: "none",
                      outline: "none",
                    }}
                    dropdownStyle={{
                      marginTop: "0",
                      borderRadius: "4px",
                      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                      border: "1px solid #ced4da",
                    }}
                  />
                  {errors.yourPhoneNumber && (
                    <Typography
                      variant="body2"
                      sx={{
                        color: "red",
                        mt: 0.4,
                        fontSize: "0.8rem",
                        fontWeight: "300",
                      }}
                    >
                      Required
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="body2"
                    sx={{ color: theme.palette.heading.main, mb: 1 }}
                  >
                    Your email address
                  </Typography>
                  <TextField
                    fullWidth
                    value={yourEmail}
                    onChange={(e) => setYourEmail(e.target.value)}
                  />
                  {errors.yourEmail && (
                    <Typography
                      variant="body2"
                      sx={{
                        color: "red",
                        mt: 0.4,
                        fontSize: "0.8rem",
                        fontWeight: "300",
                      }}
                    >
                      Required
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="body2"
                    sx={{ color: theme.palette.heading.main, mb: 1 }}
                  >
                    Note
                  </Typography>
                  <TextField
                    id="outlined-multiline-static"
                    // label="Multiline"
                    multiline
                    rows={3}
                    variant="outlined"
                    fullWidth
                    value={yourNote}
                    onChange={(e) => setYourNote(e.target.value)}
                  />
                  {errors.yourNote && (
                    <Typography
                      variant="body2"
                      sx={{
                        color: "red",
                        mt: 0.4,
                        fontSize: "0.8rem",
                        fontWeight: "300",
                      }}
                    >
                      {errors?.yourNote}
                    </Typography>
                  )}
                </Grid>
              </>
            )}
          </Grid>
        </>
      )}

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: 3,
        }}
      >
        <Button
          variant="outlined"
          color="primary"
          onClick={() => setShowLeadAddingForm(false)}
        >
          Cancel
        </Button>
        {editOptions?.lead_ref_number ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() => onSubmit()}
          >
            Update
          </Button>
        ) : (
          <Button
            onClick={() => onSubmit()}
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default AddLeadToNoticeBoardForm;
