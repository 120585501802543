import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
  IconButton,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { api } from "../../../../axios/api";
import { useSelector } from "react-redux";
import { useTheme } from "@emotion/react";

const TotalOpenDays = ({ selectedLead }) => {
  const [totalTime, setTotalTime] = useState(0);
  const [currentState, setCurrenState] = useState("Active");

  const theme = useTheme();

  useEffect(() => {
    selectedLead?.isDropped
      ? setCurrenState("Dropped")
      : selectedLead?.isWon
      ? setCurrenState("Won")
      : setCurrenState("Active");

    let startDate = new Date(selectedLead?.date);
    let totalDays = 0;
    let totalHours = 0;
    let totalMinutes = 0;
    let endDate;

    if (
      selectedLead?.end_date &&
      (selectedLead?.isDropped || selectedLead?.Won)
    ) {
      endDate = new Date(selectedLead?.end_date);
    } else {
      endDate = new Date(Date.now());
    }
    console.log("start date", startDate);
    console.log("end date", endDate);
    let timeDifference = endDate.getTime() - startDate.getTime(); // Difference in milliseconds
    totalDays = Math.floor(timeDifference / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
    totalHours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24); // Convert milliseconds to hours and calculate the remaining hours
    totalMinutes = Math.floor((timeDifference / (1000 * 60)) % 60); // Convert milliseconds to minutes and calculate the remaining minutes
    setTotalTime(
      totalDays + " days " + totalHours + " hours " + totalMinutes + " minutes"
    );
    console.log("total time", totalDays + " days " + totalHours + " hours");
  }, []);
  return (
    <Box sx={{ mt: 3, background: "#fff", p: 3 }}>
      <Box>
        <Typography variant="p">Total active number of time</Typography>
        <Typography variant="h6" sx={{ color: theme.palette.primary.main }}>
          {totalTime}
        </Typography>
      </Box>
      <Box>
        <Typography variant="p">Current state</Typography>
        <Typography variant="h6" sx={{ color: theme.palette.primary.main }}>
          {currentState}
        </Typography>
      </Box>
    </Box>
  );
};

export default TotalOpenDays;
