import React, { useState, useEffect } from "react";
import { Avatar } from "@mui/material";
import {
  Typography,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
  IconButton,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { api } from "../../axios/api";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material";
import SearchInput from "../CRM/SearchInput2.js";

const ConversationsBox = ({
  selectedLead,
  setSelectedPartnerForConversation,
  setIsGeneralChat,
  setShowConversation,
  setShowChat,
  isRelationship,
}) => {
  const theme = useTheme();
  const [happinessRating, setHappinessRating] = useState(0);
  const user = useSelector((state) => state.user);
  const [partners, setPartners] = useState([]);
  useEffect(() => {
    console.log("selected lead", selectedLead);
    if (!isRelationship) {
      api
        .get("/leadHappinessRating/" + selectedLead?.lead_serial_number)
        .then((res) => {
          // console.log("res?.data", res?.data);
          setHappinessRating(res?.data?.happiness_rating);
        })
        .catch((err) => console.log(err));
    }

    api
      .get("/organization/partners")
      .then((res) => {
        // console.log("res?.data");
        setPartners(res?.data);
      })
      .catch((err) => console.log(err));
  }, [selectedLead]);
  return (
    <Grid item xs={12} sm={3} sx={{ p: 0, pb: 3, background: "#fff" }}>
      <Box sx={{ py: 1, px: 1, borderBottom: "1px solid lightgray" }}>
        {isRelationship ? (
          <PartnerTab
            name={selectedLead?.contact_person}
            description="Lead to be discussed"
            notClick={true}
          />
        ) : (
          <PartnerTab
            name={selectedLead?.contact_person + ` (${happinessRating} star)`}
            description="Lead to be discussed"
            notClick={true}
          />
        )}
      </Box>
      <Box sx={{ p: 1 }}>
        <SearchInput />
      </Box>
      <Box
        sx={{ p: 1 }}
        // onClick={() => {
        //   setIsGeneralChat(true);
        //   setSelectedPartnerForConversation({});
        // }}
      >
        <PartnerTab
          name="# General Chat"
          isGeneral={true}
          isMargined={true}
          setIsGeneralChat={setIsGeneralChat}
          setSelectedPartnerForConversation={setSelectedPartnerForConversation}
          setShowConversation={setShowConversation}
          setShowChat={setShowChat}
        />
      </Box>
      <Box>
        <Typography
          variant="body2"
          sx={{
            mx: 2,
            my: 1,
            fontWeight: "600",
            color: theme.palette.heading.main,
          }}
        >
          Partners
        </Typography>
        <Box>
          {partners?.length < 1 ? (
            <Typography sx={{ mt: 2, p: 2 }}>N/A</Typography>
          ) : (
            <>
              {partners?.map((p, i) => (
                <Box
                // onClick={() => {
                //   setIsGeneralChat(false);
                //   setSelectedPartnerForConversation(p);
                // }}
                >
                  <PartnerTab
                    name={p?.name}
                    description="last message"
                    isMargined={true}
                    partner={p}
                    selectedLead={selectedLead}
                    setIsGeneralChat={setIsGeneralChat}
                    setSelectedPartnerForConversation={
                      setSelectedPartnerForConversation
                    }
                    index={i}
                    setShowConversation={setShowConversation}
                    setShowChat={setShowChat}
                    isRelationship={isRelationship}
                  />
                </Box>
              ))}
            </>
          )}{" "}
        </Box>
      </Box>
    </Grid>
  );
};

export default ConversationsBox;

const PartnerTab = ({
  name,
  description,
  isGeneral,
  isMargined,
  partner,
  selectedLead,
  setSelectedPartnerForConversation,
  setIsGeneralChat,
  index,
  setShowConversation,
  setShowChat,
  isRelationship,
  notClick,
}) => {
  const theme = useTheme();
  const [unreadCount, setUnreadCount] = useState(0);
  const fetchMessages = () => {
    let body;
    if (isGeneral) {
      if (isRelationship) {
        body = {
          relationship_id: selectedLead?.info_reference_number,
          is_general: true,
          isRelationship,
        };
      } else {
        body = {
          prospect_id: selectedLead?.lead_serial_number,
          is_general: true,
          isRelationship,
        };
      }
    } else {
      if (isRelationship) {
        body = {
          relationship_id: selectedLead?.info_reference_number,
          is_general: false,
          other_user_id: partner?.id,
          isRelationship,
        };
      } else {
        body = {
          prospect_id: selectedLead?.lead_serial_number,
          is_general: false,
          other_user_id: partner?.id,
          isRelationship,
        };
      }
    }

    api
      .post("/message/get", body)
      .then((res) => {
        // console.log("body", body);
        // console.log("res?.data messages", res?.data);
        let unreadCount = res?.data?.filter(
          (d) => d?.is_unread == true && d?.sender_id == partner?.id
        )?.length;
        setUnreadCount(unreadCount);
      })
      .catch((err) => console.log());
  };
  useEffect(() => {
    // Fetch messages immediately on component mount
    fetchMessages();

    // Fetch messages every 3 seconds (3000 milliseconds)
    // const interval = setInterval(() => {
    //   fetchMessages();
    // }, 3000);

    // Clean up the interval when the component is unmounted
    // return () => {
    //   clearInterval(interval);
    // };
  }, [selectedLead, isGeneral, partner]);
  // console.log("isgeneralchat", setIsGeneralChat);
  return (
    <Box
      key={index}
      onClick={() => {
        if (!notClick) {
          setIsGeneralChat(isGeneral ? true : false);
          setSelectedPartnerForConversation(isGeneral ? {} : partner);
          setUnreadCount(0);
          setShowConversation(false);
          setShowChat(true);
        }
      }}
      sx={{
        py: 1,
        px: 2,
        display: "flex",
        gap: "20px",
        alignItems: "center",
        borderBottom: isMargined ? "1px solid lightgray" : "none",
        cursor: "pointer",
        "&:hover": {
          background: theme.palette.selectedMenuBack.main,
        },
      }}
    >
      {!isGeneral && (
        <Avatar
          sx={{
            background: theme.palette.primary.main,
            height: "30px",
            width: "30px",
          }}
          size="small"
        >
          {name[0]?.toUpperCase()}
        </Avatar>
      )}

      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            gap: "20px",
          }}
        >
          <Typography
            variant="p"
            sx={{
              fontWeight: "600",
              textTransform: "capitalize",
              color: isGeneral
                ? theme.palette.primary.main
                : theme.palette.heading.main,
              fontSize: "0.9rem",
            }}
          >
            {name}
          </Typography>
          {unreadCount > 0 && (
            <Typography
              variant="body2"
              sx={{
                background: theme.palette.coloreight.main,
                borderRadius: "50%",
                width: "20px",
                height: "20px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#fff",
                fontSize: "0.8rem",
              }}
            >
              {unreadCount}
            </Typography>
          )}
        </Box>
        {description && (
          <Typography
            variant="body2"
            sx={{ color: theme.palette.para.main, fontSize: "0.8rem" }}
          >
            {description}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
