import { Box, IconButton, useTheme, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Bell, Mail, AlertOctagon } from "react-feather";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { api } from "../axios/api";
import { useSelector } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars-2";

const Notifications = () => {
  const theme = useTheme();
  const user = useSelector((state) => state?.user);
  useEffect(() => {
    console.log("user", user);
  }, [user]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setNotifications([]);
    setAdminNotifications([]);
  };
  const [notifications, setNotifications] = useState([]);
  const [adminNotifications, setAdminNotifications] = useState([]);

  //   const notifications = [
  //     { id: 1, message: "New message from John" },
  //     { id: 2, message: "You have 3 new notifications" },
  //     { id: 3, message: "Meeting reminder at 2 PM" },
  //   ];
  useEffect(() => {
    api
      .get("/message/unread")
      .then((res) => {
        console.log("unread messages>>>>>>body", res?.data);
        let unreadMessages = res?.data?.unreadMessages;
        let notif = [];
        unreadMessages?.forEach((m, i) => {
          if (m?.is_general) {
            if (m?.prospect_id) {
              notif.push({
                id: notifications?.length + i,
                message: `You have unread message in general chat of prospect#${m?.prospect_id}`,
              });
            }
            if (m?.relationship_id) {
              notif.push({
                id: notifications?.length + i,
                message: `You have unread message in general chat of relationship#${m?.relationship_id}`,
              });
            }
          } else {
            if (m?.prospect_id) {
              notif.push({
                id: notifications?.length + i,
                message: `You have unread message in chat of prospect#${m?.prospect_id}`,
              });
            }
            if (m?.relationship_id) {
              notif.push({
                id: notifications?.length + i,
                message: `You have unread message in chat of relationship#${m?.relationship_id}`,
              });
            }
          }
        });
        setNotifications(notif);
        // console.log("res?.data messages", res?.data);
        // let unreadCount = res?.data?.filter(
        //   (d) => d?.is_unread == true && d?.sender_id == partner?.id
        // )?.length;
        // setUnreadCount(unreadCount);
      })
      .catch((err) => console.log(err));
    //notifications for super admin
    if (user?.email_address == "admin@edge360.co") {
      console.log("is admin ");
      api
        .get("/noticeBoardLeads")
        .then((res) => {
          console.log("response from gettoing noticebborad leads", res?.data);
          let authorized_data = res?.data?.filter(
            (s) => !s?.authorized_by_admin
          );
          if (authorized_data?.length > 0)
            setAdminNotifications([
              {
                id: notifications?.length + 1,
                message: `${authorized_data?.length} requests are waiting for approval in notice boars leads request section`,
                type: "admin",
              },
            ]);
        })
        .catch((err) => console.log(err));
    }
  }, [user]);

  useEffect(() => {
    console.log("notifications", notifications);
  }, [notifications]);

  return (
    <Box sx={{ position: "relative" }}>
      <IconButton
        sx={{ mr: 1, position: "relative" }}
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <Bell size={22} color={theme.palette.heading.main} strokeWidth={2} />
        {[...adminNotifications, ...notifications]?.length > 0 && (
          <Box
            sx={{
              background: theme.palette.iconColor.main,
              width: "10px",
              height: "10px",
              position: "absolute",
              bottom: "10px",
              left: "20px",
              borderRadius: "50%",
            }}
          ></Box>
        )}
      </IconButton>

      <Menu
        id="demo-positioned-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            mt: "20px",
            width: "350px", // Set your desired width
            padding: "10px",
            height: "80vh",
            boxShadow:
              "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
          },
        }}
      >
        {[...adminNotifications, ...notifications]?.length < 1 && (
          <MenuItem>no notifications!</MenuItem>
        )}

        {[...adminNotifications, ...notifications].map((notification) => (
          <MenuItem
            sx={{ width: "100%", p: 1, background: "whitesmoke", mb: "2px" }}
            key={notification.id}
            onClick={handleClose}
          >
            <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
              {notification?.type == "admin" ? (
                <AlertOctagon
                  size={28}
                  color={theme.palette.iconColor.main}
                  strokeWidth={1.5}
                />
              ) : (
                <Mail
                  size={28}
                  color={theme.palette.iconColor.main}
                  strokeWidth={1.5}
                />
              )}

              <Typography
                variant="body2"
                sx={{
                  flexWrap: "wrap",
                  color: theme.palette.heading.main,
                  whiteSpace: "pre-wrap",
                }}
              >
                {notification.message}
              </Typography>
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default Notifications;
