import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

import { Box, Container } from "@mui/material";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  useMediaQuery,
  TextField,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import styled from "@mui/material/styles/styled";
import DiamondOutlinedIcon from "@mui/icons-material/DiamondOutlined";
import MeetingRoomOutlinedIcon from "@mui/icons-material/MeetingRoomOutlined";
import { Globe, Award, Eye } from "react-feather";
import LoginContainer from "../components/Login/LoginContainer";
import AdminLogin from "../components/Login/AdminLogin";

const Auth = ({ isAdminLogin }) => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box sx={{ background: theme.palette.colorthree.main, minHeight: "100vh" }}>
      {/* <Navbar /> */}
      {/* <Container> */}
      <Box sx={{ padding: "0 0px" }}>
        {isAdminLogin ? <AdminLogin /> : <LoginContainer />}

        {/* <Box sx={{ minHeight: "200px", margin: " 10px 0 70px 0" }}>
          <Typography
            variant="h4"
            sx={{
              fontWeight: "700",
              color: theme.palette.heading.main,
              textAlign: "center",
              marginBottom: "10px",
            }}
          >
            Opportunities
          </Typography>
        </Box>
      </Box> */}
      </Box>
      {/* </Container> */}
      {/* <Footer /> */}
    </Box>
  );
};

export default Auth;
