import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
  IconButton,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  useMediaQuery,
  TextField,
} from "@mui/material";
import { useSelector } from "react-redux";
import AddLeadToNoticeBoardForm from "./AddLeadToNoticeBoardForm";
import { api } from "../../../axios/api";
// import { useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import { Delete, Edit, Trash2 } from "react-feather";
import { ToastContainer, toast } from "react-toastify";

import styled from "@mui/material/styles/styled";
import DiamondOutlinedIcon from "@mui/icons-material/DiamondOutlined";
import MeetingRoomOutlinedIcon from "@mui/icons-material/MeetingRoomOutlined";
import { Globe, Award, Eye } from "react-feather";
import ViewNoticeBoardLeadDetails from "./ViewNoticeBoardLeadDetails";

const NoticeBoard = () => {
  const theme = useTheme();
  const [showLeadAddingForm, setShowLeadAddingForm] = useState(false);
  const [data, setData] = useState([]);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedLeadForDetails, setSelectedLeadForDetails] = useState({});
  const [showDetailsOfLead, setShowDetailsOfLead] = useState(false);
  const user = useSelector((state) => state.user);
  const userSubscriptionData = useSelector((state) => state.subscription_data);
  console.log("user userSubscriptionData", userSubscriptionData);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    console.log("user accountbytiep", userSubscriptionData);
    api
      .get("/noticeBoardLeads")
      .then((res) => {
        console.log("response from gettoing noticebborad leads", res?.data);
        let authorized_data = res?.data?.filter((s) => s?.authorized_by_admin);
        let f2 = authorized_data;
        if (userSubscriptionData?.account_type == "free") {
          console.log("is free");
          f2 = f2?.filter((f) => f?.lead_category == "open");
        }
        setData(f2);
      })
      .catch((err) => console.log(err));
  }, [showLeadAddingForm, user]);

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredData = data.filter((row) =>
    Object.values(row).some(
      (fieldValue) =>
        typeof fieldValue === "string" &&
        fieldValue.toLowerCase().includes(searchTerm.toLowerCase())
    )
  );
  const addLeadToPipeline = (leadData) => {
    console.log("leaded to be in pipeline", leadData);
    let obj = {
      organization_name: leadData?.organisation,
      contact_person: leadData?.referral_title,
      primary_phone_number: leadData?.phone,
      email: leadData?.email,
      industry: leadData?.referral_industry,
      service_needed: leadData?.referral_type,
      lead_source: "notice board",
      city: leadData?.city,
      country: leadData?.country,
      value_of_lead: leadData?.estimated_value,
    };
    api
      .post("/createProspect", { ...obj, owner_id: user?.id })
      .then((res) => {
        console.log("res?.data prospect", res?.data);
        toast?.success("successfully added!");
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 409) {
          toast.error(
            "Prospect already added to your organization's prospects!"
          );
        } else {
          toast.error("Unable to add. Please try again later.");
        }
      });
  };
  return (
    <Box sx={{ padding: "0px" }}>
      <ToastContainer />
      <ViewNoticeBoardLeadDetails
        leadData={selectedLeadForDetails}
        open={showDetailsOfLead}
        handleClose={() => setShowDetailsOfLead(false)}
      />
      <Box sx={{}}>
        <Typography
          variant="h6"
          sx={{
            color: theme.palette.heading.main,
            fontSize: "1.3rem",
            fontWeight: "600",
          }}
        >
          Billboard
        </Typography>
        <Box sx={{ padding: "0 0px" }}>
          <Box sx={{ minHeight: "200px", margin: " 10px 0 70px 0" }}>
            <Typography
              variant="body2"
              sx={{
                fontWeight: "500",
                color: theme.palette.para.main,
                marginBottom: "30px",
                textAlign: "left",
                fontSize: "1.1rem",
              }}
            >
              Explore and avail multiple opportunities including tender, merger,
              business sales etc.
            </Typography>{" "}
            {/* <Box
              sx={{
                display: "flex",
                justifyContent: { sm: "center", xs: "center" },
                margin: "0px 0 10px 0",
                height: "60px",
                padding: "0px",
              }}
            >
              <TextField
                variant="outlined"
                placeholder="Search opportunities by ref, organization or headline here..."
                // fullWidth
                // margin="normal"
                value={searchTerm}
                onChange={handleSearchTermChange}
                sx={{
                  marginBottom: "20px",
                  height: "60px",
                  width: { sm: "500px", xs: "280px" },
                  // borderRadius: "999px",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "999px",

                    padding: "0 5px",
                  },
                }}
              />
            </Box> */}
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                size="small"
                sx={{ ml: "auto", my: 2 }}
                variant="outlined"
                onClick={() =>
                  showLeadAddingForm
                    ? setShowLeadAddingForm(false)
                    : showDetailsOfLead
                    ? setShowDetailsOfLead(false)
                    : setShowLeadAddingForm(true)
                }
              >
                {showDetailsOfLead ? "Show Notice Board" : "Add to billboard"}
              </Button>
            </Box>
            {!showLeadAddingForm ? (
              //  !showDetailsOfLead
              <Grid container gap={4}>
                <Grid item xs={12} sm={12}>
                  <Box sx={{ height: "70vh", overflowY: "scroll" }}>
                    {/* <Typography
                variant="h4"
                sx={{
                  fontWeight: "500",
                  color: theme.palette.heading.main,
                  marginBottom: "10px",
                }}
              >
                Opportunities
              </Typography> */}

                    <TableContainer
                      component={Paper}
                      style={{
                        marginBottom: theme.spacing(3),
                        border: "none",
                        outline: "none",
                        boxShadow: "none",
                        overflowX: "initial",
                      }}
                    >
                      <Table
                        sx={{ border: "none", outline: "none" }}
                        stickyHeader
                      >
                        <TableHead>
                          <TableRow
                          // sx={{ backgroundColor: theme.palette.secondary.main }}
                          >
                            <TableCell
                              style={{
                                whiteSpace: "nowrap",
                                borderBottom: "none",
                                color: theme.palette.primary.main,
                                fontWeight: "600",
                                fontSize: "0.8rem",
                              }}
                            >
                              Access
                            </TableCell>
                            <TableCell
                              style={{
                                whiteSpace: "nowrap",
                                borderBottom: "none",
                                color: theme.palette.primary.main,
                                fontWeight: "600",
                                fontSize: "0.8rem",
                              }}
                            >
                              Ref
                            </TableCell>
                            <TableCell
                              style={{
                                whiteSpace: "nowrap",
                                borderBottom: "none",
                                color: theme.palette.primary.main,
                                fontWeight: "600",
                                fontSize: "0.8rem",
                              }}
                            >
                              Organisation
                            </TableCell>
                            <TableCell
                              style={{
                                whiteSpace: "nowrap",
                                borderBottom: "none",
                                color: theme.palette.primary.main,
                                fontWeight: "600",
                                fontSize: "0.8rem",
                              }}
                            >
                              Service line
                            </TableCell>
                            <TableCell
                              style={{
                                whiteSpace: "nowrap",
                                borderBottom: "none",
                                color: theme.palette.primary.main,
                                fontWeight: "600",
                                fontSize: "0.8rem",
                              }}
                            >
                              Opportunity In
                            </TableCell>
                            {/* <TableCell
                              style={{
                                whiteSpace: "nowrap",
                                borderBottom: "none",
                                color: theme.palette.primary.main,
                                fontWeight: "600",
                                fontSize: "0.8rem",
                              }}
                            >
                              Estimated Value
                            </TableCell> */}
                            <TableCell
                              style={{
                                whiteSpace: "nowrap",
                                borderBottom: "none",
                                color: theme.palette.primary.main,
                                fontWeight: "600",
                                fontSize: "0.8rem",
                              }}
                            >
                              Location
                            </TableCell>
                            {/* <TableCell
                          style={{
                             whiteSpace: 'nowrap',
                            borderBottom: "none",
                            color: theme.palette.primary.main,
                            fontWeight: "600",
                            fontSize: "0.8rem",
                          }}
                        >
                          Location
                        </TableCell> */}
                            {/* <TableCell
                              style={{
                                whiteSpace: "nowrap",
                                borderBottom: "none",
                                color: theme.palette.primary.main,
                                fontWeight: "600",
                                fontSize: "0.95rem",
                              }}
                            >
                              Details
                            </TableCell> */}
                            <TableCell
                              style={{
                                whiteSpace: "nowrap",
                                borderBottom: "none",
                                color: theme.palette.primary.main,
                                fontWeight: "600",
                                fontSize: "0.95rem",
                              }}
                            >
                              Actions
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        {/* <TableBody> */}
                        {data
                          ?.sort(
                            (a, b) => b.lead_ref_number - a.lead_ref_number
                          )
                          ?.map((row, index) => (
                            <TableRow
                              key={index}
                              style={{
                                backgroundColor:
                                  index % 2 === 0 &&
                                  theme.palette.colorthree.main,
                                cursor: "pointer",
                              }}
                            >
                              <TableCell
                                style={{
                                  borderBottom: "none",
                                  whiteSpace: "nowrap",
                                }}
                                onClick={() => {
                                  // alert("clck");
                                  setShowDetailsOfLead(true);
                                  setSelectedLeadForDetails(row);
                                }}
                              >
                                <Box sx={{}}>
                                  {row?.lead_category == "open" ? (
                                    <Globe
                                      size={24}
                                      strokeWidth={1}
                                      color="gray"
                                    />
                                  ) : (
                                    <Award
                                      size={24}
                                      strokeWidth={2}
                                      color="#ffc107"
                                    />
                                  )}
                                </Box>
                              </TableCell>
                              <TableCell
                                onClick={() => {
                                  // alert("clck");
                                  setShowDetailsOfLead(true);
                                  setSelectedLeadForDetails(row);
                                }}
                                style={{
                                  borderBottom: "none",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <Box sx={{}}>{row?.lead_ref_number}</Box>
                              </TableCell>
                              <TableCell
                                onClick={() => {
                                  // alert("clck");
                                  setShowDetailsOfLead(true);
                                  setSelectedLeadForDetails(row);
                                }}
                                style={{
                                  borderBottom: "none",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {row?.organisation}
                              </TableCell>
                              <TableCell
                                onClick={() => {
                                  // alert("clck");
                                  setShowDetailsOfLead(true);
                                  setSelectedLeadForDetails(row);
                                }}
                                style={{
                                  borderBottom: "none",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {row?.referral_industry}
                              </TableCell>
                              <TableCell
                                onClick={() => {
                                  // alert("clck");
                                  setShowDetailsOfLead(true);
                                  setSelectedLeadForDetails(row);
                                }}
                                style={{
                                  borderBottom: "none",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {row?.referral_type}
                              </TableCell>
                              {/* <TableCell
                              style={{
                                borderBottom: "none",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {row?.estimated_value}
                            </TableCell> */}
                              <TableCell
                                onClick={() => {
                                  // alert("clck");
                                  setShowDetailsOfLead(true);
                                  setSelectedLeadForDetails(row);
                                }}
                                style={{
                                  borderBottom: "none",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {row?.city},{row?.country}
                              </TableCell>

                              {/* <TableCell
                              style={{
                                borderBottom: "none",
                                whiteSpace: "nowrap",
                              }}
                            > */}
                              {/* {row
                              ?.details} */}
                              {/* <IconButton
                                onClick={() => {
                                  // alert("clck");
                                  setShowDetailsOfLead(true);
                                  setSelectedLeadForDetails(row);
                                }}
                              >
                                <Eye
                                  strokeWidth={1.5}
                                  size={20}
                                  color={theme.palette.primary.main}
                                  style={{ cursor: "pointer" }}
                                />
                              </IconButton> */}
                              {/* </TableCell> */}
                              <TableCell
                                style={{
                                  borderBottom: "none",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() => addLeadToPipeline(row)}
                                >
                                  Add to pipeline
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                        {/* </TableBody> */}
                      </Table>
                    </TableContainer>
                  </Box>
                </Grid>
              </Grid>
            ) : // showDetailsOfLead
            false ? (
              <ViewNoticeBoardLeadDetails leadData={selectedLeadForDetails} />
            ) : (
              showLeadAddingForm && (
                <AddLeadToNoticeBoardForm
                  setShowLeadAddingForm={setShowLeadAddingForm}
                />
              )
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default NoticeBoard;
