import React from "react";
import {
  Typography,
  Card,
  CardContent,
  CardHeader,
  Avatar,
  Grid,
  Chip,
  Box,
  useTheme,
  Button,
} from "@mui/material";

const UserDetailsCard = ({
  user,
  handleEditModalOpen,
  setAccountToUpgrade,
  handleCloseAccountDetails,
}) => {
  const {
    id,
    name,
    email_address,
    profile_type,
    verified,
    isBlocked,
    is_agent_suspended,
    account_type,
    trial_done,
    trial_started_on,
    offer_purchased,
    upgraded_on,
    last_payment_on,
    account_expired,
    created_on,
    organization_name,
    phone_number,
    profile_image,
    isSignedUpByGoogle,
    ontrial,
  } = user;

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString();
  };

  const theme = useTheme();

  return (
    <Card
      square={true}
      sx={{ background: theme.palette.selectedMenuBack.main }}
    >
      <CardHeader
        avatar={
          <Avatar
            alt={name}
            src={profile_image}
            sx={{ width: 60, height: 60 }}
          />
        }
        title={name}
        subheader={email_address}
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1">User Details</Typography>
            <Box>
              <Chip
                label={profile_type}
                color="primary"
                sx={{ borderRadius: 0, margin: "4px" }}
              />
              <Chip
                label={verified ? "Verified" : "Not Verified"}
                color={verified ? "success" : "error"}
                sx={{ borderRadius: 0, margin: "4px" }}
              />
              {ontrial && (
                <Chip
                  label={ontrial ? "On Trial" : ""}
                  color={ontrial ? "success" : "error"}
                  sx={{ borderRadius: 0, margin: "4px" }}
                />
              )}

              <Chip
                label={isBlocked ? "Blocked" : "Not Blocked"}
                color={isBlocked ? "error" : "success"}
                sx={{ borderRadius: 0, margin: "4px" }}
              />
              <Chip
                label={is_agent_suspended ? "Suspended" : "Not Suspended"}
                color={is_agent_suspended ? "error" : "success"}
                sx={{ borderRadius: 0, margin: "4px" }}
              />
              <Chip
                label={`Account Type: ${account_type}`}
                sx={{ borderRadius: 0, margin: "4px" }}
              />
              <Chip
                label={`Created On: ${formatDate(created_on)}`}
                sx={{ borderRadius: 0, margin: "4px" }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1">Subscription Details</Typography>
            <Box>
              <Chip
                label={`Trial Started: ${
                  trial_started_on
                    ? formatDate(trial_started_on)
                    : "Not Started"
                }`}
                sx={{ borderRadius: 0, margin: "4px" }}
              />
              <Chip
                label={`Trial Done: ${trial_done}`}
                sx={{ borderRadius: 0, margin: "4px" }}
              />
              <Chip
                label={`Offer Purchased: ${offer_purchased || "Not Purchased"}`}
                sx={{ borderRadius: 0, margin: "4px" }}
              />
              <Chip
                label={`Upgraded On: ${
                  upgraded_on ? formatDate(upgraded_on) : "Not Upgraded"
                }`}
                sx={{ borderRadius: 0, margin: "4px" }}
              />
              <Chip
                label={`Last Payment On: ${
                  last_payment_on || "No Payment Yet"
                }`}
                sx={{ borderRadius: 0, margin: "4px" }}
              />
              <Chip
                label={`Account Expired: ${account_expired}`}
                sx={{ borderRadius: 0, margin: "4px" }}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Other Details</Typography>
            <Box>
              <Chip
                label={`Organization: ${
                  organization_name || "No Organization"
                }`}
                sx={{ borderRadius: 0, margin: "4px" }}
              />
              <Chip
                label={`Phone Number: ${phone_number || "No Phone Number"}`}
                sx={{ borderRadius: 0, margin: "4px" }}
              />
              <Chip
                label={`Signed Up By Google: ${
                  isSignedUpByGoogle ? "Yes" : "No"
                }`}
                sx={{ borderRadius: 0, margin: "4px" }}
              />
            </Box>
          </Grid>
          {user?.ontrial && (
            <Grid item xs={12}>
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  handleEditModalOpen();
                  handleCloseAccountDetails();
                  setAccountToUpgrade(user);
                }}
              >
                Upgrade Account
              </Button>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default UserDetailsCard;
