import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";

const initialState = {
  user: null,
  token: null,
  subscription_data: {},
  dashboard_selected_tab: "",
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        user: action.payload,
      };
    case "SET_Token":
      return {
        ...state,
        token: action.payload,
      };
    case "SET_Subscription_Data":
      return {
        ...state,
        subscription_data: action.payload,
      };
    case "SET_Dashboard_Tab":
      return {
        ...state,
        dashboard_selected_tab: action.payload,
      };
    default:
      return state;
  }
}

const store = createStore(reducer, applyMiddleware(thunk));

export default store;
