import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
  IconButton,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

import ArrowUpwardIcon from "@mui/icons-material/ExpandLess";
import ArrowDownwardIcon from "@mui/icons-material/ExpandMore";
import { api } from "../../../axios/api";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import { Edit, Trash2 } from "react-feather";
import { ToastContainer, toast } from "react-toastify";
import AddProspectForm from "../Prospects/AddProspectForm";
import IndividualLeadFeatures from "./IndividualLeadFeatures";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";

const LeadsPipeline = () => {
  const theme = useTheme();
  const user = useSelector((state) => state.user);
  const [prospects, setProspects] = useState([]);
  const userSubscriptionData = useSelector((state) => state?.subscription_data);
  console.log("user subscription data", userSubscriptionData);

  const [organizationInformation, setOrganizationInformation] = useState({});
  const [showAddForm, setShowAddForm] = useState(false);
  const [editOptions, setEditOptions] = useState({});
  const [showIndividualLeadFeature, setShowIndividualLeadFeature] =
    useState(false);
  const [selectedLead, setSelectedLead] = useState({});

  useEffect(() => {
    console.log("running useeffect");
    api
      .get("/organization")
      .then((res) => {
        console.log("organization information", res?.data);
        setOrganizationInformation(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    console.log("user at show contact", user);
  }, [user]);
  useEffect(() => {
    api
      .get("/prospects")
      .then((res) => {
        console.log("response from getting prospects", res?.data);
        setProspects(res?.data);
      })
      .catch((err) => console.log("err", err));
    if (!showAddForm) {
      setEditOptions({});
    }
  }, [user, showAddForm, showIndividualLeadFeature, selectedLead]);

  /****************************** */
  const [sorting, setSorting] = useState({
    field: "lead_serial_number", // default sorting field
    order: "asc", // default sorting order
  });

  const handleSort = (field) => {
    const isAsc = sorting.field === field && sorting.order === "asc";
    setSorting({ field, order: isAsc ? "desc" : "asc" });
  };

  const sortedData = prospects.sort((a, b) => {
    const order = sorting.order === "asc" ? 1 : -1;
    if (a[sorting.field] < b[sorting.field]) {
      return -1 * order;
    }
    if (a[sorting.field] > b[sorting.field]) {
      return 1 * order;
    }
    return 0;
  });

  return (
    <Box sx={{ padding: "0" }}>
      <ToastContainer />
      {userSubscriptionData?.account_type == "free" ? (
        <Box>
          <Typography variant="h6" sx={{ color: theme.palette.primary.main }}>
            Upgrade your account to access extra features and capabilities
          </Typography>
        </Box>
      ) : (
        <>
          {showIndividualLeadFeature ? (
            <>
              <IndividualLeadFeatures
                selectedLeadId={selectedLead?.lead_serial_number}
                setShowIndividualLeadFeature={setShowIndividualLeadFeature}
                showIndividualLeadFeature={showIndividualLeadFeature}
              />
            </>
          ) : (
            <>
              {" "}
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  variant="h6"
                  sx={{
                    color: theme.palette.heading.main,
                    fontSize: "1.3rem",
                    fontWeight: "600",
                  }}
                >
                  {"Leads Pipeline"}
                </Typography>
                {organizationInformation?.id && (
                  <Button
                    variant="contained"
                    onClick={() => setShowAddForm(!showAddForm)}
                    sx={{
                      backgroundColor: theme.palette.colortwo.main,
                      color: "#fff",
                    }}
                    size="small"
                  >
                    {showAddForm ? "View leads pipeline" : "Create new"}
                  </Button>
                )}
              </Box>
              {!organizationInformation?.id ? (
                <Box>
                  <Typography sx={{ mt: 3 }}>
                    {" "}
                    Update your organization details
                  </Typography>
                </Box>
              ) : showAddForm ? (
                <AddProspectForm
                  editOptions={editOptions}
                  setShowAddForm={setShowAddForm}
                  organizationInformation={organizationInformation}
                  prospects={prospects}
                />
              ) : prospects?.length < 1 ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mt: 2,
                    gap: "10px",
                  }}
                >
                  <InfoIcon />
                  <Typography sx={{}}> No lead is added yet.</Typography>
                </Box>
              ) : (
                <>
                  <Box sx={{ marginTop: "40px" }}>
                    <Grid container spacing={2} justifyContent="center">
                      <Grid item xs={12}>
                        <TableContainer
                          component={Paper}
                          style={{ maxHeight: "70vh", overflowY: "scroll" }}
                        >
                          <Table>
                            <TableHead
                              sx={{
                                position: "sticky",
                                top: 0,
                                backgroundColor: "white",
                                zIndex: "2",
                              }}
                            >
                              <TableRow>
                                <TableCell
                                  sx={{
                                    width: "80px",
                                    // color: theme.palette.colortwo.main,
                                    fontSize: "0.9rem",
                                    whiteSpace: "nowrap",
                                    position: "sticky",
                                    left: 0,
                                    backgroundColor: "white",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      gap: "4px",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        gap: "-2px",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                      }}
                                    >
                                      {" "}
                                      <IconButton
                                        size="small"
                                        onClick={() =>
                                          setSorting({
                                            field: "lead_serial_number",
                                            order: "asc",
                                          })
                                        }
                                      >
                                        <ArrowUpwardIcon
                                          size="small"
                                          sx={{ fontSize: "0.85rem" }}
                                        />
                                      </IconButton>
                                      <IconButton
                                        size="small"
                                        onClick={() =>
                                          setSorting({
                                            field: "lead_serial_number",
                                            order: "desc",
                                          })
                                        }
                                      >
                                        <ArrowDownwardIcon
                                          size="small"
                                          sx={{ fontSize: "0.85rem" }}
                                        />
                                      </IconButton>
                                    </Box>
                                    Lead Serial
                                  </Box>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    // color: theme.palette.colortwo.main,
                                    fontSize: "0.9rem",
                                    whiteSpace: "nowrap",
                                    position: "sticky",
                                    left: 0,
                                    backgroundColor: "white",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      gap: "4px",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        gap: "-2px",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                      }}
                                    >
                                      {" "}
                                      <IconButton
                                        size="small"
                                        onClick={() =>
                                          setSorting({
                                            field: "date",
                                            order: "asc",
                                          })
                                        }
                                      >
                                        <ArrowUpwardIcon
                                          size="small"
                                          sx={{ fontSize: "0.85rem" }}
                                        />
                                      </IconButton>
                                      <IconButton
                                        size="small"
                                        onClick={() =>
                                          setSorting({
                                            field: "date",
                                            order: "desc",
                                          })
                                        }
                                      >
                                        <ArrowDownwardIcon
                                          size="small"
                                          sx={{ fontSize: "0.85rem" }}
                                        />
                                      </IconButton>
                                    </Box>
                                    Date Created
                                  </Box>
                                </TableCell>
                                {/* <TableCell>Customer ID</TableCell> */}
                                <TableCell
                                  sx={{
                                    // color: theme.palette.colortwo.main,
                                    fontSize: "0.9rem",
                                    whiteSpace: "nowrap",
                                    position: "sticky",
                                    left: 0,
                                    backgroundColor: "white",
                                    zIndex: 10,
                                  }}
                                >
                                  Organization Name
                                </TableCell>
                                <TableCell
                                  sx={{
                                    // color: theme.palette.colortwo.main,
                                    fontSize: "0.9rem",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  Service
                                </TableCell>

                                <TableCell
                                  sx={{
                                    // color: theme.palette.colortwo.main,
                                    fontSize: "0.9rem",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      gap: "4px",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        gap: "-2px",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                      }}
                                    >
                                      {" "}
                                      <IconButton
                                        size="small"
                                        onClick={() =>
                                          setSorting({
                                            field: "value_of_lead",
                                            order: "asc",
                                          })
                                        }
                                      >
                                        <ArrowUpwardIcon
                                          size="small"
                                          sx={{ fontSize: "0.85rem" }}
                                        />
                                      </IconButton>
                                      <IconButton
                                        size="small"
                                        onClick={() =>
                                          setSorting({
                                            field: "value_of_lead",
                                            order: "desc",
                                          })
                                        }
                                      >
                                        <ArrowDownwardIcon
                                          size="small"
                                          sx={{ fontSize: "0.85rem" }}
                                        />
                                      </IconButton>
                                    </Box>
                                    Value of lead (
                                    {
                                      organizationInformation?.organization_currency
                                    }
                                    )
                                  </Box>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    // color: theme.palette.colortwo.main,
                                    fontSize: "0.9rem",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      gap: "4px",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        gap: "-2px",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                      }}
                                    >
                                      {" "}
                                      <IconButton
                                        size="small"
                                        onClick={() =>
                                          setSorting({
                                            field: "points",
                                            order: "asc",
                                          })
                                        }
                                      >
                                        <ArrowUpwardIcon
                                          size="small"
                                          sx={{ fontSize: "0.85rem" }}
                                        />
                                      </IconButton>
                                      <IconButton
                                        size="small"
                                        onClick={() =>
                                          setSorting({
                                            field: "points",
                                            order: "desc",
                                          })
                                        }
                                      >
                                        <ArrowDownwardIcon
                                          size="small"
                                          sx={{ fontSize: "0.85rem" }}
                                        />
                                      </IconButton>
                                    </Box>
                                    Points
                                  </Box>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    // color: theme.palette.colortwo.main,
                                    fontSize: "0.9rem",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  Days since last action
                                </TableCell>
                                <TableCell
                                  sx={{
                                    // color: theme.palette.colortwo.main,
                                    fontSize: "0.9rem",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  Industry
                                </TableCell>
                                <TableCell
                                  sx={{
                                    // color: theme.palette.colortwo.main,
                                    fontSize: "0.9rem",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  Source
                                </TableCell>
                                <TableCell
                                  sx={{
                                    // color: theme.palette.colortwo.main,
                                    fontSize: "0.9rem",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  Contact person
                                </TableCell>
                                <TableCell
                                  sx={{
                                    // color: theme.palette.colortwo.main,
                                    fontSize: "0.9rem",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  Phone number
                                </TableCell>
                                <TableCell
                                  sx={{
                                    // color: theme.palette.colortwo.main,
                                    fontSize: "0.9rem",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  Email
                                </TableCell>

                                <TableCell
                                  sx={{
                                    // color: theme.palette.colortwo.main,
                                    fontSize: "0.9rem",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  Owner
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {sortedData?.map((contact, i) => (
                                <NormalProspect
                                  contactSrNo={contact?.lead_serial_number}
                                  i={i}
                                  prospects={prospects}
                                  setProspects={setProspects}
                                  setShowAddForm={setShowAddForm}
                                  setEditOptions={setEditOptions}
                                  setShowIndividualLeadFeature={
                                    setShowIndividualLeadFeature
                                  }
                                  setSelectedLead={setSelectedLead}
                                  showIndividualLeadFeature={
                                    showIndividualLeadFeature
                                  }
                                  selectedLead={selectedLead}
                                />
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                  </Box>
                </>
              )}
            </>
          )}
        </>
      )}
    </Box>
  );
};

const NormalProspect = ({
  // contact,
  contactSrNo,
  i,
  prospects,
  setProspects,
  setEditOptions,
  setShowAddForm,
  setShowIndividualLeadFeature,
  setSelectedLead,
  showIndividualLeadFeature,
  selectedLead,
}) => {
  const theme = useTheme();
  const user = useSelector((state) => state.user);
  const [points, setPoints] = useState(0);
  const [lastActionDays, setLastActionDays] = useState(0);
  const [contact, setContact] = useState({});

  useEffect(() => {
    console.log("contact sr no", contactSrNo);
    if (contactSrNo) {
      api.get("/prospects/bySerialNumber/" + contactSrNo).then((res) => {
        console.log("serial contact", res?.data);
        setContact(res?.data);
      });
    }
  }, [contactSrNo]);
  const [owner, setOwner] = useState({});
  useEffect(() => {
    if (contact?.owner_id) {
      api
        .get("/getUserById/" + contact?.owner_id)
        .then((res) => {
          console.log("getting owner", res?.data);
          setOwner(res?.data);
        })
        .catch((err) => console.log("err", err));
    }
  }, [contact]);

  useEffect(() => {
    if (contact?.lead_serial_number) {
      let startDate = new Date(contact?.date);
      let totalDays = 0;
      let totalHours = 0;
      let endDate;

      if (contact?.end_date && (contact?.isDropped || contact?.Won)) {
        endDate = new Date(contact?.end_date);
      } else {
        endDate = new Date(Date.now());
      }
      let timeDifference = endDate.getTime() - startDate.getTime(); // Difference in milliseconds
      totalDays = Math.floor(timeDifference / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
      totalHours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24); // Convert milliseconds to hours and calculate the remaining hours
      setLastActionDays(totalDays + " days " + totalHours + " hours ");

      api
        .get("/leadsNotes/" + contact?.lead_serial_number)
        .then((res) => {
          let notes = res?.data;
          if (notes?.length > 0) {
            let latestUploadDate = null;

            if (notes.length > 0) {
              latestUploadDate = notes.reduce((maxDate, note) => {
                const noteDate = new Date(note.date_of_upload);
                return noteDate > maxDate ? noteDate : maxDate;
              }, new Date(notes[0].date_of_upload));
            }

            let timeDifference2 =
              new Date().getTime() - latestUploadDate.getTime(); // Difference in milliseconds
            totalDays = Math.floor(timeDifference2 / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
            totalHours = Math.floor((timeDifference2 / (1000 * 60 * 60)) % 24); // Convert milliseconds to hours and calculate the remaining hours
            setLastActionDays(totalDays + " days " + totalHours + " hours ");
          }
        })
        .catch((err) => console.log(err));

      api
        .get("/leadHappinessRating/" + contact?.lead_serial_number)
        .then((res) => {
          console.log("res?.data", res?.data);
          let hr = res?.data?.happiness_rating;
          let p;
          if (hr < 1 || hr > 5) {
            setPoints(contact?.milestone_level);
            p = contact?.milestone_level;
          } else {
            setPoints(hr * contact?.milestone_level);
            p = hr * contact?.milestone_level;
          }
          console.log("points", p);
          api
            .put("/updatePoints/" + contact?.lead_serial_number, { points: p })
            .then((res) => console.log(res?.data))
            .catch((err) => console.log(err));
        })
        .catch((err) => {
          console.log(err);
          setPoints(contact?.milestone_level);
          console.log("points", contact?.lead_serial_number);
          api
            .put("/updatePoints/" + contact?.lead_serial_number, {
              points: contact?.milestone_level,
            })
            .then((res) => console.log(res?.data))
            .catch((err) => console.log(err));
        });
    }
  }, [showIndividualLeadFeature, selectedLead, contact]);

  useEffect(() => {
    console.log("point at table row", points);
  }, [points]);

  return (
    <TableRow
      key={contact?.lead_serial_number}
      sx={{
        backgroundColor:
          i % 2 == 0 ? theme.palette.colorthree.main : "transparent",
        cursor: "pointer",
        "&:hover": {
          boxShadow:
            "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
          boxShadow:
            "rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px",
        },
      }}
      onClick={() => {
        setSelectedLead(contact);
        setShowIndividualLeadFeature(true);
      }}
    >
      <TableCell
        sx={{
          padding: "4px 20px",
          whiteSpace: "nowrap",
          width: "50px",
          position: "sticky",
          left: 0,
          backgroundColor: "white",
        }}
      >
        {contact?.organizational_identifier}
      </TableCell>
      <TableCell
        sx={{
          padding: "4px 20px",
          whiteSpace: "nowrap",
          position: "sticky",
          left: 0,
          backgroundColor: "white",
        }}
      >
        {new Date(contact?.date)?.toDateString()}
      </TableCell>
      {/* <TableCell sx={{whiteSpace:'nowrap'}}>{contact?.customer_id}</TableCell> */}
      <TableCell
        sx={{
          whiteSpace: "nowrap",
          padding: "4px 20px",
          position: "sticky",
          left: 0,
          backgroundColor: "white",
        }}
      >
        {contact?.organization_name}
      </TableCell>
      <TableCell sx={{ whiteSpace: "nowrap", padding: "4px 20px" }}>
        {contact?.service_needed}
      </TableCell>

      <TableCell sx={{ whiteSpace: "nowrap", padding: "4px 20px" }}>
        {contact?.value_of_lead}
      </TableCell>
      <TableCell
        sx={{
          whiteSpace: "nowrap",
          padding: "4px 20px",
          display: "flex",
          height: "100%",
          height: "55px",
          alignItems: "center",
          gap: "12px",
        }}
      >
        <Typography>{points}</Typography>
        {!contact?.is_won && !contact?.is_dropped && (
          <Box
            sx={{
              width: "10px",
              height: "10px",
              background:
                points <= 8
                  ? "red"
                  : points > 8 && points < 16
                  ? "yellow"
                  : "green",
              borderRadius: "50%",
            }}
          ></Box>
        )}

        {contact?.is_won && <EmojiEventsIcon />}
        {contact?.is_dropped && <ThumbDownIcon />}
      </TableCell>
      <TableCell sx={{ whiteSpace: "nowrap" }}>{lastActionDays}</TableCell>
      <TableCell sx={{ whiteSpace: "nowrap", padding: "4px 20px" }}>
        {contact?.industry}
      </TableCell>
      <TableCell sx={{ whiteSpace: "nowrap", padding: "4px 20px" }}>
        {contact?.lead_source}
      </TableCell>
      <TableCell sx={{ whiteSpace: "nowrap", padding: "4px 20px" }}>
        {contact?.contact_person}
      </TableCell>
      <TableCell sx={{ whiteSpace: "nowrap", padding: "4px 20px" }}>
        {contact?.primary_phone_number}
      </TableCell>
      <TableCell sx={{ whiteSpace: "nowrap", padding: "4px 20px" }}>
        <a href={`mailto:${contact?.email}`} style={{ textDecoration: "none" }}>
          <Typography
            sx={{
              color: "#000c",
            }}
          >
            {contact?.email}
          </Typography>
        </a>
      </TableCell>

      <TableCell sx={{ whiteSpace: "nowrap" }}>{owner?.name}</TableCell>
    </TableRow>
  );
};

export default LeadsPipeline;
