import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Box,
  FormControl,
  Select,
  MenuItem,
  Input,
  CircularProgress,
} from "@mui/material";
import { useTheme } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import * as filestack from "filestack-js";
import { api } from "../../../axios/api";

const priorities = [
  "High priority – The system is experiencing critical malfunction.",
  "Medium priority – The system is experiencing decreased quality of service",
  "Low priority – General enquiries and suggestions for improvements",
];

const ContactSupport = () => {
  const user = useSelector((state) => state.user);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const theme = useTheme();

  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const [priority, setPriority] = useState("");
  const [attachment, setAttachment] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const uploadFileToBackend = async (file, isJSON) => {
    const formData = new FormData();
    isJSON
      ? formData.append(
          "files",
          new Blob([file], { type: "application/json" }),
          "data.json"
        )
      : formData.append("files", file);

    try {
      const response = await api.post("/files/upload/space", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("url", response?.data?.url);
      return response.data.url; // Assuming the response contains the S3 URL
    } catch (error) {
      // console.error(error);
      // setIsLoading(false);
      // toast.error("unable to cntinue at the moment!");
      return null;
    }
  };

  const uploadAttachment = async (files) => {
    setIsLoading(true);
    // const client = filestack.init(process.env.REACT_APP_FILESTACK_API);

    try {
      // const response = await client.upload(files[0]);
      // console.log("response from getting url", response?.url);
      // response?.url && setIsLoading(false);
      // setAttachment(response?.url);
      const url = await uploadFileToBackend(files[0]);
      setIsLoading(false);
      setAttachment(url);
    } catch (error) {
      console.log("file upload error:", error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    console.log("attachment url", attachment);
    if (attachment) {
      setIsLoading(false);
    }
  }, [attachment]);

  const onSubmit = () => {
    const requiredFields = [subject, description, priority];
    const isAllFieldsFilled = requiredFields.every((field) => field !== "");

    if (isAllFieldsFilled) {
      const ticketData = {
        subject,
        description,
        priority,
        attachment,
        // Add other ticket fields as needed
      };

      console.log("data to be submitted", ticketData);
      api
        .post("/tickets/add", ticketData)
        .then((res) => {
          console.log("res?.data from tickets", res?.data);
          toast.success("IT Ticket raised successfully!");
          // Clear form fields after successful submission
          setSubject("");
          setDescription("");
          setPriority("");
          setAttachment("");
        })
        .catch((err) => console.log(err));

      // Add your API request logic here to submit the IT ticket
    } else {
      toast.error("Please fill all the required fields.");
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        background: theme.palette.background.main,
        marginTop: "20px",
        padding: "20px",
      }}
    >
      <ToastContainer />
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          variant="h6"
          sx={{ color: theme.palette.heading.main, mt: 1, mb: 3 }}
        >
          Raise an IT Ticket
        </Typography>
        {/* Add date if needed */}
      </Box>

      <Grid container spacing={2} sx={{ maxWidth: "400px" }}>
        <Grid item xs={12}>
          <Typography
            variant="body2"
            sx={{ color: theme.palette.heading.main, mb: 1 }}
          >
            Subject
          </Typography>
          <TextField
            fullWidth
            {...register("subject", { required: true })}
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
          {errors.subject && (
            <Typography
              variant="body2"
              sx={{
                color: "red",
                mt: 0.4,
                fontSize: "0.8rem",
                fontWeight: "300",
              }}
            >
              Required
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body2"
            sx={{ color: theme.palette.heading.main, mb: 1 }}
          >
            Description
          </Typography>
          <TextField
            id="outlined-multiline-static"
            multiline
            rows={4}
            variant="outlined"
            fullWidth
            {...register("description", { required: true })}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          {errors.description && (
            <Typography
              variant="body2"
              sx={{
                color: "red",
                mt: 0.4,
                fontSize: "0.8rem",
                fontWeight: "300",
              }}
            >
              Required
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body2"
            sx={{ color: theme.palette.heading.main, mb: 1 }}
          >
            Priority
          </Typography>
          <Select
            MenuProps={{
              style: { zIndex: 1000001 },
            }}
            fullWidth
            {...register("priority", { required: true })}
            value={priority}
            onChange={(e) => setPriority(e.target.value)}
          >
            <MenuItem value="">Select priority</MenuItem>
            {priorities.map((p, index) => (
              <MenuItem key={index} value={p}>
                {p}
              </MenuItem>
            ))}
          </Select>
          {errors.priority && (
            <Typography
              variant="body2"
              sx={{
                color: "red",
                mt: 0.4,
                fontSize: "0.8rem",
                fontWeight: "300",
              }}
            >
              Required
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            variant="body2"
            sx={{ color: theme.palette.heading.main, mb: 1 }}
          >
            Attachment
          </Typography>
          <Input
            type="file"
            onChange={(e) => uploadAttachment(e.target.files)}
          />
          {isLoading && <CircularProgress />}
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: 3,
        }}
      >
        <Button type="submit" variant="contained" color="primary">
          Submit IT Ticket
        </Button>
      </Box>
    </form>
  );
};

export default ContactSupport;
