import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  useMediaQuery,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import logo from "../../utils/images/edge.jpg";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import AuthInput from "../InputFields/AuthInput";
import { User, Mail, Briefcase, Lock } from "react-feather";
import GoogleIcon from "../../utils/images/googlIcon.png";
import AES from "crypto-js/aes";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { api, setAuthToken } from "../../axios/api";
import { useDispatch } from "react-redux";
import { setUser, setToken } from "../../actions/userActions";

import PhoneInput from "react-phone-number-input";
import AuthPhoneInput from "../InputFields/AuthPhoneInput";
import { useParams } from "react-router-dom";
import GoogleLogin from "./GoogleLogin";

const LoginContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [mode, setMode] = useState(
    params?.mode == "login" ? "login" : "signup"
  );
  useEffect(() => params?.mode && setMode(params?.mode), [params]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [repeatPasswordError, setRepeatPasswordError] = useState("");
  const [error, setError] = useState("");
  const [showNotVerifiedStatus, setShowNotVerifiedStatus] = useState(false);
  const [isVerificationEmailSent, setIsVerificationEmailSent] = useState(false);
  const [isRememberMe, setIsRememberMe] = useState(false);

  const [isAgreeToTermsAndConditions, setIsAgreeToTermAndConditions] =
    useState(false);

  const handleNameChange = (event) => {
    setName(event.target.value);
    setNameError("");
  };
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setEmailError("");
  };
  // const handlePhoneNumberChange = (event) => {
  //   console.log("event", event);
  //   setPhoneNumber(event.target.value);
  //   setPhoneNumberError("");
  // };
  useEffect(() => setPhoneNumberError(""), [phoneNumber]);

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setPasswordError("");
  };

  const handleRepeatPasswordChange = (event) => {
    setRepeatPassword(event.target.value);
    setRepeatPasswordError("");
  };

  const clearForms = () => {
    setEmail("");
    setName("");
    setPassword("");
    setRepeatPassword("");
    setPhoneNumber("");
  };
  useEffect(() => clearForms(), [mode]);

  const validateName = (name) => {
    if (!name) {
      setNameError("Name is required");
      return false;
    }
    setNameError("");
    return true;
  };

  const validateEmail = (email) => {
    if (!email) {
      setEmailError("Email is required");
      return false;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email.match(emailRegex)) {
      setEmailError("Invalid email");
      return false;
    }
    setEmailError("");
    return true;
  };
  const validatePhoneNumber = (phoneNumber) => {
    const phoneRegex = /^\+?[0-9]{8,}$/; // matches phone numbers with at least 8 digits

    if (!phoneNumber) {
      setPhoneNumberError("Phone Number is required");
      return false;
    }
    if (!phoneRegex.test(phoneNumber)) {
      setPhoneNumberError("Enter valid phone number");
      return false;
    }
    setPhoneNumberError("");
    return true;
  };
  const validatePassword = (password) => {
    if (!password) {
      setPasswordError("Password is required");
      return false;
    }
    if (password.length < 6) {
      setPasswordError("Password must be at least 6 characters long");
      return false;
    }
    setPasswordError("");
    return true;
  };
  const validateRepeatPassword = (password) => {
    if (!password) {
      setRepeatPasswordError("Repeat Password is required");
      return false;
    }
    if (password.length < 6) {
      setRepeatPasswordError("Password must be at least 6 characters long");
      return false;
    }
    if (password !== repeatPassword) {
      setRepeatPasswordError("Password does not match");
      return false;
    }
    setRepeatPasswordError("");
    return true;
  };
  const sendAccountVerificationEmail = () => {
    api
      .post("/sendAccountVerificationEmail")
      .then((res) => {
        console.log("res?.data from sendAccountVerificationEmail", res?.data);
        // toast.success("email sent successfully!");
        setIsVerificationEmailSent(true);
        setTimeout(() => {
          setShowNotVerifiedStatus(false);
          setIsVerificationEmailSent(false);
        }, 4000);
        clearForms();
      })
      .catch((err) => {
        console.log(err);
        toast.error("unable to send email");
      });
  };

  const handleSubmit = () => {
    console.log({
      mode,
      email,
      password,
      name,
      // phoneNumber,
    });
    console.log("password", password);
    console.log(
      " process.env.REACT_APP_CRYPTO_JS_KEY",
      process.env.REACT_APP_CRYPTO_JS_KEY
    );

    var encPass = AES.encrypt(
      password,
      process.env.REACT_APP_CRYPTO_JS_KEY
    ).toString();
    console.log("encPass", encPass);

    if (mode == "signup") {
      let obj = {
        name: name,
        email_address: email,
        password: encPass,
        // phone_number: phoneNumber,
      };
      api
        .post("/register", obj)
        .then((res) => {
          console.log("resposne from registering user", res.data);
          toast.success("Verification email has been sent to your email!");
          clearForms();
        })
        .catch((err) => {
          console.log("error in registering user", err);

          if (err?.response?.status == 409) {
            toast.error("Account already found!");
          } else {
            toast.error("registration failed! Try agin later");
          }
        });
    } else if (mode == "login") {
      let obj = {
        email_address: email,
        password: encPass,
      };
      api
        .post("/signin", obj)
        .then((res) => {
          const { verified, token, data } = res?.data;
          if (data?.profile_type == "agent") {
            if (data?.is_agent_suspended) {
              toast.error("Your Account is suspended!");
              return;
            }
          }

          setAuthToken(token);
          if (verified) {
            dispatch(setToken(token));
            // dispatch(setToken(token));
            // setAuthToken(token);
            toast.success("logged in successfully!");
            clearForms();
            setTimeout(() => {
              navigate("/dashboard");
            }, 2000);
          } else {
            // alert("account not verified");
            setShowNotVerifiedStatus(true);
          }
        })
        .catch((err) => {
          console.log("error in logging user", err);
          if (err?.response?.status == 404) {
            toast.error("User not found!");
          } else if (err?.response?.status == 409) {
            toast.error("User already logged in some other browser!");
          } else {
            toast.error("Login with correct credentials!");
          }
        });
    } else if (mode == "forgot") {
      let obj = {
        email_address: email,
      };
      clearForms();
      api
        .post("/sendpasswordrecoverylink", obj)
        .then((res) => {
          console.log("responsefrom sending email recovery link", res.data);
          toast.success("email with instructions has been sent!");
          clearForms();
        })
        .catch((err) => {
          console.log("error", err);
          toast.error("unable to send recovery link!");
        });
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    console.log("running handle form submit");
    if (mode === "signup") {
      const isEmailValid = validateEmail(email);
      // const isPhoneNumberValid = validatePhoneNumber(phoneNumber);
      const isPasswordValid = validatePassword(password);
      const isNameValid = validateName(name);
      const isRepeatPasswordValid = validateRepeatPassword(repeatPassword);

      if (
        isEmailValid &&
        isPasswordValid &&
        isNameValid &&
        isRepeatPasswordValid
      ) {
        console.log("everything is correct");

        handleSubmit();
      } else {
        console.log("error occurs");
      }
    } else if (mode == "login") {
      const isEmailValid = validateEmail(email);
      const isPasswordValid = validatePassword(password);
      if (isEmailValid && isPasswordValid) {
        handleSubmit();
      }
    } else {
      const isEmailValid = validateEmail(email);

      if (isEmailValid) {
        handleSubmit();
      }
    }
  };

  const [credentialResponse, setCredentialResponse] = useState();

  return (
    <Box
      sx={{
        // backgroundColor: theme.palette.background?.main,
        // backgroundColor: "rgba(255, 255, 255, 0.5)", // set a semi-transparent white background color
        // backdropFilter: "blur(6px)", // add a 5px blur filter
        // zIndex: "1000000",
        // height: "100vh",
        // minHeight: "100vh",
        // position: "absolute",

        // position: "fixed",
        // top: "0px",
        // left: 0,
        // width: "100vw",
        display: "flex",
        justifyContent: "center",
        // alignItems: "center",
        overflow: "hidden",
        // backgroundColor: theme.palette.colorthree.main,
        padding: "50px 0",
      }}
    >
      <ToastContainer />
      <Box
        sx={{
          minHeight: "200px",
          // height: "60%",
          // maxHeight: "80%",
          // overflowY: "auto",
          height: "max-content",
          background: theme.palette.background.main,
          width: "40%",
          minWidth: "300px",
          maxWidth: "400px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "40px 40px 40px 40px",
          borderRadius: "20px",
          boxShadow:
            "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",

          // border: "1px solid lightgray",
          //   justifyContent: "center",
          // border: "1px solid black",
        }}
      >
        <Box sx={{ cursor: "pointer" }} onClick={() => navigate("/")}>
          <img style={{ height: "50px" }} src={logo} />
        </Box>

        <Box sx={{ width: "100%", maxWidth: "350px" }}>
          <Typography
            variant="h6"
            sx={{
              mt: 2,
              marginBottom: "30px",
              textAlign: "center",
              color: theme.palette.heading.main,
            }}
          >
            {mode == "login"
              ? "Login your Account"
              : mode == "signup"
              ? "Create your Account"
              : mode == "forgot" && "Reset your Password"}
          </Typography>
          {showNotVerifiedStatus && (
            <>
              {isVerificationEmailSent ? (
                <Box
                  sx={{
                    backgroundColor: theme.palette.colorthree.main,
                    padding: "10px 10px",
                  }}
                >
                  <Typography variant="p">Verification Email Sent.</Typography>
                </Box>
              ) : (
                <Box
                  sx={{
                    backgroundColor: theme.palette.colorthree.main,
                    padding: "10px 10px",
                  }}
                >
                  <Typography variant="p">
                    Your Account is not verified. We have already sent you an
                    email with instructions.
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      padding: "8px 14px",
                      border: "none",
                      cursor: "pointer",
                      marginTop: "10px",
                      width: "max-content",
                      borderRadius: "4px",
                      color: "#fff",
                      backgroundColor: theme.palette.colortwo.main,
                    }}
                    onClick={() => sendAccountVerificationEmail()}
                  >
                    Resend Email
                  </Typography>
                </Box>
              )}
            </>
          )}
          {mode === "signup" && (
            <AuthInput
              label="Name"
              placeholder="name"
              value={name}
              onChange={handleNameChange}
              icon={
                <User
                  size={17}
                  color={theme.palette.menuHeading.main}
                  strokeWidth={1.3}
                />
              }
              error={nameError ? true : false}
              helperText={nameError}
            />
          )}
          <Box>
            <AuthInput
              label="Email Address"
              placeholder="email"
              value={email}
              onChange={handleEmailChange}
              icon={
                <Mail
                  size={17}
                  color={theme.palette.menuHeading.main}
                  strokeWidth={1.3}
                />
              }
              error={emailError ? true : false}
              helperText={emailError}
            />
          </Box>
          {/* {mode == "signup" && (
            <Box>
              <AuthPhoneInput
                isPassword={false}
                label="Phone Number"
                placeholder="phone number"
                value={phoneNumber}
                // onChange={handlePhoneNumberChange}
                setPhoneNumber={setPhoneNumber}
                error={phoneNumberError ? true : false}
                helperText={phoneNumberError}
              />
            </Box>
          )} */}
          {mode !== "forgot" && (
            <Box>
              <AuthInput
                isPassword={true}
                label="Password"
                placeholder="password"
                value={password}
                onChange={handlePasswordChange}
                icon={
                  <Lock
                    size={17}
                    color={theme.palette.menuHeading.main}
                    strokeWidth={1.3}
                  />
                }
                error={passwordError ? true : false}
                helperText={passwordError}
              />
              {mode == "signup" && (
                <AuthInput
                  isPassword={true}
                  label="Repeat Password"
                  placeholder="repeat password"
                  value={repeatPassword}
                  onChange={handleRepeatPasswordChange}
                  icon={
                    <Lock
                      size={17}
                      color={theme.palette.menuHeading.main}
                      strokeWidth={1.3}
                    />
                  }
                  error={repeatPasswordError ? true : false}
                  helperText={repeatPasswordError}
                />
              )}
            </Box>
          )}
          {mode == "login" && (
            <Box
              sx={{
                marginTop: "6px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    // checked={isRememberMe ? true : false}
                    size="small"
                    onChange={() => setIsRememberMe(!isRememberMe)}
                  />
                }
                label={
                  <Typography
                    color={theme.palette.para.main}
                    sx={{ fontSize: "0.8rem", marginTop: "0px" }}
                  >
                    Remember Me
                  </Typography>
                }
              />
              <Typography
                variant="body2"
                sx={{
                  fontSize: "13px",
                  fontWeight: "400",
                  marginTop: "9px",
                  color: theme.palette.para.main,
                  cursor: "pointer",
                }}
                onClick={() => setMode("forgot")}
              >
                Forgot Password?
              </Typography>
            </Box>
          )}{" "}
          <Button
            fullWidth
            type="submit"
            variant="contained"
            sx={{
              mt: 3,
              backgroundColor: theme.palette.brandColorOne?.main,
              color: theme.palette.whiteFont?.main,
              height: "36px",
            }}
            onClick={handleFormSubmit}
          >
            {mode === "login" ? "Login" : mode == "forgot" ? "Send" : "Sign Up"}
          </Button>
          <Typography
            variant="body2"
            sx={{
              fontWeight: "400",
              margin: "15px 0",
              color: theme.palette.para.main,
              cursor: "pointer",
            }}
            onClick={() => setMode(mode == "login" ? "signup" : "login")}
          >
            {mode == "login"
              ? " Don't have an account? Signup"
              : "Already have an account? Login"}
          </Typography>
          {mode !== "forgot" && (
            <>
              {" "}
              <Box sx={{ display: "flex", justifyContent: "center", mt: "2" }}>
                <Typography
                  variant="body2"
                  sx={{
                    textAlign: "center",
                    color: theme.palette.para.main,
                    margin: "0px 0px 15px 0px",
                  }}
                >
                  OR
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <GoogleLogin
                  dispatch={dispatch}
                  setToken={setToken}
                  setAuthToken={setAuthToken}
                  navigate={navigate}
                  toast={toast}
                />
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default LoginContainer;
