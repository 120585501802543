import React, { useEffect, useState, useRef, createContext } from "react";

import {
  Typography,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
  IconButton,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material";

import ConversationsBox from "../../../Chat/ConversationsBox";
import ChatBox from "../../../Chat/ChatBox";
// import "./MessengerScreen.css";
// import { io } from "socket.io-client";
import { api } from "../../../../axios/api";
import { io } from "socket.io-client";

export default function Chat({ selectedLead }) {
  const user = useSelector((state) => state.user);
  let socket = useRef();
  socket.current = io(process.env.REACT_APP_BACKEND_URL_FOR_SOCKET, {
    path: "/api/socket.io/",
  });
  const [showConversation, setShowConversation] = useState(true);
  const [showChat, setShowChat] = useState(true);

  const [isGeneralChat, setIsGeneralChat] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [selectedPartnerForConvesation, setSelectedPartnerForConversation] =
    useState({});
  useEffect(() => {
    let width = window.innerWidth;
    if (window.innerWidth < 680) {
      setShowChat(false);
      setShowConversation(true);
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);

  // useEffect(() => {
  //   try {
  //     socket.current = io(process.env.REACT_APP_BACKEND_URL_FOR_SOCKET);
  //     console.log("adding user to socket", user?.id);
  //     console.log("socket current", socket.current);
  //     socket.current.emit("addUser", user?.id);
  //   } catch (err) {
  //     console.log("tum say na ho paye ga", err);
  //   }
  // }, [user]);

  // // Listen for the "getUsers" event from the server
  useEffect(() => {
    // const socket = io(process.env.REACT_APP_BACKEND_URL_FOR_SOCKET);
    socket.current.on("getUsers", (users) => {
      console.log("Received users:", users);
    });
    // Clean up the event listener when the component unmounts
    return () => {
      socket.current.off("getUsers");
    };
  }, []);

  return (
    <>
      <Box sx={{ p: 0, mt: 2 }}>
        <Grid
          container
          columnGap={1}
          sx={{ height: "90vh", minHeight: "40vh" }}
        >
          {(showConversation || !isMobile) && (
            <ConversationsBox
              selectedLead={selectedLead}
              setIsGeneralChat={setIsGeneralChat}
              setSelectedPartnerForConversation={
                setSelectedPartnerForConversation
              }
              setShowConversation={setShowConversation}
              setShowChat={setShowChat}
            />
          )}
          {(showChat || !isMobile) && (
            <ChatBox
              isGeneralChat={isGeneralChat}
              selectedPartnerForConversation={selectedPartnerForConvesation}
              selectedLead={selectedLead}
              socket={socket}
              setShowConversation={setShowConversation}
              setShowChat={setShowChat}
            />
          )}
        </Grid>
      </Box>
    </>
  );
}
