import React from "react";
import TransferIt from "./Features/TransferIt";
import { Typography, Box } from "@mui/material";
import AddNote from "./Features/AddNote";
import ViewNNotes from "./Features/ViewNNotes";
import DropIt from "./Features/DropIt";
import TotalOpenDays from "./Features/TotalOpenDays";
import LastActionDays from "./Features/LastActionDays";
import Milestones from "./Features/Milestones";
import ScheduleATask from "./Features/ScheduleATask";
import Points from "./Features/Points";
import Chat from "./Features/Chat";
import WonIt from "./Features/WonIt";
import Activities from "./Features/Activities";

const Feature = ({
  selectedFeature,
  selectedLead,
  setShowIndividualLeadFeature,
  setSelectedFeature,
}) => {
  console.log("viewNNotes", ViewNNotes);
  console.log("selected feature", selectedFeature);
  return (
    <Box>
      <Typography variant="h6" sx={{ mt: 2 }}>
        {selectedFeature}
      </Typography>
      {selectedFeature == "Transfer it" ? (
        <TransferIt
          selectedLead={selectedLead}
          setShowIndividualLeadFeature={setShowIndividualLeadFeature}
        />
      ) : selectedFeature == "Add a note" ? (
        <AddNote
          selectedLead={selectedLead}
          setShowIndividualLeadFeature={setShowIndividualLeadFeature}
        />
      ) : selectedFeature == "View Notes" ? (
        <ViewNNotes
          selectedLead={selectedLead}
          setShowIndividualLeadFeature={setShowIndividualLeadFeature}
        />
      ) : selectedFeature == "Drop it!" ? (
        <DropIt
          selectedLead={selectedLead}
          setShowIndividualLeadFeature={setShowIndividualLeadFeature}
        />
      ) : selectedFeature == "Total open days" ? (
        <TotalOpenDays
          selectedLead={selectedLead}
          setShowIndividualLeadFeature={setShowIndividualLeadFeature}
        />
      ) : selectedFeature == "Last action days" ? (
        <LastActionDays
          selectedLead={selectedLead}
          setShowIndividualLeadFeature={setShowIndividualLeadFeature}
        />
      ) : selectedFeature == "Milestone" ? (
        <Milestones
          selectedLead={selectedLead}
          setShowIndividualLeadFeature={setShowIndividualLeadFeature}
        />
      ) : selectedFeature == "Schedule a task" ? (
        <ScheduleATask
          selectedLead={selectedLead}
          setShowIndividualLeadFeature={setShowIndividualLeadFeature}
        />
      ) : selectedFeature == "Points" ? (
        <Points
          selectedLead={selectedLead}
          setShowIndividualLeadFeature={setShowIndividualLeadFeature}
          setSelectedFeature={setSelectedFeature}
        />
      ) : selectedFeature == "Chat about it" ? (
        <Chat
          selectedLead={selectedLead}
          setShowIndividualLeadFeature={setShowIndividualLeadFeature}
        />
      ) : selectedFeature == "I won it" ? (
        <WonIt
          selectedLead={selectedLead}
          setShowIndividualLeadFeature={setShowIndividualLeadFeature}
        />
      ) : selectedFeature == "Activities" ? (
        <Activities
          selectedLead={selectedLead}
          setShowIndividualLeadFeature={setShowIndividualLeadFeature}
        />
      ) : (
        <></>
      )}
    </Box>
  );
};

export default Feature;
