import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
  IconButton,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

import { api } from "../../../axios/api";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import AddUserForm from "./AddUserForm";
import { Edit, Trash2 } from "react-feather";
import { ToastContainer, toast } from "react-toastify";

const AddUsers = (props) => {
  const theme = useTheme();
  const [users, setUsers] = useState([]);
  const user = useSelector((state) => state.user);
  const [showAddForm, setShowAddForm] = useState(false);
  const [editOptions, setEditOptions] = useState({});
  const userSubscriptionData = useSelector((state) => state?.subscription_data);

  useEffect(() => {
    console.log("user at show contact", user);
  }, [user]);
  useEffect(() => {
    setUsers([]);
    api
      .get("/getAllUsersByAdmin")
      .then((res) => {
        console.log("response from getting users", res?.data);
        setUsers(
          res?.data?.filter((f) => f?.email_address != user?.email_address)
        );
      })
      .catch((err) => console.log("err", err));
    if (!showAddForm) {
      setEditOptions({});
    }
  }, [user, showAddForm]);

  const [organizationInformation, setOrganizationInformation] = useState({});

  useEffect(() => {
    console.log("running useeffect");
    api
      .get("/organization")
      .then((res) => {
        console.log("organization information", res?.data);
        setOrganizationInformation(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const [noOfUserAllowed, setNoOfUserAllowed] = useState("");
  useEffect(() => {
    console.log("user subscription dat", userSubscriptionData);
    if (userSubscriptionData?.account_type == "free") {
      setNoOfUserAllowed(1);
    } else {
      userSubscriptionData?.offer_purchased == "Starter Pack"
        ? setNoOfUserAllowed(2)
        : userSubscriptionData?.offer_purchased == "Booster Pack"
        ? setNoOfUserAllowed(4)
        : setNoOfUserAllowed(9);
    }
  }, [userSubscriptionData]);

  return (
    <Box sx={{ padding: "20px 30px" }}>
      <ToastContainer />
      {!organizationInformation?.id ? (
        <Box>
          <Typography sx={{ mt: 3 }}>
            {" "}
            Update your organization details
          </Typography>
        </Box>
      ) : (
        <>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box>
              <Typography
                variant="h6"
                sx={{
                  color: theme.palette.heading.main,
                  fontSize: "1.3rem",
                  fontWeight: "600",
                }}
              >
                {"Team members"}
              </Typography>
              <Typography>
                You can add maximum {noOfUserAllowed} users in this organization
              </Typography>
            </Box>
            {!showAddForm && users?.length == noOfUserAllowed ? (
              <Button disabled>Limit reached</Button>
            ) : (
              <Button
                variant="contained"
                onClick={() => setShowAddForm(!showAddForm)}
                sx={{
                  backgroundColor: theme.palette.colortwo.main,
                  color: "#fff",
                  height: "max-content",
                }}
                size="small"
              >
                {showAddForm ? "View Team Members" : "Add Team Members"}
              </Button>
            )}
          </Box>

          {showAddForm ? (
            <AddUserForm
              editOptions={editOptions}
              setShowAddForm={setShowAddForm}
            />
          ) : users?.length < 1 ? (
            <Box
              sx={{ display: "flex", alignItems: "center", mt: 2, gap: "10px" }}
            >
              <InfoIcon />
              <Typography sx={{}}> No member added.</Typography>
            </Box>
          ) : (
            <>
              <Box sx={{ marginTop: "20px" }}>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={12}>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell
                              sx={{
                                width: "50px",
                                color: theme.palette.colortwo.main,
                              }}
                            >
                              ID
                            </TableCell>
                            {/* <TableCell>Customer ID</TableCell> */}
                            <TableCell
                              sx={{
                                color: theme.palette.colortwo.main,
                              }}
                            >
                              Name
                            </TableCell>
                            <TableCell
                              sx={{
                                color: theme.palette.colortwo.main,
                              }}
                            >
                              Email
                            </TableCell>
                            <TableCell
                              sx={{
                                color: theme.palette.colortwo.main,
                              }}
                            >
                              Phone
                            </TableCell>
                            <TableCell
                              sx={{
                                color: theme.palette.colortwo.main,
                              }}
                            >
                              Company Details
                            </TableCell>
                            <TableCell
                              sx={{
                                color: theme.palette.colortwo.main,
                              }}
                            >
                              Actions
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {users?.map((contact, i) => (
                            <NormalUser
                              contact={contact}
                              i={i}
                              users={users}
                              setUsers={setUsers}
                              setShowAddForm={setShowAddForm}
                              setEditOptions={setEditOptions}
                            />
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
        </>
      )}
    </Box>
  );
};

const NormalUser = ({
  contact,
  i,
  users,
  setUsers,
  setEditOptions,
  setShowAddForm,
}) => {
  const theme = useTheme();
  const [isBlocked, setIsBlocked] = useState(false);

  const deleteCustomer = () => {
    console.log("contact id", contact?.id);
    api
      .delete("/deleteUserByAdmin/" + contact?.id)
      .then((res) => {
        console.log("delete user response", res?.data);
        toast.success("Deleted Successfully!");
        // setUsers(users?.filter((c) => c?.id !== contact?.id));
        setIsBlocked(true);
      })
      .catch((err) => {
        console.log("error in deleting user", err);
        toast.error("unable to delete!");
      });
  };
  const activateCustomer = () => {
    console.log("contact id", contact?.id);
    api
      .delete("/activateUserByAdmin/" + contact?.id)
      .then((res) => {
        console.log("activate user response", res?.data);
        toast.success("activated Successfully!");
        // setUsers(users?.filter((c) => c?.id !== contact?.id));
        setIsBlocked(false);
      })
      .catch((err) => {
        console.log("error in activating user", err);
        toast.error("unable to activate!");
      });
  };

  const editSupplier = () => {
    setEditOptions(contact);
    setShowAddForm(true);
  };
  return (
    <TableRow
      key={contact?.id}
      style={{
        backgroundColor:
          i % 2 == 0 ? theme.palette.colorthree.main : "transparent",
      }}
    >
      <TableCell sx={{ width: "50px", padding: "8px 20px" }}>
        {contact?.id}
      </TableCell>
      {/* <TableCell>{contact?.customer_id}</TableCell> */}
      <TableCell sx={{ padding: "8px 20px" }}>{contact?.name}</TableCell>
      <TableCell sx={{ padding: "8px 20px" }}>
        {contact?.email_address}
      </TableCell>
      <TableCell sx={{ padding: "8px 20px" }}>
        {contact?.phone_number}
      </TableCell>
      <TableCell sx={{ padding: "8px 20px" }}>
        {contact?.authorized_to_company_details
          ? "Authorized"
          : "Not Authorized"}
      </TableCell>
      <TableCell sx={{ padding: "8px 20px" }}>
        {contact?.profile_type == "admin" ? (
          "Admin Account"
        ) : (
          <Box sx={{ display: "flex", gap: "5px", flexDirection: "row" }}>
            {contact?.isBlocked || isBlocked ? (
              <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <Typography> Blocked.</Typography>{" "}
                <Button
                  onClick={() => activateCustomer()}
                  sx={{ background: theme.palette.colorthree.main }}
                >
                  Activate
                </Button>
              </Box>
            ) : (
              <>
                {" "}
                {/* <IconButton onClick={() => editSupplier()}>
                <Edit size="18" color={theme.palette.colortwo.main} />
              </IconButton> */}
                <Button
                  onClick={() => editSupplier()}
                  sx={{ background: theme.palette.colorthree.main }}
                >
                  Edit
                </Button>
                {/* <IconButton
                onClick={() => {
                  deleteCustomer();
                }}
              >
                <Trash2 size="18" color={theme.palette.colortwo.main} />
              </IconButton> */}
                <Button
                  onClick={() => {
                    deleteCustomer();
                  }}
                  variant="contained"
                >
                  Block
                </Button>
              </>
            )}
          </Box>
        )}
      </TableCell>
    </TableRow>
  );
};

export default AddUsers;
