import React, { useEffect, useState } from "react";
import OrganizationFormModal from "./OrganizationFormModal";
import {
  Box,
  Button,
  Grid,
  Typography,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import { useTheme } from "@mui/material";
import { api } from "../../../axios/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import OrganizationLogo from "../../../utils/images/organization_logo.png";
import OrganizationLogo from "../../../utils/images/organizaton_logo.png";
import logo from "../../../utils/images/edge.jpg";
import InfoColumn from "./InfoColumn";
import EditLeadsMilestones from "./EditLeadsMilestones";
import EditHappinessRatingOptions from "./EditHappinessRatingOptions";

const GeneralSettings = () => {
  const theme = useTheme();
  const [modalOpen, setModalOpen] = useState(false);
  const [isEditInfo, setIsEditInfo] = useState(false);
  const [showLogoInputField, setShowLogoInputField] = useState(false);
  const [logoFile, setLogoFile] = useState("");
  const [logoImage, setLogoImage] = useState("");

  const [organizationInformation, setOrganizationInformation] = useState();

  useEffect(() => {
    console.log("running useeffect");
    api
      .get("/organization")
      .then((res) => {
        console.log("res?.data", res?.data);
        setOrganizationInformation(res?.data);
      })
      .catch((err) => console.log(err));
  }, [isEditInfo, modalOpen]);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleEditInfo = () => {
    setIsEditInfo(true);
    handleOpenModal();
  };
  /*************profile image */
  const handleProfileChange = (e) => {
    console.log("e", e.target.files);
    setLogoFile(e.target.files[0]);
  };
  const submitProfileImage = async () => {
    console.log("logoFile", logoFile);
    const dataa = new FormData();
    dataa.append("file", logoFile);
    dataa.append("upload_preset", "bpyoqbty");
    console.log("dataa", dataa);
    try {
      const res = await fetch(
        "https://api.cloudinary.com/v1_1/dqmbkvawu/image/upload",

        {
          method: "POST",
          body: dataa,
        }
      );
      const file = await res.json();
      console.log("response from cloudinary>>>", file);
      const url = file?.secure_url;
      api
        .post("/organization/updateLogo/" + organizationInformation?.id, {
          logo: url,
        })
        .then((res) => {
          console.log("response from updating logo", res?.data);
          toast.success("profile image uploaded successfully");
          setLogoImage(url);
          setShowLogoInputField(false);
        })
        .catch((err) => {
          console.log("err", err);
          toast.error("unable to upload logo");
        });
    } catch (err) {
      toast.error("unable to upload image");
    }
  };

  return (
    <Box>
      <ToastContainer />
      <OrganizationFormModal
        open={modalOpen}
        onClose={handleCloseModal}
        isEdit={isEditInfo}
        organizationInformation={organizationInformation}
        setIsEditInfo={setIsEditInfo}
        toast={toast}
      />
      <Typography variant="h6" sx={{ margin: "20px 0" }}>
        My Organization
      </Typography>
      <Box
        sx={{
          // minHeight: "300px",
          background: theme.palette.background.main,
          padding: "40px 20px",
        }}
      >
        {!organizationInformation ? (
          <>
            <Typography variant="body2" sx={{ color: theme.palette.para.main }}>
              You have not created your oranization. Lets create it{" "}
            </Typography>
            <Button
              variant="contained"
              onClick={handleOpenModal}
              sx={{ mt: 3 }}
              size="small"
            >
              Create Organization
            </Button>
          </>
        ) : (
          <>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  gap: { xs: "30px", sm: "10px" },
                }}
              >
                <Box sx={{ flex: { xs: "1", sm: "0.3" } }}>
                  <img
                    src={
                      logoImage
                        ? logoImage
                        : organizationInformation?.logo
                        ? organizationInformation?.logo
                        : logo
                    }
                    style={{
                      // width: { xs: "60%", sm: "70%" },
                      width: "auto",
                      height: "80px",

                      marginLeft: "50%",
                      transform: "translateX(-50%)",
                    }}
                  />
                  {showLogoInputField ? (
                    <>
                      {" "}
                      <Box
                        sx={{
                          marginTop: "20px",
                          paddingRight: "20px",
                          // marginLeft: "50%",
                          // transform: "translateX(-50%)",
                        }}
                      >
                        <input
                          type="file"
                          style={{
                            border: "1px solid lightgray",
                            marginRight: "0px",
                            padding: "10px",
                          }}
                          onChange={handleProfileChange}
                        />
                        <Box sx={{ marginTop: "20px" }}>
                          {" "}
                          <Button
                            variant="contained"
                            size="small"
                            onClick={submitProfileImage}
                          >
                            Upload
                          </Button>
                          <Button
                            size="small"
                            sx={{ marginLeft: "10px" }}
                            onClick={() => setShowLogoInputField("")}
                          >
                            Cancel
                          </Button>
                        </Box>
                      </Box>
                    </>
                  ) : (
                    <>
                      {" "}
                      <Button
                        size="small"
                        sx={{
                          background: theme.palette.colorthree.main,
                          mt: 2,
                          marginLeft: "50%",
                          transform: "translateX(-50%)",
                        }}
                        onClick={() => setShowLogoInputField(true)}
                      >
                        Update
                        {/* Organization */} Logo
                      </Button>
                    </>
                  )}
                </Box>
                <Box
                  sx={{
                    flex: "1",
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: { xs: "column", sm: "row" },
                  }}
                >
                  <Box>
                    <Typography
                      variant="h6"
                      sx={{
                        color: theme.palette.heading.main,

                        fontWeight: "600",
                      }}
                    >
                      {organizationInformation?.organization_name}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        color: theme.palette.para.main,
                        fontSize: "0.9rem",
                        fontWeight: "400",
                        mt: 1,
                      }}
                    >
                      {organizationInformation?.phone_number}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        color: theme.palette.para.main,
                        fontSize: "0.9rem",
                        fontWeight: "400",
                      }}
                    >
                      {organizationInformation?.physical_address}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        color: theme.palette.para.main,
                        fontSize: "0.9rem",
                        fontWeight: "400",
                      }}
                    >
                      Currency: {organizationInformation?.organization_currency}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        color: theme.palette.para.main,
                        fontSize: "0.9rem",
                        fontWeight: "400",
                      }}
                    >
                      Industry: {organizationInformation?.industry}
                    </Typography>
                  </Box>
                  <Button
                    sx={{
                      mt: { xs: 3, sm: 0 },
                      background: theme.palette.colorthree.main,
                      height: "max-content",
                      width: "max-content",
                    }}
                    onClick={() => handleEditInfo()}
                  >
                    Edit Information
                  </Button>
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Box>
      <Box
        sx={{
          // background: theme.palette.background.main,
          mt: 3,
        }}
      >
        <Grid container gap={3}>
          {organizationInformation && (
            <InfoColumn
              type="lead_sources"
              organizationInformation={organizationInformation}
              setOrganizationInformation={setOrganizationInformation}
              toast={toast}
            />
          )}
          {organizationInformation && (
            <InfoColumn
              type="services"
              organizationInformation={organizationInformation}
              setOrganizationInformation={setOrganizationInformation}
              toast={toast}
            />
          )}
          {organizationInformation && (
            <InfoColumn
              type="industries"
              organizationInformation={organizationInformation}
              setOrganizationInformation={setOrganizationInformation}
              toast={toast}
            />
          )}
        </Grid>
      </Box>
      {organizationInformation && (
        <EditLeadsMilestones
          organizationInformation={organizationInformation}
          setOrganizationInformation={setOrganizationInformation}
          toast={toast}
        />
      )}
      {organizationInformation && (
        <EditHappinessRatingOptions
          organizationInformation={organizationInformation}
          setOrganizationInformation={setOrganizationInformation}
          toast={toast}
        />
      )}
    </Box>
  );
};

export default GeneralSettings;
