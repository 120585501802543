import React from "react";
import {
  Modal,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Typography,
  Tooltip,
  IconButton,
} from "@mui/material";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTheme } from "@mui/material";
import { api } from "../../../axios/api";
import InfoIcon from "@mui/icons-material/Info";

const validationSchema = Yup.object({
  organizationName: Yup.string().required("Company name is required"),
  address: Yup.string().required("Address is required"),
  phone: Yup.string().required("Phone number is required"),
  currency: Yup.string().required("Company currency is required"),
  industry: Yup.string().required("industry is required"),
});

const currencyOptions = [
  { value: "USD", label: "$ (USD)" },
  { value: "EUR", label: "€ (EUR)" },
  { value: "GBP", label: "£ (GBP)" },
  { value: "JPY", label: "¥ (JPY)" },
  { value: "KES", label: "KSh (KES)" },
  // Add more currency options here
];

const OrganizationFormModal = ({
  open,
  onClose,
  isEdit,
  organizationInformation,
  setIsEditInfo,
}) => {
  const initialValues = isEdit
    ? {
        organizationName: organizationInformation?.organization_name,
        address: organizationInformation?.physical_address,
        phone: organizationInformation?.phone_number,
        industry: organizationInformation?.industry,
        currency: organizationInformation?.organization_currency,
      }
    : {
        organizationName: "",
        address: "",
        phone: "",
        users: [],
        leadSources: [],
        services: [],
        industry: "",
        currency: "",
      };
  const theme = useTheme();
  const handleSubmit = (values) => {
    console.log("Form values:", values);
    let body = {
      organization_name: values?.organizationName,
      physical_address: values?.address,
      phone_number: values?.phone,
      organization_currency: values?.currency,
      industry: values?.industry,
    };
    console.log("isEdit", isEdit);
    if (isEdit) {
      api
        .post("/organization/edit/" + organizationInformation?.id, body)
        .then((res) => {
          console.log("res?.data", res?.data);
          setIsEditInfo(false);
          onClose(); // Close the modal after form submission
        })
        .catch((err) => console.log(err));
    } else {
      api
        .post("/createOrganization", body)
        .then((res) => {
          console.log("res?.data", res?.data);
          onClose(); // Close the modal after form submission
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Box
        sx={{
          backgroundColor: theme.palette.background.main,
          minWidth: "280px",
          maxWidth: "600px",
          maxHeight: "80vh",
          overflowY: "auto",
          padding: "30px 40px",
          borderRadius: "4px",
        }}
      >
        <Typography variant="h6" sx={{ color: theme.palette.heading.main }}>
          {isEdit ? "Edit your organization details" : " Add Your organization"}
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched }) => (
            <Form>
              <Field
                as={TextField}
                name="organizationName"
                label="Name of the company"
                fullWidth
                margin="normal"
                error={touched.organizationName && errors.organizationName}
                helperText={<ErrorMessage name="organizationName" />}
              />
              <Field
                as={TextField}
                name="address"
                label="Physical address"
                fullWidth
                margin="normal"
                error={touched.address && errors.address}
                helperText={<ErrorMessage name="address" />}
              />
              <Field
                as={TextField}
                name="phone"
                label="Business phone number"
                fullWidth
                margin="normal"
                error={touched.phone && errors.phone}
                helperText={<ErrorMessage name="phone" />}
              />

              <FormControl fullWidth margin="normal">
                <InputLabel id="industry-label">Industry</InputLabel>
                <Field
                  as={Select}
                  name="industry"
                  labelId="industry-label"
                  error={touched.industry && errors.industry}
                  helperText={<ErrorMessage name="industry" />}
                >
                  <MenuItem value="">Select an industry</MenuItem>
                  <MenuItem value="Accounting, Tax and Auditing">
                    Accounting, Tax and Auditing
                  </MenuItem>
                  <MenuItem value="Management Consulting">
                    Management Consulting
                  </MenuItem>
                  <MenuItem value="Legal Services">Legal Services</MenuItem>
                  <MenuItem value="Information Technology (IT) Consulting">
                    Information Technology (IT) Consulting
                  </MenuItem>
                  <MenuItem value="Human Resources Consulting">
                    Human Resources Consulting
                  </MenuItem>
                  <MenuItem value="Marketing and Advertising Services">
                    Marketing and Advertising Services
                  </MenuItem>
                  <MenuItem value="Environmental and Sustainability Consulting">
                    Environmental and Sustainability Consulting
                  </MenuItem>
                  <MenuItem value="Engineering and Construction Consulting">
                    Engineering and Construction Consulting
                  </MenuItem>
                  <MenuItem value="Real Estate Services">
                    Real Estate Services
                  </MenuItem>
                  <MenuItem value="Risk Management Consulting">
                    Risk Management Consulting
                  </MenuItem>
                  <MenuItem value="Supply Chain and Logistics Consulting">
                    Supply Chain and Logistics Consulting
                  </MenuItem>
                  <MenuItem value="Healthcare Consulting">
                    Healthcare Consulting
                  </MenuItem>
                  <MenuItem value="Energy and Utilities Consulting">
                    Energy and Utilities Consulting
                  </MenuItem>
                  <MenuItem value="Government and Public Sector Consulting">
                    Government and Public Sector Consulting
                  </MenuItem>
                  <MenuItem value="Education Consulting">
                    Education Consulting
                  </MenuItem>
                  <MenuItem value="Data Analytics and Business Intelligence Consulting">
                    Data Analytics and Business Intelligence Consulting
                  </MenuItem>
                  <MenuItem value="Social Media and Digital Marketing Consulting">
                    Social Media and Digital Marketing Consulting
                  </MenuItem>
                  <MenuItem value="Financial Planning and Wealth Management">
                    Financial Planning and Wealth Management
                  </MenuItem>
                  <MenuItem value="Research and Market Analysis Consulting">
                    Research and Market Analysis Consulting
                  </MenuItem>
                  <MenuItem value="Insurance Consulting">
                    Insurance Consulting
                  </MenuItem>
                  <MenuItem value="Quality Assurance and Compliance Consulting">
                    Quality Assurance and Compliance Consulting
                  </MenuItem>
                  <MenuItem value="Other Services">
                    General Business services
                  </MenuItem>
                </Field>
              </FormControl>

              {!isEdit && (
                <Box sx={{ position: "relative" }}>
                  <Tooltip
                    title="Organisation reporting currency is final and cannot be
                    amended in the future.
                    "
                    placement="top-start"
                    sx={{ position: "absolute", top: "20px", left: "-33px" }}
                  >
                    <IconButton>
                      <InfoIcon sx={{ color: theme.palette.primary.main }} />
                    </IconButton>
                  </Tooltip>{" "}
                  <FormControl fullWidth margin="normal">
                    <InputLabel id="currency-label">
                      Company's Currency
                    </InputLabel>
                    <Field
                      as={Select}
                      name="currency"
                      labelId="currency-label"
                      error={touched.currency && errors.currency}
                      helperText={<ErrorMessage name="currency" />}
                    >
                      {currencyOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                </Box>
              )}

              {/* Add more fields here */}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ mt: "3" }}
              >
                {isEdit ? "Update" : "Submit"}
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default OrganizationFormModal;
