import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  Button,
  TableContainer,
  TableRow,
  TableCell,
  TableHead,
  useTheme,
  Paper,
  Table,
  TableBody,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { api } from "../../../axios/api";
import * as XLSX from "xlsx";
import { useSelector } from "react-redux";

const ProcessedForm = ({
  organizationInformation,
  selectedFile,
  setShowProcessedForm,
  setShowImportForm,
  alreadyExisitingProspects,
}) => {
  const user = useSelector((state) => state?.user);
  const userSubscriptionData = useSelector((state) => state?.subscription_data);
  console.log("user subscription data", userSubscriptionData);
  const theme = useTheme();
  const [data, setData] = useState([]);
  const [isProcessing, setIsProcessing] = useState(true);
  const [error, setError] = useState("");
  const [submittedData, setSubmittedData] = useState([]);

  useEffect(() => {
    setError("");
    console.log("selected file in processed form", selectedFile);
    if (selectedFile) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const binaryData = e.target.result;
        const workbook = XLSX.read(binaryData, { type: "binary" });
        const sheetName = workbook.SheetNames[0]; // Assuming you're working with the first sheet
        const worksheet = workbook.Sheets[sheetName];
        const rows = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        console.log("rows", rows);

        if (rows.length > 1) {
          // Assuming the first row contains headings
          const headings = rows[0];
          const extractedData = rows.slice(1).map((row) => {
            const obj = {};
            headings.forEach((heading, index) => {
              obj[heading] = row[index];
            });
            return obj;
          });
          console.log("extarcted data", extractedData);

          let { lead_sources, industries, services } = organizationInformation;
          setData(
            extractedData?.map((d) => {
              let final = d;
              if (lead_sources?.includes(d?.lead_source)) {
              } else {
                final = { ...final, lead_source: "" };
              }
              if (industries?.includes(d?.industry)) {
              } else {
                final = { ...final, industry: "" };
              }
              if (services?.includes(d?.service_needed)) {
              } else {
                final = { ...final, service_needed: "" };
              }
              return final;
            })
          );

          // setData(extractedData);
        } else {
          setData([]);
          toast.error("The file does not contain any data rows.");
          setError("The file does not contain any data rows.");
        }
        setIsProcessing(false);
      };

      reader.readAsBinaryString(selectedFile);
    } else {
      setError("There is some problem with the imported file!");
    }
  }, []);

  const handleRemoveRow = (index) => {
    const updatedData = [...data];
    updatedData.splice(index, 1);
    setData(updatedData);
  };
  useEffect(() => {
    console.log("data", data);
  }, [data]);

  const handleSubmitAll = async () => {
    // Check if any text fields are empty
    console.log("alreadyExisitingProspects", alreadyExisitingProspects);
    if (userSubscriptionData?.account_type == "free") {
      if (alreadyExisitingProspects?.length + data?.length > 10) {
        return toast.error(
          "you cannot add more than 10 leads in an organization as a free account user"
        );
      }
    }
    setIsProcessing(true);
    if (data.some((row) => Object.values(row).some((value) => !value))) {
      toast.error("Please fill in all fields in the table.");
      setIsProcessing(false);
    } else {
      try {
        for (const row of data) {
          await api.post("/createProspect", { ...row, owner_id: user?.id });
          setSubmittedData((prevData) => [...prevData, row]);
        }
        toast.success("All data submitted successfully!");
        setShowImportForm(false);
        setIsProcessing(false);
      } catch (error) {
        setIsProcessing(false);
        toast.error("An error occurred while submitting data.");
      }
    }
  };

  return (
    <Box>
      <ToastContainer />
      {isProcessing ? (
        <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {" "}
          <Button
            variant="contained"
            onClick={() => setShowProcessedForm(false)}
            sx={{ my: 2 }}
          >
            Import again
          </Button>
          {error && <Typography sx={{ color: "red" }}>{error}</Typography>}
          <Box>
            <TableContainer component={Paper} sx={{ mt: 2 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {Object.keys(data[0]).map((heading) => (
                      <TableCell
                        key={heading}
                        sx={{
                          width: "80px",
                          fontSize: "0.9rem",
                          whiteSpace: "nowrap",
                          padding: "8px 20px",
                          minWidth: "100px",
                        }}
                      >
                        {heading}
                      </TableCell>
                    ))}
                    <TableCell
                      sx={{
                        width: "80px",
                        fontSize: "0.9rem",
                        whiteSpace: "nowrap",
                        padding: "8px 20px",
                        minWidth: "100px",
                      }}
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((d, index) => (
                    <FormRow
                      indexF={index}
                      dataRow={d}
                      setData={setData}
                      onRemove={() => handleRemoveRow(index)}
                      org={organizationInformation}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Button
              variant="contained"
              onClick={handleSubmitAll}
              sx={{ my: 2 }}
            >
              Submit all
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default ProcessedForm;

const FormRow = ({ dataRow, setData, onRemove, indexF, org }) => {
  return (
    <TableRow>
      {Object.keys(dataRow).map((key, index) => (
        <TableCell
          key={index}
          sx={{ whiteSpace: "nowrap", padding: "8px 20px", minWidth: "100px" }}
        >
          {key == "industry" ||
          key == "service_needed" ||
          key == "lead_source" ? (
            <Select
              size="small"
              variant="outlined"
              fullWidth
              value={dataRow[key] ? dataRow[key] : ""}
              onChange={(e) => {
                const updatedDataRow = { ...dataRow };
                console.log("updated row 1", updatedDataRow);
                updatedDataRow[key] = e.target.value;
                console.log("updated row 2", updatedDataRow);
                setData((prevData) => {
                  const newData = [...prevData];
                  console.log("new data 1", newData);
                  newData[indexF] = updatedDataRow;
                  console.log("new Data 2", newData);
                  return newData;
                });
              }}
            >
              {key == "industry" &&
                org?.industries?.map((d) => <MenuItem value={d}>{d}</MenuItem>)}
              {key == "service_needed" &&
                org?.services?.map((d) => <MenuItem value={d}>{d}</MenuItem>)}
              {key == "lead_source" &&
                org?.lead_sources?.map((d) => (
                  <MenuItem value={d}>{d}</MenuItem>
                ))}
            </Select>
          ) : (
            <TextField
              variant="outlined"
              fullWidth
              size="small"
              value={dataRow[key] ? dataRow[key] : ""}
              onChange={(e) => {
                const updatedDataRow = { ...dataRow };
                console.log("updated row 1", updatedDataRow);
                updatedDataRow[key] = e.target.value;
                console.log("updated row 2", updatedDataRow);
                setData((prevData) => {
                  const newData = [...prevData];
                  console.log("new data 1", newData);
                  newData[indexF] = updatedDataRow;
                  console.log("new Data 2", newData);
                  return newData;
                });
              }}
            />
          )}
        </TableCell>
      ))}
      <TableCell
        sx={{ whiteSpace: "nowrap", padding: "8px 20px", minWidth: "100px" }}
      >
        <Button variant="contained" onClick={onRemove}>
          Remove
        </Button>
      </TableCell>
    </TableRow>
  );
};

// import React, { useState, useEffect } from "react";
// import {
//   Box,
//   Typography,
//   CircularProgress,
//   Button,
//   TableContainer,
//   TableRow,
//   TableCell,
//   TableHead,
//   useTheme,
//   Paper,
//   Table,
//   TableBody,
//   TextField,
// } from "@mui/material";
// import * as XLSX from "xlsx";
// import { ToastContainer, toast } from "react-toastify";
// import { useSelector } from "react-redux";
// import { api } from "../../../axios/api";

// const ProcessedForm = ({
//   organizationInformation,
//   selectedFile,
//   setShowProcessedForm,
// }) => {
//   const theme = useTheme();
//   const [data, setData] = useState([]);
//   const [headings, setHeadings] = useState([
//     "Organization Name",
//     "Contact Person",
//     "Primary Phone Number",
//     "Other Phone Number",
//     "Email address",
//     "Industry",
//     "Service Needed",
//     "Lead source",
//     "City",
//     "Country",
//     "Value of Lead",
//   ]);
//   const [isProcessing, setIsProcessing] = useState(true);
//   const [error, setError] = useState("");
//   useEffect(() => {
//     setError("");
//     console.log("selected file in processed form", selectedFile);
//     if (selectedFile) {
//       const reader = new FileReader();

//       reader.onload = (e) => {
//         const binaryData = e.target.result;
//         const workbook = XLSX.read(binaryData, { type: "binary" });
//         const sheetName = workbook.SheetNames[0]; // Assuming you're working with the first sheet
//         const worksheet = workbook.Sheets[sheetName];
//         const rows = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
//         console.log("rows", rows);

//         if (rows.length > 1) {
//           // Assuming the first row contains headings
//           const headings = rows[0];
//           const extractedData = rows.slice(1).map((row) => {
//             const obj = {};
//             headings.forEach((heading, index) => {
//               obj[heading] = row[index];
//             });
//             return obj;
//           });
//           console.log("extarcted data", extractedData);

//           setData(extractedData);
//         } else {
//           setData([]);
//           toast.error("The file does not contain any data rows.");
//           setError("The file does not contain any data rows.");
//         }
//         setIsProcessing(false);
//       };

//       reader.readAsBinaryString(selectedFile);
//     } else {
//       setError("there is some problem with imported file!");
//     }
//   }, []);
//   return (
//     <Box>
//       <ToastContainer />
//       {isProcessing ? (
//         <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}></Box>
//       ) : (
//         <>
//           {" "}
//           <Button
//             variant="contained"
//             onClick={() => setShowProcessedForm(false)}
//             sx={{ my: 2 }}
//           >
//             Import again
//           </Button>
//           {error && <Typography sx={{ color: "red" }}>{error}</Typography>}
//           <Box>
//             <TableContainer component={Paper} sx={{ mt: 2 }}>
//               <Table>
//                 <TableHead>
//                   <TableRow>
//                     {headings?.map((h) => (
//                       <TableCell
//                         sx={{
//                           width: "80px",
//                           // color: theme.palette.colortwo.main,
//                           fontSize: "0.9rem",
//                           whiteSpace: "nowrap",
//                           padding: "8px 20px",
//                           minWidth: "100px",
//                         }}
//                       >
//                         {h}
//                       </TableCell>
//                     ))}
//                   </TableRow>
//                 </TableHead>
//                 <TableBody>
//                   {data?.map((d) => (
//                     <FormRow setData={setData} dataRow={d} />
//                   ))}
//                 </TableBody>
//               </Table>
//             </TableContainer>
//             <Button variant="contained" sx={{ my: 2 }}>
//               Submit all
//             </Button>
//           </Box>
//         </>
//       )}
//     </Box>
//   );
// };

// export default ProcessedForm;

// const FormRow = ({ dataRow }) => {
//   //   const user = useSelector((state) => state.user);
//   const theme = useTheme();

//   //   useEffect(() => {
//   //     api
//   //       .get("/getUserById/" + activity?.done_by)
//   //       .then((res) => setFrom(res?.data))
//   //       .catch((err) => console.log(err));
//   //   }, []);
//   return (
//     <TableRow>
//       <TableCell
//         sx={{ whiteSpace: "nowrap", padding: "8px 20px", minWidth: "100px" }}
//       >
//         <TextField
//           variant="outlined"
//           fullWidth
//           size="small"
//           value={dataRow?.organization_name ? dataRow?.organization_name : ""}
//         />
//       </TableCell>
//       <TableCell
//         sx={{ whiteSpace: "nowrap", padding: "8px 20px", minWidth: "100px" }}
//       >
//         <TextField
//           variant="outlined"
//           fullWidth
//           size="small"
//           value={dataRow?.contact_person ? dataRow?.contact_person : ""}
//         />
//       </TableCell>
//       <TableCell
//         sx={{ whiteSpace: "nowrap", padding: "8px 20px", minWidth: "100px" }}
//       >
//         <TextField
//           variant="outlined"
//           fullWidth
//           size="small"
//           value={
//             dataRow?.primary_phone_number ? dataRow?.primary_phone_number : ""
//           }
//         />
//       </TableCell>
//       <TableCell
//         sx={{ whiteSpace: "nowrap", padding: "8px 20px", minWidth: "100px" }}
//       >
//         <TextField
//           variant="outlined"
//           fullWidth
//           size="small"
//           value={dataRow?.other_phone_number ? dataRow?.other_phone_number : ""}
//         />
//       </TableCell>
//       <TableCell
//         sx={{ whiteSpace: "nowrap", padding: "8px 20px", minWidth: "100px" }}
//       >
//         <TextField
//           variant="outlined"
//           fullWidth
//           size="small"
//           value={dataRow?.email ? dataRow?.email : ""}
//         />
//       </TableCell>
//       <TableCell
//         sx={{ whiteSpace: "nowrap", padding: "8px 20px", minWidth: "100px" }}
//       >
//         <TextField
//           variant="outlined"
//           fullWidth
//           size="small"
//           value={dataRow?.industry ? dataRow?.industry : ""}
//         />
//       </TableCell>
//       <TableCell
//         sx={{ whiteSpace: "nowrap", padding: "8px 20px", minWidth: "100px" }}
//       >
//         <TextField
//           variant="outlined"
//           fullWidth
//           size="small"
//           value={dataRow?.service_needed ? dataRow?.service_needed : ""}
//         />
//       </TableCell>
//       <TableCell
//         sx={{ whiteSpace: "nowrap", padding: "8px 20px", minWidth: "100px" }}
//       >
//         <TextField
//           variant="outlined"
//           fullWidth
//           size="small"
//           value={dataRow?.lead_source ? dataRow?.lead_source : ""}
//         />
//       </TableCell>
//       <TableCell
//         sx={{ whiteSpace: "nowrap", padding: "8px 20px", minWidth: "100px" }}
//       >
//         <TextField
//           variant="outlined"
//           fullWidth
//           size="small"
//           value={dataRow?.city ? dataRow?.city : ""}
//         />
//       </TableCell>
//       <TableCell
//         sx={{ whiteSpace: "nowrap", padding: "8px 20px", minWidth: "100px" }}
//       >
//         <TextField
//           variant="outlined"
//           fullWidth
//           size="small"
//           value={dataRow?.country ? dataRow?.country : ""}
//         />
//       </TableCell>
//       <TableCell
//         sx={{ whiteSpace: "nowrap", padding: "8px 20px", minWidth: "100px" }}
//       >
//         <TextField
//           variant="outlined"
//           fullWidth
//           size="small"
//           value={dataRow?.value_of_lead ? dataRow?.value_of_lead : ""}
//         />
//       </TableCell>
//     </TableRow>
//   );
// };
