import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
  IconButton,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { api } from "../../../../axios/api";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material";
import { ThemeContext } from "@emotion/react";
import { Star } from "react-feather";

const Milestones = ({ selectedLead }) => {
  const theme = useTheme();
  const user = useSelector((state) => state.user);
  const [milestones, setMilestones] = useState([
    { level: 1, description: "Discovery, seeking to know the client better" },
    {
      level: 2,
      description:
        " Nurturing, Working on understanding the client needs, identifying a service fit and building trust.",
    },
    {
      level: 3,
      description:
        "Currently engaged in negotiations and a proposal preparation including presentations and meetings on a need to basis.",
    },
    {
      level: 4,
      description:
        " Proposal submitted; currently providing additional clarification on a need basis",
    },
    {
      level: 5,
      description:
        "All clarifications have been made; currently following up for approval of the proposal.",
    },
  ]);
  const [currentMilestone, setCurrentMilestone] = useState({});
  useEffect(() => {
    console.log("selected lead", selectedLead);
    setCurrentMilestone(
      milestones?.filter((m) => m?.level == selectedLead?.milestone_level)[0]
    );
  }, []);

  const upgradeTo = (milestone) => {
    console.log("to be upgraded to", milestone);
    console.log("current milestone", currentMilestone);
    api
      .post("/updateMilestone", {
        prospect_id: selectedLead?.lead_serial_number,
        milestone_level: milestone?.level,
      })
      .then((res) => {
        console.log(res?.data);
        toast.success("Milestone Updated Successfully!");
        api.post("/addRelationshipActivity", {
          relationship_id: selectedLead?.info_reference_number,
          done_by: user?.id,
          action: "Note added!",
          note: `Milestone: ${milestone?.level}!`,
        });
        setCurrentMilestone(milestone);
      })
      .catch((err) => toast.error("unable to update milestone"));
  };
  return (
    <Box>
      <Box
        sx={{ mt: 2, background: theme.palette.selectedMenuBack.main, p: 2 }}
      >
        <Typography variant="h6" sx={{ color: theme.palette.primary.main }}>
          Current Milestone
        </Typography>
        <Box sx={{ display: "flex" }}>
          <Box
            sx={{
              flex: "0.2",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                border: "1px solid gold",
                borderRadius: "50%",
                width: "40px",
                height: "40px",
                textAlign: "center",
              }}
            >
              {currentMilestone?.level}
            </Typography>
          </Box>
          <Box sx={{ flex: 1, p: 3 }}>
            <Typography>{currentMilestone?.description}</Typography>
          </Box>
        </Box>
        <Box sx={{ mt: 3, backround: "#fff", p: 2 }}>
          <Typography variant="h6" sx={{ color: theme.palette.primary.main }}>
            Upgrade to
          </Typography>
          <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      width: "80px",
                      // color: theme.palette.colortwo.main,
                      fontSize: "0.9rem",
                    }}
                  ></TableCell>
                  <TableCell
                    sx={{
                      // color: theme.palette.colortwo.main,
                      fontSize: "0.9rem",
                    }}
                  >
                    <Typography variant="body2">MILESTONES</Typography>
                  </TableCell>
                  {/* <TableCell>Customer ID</TableCell> */}
                  <TableCell
                    sx={{
                      // color: theme.palette.colortwo.main,
                      fontSize: "0.9rem",
                    }}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {milestones
                  ?.filter((m) => m?.level != currentMilestone?.level)
                  ?.map((m) => (
                    <TableRow>
                      <TableCell sx={{ padding: "4px 20px" }}>
                        {m?.level}
                      </TableCell>
                      <TableCell sx={{ padding: "4px 20px" }}>
                        <Typography variant="p">{m?.description}</Typography>
                      </TableCell>
                      <TableCell sx={{ padding: "4px 20px" }}>
                        <Button
                          color="primary"
                          onClick={() => upgradeTo(m)}
                          size="small"
                          sx={{
                            background: theme.palette.selectedMenuBack.main,
                            p: 0.5,
                          }}
                        >
                          <Typography variant="p">
                            {" "}
                            {m?.level < currentMilestone?.level
                              ? "Downgrade"
                              : "Upgrade"}
                          </Typography>
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
};

export default Milestones;
