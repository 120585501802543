import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  IconButton,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
} from "@mui/material";
import { useTheme } from "@mui/material";
import { api } from "../../../../axios/api";
import TransferHistory from "./TransferHistory";
import { useSelector } from "react-redux";

const Activities = ({ selectedLead, setViewMode }) => {
  const [activities, setActivities] = useState([]);
  const user = useSelector((state) => state.user);
  const theme = useTheme();
  useEffect(() => {
    console.log("runnibg useefeect");
    api
      .get("/relationshipsActivities/" + selectedLead?.info_reference_number)
      .then((res) => {
        console.log("res", res?.data);
        setActivities(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <Box sx={{ background: theme.palette.selectedMenuBack.main }}>
      <Box
        sx={{ mt: 2, display: "flex", alignItems: "center", gap: "20px", p: 2 }}
      >
        <Typography variant="p">Total Activities= </Typography>
        <Typography
          variant="p"
          sx={{
            fontSize: "1.3rem",
            fontWeight: "600",
            // color: theme.palette.primary.main,
          }}
        >
          {activities?.length}
        </Typography>
      </Box>
      <Box sx={{ mt: 1, p: 2, background: "transparent" }}>
        <Typography variant="h6" sx={{ color: theme.palette.primary.main }}>
          Activities related to lead: {selectedLead?.contact_person}
        </Typography>
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    width: "80px",
                    // color: theme.palette.colortwo.main,
                    fontSize: "0.9rem",
                    whiteSpace: "nowrap",
                    padding: "8px 20px",
                  }}
                >
                  Date
                </TableCell>
                <TableCell
                  sx={{
                    // color: theme.palette.colortwo.main,
                    fontSize: "0.9rem",
                    whiteSpace: "nowrap",
                    padding: "8px 20px",
                  }}
                >
                  Action
                </TableCell>
                <TableCell
                  sx={{
                    // color: theme.palette.colortwo.main,
                    fontSize: "0.9rem",
                    whiteSpace: "nowrap",
                    padding: "8px 20px",
                  }}
                >
                  Note
                </TableCell>
                <TableCell
                  sx={{
                    // color: theme.palette.colortwo.main,
                    fontSize: "0.9rem",
                    whiteSpace: "nowrap",
                    padding: "8px 20px",
                  }}
                >
                  By
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {activities?.map((m) => (
                <ActivityRow activity={m} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default Activities;

const ActivityRow = ({ activity, i }) => {
  const [from, setFrom] = useState({});
  const user = useSelector((state) => state.user);
  const theme = useTheme();

  useEffect(() => {
    api
      .get("/getUserById/" + activity?.done_by)
      .then((res) => setFrom(res?.data))
      .catch((err) => console.log(err));
  }, []);
  return (
    <TableRow>
      <TableCell sx={{ whiteSpace: "nowrap", padding: "8px 20px" }}>
        {new Date(activity?.date)?.toDateString()}
      </TableCell>
      <TableCell sx={{ whiteSpace: "nowrap", padding: "8px 20px" }}>
        <Typography variant="p">{activity?.action}</Typography>
      </TableCell>
      <TableCell sx={{ whiteSpace: "nowrap", padding: "8px 20px" }}>
        <Typography variant="p">{activity?.note}</Typography>
      </TableCell>
      <TableCell sx={{ whiteSpace: "nowrap", padding: "8px 20px" }}>
        <Typography variant="p">{from?.name}</Typography>
      </TableCell>
    </TableRow>
  );
};
