import React from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import styled from "@mui/material/styles/styled";
import emailImg from "../../utils/images/email.png";
import chatImg from "../../utils/images/chat.png";
import scoreImg from "../../utils/images/score.png";

const CustomButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.colortwo.main,
  color: theme.palette.common.white,
  borderRadius: "999px",
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
  },
}));

const GetStarted = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        // border: "1px solid lightgray",
        padding: "50px 0",
        margin: "100px 0 200px 0",

        backgroundColor: theme.palette.colorthree.main,
      }}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={6} sx={{ paddingTop: "30px" }}>
            <Typography
              variant="h3"
              sx={{
                textAlign: { xs: "center", md: "left" },
                fontWeight: "600",
                color: theme.palette.primary.main,
              }}
            >
              Our CRM Software
            </Typography>
            <br />
            <Typography
              variant="h6"
              sx={{
                fontSize: "1.2rem",
                fontWeight: "300",
                textAlign: { xs: "center", md: "left" },
              }}
            >
              Tool for use by businesses to find sales leads and to keep track
              of their sales prospects.
            </Typography>
            <CustomButton
              sx={{
                width: "150px",
                height: "45px",
                padding: "8px",
                marginTop: "30px",
                marginLeft: { xs: "50%", md: "0" },
                transform: { xs: "translateX(-50%)", md: "translateX(0)" },
              }}
            >
              Get Started
            </CustomButton>
          </Grid>
          <Grid item xs={12} md={6} sx={{ marginTop: { xs: "60px", md: "0" } }}>
            <Box
              sx={{
                position: "relative",
                // border: "1px solid black",
                height: "100%",
                minHeight: "300px",
              }}
            >
              <img
                src={emailImg}
                style={{
                  height: "auto",
                  width: "50%",
                  position: "absolute",
                  top: "30px",
                  left: "10%",
                }}
              />
              <img
                src={scoreImg}
                style={{
                  height: "auto",
                  width: "50%",
                  position: "absolute",
                  top: "100px",
                  left: "40%",
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default GetStarted;
