import React, { useEffect, useState } from "react";
import { api } from "../../axios/api";

import ClearIcon from "@mui/icons-material/Clear";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  useTheme,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
  Card,
  CardContent,
  Grid,
  IconButton,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import * as filestack from "filestack-js";
import * as XLSX from "xlsx";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import UserDetailsCard from "./UserDetails";
import AuthPhoneInputMpesa from "../InputFields/AuthPhoneInputMpesa";

const AgentAnalytics = () => {
  const [analyticsOption, setAnalyticsOption] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [downloadFileUrl, setDownloadFileUrl] = useState("");
  const [showDownloadButton, setShowDownloadButton] = useState("");
  const [accounts, setAccounts] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [selectedFilter2, setSelectedFilter2] = useState("leads created");
  const theme = useTheme();
  const user = useSelector((state) => state?.user);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  let intervalId;

  /********phone */
  const validateMpesaPhoneNumber = (phoneNumber) => {
    const phoneRegex = /^\+?[0-9]{12,}$/; // matches phone numbers with at least 12 digits

    if (!phoneNumber) {
      setPhoneNumberError("Phone Number is required");
      return false;
    }
    if (!phoneRegex.test(phoneNumber)) {
      setPhoneNumberError("Enter valid phone number");
      return false;
    }
    if (!phoneNumber.startsWith("254")) {
      setPhoneNumberError("Enter a valid kenyan phone number 254...");
      return false;
    }
    setPhoneNumberError("");
    return true;
  };
  const handlePhoneNumberChange = (event) => {
    console.log("event", event);
    setPhoneNumber(event.target.value);
    setPhoneNumberError("");
  };

  const fetchUsers = () => {
    api
      .get(`/users/${selectedFilter}`)
      .then((res) => {
        console.log("all users", res?.data);
        let one = res?.data?.filter(
          (d) =>
            d?.email_address !== "admin@edge360.co" &&
            d?.profile_type != "agent" &&
            d?.profile_type != "employee"
        );
        let two = one?.filter((data) => {
          if (data?.account_type == "premium" || data?.ontrial) {
            if (data?.agent_id == user?.id) return true;
            else return false;
          }
          return true;
        });
        setAccounts(two);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    fetchUsers();
  }, [selectedFilter, analyticsOption]);

  const getNormalCase = (str) => {
    return str.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
      return str.toUpperCase();
    });
  };
  const uploadFileToBackend = async (file, isJSON) => {
    const formData = new FormData();
    isJSON
      ? formData.append(
          "files",
          new Blob([file], { type: "application/json" }),
          "data.json"
        )
      : formData.append("files", file);

    try {
      const response = await api.post("/files/upload/space", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("url", response?.data?.url);
      return response.data.url; // Assuming the response contains the S3 URL
    } catch (error) {
      // console.error(error);
      setIsLoading(false);
      // toast.error("unable to cntinue at the moment!");
      return null;
    }
  };
  const downloadReport = async () => {
    setIsLoading(true);
    const fileName =
      analyticsOption == 1
        ? `${selectedFilter.replaceAll(" ", "_")}_Report.xlsx`
        : `${selectedFilter2.replaceAll(" ", "_")}_Report.xlsx`;

    // Create a new workbook and add a worksheet
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(accounts);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Generate an XLSX file
    const wbout = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array",
    });

    // const client = filestack.init(process.env.REACT_APP_FILESTACK_API);
    const file = new File([wbout], fileName, {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    try {
      const url = await uploadFileToBackend(file);
      setIsLoading(false);
      setShowDownloadButton(true);
      setDownloadFileUrl(url);
    } catch (err) {
      console.log("err", err);
      setIsLoading(false);
    }

    // client
    //   .upload(file, {})
    //   .then((res) => {
    //     console.log("Uploaded successfully", res);
    //     // window.open(res.url, "_blank");

    //     setIsLoading(false);
    //     setShowDownloadButton(true);
    //     setDownloadFileUrl(res?.url);
    //   })
    //   .catch((error) => {
    //     console.error("Error uploading file: ", error);
    //     setIsLoading(false);
    //   });

    if (analyticsOption == 1) {
      //for accounts reports
    }

    if (analyticsOption == 2) {
      //for activities reports
    }
  };

  const navigate = useNavigate();
  const [accountToUpgrade, setAccountToUpgrade] = useState({});
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const handleEditModalOpen = () => setOpenEditModal(true);
  const handleEditModalClose = () => {
    setOpenEditModal(false);
    setAccountToUpgrade({});
    setShowUpgradeToTrial(false);
    setShowUpgradeToPaid(false);
  };

  const [selectedOption, setSelectedOption] = useState(null);

  const handleSelectOption = (option) => {
    if (!user?.id) {
      navigate("/auth/login");
    }

    setSelectedOption(option);

    handleOpen();
  };
  useEffect(() => {
    console.log("selected option", selectedOption);
  }, [selectedOption]);
  const [showUpgradeToTrial, setShowUpgradeToTrial] = useState(false);
  const [showUpgradeToPaid, setShowUpgradeToPaid] = useState(false);

  const [subscriptionOptions, setSubscriptionOptions] = useState([]);
  useEffect(() => {
    api
      .get("/subscriptions/agent")
      .then((res) => {
        console.log("subscriptions", res?.data);
        setSubscriptionOptions(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const [totalOrderValue, setTotalOrderValue] = useState(0);
  const [openPaymentModal, setOpenPaymentModal] = React.useState(false);
  const handleOpen = () => setOpenPaymentModal(true);
  const handleClose = () => {
    setCouponCode("");
    setIsCouponApplied(false);
    setTotalOrderValue(0);
    setOpenPaymentModal(false);
  };

  useEffect(() => {
    if (selectedOption?.price) {
      setTotalOrderValue(selectedOption?.price);
    }
  }, [selectedOption]);
  const [couponCode, setCouponCode] = useState("");
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  const [couponError, setCouponError] = useState("");
  const useCouponCode = () => {
    if (!couponCode) {
      setCouponError("missing field");
      return;
    }
    api
      .put("/coupons/use", { code: couponCode })
      .then((res) => {
        console.log("res", res?.data);
        let c = res?.data;
        console.log("totalOrderValue ", totalOrderValue);
        console.log(" parseInt(c?.value)", parseInt(c?.value));
        console.log(
          "totalOrderValue - parseInt(c?.value)",
          totalOrderValue - parseInt(c?.value)
        );
        setTotalOrderValue(totalOrderValue - parseInt(c?.value));
        setIsCouponApplied(true);
        toast.success("coupon applied!");
      })
      .catch((err) => {
        console.log(err);
        setCouponError("unable to apply this coupon");
      });
  };
  useEffect(() => {
    setCouponError("");
  }, [couponCode]);

  const [accountToView, setAccountToView] = useState({});

  const [showAccountDetails, setShowAccountDetails] = useState(false);

  const handleOpenAccountDetails = () => setShowAccountDetails(true);
  const handleCloseAccountDetails = () => setShowAccountDetails(false);

  const startTrial = () => {
    api
      .put("/users/start-trial/" + user?.id + "/" + accountToUpgrade?.id)
      .then((res) => {
        console.log("response after creating trial", res?.data);
        fetchUsers();
        handleEditModalClose();
      })
      .catch((err) => console.log(err));
  };

  // Function to check for txn changes
  const checkForTxnChanges = async (mpesaRef) => {
    try {
      api
        .get("/get-mpesa-transaction/" + mpesaRef)
        .then((res) => {
          console.log("response from getting mpesa txn", res.data);
          const newStatus = res?.data?.status;
          console.log("txn check status", newStatus);
          if (newStatus == "Waiting User Action") {
            toast.success("Mpesa request sent. Please check your phone!", {
              autoClose: 3000,
            });
          } else if (newStatus == "Transaction Successful") {
            toast.success(
              "Transaction Successful. Account upgraded successfully!",
              {
                autoClose: 10000,
              }
            );
            clearInterval(intervalId);
            fetchUsers();
          } else {
            toast.error(newStatus, {
              autoClose: 10000,
            });
            clearInterval(intervalId);
            fetchUsers();
          }
        })
        .catch((err) => console.log("err from getting txn", err));
    } catch (error) {
      console.log("txn check error", error);
    }
  };

  const checkoutByAgent = () => {
    const isPhoneValid = validateMpesaPhoneNumber(phoneNumber);
    if (isPhoneValid) {
      console?.log("checkout by agent");
      console.log("account ti upgrades", accountToUpgrade);
      console.log("selected option", selectedOption);
      if (selectedOption?.title) {
        api
          .put(
            `/users/purchase-subscription-mpesa/${accountToUpgrade?.id}/${user?.id}`,
            {
              title: selectedOption?.title,
              phoneNumber: phoneNumber,
              totalOrderValue: totalOrderValue,
            }
          )
          .then((res) => {
            console.log("response from checkout", res?.data);
            // toast.success("Account upgraded successfully!");
            toast.success(
              "Mpesa request sent successfully ✔. Please check mpesa phone!",
              {
                autoClose: 5000, // Set the duration in milliseconds (5 seconds)
              }
            );

            if (
              res?.data?.ref !== undefined &&
              res?.data?.ref !== null &&
              res?.data?.ref !== ""
            ) {
              console.log("txn check initiated", res?.data?.ref);
              intervalId = setInterval(() => {
                checkForTxnChanges(res?.data?.ref);
              }, 5000); // Check txn status every 5 seconds
            } else {
              console.log("could not initiate txn check", res?.data?.ref);
            }

            fetchUsers();
            setOpenPaymentModal(false);
            setOpenEditModal(false);
          })
          .catch((err) => {
            console.log("error", err);
            toast.error("An error occurred while subscribing. " + err);
          });
      }
    } else {
      toast.error("Enter valid phone number!");
    }
  };
  return (
    <Box>
      <ToastContainer />
      <Box
        sx={{
          borderBottom: "1px solid lightgray",
          my: 2,
          display: "flex",
        }}
      >
        <Modal
          open={showAccountDetails}
          onClose={handleCloseAccountDetails}
          sx={{ zIndex: "1000000000" }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              minWidth: 400,
              maxHeight: "90vh",
              overflow: "auto",
              bgcolor: "background.paper",

              boxShadow: 24,
              p: 4,
            }}
          >
            <UserDetailsCard
              user={accountToView}
              handleEditModalOpen={handleEditModalOpen}
              setAccountToUpgrade={setAccountToUpgrade}
              handleCloseAccountDetails={handleCloseAccountDetails}
            />
          </Box>
        </Modal>
        <Modal
          open={openPaymentModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ zIndex: "1000000000" }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Box sx={{}}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6">Order Summary</Typography>
                <IconButton onClick={handleClose}>
                  <ClearIcon />
                </IconButton>
              </Box>

              <Box sx={{ my: 1 }}>
                <Typography variant="body2">Selected Option</Typography>
                <Typography
                  sx={{
                    fontWeight: "500",
                    color: theme.palette.primary.main,
                    fontSize: "1.2rem",
                  }}
                >
                  {selectedOption?.title}
                </Typography>
              </Box>
              <Box sx={{ my: 3, borderBottom: "1px solid lightgray", pb: 5 }}>
                {isCouponApplied ? (
                  <>
                    <Typography>Coupon Applied.</Typography>
                  </>
                ) : (
                  <>
                    <Typography>Coupon Code</Typography>
                    <Box>
                      <Input onChange={(e) => setCouponCode(e.target.value)} />
                      <Button onClick={useCouponCode}>Apply</Button>
                      {couponError && (
                        <Typography sx={{ color: "red" }}>
                          {couponError}
                        </Typography>
                      )}
                    </Box>
                  </>
                )}
              </Box>
              <Box
                sx={{ my: 2, display: "flex", justifyContent: "space-between" }}
              >
                <Typography>Total</Typography>
                <Typography sx={{ fontWeight: "600", fontSize: "1.3rem" }}>
                  Ksh {totalOrderValue}
                </Typography>
              </Box>
              <Typography>Mpesa Phone Number</Typography>
              <Box sx={{ margin: "15px 0" }}>
                <AuthPhoneInputMpesa
                  country={"ke"}
                  isPassword={false}
                  label="Phone Number"
                  placeholder="phone number"
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                  setPhoneNumber={setPhoneNumber}
                  error={phoneNumberError ? true : false}
                  helperText={phoneNumberError}
                />
              </Box>
              <Button
                variant="contained"
                sx={{ width: "100%", mt: 2 }}
                onClick={() => checkoutByAgent()}
              >
                Checkout
              </Button>
            </Box>
          </Box>
        </Modal>
        <Modal open={openEditModal} onClose={handleEditModalClose}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              minWidth: 400,
              maxWidth: "90vw",
              width: "max-content",
              maxHeight: "90vh",
              overflow: "auto",
              bgcolor: "background.paper",
              //   border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Box sx={{ py: 2, px: 3 }}>
              <Typography variant="h5" sx={{ textAlign: "center" }}>
                Account Updgrade
              </Typography>
              {showUpgradeToTrial ? (
                <Box>
                  <Box
                    sx={{
                      mt: 3,
                      display: "flex",
                      flexDirection: "column",
                      gap: "20px",
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{ textAlign: "center", fontSize: "1rem" }}
                    >
                      Starter Pack for 30 Days
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        textAlign: "center",
                        color: theme.palette.primary.main,
                      }}
                    >
                      Free Trial
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "20px",
                        mt: 4,
                      }}
                    >
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={handleEditModalClose}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => {
                          startTrial();
                        }}
                      >
                        Confirm
                      </Button>
                    </Box>
                  </Box>
                </Box>
              ) : showUpgradeToPaid ? (
                <Box sx={{ width: "100%", p: 1 }}>
                  <Typography sx={{ textAlign: "center" }}>
                    Select one of these packages to upgrade
                  </Typography>
                  <Grid container sx={{ my: 2 }}>
                    {subscriptionOptions.map((option, index) => (
                      <Grid xs={9} sm={6} md={4} item>
                        <SubscriptionOption
                          key={index}
                          {...option}
                          onSelect={() => {
                            console.log("handleSelectOption", option);
                            handleSelectOption(option);
                          }}
                          selected={selectedOption === option}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              ) : (
                <Box
                  sx={{
                    mt: 3,
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                    justifyContent: "center",
                  }}
                >
                  {!accountToUpgrade?.trial_done &&
                    !accountToUpgrade?.ontrial && (
                      <>
                        <Button
                          variant="outlined"
                          onClick={() => setShowUpgradeToTrial(true)}
                        >
                          Upgrade to Trial Account
                        </Button>
                        <Typography
                          variant="body2"
                          sx={{ textAlign: "center" }}
                        >
                          OR
                        </Typography>
                      </>
                    )}

                  <Button
                    variant="contained"
                    onClick={() => setShowUpgradeToPaid(true)}
                  >
                    Upgrade to Paid Account
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Modal>
        <Typography
          sx={{
            // border: "1px solid lightgray",
            borderTopLeftRadius: "5px",
            p: 1,
            cursor: "pointer",
            background:
              analyticsOption == 1 ? theme.palette.primary.main : " #fff",
            color: analyticsOption == 1 ? "#fff" : theme.palette.primary.main,
          }}
          onClick={() => setAnalyticsOption(1)}
        >
          All Accounts
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Box>
          {showDownloadButton ? (
            <Box sx={{ display: "flex" }}>
              <a href={downloadFileUrl} target="_blank">
                <Typography>View File</Typography>
              </a>
              <Button
                size="small"
                sx={{ ml: 2 }}
                onClick={() => setShowDownloadButton(false)}
                variant="contained"
              >
                Download again
              </Button>
            </Box>
          ) : (
            <Button
              size="small"
              variant="contained"
              onClick={() => downloadReport()}
            >
              Download Report
            </Button>
          )}
        </Box>
      </Box>

      <Box>
        <Box>
          {" "}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              mb: 3,
              alignItems: "center",
            }}
          >
            <Typography variant="h6">
              {getNormalCase(`${selectedFilter} accounts`)}
            </Typography>
            <Box sx={{ mt: 2 }}>
              <FormControl>
                {/* <InputLabel id="filter-label">Filter</InputLabel> */}
                <Select
                  labelId="filter-label"
                  id="filter"
                  value={selectedFilter}
                  onChange={(e) => setSelectedFilter(e.target.value)}
                >
                  <MenuItem value="all">All Accounts</MenuItem>
                  <MenuItem value="free">Free Accounts</MenuItem>
                  <MenuItem value="paid">Paid Accounts</MenuItem>
                  <MenuItem value="on-trial">Trial Accounts</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow sx={{}}>
                  {Object.keys(accounts[0] || {})
                    ?.filter(
                      (key) =>
                        key !== "profile_image" &&
                        key !== "is_agent_suspended" &&
                        key !== "isSignedUpByGoogle" &&
                        key !== "organization_name" &&
                        key !== "created_on" &&
                        key !== "phone_number" &&
                        key !== "authorized_to_company_details" &&
                        key !== "trial_started_on" &&
                        // key !== "trial_done" &&
                        // key !== "offer_purchased" &&
                        key !== "upgraded_on" &&
                        key !== "last_payment_on" &&
                        key !== "account_expired"
                    )
                    .map((key, index) => (
                      <TableCell
                        sx={{
                          whiteSpace: "nowrap",
                          color: theme.palette.primary.main,
                        }}
                        key={index}
                      >
                        {getNormalCase(key)}
                      </TableCell>
                    ))}
                  <TableCell
                    sx={{
                      whiteSpace: "nowrap",
                      color: theme.palette.primary.main,
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {accounts
                  ?.map(({ lastActionTimeInMilliSeconds, ...rest }) => rest)
                  ?.map((row, index) => (
                    <TableRow key={index}>
                      {Object.keys(row)
                        ?.filter(
                          (key) =>
                            key !== "profile_image" &&
                            key !== "is_agent_suspended" &&
                            key !== "isSignedUpByGoogle" &&
                            key !== "phone_number" &&
                            key !== "organization_name" &&
                            key !== "authorized_to_company_details" &&
                            key !== "created_on" &&
                            key !== "trial_started_on" &&
                            // (key !== "trial_done" ||
                            //   (!row?.onTrial && row?.trial_started_on)) &&
                            // key !== "offer_purchased" &&
                            key !== "upgraded_on" &&
                            key !== "last_payment_on" &&
                            key !== "account_expired"
                        )
                        .map((key, index) => (
                          <TableCell sx={{ whiteSpace: "nowrap" }} key={index}>
                            {typeof row[key] === "boolean"
                              ? row[key]
                                ? "true"
                                : "false"
                              : key === "created_on"
                              ? new Date(row[key])?.toDateString()
                              : row[key]
                              ? row[key]
                              : "N/A"}
                          </TableCell>
                        ))}
                      <TableCell>
                        {row?.account_type == "free" ||
                        (row?.trial_done && row?.offer_purchased == "") ? (
                          <Box>
                            <Button
                              variant="contained"
                              size="small"
                              onClick={() => {
                                handleEditModalOpen();
                                setAccountToUpgrade(row);
                              }}
                            >
                              Edit
                            </Button>
                          </Box>
                        ) : (
                          (row?.account_type == "premium" || row?.ontrial) && (
                            <Box>
                              <Button
                                variant="outlined"
                                size="small"
                                onClick={() => {
                                  setAccountToView(row);
                                  setShowAccountDetails(true);
                                }}
                              >
                                View
                              </Button>
                            </Box>
                          )
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      {/******************  */}
    </Box>
  );
};

export default AgentAnalytics;

const SubscriptionOption = ({
  title,
  description,
  price,
  users,
  leads,
  features,
  onSelect,
}) => {
  const user = useSelector((state) => state.user);
  const theme = useTheme();
  const optionStyle = {
    margin: "16px",
    padding: "16px",
    transition: "box-shadow 0.3s",
    cursor: "pointer",
  };

  return (
    <Card
      style={optionStyle}
      sx={{
        "&:hover": {
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
          backgroundColor: theme.palette.selectedMenuBack.main,
        },
      }}
      onClick={onSelect}
    >
      <CardContent>
        <Box
          sx={{
            minHeight: "65px",
            my: 1,
            // border: "3px solid green"
          }}
        >
          <Typography
            variant="h6"
            component="Box"
            sx={{
              color: theme.palette.heading.main,
              fontWeight: "600",
              fontSize: "1.1rem",
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontWeight: "400",
              fontSize: "0.8rem",
              color: theme.palette.para.main,
              mt: "-0.1rem",
              lineHeight: "15px",
            }}
          >
            {description}
          </Typography>
        </Box>
        <Box
          sx={{
            height: "60px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // border: "2px solid red",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              textAlign: "center",
              fontSize: "1.6rem",
              fontWeight: "600",
            }}
          >
            Ksh {price}
            <sub style={{ fontSize: "1rem" }}>/Year</sub>
          </Typography>
        </Box>

        {/* <Typography color="textSecondary">{users} users</Typography>
          <Typography color="textSecondary">{leads} leads</Typography> */}
        <Box
          sx={{ display: "flex", flexDirection: "column", gap: "10px", mt: 2 }}
        >
          {features.map((feature, index) => (
            <Box key={index} sx={{ display: "flex" }}>
              <Box
                sx={{
                  marginRight: "11px",
                  color: theme.palette.primary.main,
                  fontWeight: "600",
                }}
              >
                ✓
              </Box>
              <Typography variant="p" sx={{ fontSize: "0.9rem" }}>
                {feature}
              </Typography>
            </Box>
          ))}
        </Box>
        {/* <Button variant="contained" color="primary">
            Subscribe
          </Button> */}
      </CardContent>
    </Card>
  );
};
