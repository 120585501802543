import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Opportunities from "./pages/Opportunities";
import Pricing from "./pages/Pricing";
import Stories from "./pages/Stories";
import VerifyAccount from "./pages/VerifyAccount";
import RecoverPassword from "./pages/RecoverPassword";
import LoginContainer from "./components/Login/LoginContainer";
import Auth from "./pages/Auth";
import Dashboard from "./pages/Dashboard";
import { useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Router>
          <Routes>
            <Route path="/billboard" element={<Opportunities />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/stories" element={<Stories />} />
            <Route path="/auth/:mode" element={<Auth />} />
            <Route path="/admin" element={<Auth isAdminLogin={true} />} />
            <Route path="/dashboard/:selectedItem?" element={<Dashboard />} />
            <Route
              path="/reset-password/:userId/:uniqueString"
              element={<RecoverPassword />}
            />
            <Route
              path="/verify-account/:userId/:uniqueString"
              element={<VerifyAccount />}
            />
            <Route path="/" element={<Home />} />
          </Routes>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
