import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Box, Button, Container, IconButton, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { WhatsAppWidget } from "react-whatsapp-widget";

import "react-whatsapp-widget/dist/index.css";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  useMediaQuery,
  TextField,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import styled from "@mui/material/styles/styled";
import DiamondOutlinedIcon from "@mui/icons-material/DiamondOutlined";
import MeetingRoomOutlinedIcon from "@mui/icons-material/MeetingRoomOutlined";
import { Globe, Award, Eye } from "react-feather";
import { api } from "../axios/api";
import AddLeadToNoticeBoardForm from "../components/CRM/NoticeBoard/AddLeadToNoticeBoardForm";
import ViewNoticeBoardLeadDetails from "../components/CRM/NoticeBoard/ViewNoticeBoardLeadDetails";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Ads from "../components/Opoortunities/Ads";

const modalStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: "100000",
};

const paperStyle = {
  backgroundColor: "white",
  boxShadow: "0 3px 6px rgba(0, 0, 0, 0.16)",
  padding: "16px",
  maxWidth: "80%",
  maxHeight: "80%",
  overflow: "auto",
  position: "relative",
  maxWidth: "600px",
};

const Opportunities = () => {
  const theme = useTheme();
  const [data, setData] = useState([]);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [showLeadAddingForm, setShowLeadAddingForm] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedLeadForDetails, setSelectedLeadForDetails] = useState({});
  const [showDetailsOfLead, setShowDetailsOfLead] = useState(false);
  const user = useSelector((state) => state?.user);
  const navigate = useNavigate();
  const [adMediaFiles, setAdMediaFiles] = useState([]);
  const [adChangeInterval, setAddChangeInterval] = useState(6);
  const userSubscriptionData = useSelector((state) => state.subscription_data);
  useEffect(() => {
    // Fetch change interval data
    const fetchData = async () => {
      try {
        const response = await api.get("/ads/fetch-ad-data/" + 1);
        const data = await response.data;
        console.log(data);
        setAddChangeInterval(
          data.data.change_interval ? data.data.change_interval : 2
        );
        setAdMediaFiles(
          data?.data?.media_files?.length > 0 ? data?.data?.media_files : []
        );
      } catch (error) {
        console.error("Error fetching change interval:", error);
      }
    };

    fetchData();
  }, []); // Run once on component mount

  useEffect(() => {
    if (userSubscriptionData) {
      api
        .get("/noticeBoardLeads")
        .then((res) => {
          let authorized_data = res?.data?.filter(
            (s) => s?.authorized_by_admin
          );
          let f2 = authorized_data;
          if (userSubscriptionData?.account_type == "free") {
            console.log("is free");
            f2 = f2?.filter((f) => f?.lead_category == "open");
          }
          console.log("f2", f2);
          setData(f2);
        })
        .catch((err) => console.log(err));
    }
  }, [showLeadAddingForm, userSubscriptionData]);

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredData = data.filter((row) =>
    Object.values(row).some(
      (fieldValue) =>
        typeof fieldValue === "string" &&
        fieldValue.toLowerCase().includes(searchTerm.toLowerCase())
    )
  );
  const cloaseDetailOfLead = () => {
    setShowDetailsOfLead(false);
  };

  useEffect(() => {
    console.log("data>>>>>>>>>>>", data);
  }, [data]);
  return (
    <Box>
      <Navbar />
      <ViewNoticeBoardLeadDetails
        fromHome={true}
        leadData={selectedLeadForDetails}
        open={showDetailsOfLead}
        handleClose={cloaseDetailOfLead}
      />
      <Box sx={{ padding: "0 30px" }}>
        <Box sx={{ minHeight: "200px", margin: " 10px 0 70px 0" }}>
          <Typography
            variant="h4"
            sx={{
              fontWeight: "700",
              color: theme.palette.heading.main,
              textAlign: "center",
              marginBottom: "10px",
            }}
          >
            Opportunities
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontWeight: "500",
              color: theme.palette.para.main,
              marginBottom: "30px",
              textAlign: "center",
              fontSize: "1.1rem",
            }}
          >
            Explore and avail multiple opportunities including tender, merger,
            business sales, etc.
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: { sm: "center", xs: "center" },
              margin: "0px 0 10px 0",
              height: "60px",
              padding: "0px",
            }}
          >
            <TextField
              variant="outlined"
              placeholder="Search opportunities by ref, organization or headline here..."
              value={searchTerm}
              onChange={handleSearchTermChange}
              sx={{
                marginBottom: "20px",
                height: "60px",
                width: { sm: "500px", xs: "280px" },
                "& .MuiOutlinedInput-root": {
                  borderRadius: "999px",
                  padding: "0 5px",
                },
              }}
            />
          </Box>
          <Grid container gap={4}>
            <Grid item xs={12} sm={8}>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  size="small"
                  sx={{ ml: "auto", my: 2 }}
                  variant="contained"
                  onClick={() =>
                    showLeadAddingForm
                      ? setShowLeadAddingForm(false)
                      : showDetailsOfLead
                      ? setShowDetailsOfLead(false)
                      : setShowLeadAddingForm(true)
                  }
                >
                  {showDetailsOfLead ? "Show billboard" : " Add to billboard"}
                </Button>
              </Box>
              {showLeadAddingForm && (
                <Modal
                  open={showLeadAddingForm}
                  onClose={() => setShowLeadAddingForm(false)}
                  style={modalStyle}
                >
                  <Box style={paperStyle}>
                    <AddLeadToNoticeBoardForm
                      setShowLeadAddingForm={setShowLeadAddingForm}
                    />
                  </Box>
                </Modal>
              )}
              {
                // showDetailsOfLead
                false ? (
                  <ViewNoticeBoardLeadDetails
                    fromHome={true}
                    leadData={selectedLeadForDetails}
                  />
                ) : (
                  <Box sx={{ height: "70vh", overflowY: "scroll" }}>
                    <TableContainer
                      component={Paper}
                      style={{
                        marginBottom: theme.spacing(3),
                        border: "none",
                        outline: "none",
                        boxShadow: "none",
                        overflowX: "initial",
                      }}
                    >
                      <Table
                        sx={{ border: "none", outline: "none" }}
                        stickyHeader
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell
                              style={{
                                whiteSpace: "nowrap",
                                padding: "3px 5px",
                                borderBottom: "none",
                                color: theme.palette.primary.main,
                                fontWeight: "600",
                                fontSize: "0.8rem",
                              }}
                            >
                              Access
                            </TableCell>
                            <TableCell
                              style={{
                                whiteSpace: "nowrap",
                                padding: "3px 5px",
                                borderBottom: "none",
                                color: theme.palette.primary.main,
                                fontWeight: "600",
                                fontSize: "0.8rem",
                              }}
                            >
                              Ref
                            </TableCell>
                            <TableCell
                              style={{
                                whiteSpace: "nowrap",
                                padding: "3px 5px",
                                borderBottom: "none",
                                color: theme.palette.primary.main,
                                fontWeight: "600",
                                fontSize: "0.8rem",
                              }}
                            >
                              Organisation
                            </TableCell>
                            <TableCell
                              style={{
                                whiteSpace: "nowrap",
                                padding: "3px 5px",
                                borderBottom: "none",
                                color: theme.palette.primary.main,
                                fontWeight: "600",
                                fontSize: "0.8rem",
                              }}
                            >
                              Opportunity In
                            </TableCell>
                            <TableCell
                              style={{
                                whiteSpace: "nowrap",
                                padding: "3px 5px",
                                borderBottom: "none",
                                color: theme.palette.primary.main,
                                fontWeight: "600",
                                fontSize: "0.8rem",
                              }}
                            >
                              Headline
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        {data
                          ?.sort(
                            (a, b) => b.lead_ref_number - a.lead_ref_number
                          )
                          ?.map((row, index) => (
                            <TableRow
                              key={index}
                              sx={{
                                padding: "0px",
                                margin: "0px",
                                backgroundColor:
                                  index % 2 === 0 &&
                                  theme.palette.colorthree.main,
                                cursor: "pointer",
                                "&:hover": {
                                  boxShadow:
                                    "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                                },
                              }}
                              onClick={() => {
                                if (user?.id) {
                                  setShowDetailsOfLead(true);
                                  setSelectedLeadForDetails(row);
                                } else {
                                  navigate("/auth/login");
                                }
                              }}
                            >
                              <TableCell
                                style={{
                                  borderBottom: "none",
                                  padding: "3px 5px",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <Box sx={{}}>
                                  {row?.lead_category === "open" ? (
                                    <Globe
                                      size={24}
                                      strokeWidth={1}
                                      color="gray"
                                    />
                                  ) : (
                                    <Award
                                      size={24}
                                      strokeWidth={2}
                                      color="#ffc107"
                                    />
                                  )}
                                </Box>
                              </TableCell>
                              <TableCell
                                style={{
                                  borderBottom: "none",
                                  padding: "0px",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <Box sx={{}}>{row?.lead_ref_number}</Box>
                              </TableCell>
                              <TableCell
                                style={{
                                  borderBottom: "none",
                                  padding: "0px",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {row?.organisation}
                              </TableCell>
                              <TableCell
                                style={{
                                  borderBottom: "none",
                                  padding: "0px",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {row?.referral_type}
                              </TableCell>
                              <TableCell
                                style={{
                                  borderBottom: "none",
                                  padding: "0px",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {row?.referral_title}
                              </TableCell>
                            </TableRow>
                          ))}
                      </Table>
                    </TableContainer>
                  </Box>
                )
              }
            </Grid>
            {user?.account_type != "premium" && (
              <Grid
                item
                xs={12}
                sm={3.2}
                md={3}
                sx={{
                  // border: "1px solid lightgray",
                  display: { xs: "none", sm: "flex" },
                  justifyContent: "center",
                  alignItems: "center",
                  // position: "sticky",
                  // top: 0,
                }}
              >
                <Ads
                  mediaFiles={adMediaFiles}
                  changeInterval={adChangeInterval}
                />
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
      {/* WhatsApp floating icon */}{" "}
      <WhatsAppWidget phoneNumber={process.env.REACT_APP_WHATSAPP_NUMBER} />
      <Footer />
    </Box>
  );
};

export default Opportunities;
