import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
  IconButton,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useTheme } from "@emotion/react";
import { api } from "../../../../axios/api";
import Modal from "@mui/material/Modal";
import { ToastContainer, toast } from "react-toastify";
import TransferHistory from "./TransferHistory";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  //   border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const TransferIt = ({ selectedLead, setShowIndividualLeadFeature }) => {
  const user = useSelector((state) => state.user);
  const theme = useTheme();
  const [organizationUsers, setOrganizationUsers] = useState([]);
  const [selectedUsertoTransfer, setSelectedUsertoTransfer] = useState({});
  const [showNoteModal, setShowoteModal] = useState(false);
  const [transferNote, setTransferNote] = useState("");
  const [showTransferHistory, setShowTransferHistory] = useState(false);
  useEffect(() => {
    api
      .get("/getAllUsersInOrganization/" + user?.organization_id)
      .then((res) => {
        console.log("users within organization", res?.data);
        let u = res?.data?.filter((r) => r?.id !== user?.id);
        setOrganizationUsers(u);
      })
      .catch((err) => console.log(err));
  }, []);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const cancelTransfer = () => {
    setSelectedUsertoTransfer({});
    handleClose();
    setTransferNote("");
  };
  const confirmTransfer = () => {
    console.log("user to transfer", selectedUsertoTransfer);
    console.log("note", transferNote);
    let obj = {
      prevOwnerId: user?.id,
      futureOwnerId: selectedUsertoTransfer?.id,
      infoRefNo: selectedLead?.info_reference_number,
      note: transferNote,
    };

    api
      .post("/transferRelationship", obj)
      .then((res) => {
        console.log("res?.data", res?.data);
        api.post("/addRelationshipActivity", {
          relationship_id: selectedLead?.info_reference_number,
          done_by: user?.id,
          action: "Transfer it",
          note: `to ${selectedUsertoTransfer?.name}!`,
        });
        toast.success("succesfully transferred");
        setSelectedUsertoTransfer({});
        handleClose();
        setTransferNote("");
        setShowIndividualLeadFeature(false);
      })
      .catch((err) => {
        console.log(err);
        toast?.error("unable to transfer");
      });
  };

  return (
    <Box>
      <ToastContainer />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ mb: 2 }}
          >
            Enter note
          </Typography>
          <textarea
            style={{ padding: "10px" }}
            placeholder="enter here"
            onChange={(e) => setTransferNote(e.target.value)}
          />
          <Box sx={{ mt: 3, display: "flex", gap: "10px" }}>
            <Button
              color="primary"
              size="small"
              onClick={() => cancelTransfer()}
            >
              Cancel Transfer
            </Button>
            <Button
              variant="contained"
              size="samll"
              color="primary"
              onClick={() => confirmTransfer()}
            >
              Confirm Transfer
            </Button>
          </Box>
        </Box>
      </Modal>
      <Box sx={{ p: "2", display: "flex", justifyContent: "flex-end", mb: 2 }}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() =>
            showTransferHistory
              ? setShowTransferHistory(false)
              : setShowTransferHistory(true)
          }
        >
          {showTransferHistory ? "Transfer" : " View Transfer History"}
        </Button>
      </Box>
      {showTransferHistory ? (
        <>
          <TransferHistory selectedLead={selectedLead} />
        </>
      ) : (
        <>
          {user && selectedLead && user?.id == selectedLead?.owner_id ? (
            <Box
              sx={{
                mt: 2,
                p: 2,
                minHeight: "300px",
                backgroundColor: "white",
              }}
            >
              <Typography variant="body2">
                Select among following users to transfer selected lead.
              </Typography>

              <Box sx={{ mt: 3 }}>
                {organizationUsers?.length > 0 ? (
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={{
                              // color: theme.palette.colortwo.main,
                              fontSize: "0.9rem",
                            }}
                          >
                            Name
                          </TableCell>
                          <TableCell
                            sx={{
                              // color: theme.palette.colortwo.main,
                              fontSize: "0.9rem",
                            }}
                          >
                            Email
                          </TableCell>
                          <TableCell
                            sx={{
                              // color: theme.palette.colortwo.main,
                              fontSize: "0.9rem",
                            }}
                          >
                            Profile
                          </TableCell>
                          <TableCell
                            sx={{
                              // color: theme.palette.colortwo.main,
                              fontSize: "0.9rem",
                            }}
                          >
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {organizationUsers?.map((o) => (
                          <TableRow>
                            <TableCell>{o?.name}</TableCell>
                            <TableCell>{o?.email_address}</TableCell>
                            <TableCell>
                              {o?.profile_type == "employee"
                                ? "partner"
                                : "admin"}
                            </TableCell>
                            <TableCell>
                              <Button
                                color="primary"
                                variant="contained"
                                onClick={() => {
                                  setSelectedUsertoTransfer(o);
                                  handleOpen();
                                }}
                              >
                                Select
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Typography variant="body2">N/A</Typography>
                )}
              </Box>
            </Box>
          ) : (
            <Typography
              variant="body2"
              sx={{ color: theme.palette.primary.main }}
            >
              You are not the owner of the selected lead. You have no authority
              to transfer it.
            </Typography>
          )}
        </>
      )}
    </Box>
  );
};

export default TransferIt;
