import React from "react";
import { Box, Container, Grid, Typography, Link } from "@mui/material";
import { Facebook, Twitter, Instagram } from "react-feather";
import logo from "../utils/images/edge.jpg";
import { useTheme } from "@mui/material/styles";

const Footer = () => {
  const theme = useTheme();
  return (
    <Box
      // sx={{
      //   bgcolor: "#f5f5f5",
      //   py: 8,
      //   backgroundColor: theme.palette.primary.main,
      // }}
      sx={{
        bgcolor: "#f5f5f5",
        py: 8,
        // backgroundImage: `linear-gradient(to top, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
        backgroundColor: theme.palette.primary.main,
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={3} sx={{}}>
          {/* First Column */}
          <Grid item xs={12} sm={6} md={3}>
            <Box
              sx={{
                display: "flex",
                // alignItems: "center",
                // justifyCOntent: "center",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              <img src={logo} alt="Site Logo" height="40" width="110" />
              <Typography
                variant="caption"
                sx={{ ml: 1, color: theme.palette.fontcolor.main }}
              >
                &copy;2023 Site Name
              </Typography>
            </Box>
          </Grid>

          {/* Second Column */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ color: theme.palette.fontcolor.main, fontWeight: "700" }}
            >
              Services
            </Typography>
            <Typography variant="body2" gutterBottom>
              <Link sx={{ color: theme.palette.fontcolor.main }} href="#">
                Sales Management
              </Link>
            </Typography>
            <Typography variant="body2" gutterBottom>
              <Link sx={{ color: theme.palette.fontcolor.main }} href="#">
                Lead Generation
              </Link>
            </Typography>
            <Typography variant="body2" gutterBottom>
              <Link sx={{ color: theme.palette.fontcolor.main }} href="#">
                Opportunity Tracking
              </Link>
            </Typography>
            <Typography variant="body2" gutterBottom>
              <Link sx={{ color: theme.palette.fontcolor.main }} href="#">
                Customer Relationship
              </Link>
            </Typography>
          </Grid>

          {/* Third Column */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ color: theme.palette.fontcolor.main, fontWeight: "700" }}
            >
              Pages
            </Typography>
            <Typography variant="body2" gutterBottom>
              <Link sx={{ color: theme.palette.fontcolor.main }} href="#">
                Opportunities
              </Link>
            </Typography>
            <Typography variant="body2" gutterBottom>
              <Link sx={{ color: theme.palette.fontcolor.main }} href="#">
                Pricing
              </Link>
            </Typography>
            <Typography variant="body2" gutterBottom>
              <Link sx={{ color: theme.palette.fontcolor.main }} href="#">
                Stories
              </Link>
            </Typography>
          </Grid>

          {/* Fourth Column */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ color: theme.palette.fontcolor.main, fontWeight: "700" }}
            >
              Follow Us
            </Typography>
            <Box sx={{ display: "flex" }}>
              <Facebook
                style={{
                  color: theme.palette.fontcolor.main,
                  borderRadius: "50%",
                  padding: "4px",
                  cursor: "pointer",
                }}
              />
              <Twitter
                style={{
                  marginLeft: "8px",
                  color: theme.palette.fontcolor.main,
                  borderRadius: "50%",
                  padding: "4px",
                  cursor: "pointer",
                }}
              />
              <Instagram
                style={{
                  marginLeft: "8px",
                  color: theme.palette.fontcolor.main,
                  borderRadius: "50%",
                  padding: "4px",
                  cursor: "pointer",
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
