import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  useMediaQuery,
} from "@mui/material";
import video from "../../utils/reels/tutorial.mp4";
import posterImage from "../../utils/images/postercrm.png";
import { useTheme } from "@mui/material/styles";
import Filestack from "./Filestack";

const HowToUse = () => {
  const theme = useTheme();
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlay = () => {
    setIsPlaying(!isPlaying);
  };

  return (
    <Box
      sx={{
        width: "100%",
        // minHeight: "300px",
        // border: "2px solid black",
        margin: "100px 0 200px 0",
        marginTop: "-120px",
      }}
    >
      <Container>
        <Box
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: { sm: "70vw", xs: "85vw" },
              width: { md: "70vw", sm: "80vw", xs: "85vw" },
              maxWidth: "1000px",
              height: "55px",
              background: "#E9EEF7",
              borderRadius: "30px 30px 0px 0px",
              position: "relative",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                width: "11.15px",
                height: "12px",
                left: "40px",
                top: "23px",
                background: "#CBD5E1",
                borderRadius: "50%",
                background: theme.palette.colortwo.main,
                display: { xs: "none", sm: "block" },
              }}
            ></Box>
            <Box
              sx={{
                position: "absolute",
                width: "11.15px",
                height: "12px",
                left: "60px",
                top: "23px",
                background: "#CBD5E1",
                borderRadius: "50%",
                background: theme.palette.colortwo.main,
                display: { xs: "none", sm: "block" },
              }}
            ></Box>
            <Box
              sx={{
                position: "absolute",
                width: "11.15px",
                height: "12px",
                left: "80px",
                top: "23px",
                background: "#CBD5E1",
                borderRadius: "50%",
                background: theme.palette.colortwo.main,
                display: { xs: "none", sm: "block" },
              }}
            ></Box>
            <Box
              sx={{
                //   border: "1px solid black",
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h6"
                sx={{ color: theme.palette.heading.main }}
              >
                How to use{" "}
                <span style={{ color: "red", fontWeight: "600" }}>Edge360</span>{" "}
                ?
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              boxSizing: "border-box",
              width: { md: "70vw", sm: "80vw", xs: "85vw" },
              maxWidth: "1000px",
              minHeight: "350px",
              height: "80vh",
              maxHeight: "600px",
              background:
                "linear-gradient(180.09deg, rgba(254, 250, 250, 0.4) 0.07%, rgba(255, 254, 254, 0.1) 94.04%)",
              boxShadow: "0px 4px 24px -1px rgba(0, 0, 0, 0.2)",
              backdropFilter: "blur(20px)",
              position: "relative",
              /* Note: backdrop-filter has minimal browser support */
              // border: "1px solid black",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <Box sx={{ border: "1px solid black" }}> */}{" "}
            <video
              style={{
                maxWidth: "100%",
                height: "90%",
                width: "auto",
              }}
              controls
              poster={posterImage} // replace with the actual path to your thumbnail image
            >
              <source
                src="https://files.edge360crm.com/e2urms60nw6rn3luenv8.mp4"
                //"https://sfo3.digitaloceanspaces.com/edge360crm.com/e2urms60nw6rn3luenv8.mp4"
                // "https://res.cloudinary.com/dosfquxpc/video/upload/v1698521078/e2urms60nw6rn3luenv8.mp4"
                type="video/mp4"
              />
            </video>
            {/* </Box> */}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default HowToUse;
