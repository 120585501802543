import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  IconButton,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
} from "@mui/material";
import { useTheme } from "@mui/material";
import { api } from "../../../../axios/api";
import TransferHistory from "./TransferHistory";
import { useSelector } from "react-redux";

const ViewNotes = ({ selectedLead, setViewMode }) => {
  const [notes, setNotes] = useState([]);
  const user = useSelector((state) => state.user);
  const theme = useTheme();
  console.log("selected lead at lead serial number>>>>>>>>>>>", selectedLead);
  useEffect(() => {
    console.log("runnibg useefeect");
    api
      .get("/leadsNotes/" + selectedLead?.lead_serial_number)
      .then((res) => {
        console.log("res", res?.data);
        setNotes(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);
  const [showTransferHistory, setShowTransferHistory] = useState(false);
  return (
    <Box>
      <Box sx={{ p: "2", display: "flex", justifyContent: "flex-end", mb: 2 }}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          sx={{ mt: { xs: 3, sm: 1 }, mr: 2 }}
          onClick={() => setViewMode(false)}
        >
          Add Note
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          sx={{ mt: { xs: 3, sm: 1 } }}
          onClick={() =>
            showTransferHistory
              ? setShowTransferHistory(false)
              : setShowTransferHistory(true)
          }
        >
          {showTransferHistory ? "View Leads Notes" : " View Transfer Notes"}
        </Button>
      </Box>
      {showTransferHistory ? (
        <>
          <TransferHistory selectedLead={selectedLead} />
        </>
      ) : (
        <>
          <Box
            sx={{ mt: 2, display: "flex", alignItems: "center", gap: "20px" }}
          >
            <Typography variant="p">Total No. of Notes= </Typography>
            <Typography
              variant="p"
              sx={{
                fontSize: "1.3rem",
                fontWeight: "600",
                // color: theme.palette.primary.main,
              }}
            >
              {notes?.length}
            </Typography>
          </Box>
          <Box sx={{ mt: 2, p: 2, background: "white" }}>
            <Typography variant="h6" sx={{ color: theme.palette.heading.main }}>
              Notes for the lead: {selectedLead?.contact_person}
            </Typography>
            <Box sx={{ mt: 3 }}>
              {notes?.map((n, i) => (
                <NoteBox note={n} i={i} />
              ))}
            </Box>
          </Box>
          {/* <Box sx={{ mt: 3 }}>
            {notes?.length > 0 ? (
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          // color: theme.palette.colortwo.main,
                          fontSize: "0.9rem",
                        }}
                      >
                        ID
                      </TableCell>
                      <TableCell
                        sx={{
                          // color: theme.palette.colortwo.main,
                          fontSize: "0.9rem",
                        }}
                      >
                        Upload Date
                      </TableCell>
                      <TableCell
                        sx={{
                          // color: theme.palette.colortwo.main,
                          fontSize: "0.9rem",
                        }}
                      >
                        Uploaded By
                      </TableCell>
                      <TableCell
                        sx={{
                          // color: theme.palette.colortwo.main,
                          fontSize: "0.9rem",
                        }}
                      >
                        Note
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {notes?.map((o, i) => (
                      <NotesRow o={o} i={i} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer> 
            ) : (
              <Typography>N/A</Typography>
            )}
          </Box> */}
        </>
      )}
    </Box>
  );
};

export default ViewNotes;

const NoteBox = ({ note, i }) => {
  const [from, setFrom] = useState({});
  const user = useSelector((state) => state.user);
  const theme = useTheme();

  useEffect(() => {
    api
      .get("/getUserById/" + note?.uploaded_by)
      .then((res) => setFrom(res?.data))
      .catch((err) => console.log(err));
  }, []);
  return (
    <Box
      sx={{
        mb: 2,
        p: 1,
        background: theme.palette.selectedMenuBack.main,
        borderRadius: "3px",
        maxWidth: "500px",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Typography
          variant="p"
          sx={{ my: 0.5, color: "gold", background: "white", p: 0.5 }}
        >
          Star {note?.happiness_rating}
        </Typography>
      </Box>
      <Box>
        <Typography sx={{ color: theme.palette.primary.main }}>
          {note?.note}
        </Typography>
      </Box>
      <Box
        sx={{
          mt: 1,
          display: "flex",
          justifyContent: "space-between",
          gap: "10px",
        }}
      >
        <Typography variant="body2" sx={{ color: theme.palette.para?.main }}>
          {from?.id == user?.id ? "You" : from?.name}
        </Typography>
        <Typography variant="body2" sx={{ color: theme.palette.para?.main }}>
          {new Date(note?.date_of_upload)?.toDateString()}
        </Typography>
      </Box>
    </Box>
  );
};
// const NotesRow = ({ o, i }) => {
//   const [from, setFrom] = useState({});

//   useEffect(() => {
//     api
//       .get("/getUserById/" + o?.uploaded_by)
//       .then((res) => setFrom(res?.data))
//       .catch((err) => console.log(err));
//   }, []);
//   return (
//     <TableRow>
//       <TableCell>{i + 1}</TableCell>
//       <TableCell>{new Date(o?.date_of_upload)?.toDateString()}</TableCell>
//       <TableCell>{from?.name}</TableCell>
//       <TableCell>{o?.note}</TableCell>
//     </TableRow>
//   );
// };
