import React, { useEffect, useState } from "react";
import { Formik, Field, Form, FormikConsumer } from "formik";
import * as Yup from "yup";
import {
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
  Button,
  Grid,
  Box,
  CircularProgress,
} from "@mui/material";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { api } from "../../../axios/api";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AES from "crypto-js/aes";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  email: Yup.string().required("Required"),
  // password: Yup.string().required("Required"),
  phone_number: Yup.string().required("Required"),
});

const AddUserForm = ({ editOptions, setShowAddForm }) => {
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const user = useSelector((state) => state.user);
  const [authorizedToCompany, setAuthorizedToCompany] = useState(true);
  const [authorizedToCompanyError, setAuthorizedToCompanyError] = useState("");

  const [initialValues, setInitialValues] = useState(
    editOptions
      ? {
          name: editOptions?.name,
          email: editOptions?.email_address,
          password: "",
          phone_number: editOptions?.phone_number,
          profile_type: "employee",
          authorized_to_company_details:
            editOptions?.authorized_to_company_details,
        }
      : {
          name: "",
          email: "",
          password: "",
          phone_number: "",
          profile_type: "employee",
          authorized_to_company_details: "true",
        }
  );

  useEffect(() => {
    console.log("user at add contact", user);
  }, [user]);
  useEffect(() => {
    console.log("edit options at add supplier form", editOptions);
    setAuthorizedToCompany(editOptions?.authorized_to_company_details);
  }, [editOptions]);
  useEffect(() => {
    setAuthorizedToCompanyError("");
  }, [authorizedToCompany]);
  useEffect(() => {
    console.log("authorizedToCompanyError", authorizedToCompanyError);
  }, [authorizedToCompanyError]);
  const onSubmit = (values, { resetForm }) => {
    console.log("running on submit");
    setLoading(true);
    console.log(values);
    console.log("authorizedToCompany", authorizedToCompany);
    console.log("authorizedToCompanyError", authorizedToCompanyError);
    if (!authorizedToCompany) {
      console.log("error occur");
      setAuthorizedToCompanyError("select one");
      setLoading(false);
      return;
    }
    var encPass = AES.encrypt(
      values.password,
      process.env.REACT_APP_CRYPTO_JS_KEY
    ).toString();

    // return;
    if (editOptions?.id) {
      let body = {
        name: values.name,
        email_address: values.email,
        phone_number: values.phone_number,
        authorized_to_company_details: authorizedToCompany,
      };
      if (showResetPassword) {
        body = { ...body, password: encPass };
      }
      api
        .put("/updateUserByAdmin/" + editOptions?.id, body)
        .then((res) => {
          console.log("response from posting content", res?.data);
          toast.success("user updated successfully!");
          setShowAddForm(false);
          setLoading(false);
          resetForm();
        })
        .catch((err) => {
          console.log("err", err);
          setLoading(false);
          toast.error("error in adding contact");
        });
    } else {
      let body = {
        name: values.name,
        email_address: values.email,
        password: encPass,
        phone_number: values.phone_number,
        profile_type: values.profile_type,
        authorized_to_company_details: authorizedToCompany,
      };
      api
        .post("/addUserByAdmin", body)
        .then((res) => {
          console.log("response from posting content", res?.data);
          toast.success("contact added successfully!");
          resetForm();
          setLoading(false);
          setTimeout(() => {
            setShowAddForm(false);
          }, 2000);
        })
        .catch((err) => {
          if (err?.response?.status == 409)
            toast.error("Email already registered in our system");
          else toast.error("error in adding contact");
          setLoading(false);
        });
    }
  };
  const cancelForm = () => {
    setShowAddForm(false);
  };
  const [showResetPassword, setShowResetPassword] = useState(false);
  return (
    <Box sx={{ padding: "30px 0px" }}>
      <ToastContainer />
      {/* <Typography
        variant="h6"
        sx={{
          color: theme.palette.heading.main,
          fontSize: "1.3rem",
          fontWeight: "600",
        }}
      >
        Add Contact
      </Typography> */}
      <Box
        sx={{
          backgroundColor: theme.palette.background.main,
          padding: "20px",
          borderRadius: "5px",
          marginTop: "20px",
          maxWidth: "700px",
        }}
      >
        <Typography
          variant="body2"
          sx={{ margin: "10px 0 30px 0", color: theme.palette.para.main }}
        >
          Fill up the form with user data.
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ errors, touched }) => (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Field
                    name="name"
                    as={TextField}
                    label="Name"
                    fullWidth
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                  />
                </Grid>

                <Grid item xs={12} sm={editOptions?.id ? 12 : 6}>
                  <Field
                    disabled={editOptions?.id}
                    name="email"
                    as={TextField}
                    label="Email"
                    fullWidth
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </Grid>
                {editOptions?.id && !showResetPassword && (
                  <Grid item xs={12} sm={editOptions?.id ? 12 : 6}>
                    <Button onClick={() => setShowResetPassword(true)}>
                      Reset Password
                    </Button>
                  </Grid>
                )}
                {(!editOptions?.id || showResetPassword) && (
                  <Grid item xs={12} sm={6}>
                    <Field
                      name="password"
                      as={TextField}
                      label="Password"
                      fullWidth
                      error={touched.password && Boolean(errors.password)}
                      helperText={touched.password && errors.password}
                    />
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Field
                    name="phone_number"
                    as={TextField}
                    label="Phone Number"
                    fullWidth
                    error={touched.phone_number && Boolean(errors.phone_number)}
                    helperText={touched.phone_number && errors.phone_number}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="p"
                    sx={{ color: theme.palette.heading.main }}
                  >
                    Authorize to view company's performance dashboard
                  </Typography>
                  <FormikConsumer>
                    {({ values }) => (
                      <RadioGroup
                        name="authorized_to_company_details"
                        value={authorizedToCompany}
                        onChange={(event) =>
                          setAuthorizedToCompany(event.target.value)
                        }
                        row
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="Yes"
                          defaultChecked={
                            values.authorized_to_company_details == "true"
                          }
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="No"
                          defaultChecked={
                            values.authorized_to_company_details == "false"
                          }
                        />
                      </RadioGroup>
                    )}
                  </FormikConsumer>
                  {authorizedToCompanyError && (
                    <Typography variant="body2" sx={{ color: "red" }}>
                      {authorizedToCompanyError}
                    </Typography>
                  )}
                </Grid>

                <Grid item xs={12} sm={4}></Grid>
                <Grid item xs={12} sm={4}></Grid>
                {/* <Grid item xs={12}>
                  <Field name="type">
                    {({ field }) => (
                      <RadioGroup {...field} row>
                        <FormControlLabel
                          value="supplier"
                          control={<Radio defaultChecked={true} />}
                          label="Supplier"
                        />
                        <FormControlLabel
                          value="consignee"
                          control={<Radio />}
                          label="Consignee"
                        />
                      </RadioGroup>
                    )}
                  </Field>
                </Grid> */}
                <Grid item xs={12}>
                  {" "}
                  {loading && <CircularProgress sx={{}} />}
                </Grid>

                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    // color={theme.palette.colortwo.main}
                    sx={{
                      backgroundColor: theme.palette.colortwo.main,
                      color: theme.palette.whiteFont.main,
                    }}
                    type="submit"
                    size="small"
                  >
                    {editOptions?.id ? "Update" : "Submit"}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => cancelForm()}
                    sx={{
                      marginLeft: "10px",
                      backgroundColor: theme.palette.colortwo.main,
                      color: theme.palette.whiteFont.main,
                    }}
                    size="small"
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default AddUserForm;
