import React, { useState, useEffect } from "react";
import { Box, Button } from "@mui/material";

const Ads = ({ mediaFiles, changeInterval }) => {
  const [currentAdIndex, setCurrentAdIndex] = useState(0);

  useEffect(() => {
    console.log("mediaFiles, changeInterval", mediaFiles, changeInterval);
    const intervalId = setInterval(() => {
      setCurrentAdIndex((prevIndex) => (prevIndex + 1) % mediaFiles.length);
    }, changeInterval * 1000);

    return () => clearInterval(intervalId);
  }, [mediaFiles, changeInterval]);

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: "2vh",
        riht: "20px",
        width: "20vw",
        height: "80vh",
        overflow: "hidden",
        zIndex: 1000, // Adjust the z-index as needed
        display: "flex",
        alignItems: "center",
      }}
    >
      {mediaFiles.length > 0 && (
        <a
          href={mediaFiles[currentAdIndex]?.detail_url}
          target="_blank"
          // style={{ position: "absolute", bottom: "10px", right: "10px" }}
        >
          <Box sx={{ position: "relative" }}>
            {mediaFiles[currentAdIndex]?.type === "image" && (
              <img
                src={mediaFiles[currentAdIndex]?.url}
                alt={`Ad ${currentAdIndex}`}
                style={{ width: "100%", height: "auto" }}
              />
            )}
            {mediaFiles[currentAdIndex]?.type === "video" && (
              <video
                controls
                // width="400"
                // height="300"
                style={{ maxHeight: "100%", maxWidth: "100%" }}
              >
                <source
                  src={mediaFiles[currentAdIndex]?.url}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            )}

            {/* <a
            href={mediaFiles[currentAdIndex]?.detail_url}
            target="_blank"
            style={{ position: "absolute", bottom: "10px", right: "10px" }}
          >
            <Button variant="contained" size="small" sx={{}}>
              Click for details
            </Button>
          </a> */}
          </Box>
        </a>
      )}
    </Box>
  );
};

export default Ads;
