import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Typography,
  Box,
  IconButton,
  Tooltip,
} from "@mui/material";
import AddCircle from "@mui/icons-material/AddCircle";
import { useTheme } from "@mui/material";
import { api } from "../../../axios/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";

const AddRelationshipForm = ({
  editOptions,
  setShowAddForm,
  organizationInformation,
}) => {
  const user = useSelector((state) => state.user);
  useEffect(() => {
    console.log("user at add prospect form", user);
  }, [user]);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const theme = useTheme();
  const [organizationName, setOrganizationName] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [primaryPhoneNumber, setPrimaryPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [whatIKnow, setWhatIKnow] = useState("");
  const [whyTheyimportant, setWhyTheyImportant] = useState("");
  const [whatTheyValue, setWhatTheyValue] = useState("");

  const socialMediaOptions = [
    "Facebook",
    "Twitter",
    "Instagram",
    "LinkedIn",
    "Website",
    // Add more social media platforms as needed
  ];

  let socLink = {
    socialMedia: socialMediaOptions[0],
    accountLink: "",
  };
  const [socialLinks, setSocialLinks] = useState([]);
  const [hasSocialError, setHasSocialError] = useState([]);
  const addSocialInput = (e) => {
    e.preventDefault();
    setSocialLinks([...socialLinks, socLink]);
    console.log("have set");
  };
  useEffect(() => {
    console.log("changed  social links", socialLinks);
  }, [socialLinks]);

  const [primaryPhoneNumberError, setPrimaryPhoneNumberError] = useState("");
  const [organizationNameError, setOrganizationNameError] = useState("");
  const [contactPersonError, setContactPersonError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [cityError, setCityError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [whatIKnowError, setWhatIKnowError] = useState("");
  const [whatTheyValueError, setWhatTheyValueError] = useState("");
  const [whyTheyImportantError, setWhyTheyImportantError] = useState("");

  useEffect(() => {
    console.log("edit options", editOptions);
    if (editOptions?.info_reference_number) {
      setOrganizationName(editOptions?.organization_name);
      setContactPerson(editOptions?.contact_person);
      setPrimaryPhoneNumber(editOptions?.primary_phone_number);
      setEmail(editOptions?.email);
      setCity(editOptions?.city);
      setCountry(editOptions?.country);
      setWhatIKnow(editOptions?.what_i_know);
      setWhyTheyImportant(editOptions?.why_they_important);
      setWhatTheyValue(editOptions?.what_they_value);
      setSocialLinks(editOptions?.social_media_links);
    }
  }, [editOptions]);

  const onSubmit = (data) => {
    // console.log("social links", socialLinks);
    // console.log("social links Errors", hasSocialError);
    // return;
    let isErrorFound = false;
    if (!primaryPhoneNumber) {
      setPrimaryPhoneNumberError("required");
      isErrorFound = true;
    }
    if (hasSocialError) {
      isErrorFound = true;
    }
    if (!organizationName) {
      setOrganizationNameError("required");
      isErrorFound = true;
    }
    if (!contactPerson) {
      setContactPersonError("required");
      isErrorFound = true;
    }
    if (!email) {
      setEmailError("required");
      isErrorFound = true;
    }

    if (!city) {
      setCityError("required");
      isErrorFound = true;
    }
    if (!country) {
      setCountryError("required");
      isErrorFound = true;
    }
    if (!whatTheyValue) {
      setWhatTheyValueError("required");
      isErrorFound = true;
    }
    if (!whatIKnow) {
      setWhatIKnowError("required");
      isErrorFound = true;
    }
    if (!whyTheyimportant) {
      setWhyTheyImportantError("required");
      isErrorFound = true;
    }
    if (isErrorFound) {
      return;
    }
    data.primary_phone_number = primaryPhoneNumber;
    data.social_media_links = socialLinks;
    data.why_they_important = whyTheyimportant;

    console.log(data); // You can do whatever you want with the form data here
    console.log("data ", data);
    // return;
    if (editOptions?.info_reference_number) {
      data.organization_name = organizationName;
      data.contact_person = contactPerson;
      data.primary_phone_number = primaryPhoneNumber;
      data.email = email;
      data.city = city;
      data.country = country;
      data.why_they_important = whyTheyimportant;
      data.what_they_value = whatTheyValue;
      data.what_i_know = whatIKnow;

      api
        .post("/editRelationship/" + editOptions?.info_reference_number, data)
        .then((res) => {
          console.log("res?.data prospect", res?.data);
          toast?.success("successfully updated!");
          setShowAddForm(false);
        })
        .catch((err) => {
          console.log(err);
          toast?.error("Data not updated, try again later");
        });
    } else {
      api
        .post("/createRelationship", { ...data, owner_id: user?.id })
        .then((res) => {
          console.log("res?.data prospect", res?.data);
          toast?.success("successfully added!");
          setShowAddForm(false);
        })
        .catch((err) => {
          console.log("message", err?.message);
          console.log(err?.message == "Request failed with status code 409");
          if (err?.message == "Request failed with status code 409") {
            toast.error(
              "relationship with this primary email or email already exist in your organization!"
            );
          } else {
            toast.error("Data not updated, try again later");
          }
        });
    }
  };
  const handleSocialLinkChange = (e, index, fieldName) => {
    const updatedSocialLinks = [...socialLinks];
    updatedSocialLinks[index] = {
      ...updatedSocialLinks[index],
      [fieldName]: e.target.value,
    };
    setSocialLinks(updatedSocialLinks);
  };
  useEffect(() => {
    let hasError = false;
    socialLinks.forEach((socialLink) => {
      if (!socialLink.socialMedia || !socialLink.accountLink) {
        hasError = true;
        return;
      }
    });
    setHasSocialError(hasError);
  }, [socialLinks]);

  const options = [
    {
      value: 1,
      title: "Business Referrals source",
      description:
        "This person can help me find new business opportunities that I might not have otherwise encountered. This person is either a key decision maker or can help me gain access to key decision makers to secure business or gain support for my plans.",
    },
    {
      value: 2,
      title: "Industry insights",
      description:
        "Through this person, I can gain access to valuable industry knowledge, trends, and insights that can keep me informed and competitive in my work.",
    },
    {
      value: 3,
      title: "Mentorship benefit",
      description:
        "This person can give me guidance and advice because they have been through similar experiences. This person can help me refine skills such as negotiation, leadership, and problem-solving.",
    },
    {
      value: 4,
      title: "Brand visibility",
      description:
        "This person has the potential to increase my visibility and make me more recognizable and influential. This person is good for my social capital.",
    },
    {
      value: 5,
      title: "Partnership and collaboration",
      description:
        "I can combine resources and expertise with this person to achieve common goals.",
    },
  ];
  const handleWhyImpChange = (event) => {
    event?.preventDefault();
    console.log("running handleWhyImpChange");
    console.log(event);
    // alert(event.target.value);
    setWhyTheyImportant(event.target.value);
  };
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        background: theme.palette.background.main,
        marginTop: "20px",
        padding: "20px",
        maxWidth: "700px",
      }}
    >
      <ToastContainer />
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        {" "}
        <Typography
          variant="h6"
          sx={{ color: theme.palette.heading.main, mt: 1, mb: 3 }}
        >
          Enter relationship details
        </Typography>
        <Typography variant="p" sx={{ color: theme.palette.para.main }}>
          Date: {new Date(Date.now()).toDateString()}
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography
            variant="body2"
            sx={{ color: theme.palette.heading.main, mb: 1 }}
          >
            Organization
          </Typography>
          <TextField
            {...register("organization_name")}
            value={organizationName}
            onChange={(e) => setOrganizationName(e.target.value)}
            // label="Organization Name"
            // error={!!errors.organization_name}
            // helperText={errors.organization_name?.message}
            fullWidth
          />
          {organizationNameError && (
            <Typography
              varaint="body2"
              sx={{
                color: "red",
                mt: 0.4,
                fontSize: "0.8rem",
                fontWeight: "300",
              }}
            >
              {organizationNameError}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            variant="body2"
            sx={{ color: theme.palette.heading.main, mb: 1 }}
          >
            Contact Person
          </Typography>
          <TextField
            {...register("contact_person")}
            // label="Contact Person"
            // error={!!errors.contact_person}
            // helperText={errors.contact_person?.message}
            fullWidth
            value={contactPerson}
            onChange={(e) => setContactPerson(e.target.value)}
          />
          {contactPersonError && (
            <Typography
              varaint="body2"
              sx={{
                color: "red",
                mt: 0.4,
                fontSize: "0.8rem",
                fontWeight: "300",
              }}
            >
              {contactPersonError}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            variant="body2"
            sx={{ color: theme.palette.heading.main, mb: 1 }}
          >
            Primary phone number
          </Typography>
          <PhoneInput
            // {...register("primary_phone_number", {
            //   required: "Primary phone number is required",
            // })}
            disabled={editOptions?.info_reference_number ? true : false}
            value={primaryPhoneNumber}
            onChange={(value) => setPrimaryPhoneNumber(value)}
            country={"us"}
            inputProps={{
              name: "primary_phone_number",
              required: "Primary phone number is required",
            }}
            fullWidth
            inputStyle={{
              border: "none",
              outline: "none",
              width: "100%",
              paddingLeft: "47px",
              fontWeight: "300",
              fontFamily: "inherit",
            }}
            searchStyle={{
              border: "1px solid black",
              fontWeight: "300",
              fontFamily: "inherit",
            }}
            containerStyle={{
              flex: "1",
              border: "1px solid lightgray",
              borderRadius: "4px",
              padding: "4px 4px",
            }}
            buttonStyle={{
              // height: "45px",
              borderRadius: "4px",

              padding: "0 5px",
              backgroundColor: "white",
              border: "none",
              outline: "none",
            }}
            dropdownStyle={{
              marginTop: "0",
              borderRadius: "4px",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              border: "1px solid #ced4da",
            }}
          />
          {primaryPhoneNumberError && (
            <Typography
              varaint="body2"
              sx={{
                color: "red",
                mt: 0.4,
                fontSize: "0.8rem",
                fontWeight: "300",
              }}
            >
              {primaryPhoneNumberError}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            variant="body2"
            sx={{ color: theme.palette.heading.main, mb: 1 }}
          >
            Email
          </Typography>
          <TextField
            {...register("email")}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            // label="Email"
            // error={!!errors.email}
            // helperText={errors.email?.message}
            fullWidth
            disabled={editOptions?.info_reference_number ? true : false}
          />
          {emailError && (
            <Typography
              varaint="body2"
              sx={{
                color: "red",
                mt: 0.4,
                fontSize: "0.8rem",
                fontWeight: "300",
              }}
            >
              {emailError}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            variant="body2"
            sx={{ color: theme.palette.heading.main, mb: 1 }}
          >
            City
          </Typography>
          <TextField
            fullWidth
            {...register("city")}
            // label="City"
            // error={!!errors.city}
            // helperText={errors.city?.message}
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
          {cityError && (
            <Typography
              varaint="body2"
              sx={{
                color: "red",
                mt: 0.4,
                fontSize: "0.8rem",
                fontWeight: "300",
              }}
            >
              {cityError}
            </Typography>
          )}
        </Grid>{" "}
        <Grid item xs={12} sm={6}>
          <Typography
            variant="body2"
            sx={{ color: theme.palette.heading.main, mb: 1 }}
          >
            Country
          </Typography>
          <TextField
            fullWidth
            {...register("country")}
            // label="Country"
            // error={!!errors.country}
            // helperText={errors.country?.message}
            value={country}
            onChange={(e) => setCountry(e.target.value)}
          />
          {countryError && (
            <Typography
              varaint="body2"
              sx={{
                color: "red",
                mt: 0.4,
                fontSize: "0.8rem",
                fontWeight: "300",
              }}
            >
              {countryError}
            </Typography>
          )}
        </Grid>{" "}
        <Grid item xs={12} sm={6}>
          <Typography
            variant="body2"
            sx={{ color: theme.palette.heading.main, mb: 1 }}
          >
            What do I know about this person?
          </Typography>
          <TextField
            fullWidth
            {...register("what_i_know")}
            // label="Country"
            // error={!!errors.country}
            // helperText={errors.country?.message}
            value={whatIKnow}
            onChange={(e) => setWhatIKnow(e.target.value)}
          />
          {whatIKnowError && (
            <Typography
              varaint="body2"
              sx={{
                color: "red",
                mt: 0.4,
                fontSize: "0.8rem",
                fontWeight: "300",
              }}
            >
              {whatIKnowError}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            variant="body2"
            sx={{ color: theme.palette.heading.main, mb: 1 }}
          >
            Why are they important to the business?
          </Typography>
          <Select
            fullWidth
            value={whyTheyimportant}
            onChange={handleWhyImpChange}
          >
            <MenuItem value="">Select most applicable</MenuItem>
            {options.map((option) => (
              // <Tooltip key={option.value} title={option.description} arrow>
              <MenuItem value={option.title}>
                <Tooltip title={option.description} arrow>
                  <span>{option.title}</span>
                </Tooltip>
              </MenuItem>
              // </Tooltip>
            ))}
          </Select>
          {whyTheyImportantError && (
            <Typography
              varaint="body2"
              sx={{
                color: "red",
                mt: 0.4,
                fontSize: "0.8rem",
                fontWeight: "300",
              }}
            >
              {whyTheyImportantError}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            variant="body2"
            sx={{ color: theme.palette.heading.main, mb: 1 }}
          >
            What is important to this person? What do they value?
          </Typography>
          <TextField
            fullWidth
            {...register("what_they_value")}
            // label="Country"
            // error={!!errors.country}
            // helperText={errors.country?.message}
            value={whatTheyValue}
            onChange={(e) => setWhatTheyValue(e.target.value)}
          />
          {whatTheyValueError && (
            <Typography
              varaint="body2"
              sx={{
                color: "red",
                mt: 0.4,
                fontSize: "0.8rem",
                fontWeight: "300",
              }}
            >
              {whatTheyValueError}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={12}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              variant="body2"
              sx={{ color: theme.palette.heading.main, mb: 1 }}
            >
              Social media Links
            </Typography>
            <IconButton onClick={(e) => addSocialInput(e)}>
              <AddCircle />
            </IconButton>
          </Box>
          {hasSocialError && (
            <Typography
              varaint="body2"
              sx={{
                color: "red",
                mt: 0.4,
                fontSize: "0.8rem",
                fontWeight: "300",
                mb: 1,
              }}
            >
              {"Fill up all values"}
            </Typography>
          )}

          <Box sx={{}}>
            {socialLinks?.length > 0 &&
              socialLinks?.map((social, index) => (
                <Box sx={{ display: "flex", gap: "5px", mb: 2 }} key={index}>
                  {/* <TextField
                    fullWidth
                    placeholder="Social Media"
                    value={social.socialMedia}
                    onChange={(e) =>
                      handleSocialLinkChange(e, index, "socialMedia")
                    }
                  /> */}
                  <Select
                    fullWidth
                    placeholder="Social Media"
                    value={social.socialMedia}
                    onChange={(e) =>
                      handleSocialLinkChange(e, index, "socialMedia")
                    }
                  >
                    {/* <MenuItem value="">Select a Social Media</MenuItem> */}
                    {socialMediaOptions
                      //   ?.filter((platform) => {
                      //     let res = true;
                      //     if (socialLinks?.length > 0) {
                      //       socialLinks?.forEach((s) => {
                      //         if (s?.socialMedia == platform) {
                      //           res = false;
                      //         }
                      //       });
                      //     }
                      //     return res;
                      //   })
                      .map((platform) => (
                        <MenuItem key={platform} value={platform}>
                          {platform}
                        </MenuItem>
                      ))}
                  </Select>
                  <TextField
                    placeholder="Account link"
                    fullWidth
                    value={social.accountLink}
                    onChange={(e) =>
                      handleSocialLinkChange(e, index, "accountLink")
                    }
                  />
                </Box>
              ))}
          </Box>
        </Grid>
      </Grid>
      <Button variant="contained" type="submit" sx={{ mt: 3 }}>
        Submit
      </Button>
    </form>
  );
};

export default AddRelationshipForm;
