import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Box,
  Button,
  IconButton,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { api } from "../../../../axios/api";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material";
import ViewNotes from "./ViewNNotes";

const AddNote = ({ selectedLead }) => {
  const [allNotes, setAllNotes] = useState([]);
  useEffect(() => {
    api
      .get("/relationshipsNotes/" + selectedLead?.info_reference_number)
      .then((res) => {
        console.log("res", res?.data);
        setAllNotes(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);
  const theme = useTheme();
  const [note, setNote] = useState("");
  const [viewMode, setViewMode] = useState(false);
  const user = useSelector((state) => state.user);
  const submitNote = () => {
    if (note?.length < 1) {
      return toast.error(`Note field can’t be empty`);
    }

    let obj = {
      relationship_id: selectedLead?.info_reference_number,
      uploaded_by: user?.id,
      note,
    };
    api
      .post("/addRelationshipNote", obj)
      .then((res) => {
        console.log("response", res?.data);
        setNote("");
        api.post("/addRelationshipActivity", {
          relationship_id: selectedLead?.info_reference_number,
          done_by: user?.id,
          action: "Note added!",
          note: `Total Notes: ${allNotes?.length}!`,
        });
        toast.success("note added successfully");
      })
      .catch((err) => toast.error("unable to add note"));
  };
  return (
    <Box
      sx={{
        background: "#fff",
        p: 3,
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        mt: 3,
      }}
    >
      <ToastContainer />
      {viewMode ? (
        <ViewNotes setViewMode={setViewMode} />
      ) : (
        <>
          {" "}
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6" sx={{ color: theme.palette.primary.main }}>
              Notes
            </Typography>
            <Button variant="contained" onClick={() => setViewMode(!viewMode)}>
              {viewMode ? "Add a Note" : "View Notes"}
            </Button>
          </Box>
          <Typography variant="body2" sx={{ ml: 1, fontWeight: "600" }}>
            Enter Note
          </Typography>
          <Box sx={{ display: "flex" }}>
            <TextField
              placeholder="enter here..."
              sx={{
                width: "100%",
                padding: "10px",
                fontFamily: "inherit",
              }}
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </Box>
          <Button
            sx={{ width: "max-content" }}
            variant="contained"
            onClick={() => submitNote()}
          >
            Submit
          </Button>
        </>
      )}
    </Box>
  );
};

export default AddNote;
