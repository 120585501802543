import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import { Box, Button } from "@mui/material";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setUser, setToken } from "../actions/userActions";
import { useNavigate } from "react-router-dom";
import { api } from "../axios/api";
// import DashboardContainer from "../components/CRM/DashboardContainer";
import DashboardLayout from "../layout/CRMLayout";
import DashboardContent from "../container/CRMContent";

const Dashboard = () => {
  const user = useSelector((state) => state.user);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    api
      .get("/getUserData")
      .then((res) => {
        console.log("response from getting user", res.data);
        dispatch(setUser(res.data));
        // setIsCompanyDataExist(res?.data?.company_id ? true : false);
      })
      .catch((err) => console.log("err", err));
  }, []);
  const logout = (e) => {
    e.preventDefault();
    dispatch(setUser({}));
    dispatch(setToken(""));
    navigate("/auth/login");
    localStorage.setItem("user", null);
    localStorage.setItem("token", null);
  };

  return (
    <DashboardLayout>
      {(selectedItem) => <DashboardContent selectedItem={selectedItem} />}
    </DashboardLayout>
  );
};

export default Dashboard;
