import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { api } from "../../axios/api";

const GoogleLogin = ({ dispatch, setToken, setAuthToken, navigate, toast }) => {
  const [googleAPIReady, setGoogleAPIReady] = useState(false);
  const handleCallbackResponse = (response) => {
    console.log("response- encode jwt id token", response.credential);
    const userObject = jwt_decode(response.credential);
    console.log("userobject", userObject);
    let obj = {
      name: userObject?.name ? userObject?.name : userObject?.given_name,
      profile_image: userObject?.picture,
      email: userObject?.email,
      isSignedUpByGoogle: true,
      verified: true,
    };
    api
      .post("/googleSignin", obj)
      .then((res) => {
        console.log("response from google login custom backend", res?.data);
        // toast.success("succesfully logged in!");
        const { verified, token, data } = res?.data;
        if (data?.profile_type == "agent") {
          if (data?.is_agent_suspended) {
            toast.error("Your Account is suspended!");
            return;
          }
        }
        setAuthToken(token);
        console.log("token at google login", token);
        // if (verified) {
        dispatch(setToken(token));
        // dispatch(setToken(token));
        // setAuthToken(token);
        toast.success("logged in successfully!");
        // clearForms();
        setTimeout(() => {
          navigate("/dashboard");
        }, 2000);
        // }
      })
      .catch((err) => {
        console.log("error in logging user", err);
        if (err?.response?.status == 404) {
          toast.error("User not found!");
        } else if (err?.response?.status == 409) {
          toast.error("User already logged in some other browser!");
        } else {
          toast.error("Login failed!");
        }
      });
  };
  useEffect(() => {
    const loadGoogleAPI = async () => {
      try {
        await new Promise((resolve, reject) => {
          const script = document.createElement("script");
          script.src = "https://accounts.google.com/gsi/client";
          script.onload = resolve;
          script.onerror = reject;
          document.head.appendChild(script);
        });

        google.accounts.id.initialize({
          client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
          callback: handleCallbackResponse,
        });

        setGoogleAPIReady(true);
      } catch (error) {
        console.error("Error loading Google API:", error);
      }
    };

    loadGoogleAPI();
  }, []);
  useEffect(() => {
    if (googleAPIReady) {
      /* global google */
      google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback: handleCallbackResponse,
      });
      google.accounts.id.renderButton(document.getElementById("signInDiv"), {
        //theme: "outline",
        theme: "filled_black",
        // theme: "filled_blue",
        //   type:'icon',
        shap: "pill",
        size: "large",
      });

      // google.accounts.id.prompt();
    }
  }, [googleAPIReady]);
  return <div id="signInDiv"></div>;
};

export default GoogleLogin;
