import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Typography,
  Box,
} from "@mui/material";
import { useTheme } from "@mui/material";
import { api } from "../../../axios/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";

const AddProspectForm = ({
  editOptions,
  setShowAddForm,
  organizationInformation,
  prospects,
}) => {
  const user = useSelector((state) => state.user);
  useEffect(() => {
    console.log("user at add prospect form", user);
  }, [user]);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const theme = useTheme();
  const [organizationName, setOrganizationName] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [primaryPhoneNumber, setPrimaryPhoneNumber] = useState("");
  const [otherPhoneNumber, setOtherPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [industry, setIndustry] = useState("");
  const [serviceNeeded, setServiceNeeded] = useState("");
  const [leadSource, setLeadSource] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [valueOfLead, setValueOfLead] = useState("");

  const [primaryPhoneNumberError, setPrimaryPhoneNumberError] = useState("");
  const [organizationNameError, setOrganizationNameError] = useState("");
  const [contactPersonError, setContactPersonError] = useState("");
  const [otherPhoneNumberError, setOtherPhoneNumberError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [industryError, setIndustryError] = useState("");

  const [serviceNeededError, setServiceNeededError] = useState("");
  const [leadSourceError, setLeadSourceError] = useState("");
  const [cityError, setCityError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [valueOfLeadError, setValueOfLeadError] = useState("");
  const [duplicateWarning, setDuplicateWarning] = useState(false);
  const [allowDuplicate, setAllowDuplicate] = useState(false);

  useEffect(() => {
    console.log("edit options", editOptions);
    if (editOptions?.lead_serial_number) {
      setOrganizationName(editOptions?.organization_name);
      setContactPerson(editOptions?.contact_person);
      setPrimaryPhoneNumber(editOptions?.primary_phone_number);
      setOtherPhoneNumber(editOptions?.other_phone_number);
      setEmail(editOptions?.email);
      setIndustry(editOptions?.industry);
      setServiceNeeded(editOptions?.service_needed);
      setLeadSource(editOptions?.lead_source);
      setCity(editOptions?.city);
      setCountry(editOptions?.country);
      setValueOfLead(editOptions?.value_of_lead);
    }
  }, [editOptions]);

  const onSubmit = (data) => {
    setDuplicateWarning(false);
    console.log("running on submit", data);
    let isErrorFound = false;
    if (!primaryPhoneNumber) {
      setPrimaryPhoneNumberError("required");
      isErrorFound = true;
    }
    if (!organizationName) {
      setOrganizationNameError("required");
      isErrorFound = true;
    }
    if (!contactPerson) {
      setContactPersonError("required");
      isErrorFound = true;
    }
    if (!email) {
      setEmailError("required");
      isErrorFound = true;
    }
    if (!industry) {
      setIndustryError("required");
      isErrorFound = true;
    }
    if (!serviceNeeded) {
      setServiceNeededError("required");
      isErrorFound = true;
    }
    if (!leadSource) {
      setLeadSourceError("required");
      isErrorFound = true;
    }
    if (!city) {
      setCityError("required");
      isErrorFound = true;
    }
    if (!country) {
      setCountryError("required");
      isErrorFound = true;
    }
    if (!valueOfLead) {
      setValueOfLeadError("required");
      isErrorFound = true;
    }
    console.log("isError found", isErrorFound);
    // if (!allowDuplicate) {
    //   let isDuplicate = false;
    //   prospects?.map((p) => {
    //     if (
    //       p?.organization_name?.toLowerCase() ==
    //         organizationName?.toLowerCase() &&
    //       p?.contact_person?.toLowerCase() == contactPerson?.toLowerCase()
    //     ) {
    //       isDuplicate = true;
    //     }
    //   });
    //   setDuplicateWarning(isDuplicate);
    //   isErrorFound = true;
    // }
    console.log("isError found", isErrorFound);
    if (isErrorFound) {
      return;
    }
    data.primary_phone_number = primaryPhoneNumber;
    if (otherPhoneNumber) {
      data.other_phone_number = otherPhoneNumber;
    }
    console.log(data); // You can do whatever you want with the form data here

    if (editOptions?.lead_serial_number) {
      data.organization_name = organizationName;
      data.contact_person = contactPerson;
      data.primary_phone_number = primaryPhoneNumber;
      data.other_phone_number = otherPhoneNumber;
      data.email = email;
      data.industry = industry;
      data.service_needed = serviceNeeded;
      data.lead_source = leadSource;
      data.city = city;
      data.country = country;
      data.value_of_lead = valueOfLead;
      api
        .post("/editProspect/" + editOptions?.lead_serial_number, data)
        .then((res) => {
          console.log("res?.data prospect", res?.data);
          toast?.success("successfully updated!");
          setShowAddForm(false);
        })
        .catch((err) => {
          console.log(err);
          toast?.error("unable to update!");
        });
    } else {
      api
        .post("/createProspect", { ...data, owner_id: user?.id })
        .then((res) => {
          console.log("res?.data prospect", res?.data);
          toast?.success("successfully added!");
          setShowAddForm(false);
        })
        .catch((err) => {
          console.log("message", err?.message);
          console.log(err?.message == "Request failed with status code 409");
          if (err?.message == "Request failed with status code 409") {
            toast.error(
              "prospect with this primary email or email already exist in your organization!"
            );
          } else {
            toast.error(
              "there is some issue regarding server. Try agin later!"
            );
          }
        });
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        background: theme.palette.background.main,
        marginTop: "20px",
        padding: "20px",
        maxWidth: "700px",
      }}
    >
      <ToastContainer />
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        {" "}
        <Typography
          variant="h6"
          sx={{ color: theme.palette.heading.main, mt: 1, mb: 3 }}
        >
          Enter prospect details
        </Typography>
        <Typography variant="p" sx={{ color: theme.palette.para.main }}>
          Date: {new Date(Date.now()).toDateString()}
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography
            variant="body2"
            sx={{ color: theme.palette.heading.main, mb: 1 }}
          >
            Entity Name
          </Typography>
          <TextField
            {...register("organization_name")}
            value={organizationName}
            onChange={(e) => setOrganizationName(e.target.value)}
            // label="Organization Name"
            // error={!!errors.organization_name}
            // helperText={errors.organization_name?.message}
            fullWidth
          />
          {organizationNameError && (
            <Typography
              varaint="body2"
              sx={{
                color: "red",
                mt: 0.4,
                fontSize: "0.8rem",
                fontWeight: "300",
              }}
            >
              {organizationNameError}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            variant="body2"
            sx={{ color: theme.palette.heading.main, mb: 1 }}
          >
            Contact Person
          </Typography>
          <TextField
            {...register("contact_person")}
            // label="Contact Person"
            // error={!!errors.contact_person}
            // helperText={errors.contact_person?.message}
            fullWidth
            value={contactPerson}
            onChange={(e) => setContactPerson(e.target.value)}
          />
          {contactPersonError && (
            <Typography
              varaint="body2"
              sx={{
                color: "red",
                mt: 0.4,
                fontSize: "0.8rem",
                fontWeight: "300",
              }}
            >
              {contactPersonError}
            </Typography>
          )}
        </Grid>
        {duplicateWarning && (
          <Grid item xs={12}>
            <Typography
              sx={{ fontSize: "0.9rem", color: theme.palette.para.main }}
            >
              You have entered values that already exist{" "}
            </Typography>
            <Button
              variant="contained"
              onClick={() => {
                setDuplicateWarning(false);
                setAllowDuplicate(true);
              }}
              size="small"
            >
              Allow Duplicate
            </Button>
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <Typography
            variant="body2"
            sx={{ color: theme.palette.heading.main, mb: 1 }}
          >
            Primary phone number
          </Typography>
          <PhoneInput
            disabled={editOptions?.lead_serial_number ? true : false}
            // {...register("primary_phone_number", {
            //   required: "Primary phone number is required",
            // })}
            value={primaryPhoneNumber}
            onChange={(value) => setPrimaryPhoneNumber(value)}
            country={"ke"}
            inputProps={{
              name: "primary_phone_number",
              required: "Primary phone number is required",
            }}
            fullWidth
            inputStyle={{
              border: "none",
              outline: "none",
              width: "100%",
              paddingLeft: "47px",
              fontWeight: "300",
              fontFamily: "inherit",
            }}
            searchStyle={{
              border: "1px solid black",
              fontWeight: "300",
              fontFamily: "inherit",
            }}
            containerStyle={{
              flex: "1",
              border: "1px solid lightgray",
              borderRadius: "4px",
              padding: "4px 4px",
            }}
            buttonStyle={{
              // height: "45px",
              borderRadius: "4px",

              padding: "0 5px",
              backgroundColor: "white",
              border: "none",
              outline: "none",
            }}
            dropdownStyle={{
              marginTop: "0",
              borderRadius: "4px",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              border: "1px solid #ced4da",
            }}
          />
          {primaryPhoneNumberError && (
            <Typography
              varaint="body2"
              sx={{
                color: "red",
                mt: 0.4,
                fontSize: "0.8rem",
                fontWeight: "300",
              }}
            >
              {primaryPhoneNumberError}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            variant="body2"
            sx={{ color: theme.palette.heading.main, mb: 1 }}
          >
            Other phone number
          </Typography>
          <PhoneInput
            // {...register("other_phone_number")}
            value={otherPhoneNumber}
            onChange={(value) => setOtherPhoneNumber(value)}
            country={"us"}
            inputProps={{
              name: "other_phone_number",
            }}
            fullWidth
            inputStyle={{
              border: "none",
              outline: "none",
              width: "100%",
              paddingLeft: "47px",
              fontWeight: "300",
              fontFamily: "inherit",
            }}
            searchStyle={{
              border: "1px solid black",
              fontWeight: "300",
              fontFamily: "inherit",
            }}
            containerStyle={{
              flex: "1",
              border: "1px solid lightgray",
              borderRadius: "4px",
              padding: "4px 4px",
            }}
            buttonStyle={{
              // height: "45px",
              borderRadius: "4px",

              padding: "0 5px",
              backgroundColor: "white",
              border: "none",
              outline: "none",
            }}
            dropdownStyle={{
              marginTop: "0",
              borderRadius: "4px",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              border: "1px solid #ced4da",
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            variant="body2"
            sx={{ color: theme.palette.heading.main, mb: 1 }}
          >
            Email
          </Typography>
          <TextField
            {...register("email")}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={editOptions?.lead_serial_number ? true : false}
            // label="Email"
            // error={!!errors.email}
            // helperText={errors.email?.message}
            fullWidth
          />
          {emailError && (
            <Typography
              varaint="body2"
              sx={{
                color: "red",
                mt: 0.4,
                fontSize: "0.8rem",
                fontWeight: "300",
              }}
            >
              {emailError}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            variant="body2"
            sx={{ color: theme.palette.heading.main, mb: 1 }}
          >
            Industry
          </Typography>
          <FormControl error={!!errors.industry} fullWidth>
            {/* <InputLabel>Industry</InputLabel> */}
            <Select
              {...register("industry")}
              fullWidth
              value={industry}
              onChange={(e) => setIndustry(e.target.value)}
            >
              <MenuItem value="">Select an industry</MenuItem>
              {organizationInformation?.industries?.length > 0 &&
                organizationInformation?.industries?.map((industry) => (
                  <MenuItem value={industry}>{industry}</MenuItem>
                ))}
            </Select>
          </FormControl>
          {industryError && (
            <Typography
              varaint="body2"
              sx={{
                color: "red",
                mt: 0.4,
                fontSize: "0.8rem",
                fontWeight: "300",
              }}
            >
              {industryError}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            variant="body2"
            sx={{ color: theme.palette.heading.main, mb: 1 }}
          >
            Service Needed
          </Typography>
          <FormControl
            error={!!errors.service_needed}
            style={{ width: "100%" }}
          >
            {/* <InputLabel>Service Needed</InputLabel> */}
            <Select
              {...register("service_needed")}
              value={serviceNeeded}
              onChange={(e) => setServiceNeeded(e.target.value)}
            >
              <MenuItem value="">Select a service</MenuItem>
              {organizationInformation?.services?.length > 0 &&
                organizationInformation?.services?.map((service) => (
                  <MenuItem value={service}>{service}</MenuItem>
                ))}
            </Select>
          </FormControl>
          {serviceNeededError && (
            <Typography
              varaint="body2"
              sx={{
                color: "red",
                mt: 0.4,
                fontSize: "0.8rem",
                fontWeight: "300",
              }}
            >
              {serviceNeededError}
            </Typography>
          )}
        </Grid>{" "}
        <Grid item xs={12} sm={6}>
          <Typography
            variant="body2"
            sx={{ color: theme.palette.heading.main, mb: 1 }}
          >
            Lead Source
          </Typography>
          <FormControl error={!!errors.lead_source} style={{ width: "100%" }}>
            {/* <InputLabel>Lead Source</InputLabel> */}
            <Select
              {...register("lead_source")}
              value={leadSource}
              onChange={(e) => setLeadSource(e.target.value)}
            >
              <MenuItem value="">Select a lead source</MenuItem>
              {organizationInformation?.lead_sources?.length > 0 &&
                organizationInformation?.lead_sources?.map((leadSource) => (
                  <MenuItem value={leadSource}>{leadSource}</MenuItem>
                ))}
            </Select>
          </FormControl>
          {leadSourceError && (
            <Typography
              varaint="body2"
              sx={{
                color: "red",
                mt: 0.4,
                fontSize: "0.8rem",
                fontWeight: "300",
              }}
            >
              {leadSourceError}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            variant="body2"
            sx={{ color: theme.palette.heading.main, mb: 1 }}
          >
            City
          </Typography>
          <TextField
            fullWidth
            {...register("city")}
            // label="City"
            // error={!!errors.city}
            // helperText={errors.city?.message}
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
          {cityError && (
            <Typography
              varaint="body2"
              sx={{
                color: "red",
                mt: 0.4,
                fontSize: "0.8rem",
                fontWeight: "300",
              }}
            >
              {cityError}
            </Typography>
          )}
        </Grid>{" "}
        <Grid item xs={12} sm={6}>
          <Typography
            variant="body2"
            sx={{ color: theme.palette.heading.main, mb: 1 }}
          >
            Country
          </Typography>
          <TextField
            fullWidth
            {...register("country")}
            // label="Country"
            // error={!!errors.country}
            // helperText={errors.country?.message}
            value={country}
            onChange={(e) => setCountry(e.target.value)}
          />
          {countryError && (
            <Typography
              varaint="body2"
              sx={{
                color: "red",
                mt: 0.4,
                fontSize: "0.8rem",
                fontWeight: "300",
              }}
            >
              {countryError}
            </Typography>
          )}
        </Grid>{" "}
        <Grid item xs={12} sm={6}>
          <Typography
            variant="body2"
            sx={{ color: theme.palette.heading.main, mb: 1 }}
          >
            Value of lead ({organizationInformation?.organization_currency})
          </Typography>
          <TextField
            fullWidth
            {...register("value_of_lead")}
            // label="Value of Lead"
            // error={!!errors.value_of_lead}
            // helperText={errors.value_of_lead?.message}
            value={valueOfLead}
            onChange={(e) => setValueOfLead(e.target.value)}
          />
          {valueOfLeadError && (
            <Typography
              varaint="body2"
              sx={{
                color: "red",
                mt: 0.4,
                fontSize: "0.8rem",
                fontWeight: "300",
              }}
            >
              {valueOfLeadError}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Button variant="contained" type="submit" sx={{ mt: 3 }}>
        Submit
      </Button>
    </form>
  );
};

export default AddProspectForm;

// import React, { useEffect, useState } from "react";
// import { Formik, Field, Form, FormikConsumer } from "formik";
// import * as Yup from "yup";
// import {
//   TextField,
//   RadioGroup,
//   Radio,
//   FormControlLabel,
//   Button,
//   Grid,
//   Box,
// } from "@mui/material";
// import { Typography } from "@mui/material";
// import { useTheme } from "@mui/material/styles";
// import { api } from "../../../axios/api";
// import { useSelector } from "react-redux";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import AES from "crypto-js/aes";

// const validationSchema = Yup.object().shape({
//   organization_name: Yup.string().required("Required"),
//   contact_person: Yup.string().required("Required"),
//   primary_phone_number: Yup.string().required("Required"),
//   email: Yup.string().required("Required"),
//   industry: Yup.string().required("Required"),
//   service_needed: Yup.string().required("Required"),
//   lead_source: Yup.string().required("Required"),
//   city: Yup.string().required("Required"),
//   country: Yup.string().required("Required"),
//   email: Yup.string().required("Required"),
//   value_of_lead: Yup.string().required("Required"),
// });

// const AddProspectForm = ({ editOptions, setShowAddForm }) => {
//   const theme = useTheme();
//   const user = useSelector((state) => state.user);
//   const [authorizedToCompany, setAuthorizedToCompany] = useState();
//   const [authorizedToCompanyError, setAuthorizedToCompanyError] = useState("");

//   const [initialValues, setInitialValues] = useState(
//     editOptions
//       ? {
//           organization_name: "",
//           contact_person: "",
//           primary_phone_number: "",
//           other_phone_number: "",
//           email: "",
//           industry: "",
//           service_needed: "",
//           lead_source: "",
//           city: "",
//           country: "",
//           email: "",
//           value_of_lead: "",
//         }
//       : {
//           organization_name: "",
//           contact_person: "",
//           primary_phone_number: "",
//           other_phone_number: "",
//           email: "",
//           industry: "",
//           service_needed: "",
//           lead_source: "",
//           city: "",
//           country: "",
//           email: "",
//           value_of_lead: "",
//         }
//   );

//   useEffect(() => {
//     console.log("user at add contact", user);
//   }, [user]);
//   useEffect(() => {
//     console.log("edit options at add supplier form", editOptions);
//     setAuthorizedToCompany(editOptions?.authorized_to_company_details);
//   }, [editOptions]);
//   useEffect(() => {
//     setAuthorizedToCompanyError("");
//   }, [authorizedToCompany]);
//   useEffect(() => {
//     console.log("authorizedToCompanyError", authorizedToCompanyError);
//   }, [authorizedToCompanyError]);
//   const onSubmit = (values, { resetForm }) => {
//     console.log(values);
//     console.log("authorizedToCompany", authorizedToCompany);
//     console.log("authorizedToCompanyError", authorizedToCompanyError);
//     if (!authorizedToCompany) {
//       console.log("error occur");
//       setAuthorizedToCompanyError("select one");
//       return;
//     }
//     var encPass = AES.encrypt(
//       values.password,
//       process.env.REACT_APP_CRYPTO_JS_KEY
//     ).toString();

//     // return;
//     if (editOptions?.id) {
//       let body = {
//         name: values.name,
//         email_address: values.email,
//         phone_number: values.phone_number,
//         authorized_to_company_details: authorizedToCompany,
//       };
//       api
//         .put("/updateUserByAdmin/" + editOptions?.id, body)
//         .then((res) => {
//           console.log("response from posting content", res?.data);
//           toast.success("user updated successfully!");
//           setShowAddForm(false);
//           resetForm();
//         })
//         .catch((err) => {
//           console.log("err", err);
//           toast.error("error in adding contact");
//         });
//     } else {
//       let body = {
//         name: values.name,
//         email_address: values.email,
//         password: encPass,
//         phone_number: values.phone_number,
//         profile_type: values.profile_type,
//         authorized_to_company_details: authorizedToCompany,
//       };
//       api
//         .post("/addUserByAdmin", body)
//         .then((res) => {
//           console.log("response from posting content", res?.data);
//           toast.success("contact added successfully!");
//           resetForm();
//           setTimeout(() => {
//             setShowAddForm(false);
//           }, 2000);
//         })
//         .catch((err) => {
//           console.log("err", err);
//           toast.error("error in adding contact");
//         });
//     }
//   };
//   const cancelForm = () => {
//     setShowAddForm(false);
//   };
//   return (
//     <Box sx={{ padding: "30px 0px" }}>
//       <ToastContainer />
//       {/* <Typography
//         variant="h6"
//         sx={{
//           color: theme.palette.heading.main,
//           fontSize: "1.3rem",
//           fontWeight: "600",
//         }}
//       >
//         Add Contact
//       </Typography> */}
//       <Box
//         sx={{
//           backgroundColor: theme.palette.background.main,
//           padding: "20px",
//           borderRadius: "5px",
//           marginTop: "20px",
//           maxWidth: "700px",
//         }}
//       >
//         <Typography
//           variant="body2"
//           sx={{ margin: "10px 0 30px 0", color: theme.palette.para.main }}
//         >
//           Fill up the form with user data.
//         </Typography>
//         <Formik
//           initialValues={initialValues}
//           validationSchema={validationSchema}
//           onSubmit={onSubmit}
//         >
//           {({ errors, touched }) => (
//             <Form>
//               <Grid container spacing={3}>
//                 <Grid item xs={12}>
//                   <Field
//                     name="name"
//                     as={TextField}
//                     label="Name"
//                     fullWidth
//                     error={touched.name && Boolean(errors.name)}
//                     helperText={touched.name && errors.name}
//                   />
//                 </Grid>

//                 <Grid item xs={12} sm={editOptions?.id ? 12 : 6}>
//                   <Field
//                     name="email"
//                     as={TextField}
//                     label="Email"
//                     fullWidth
//                     error={touched.email && Boolean(errors.email)}
//                     helperText={touched.email && errors.email}
//                   />
//                 </Grid>
//                 {!editOptions?.id && (
//                   <Grid item xs={12} sm={6}>
//                     <Field
//                       name="password"
//                       as={TextField}
//                       label="Password"
//                       fullWidth
//                       error={touched.password && Boolean(errors.password)}
//                       helperText={touched.password && errors.password}
//                     />
//                   </Grid>
//                 )}

//                 <Grid item xs={12}>
//                   <Field
//                     name="phone_number"
//                     as={TextField}
//                     label="Phone Number"
//                     fullWidth
//                     error={touched.phone_number && Boolean(errors.phone_number)}
//                     helperText={touched.phone_number && errors.phone_number}
//                   />
//                 </Grid>
//                 <Grid item xs={12}>
//                   <Typography
//                     variant="p"
//                     sx={{ color: theme.palette.heading.main }}
//                   >
//                     Authorize to company details
//                   </Typography>
//                   <FormikConsumer>
//                     {({ values }) => (
//                       <RadioGroup
//                         name="authorized_to_company_details"
//                         value={authorizedToCompany}
//                         onChange={(event) =>
//                           setAuthorizedToCompany(event.target.value)
//                         }
//                         row
//                       >
//                         <FormControlLabel
//                           value={true}
//                           control={<Radio />}
//                           label="Yes"
//                           defaultChecked={
//                             values.authorized_to_company_details == "true"
//                           }
//                         />
//                         <FormControlLabel
//                           value={false}
//                           control={<Radio />}
//                           label="No"
//                           defaultChecked={
//                             values.authorized_to_company_details == "false"
//                           }
//                         />
//                       </RadioGroup>
//                     )}
//                   </FormikConsumer>
//                   {authorizedToCompanyError && (
//                     <Typography variant="body2" sx={{ color: "red" }}>
//                       {authorizedToCompanyError}
//                     </Typography>
//                   )}
//                 </Grid>

//                 <Grid item xs={12} sm={4}></Grid>
//                 <Grid item xs={12} sm={4}></Grid>
//                 {/* <Grid item xs={12}>
//                   <Field name="type">
//                     {({ field }) => (
//                       <RadioGroup {...field} row>
//                         <FormControlLabel
//                           value="supplier"
//                           control={<Radio defaultChecked={true} />}
//                           label="Supplier"
//                         />
//                         <FormControlLabel
//                           value="consignee"
//                           control={<Radio />}
//                           label="Consignee"
//                         />
//                       </RadioGroup>
//                     )}
//                   </Field>
//                 </Grid> */}
//                 <Grid item xs={12}>
//                   <Button
//                     variant="contained"
//                     // color={theme.palette.colortwo.main}
//                     sx={{
//                       backgroundColor: theme.palette.colortwo.main,
//                       color: theme.palette.whiteFont.main,
//                     }}
//                     type="submit"
//                     size="small"
//                   >
//                     {editOptions?.id ? "Update" : "Submit"}
//                   </Button>
//                   <Button
//                     variant="contained"
//                     color="primary"
//                     onClick={() => cancelForm()}
//                     sx={{
//                       marginLeft: "10px",
//                       backgroundColor: theme.palette.colortwo.main,
//                       color: theme.palette.whiteFont.main,
//                     }}
//                     size="small"
//                   >
//                     Cancel
//                   </Button>
//                 </Grid>
//               </Grid>
//             </Form>
//           )}
//         </Formik>
//       </Box>
//     </Box>
//   );
// };

// export default AddProspectForm;
