import { useEffect } from "react";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import AccountDetails from "../components/CRM/Profile/AccountDetails";
import AddUsers from "../components/CRM/Profile/AddUsers";
import DashboardContainer from "../components/CRM/DashboardContainer";
import Subscriptions from "../components/CRM/Profile/Subscriptions";
import Organization from "../components/CRM/Organization/GeneralSettings";
import Prospects from "../components/CRM/Prospects";
import LeadsPipeline from "../components/CRM/LeadsPipeline";
import Relationships from "../components/CRM/Relationships";
import FAQs from "../components/CRM/Organization/FAQs";
import NoticeBoard from "../components/CRM/NoticeBoard";
import ManageNoticeBoard from "../components/Admin/ManageNoticeBoard";
import ManageStories from "../components/Admin/ManageStories";
import Reports from "../components/CRM/Reports";
import SetTragets from "../components/CRM/Reports/SetTragets";
import Analytics from "../components/Admin/Analytics";
import ManageAds from "../components/Admin/ManageAds";
import Coupons from "../components/Admin/Coupons";
import ContactSupport from "../components/CRM/Profile/ContactSupport";
import SupportTickets from "../components/Admin/SupportTickets";
import CreateAgents from "../components/Admin/Agents/CreateAgents";
import ListOfAgents from "../components/Admin/Agents/ListOfAgents";
import AgentPrice from "../components/Admin/Agents/AgentPrice";
import AgentAnalytics from "../components/Agent/AgentAnalytics";
import { useParams } from "react-router-dom";

// import AddContactForm from "../components/AddContactForm";
// import ShowCustomers from "../components/ShowCustomers";
// import ShowSuppliers from "../components/ShowSuppliers";

const CRMContent = () =>
  // { selectedItem }
  {
    // alert("content");
    const theme = useTheme();
    const { selectedItem } = useParams();
    console.log("selected item>>>>>>>>>>", selectedItem);
    useEffect(() => {
      console.log("selected item at dashboardCOntent", selectedItem);
    }, [selectedItem]);
    return (
      <Box
        sx={{
          backgroundColor: theme.palette.secondary.main,
          p: 3,
          minHeight: "calc(100vh - 70px)",
          borderTopLeftRadius: "10px",
        }}
      >
        {/* {selectedItem == "addContacts" && <AddContactForm />} */}
        {/* {selectedItem == "suppliers" && <ShowSuppliers />}
      {selectedItem == "customers" && <ShowCustomers />} */}
        {(selectedItem == "analytics" || selectedItem == "dashboard") && (
          <DashboardContainer />
        )}
        {selectedItem == "accountSettings" && <AccountDetails />}
        {selectedItem == "addUsers" && <AddUsers />}
        {selectedItem == "subscriptions" && <Subscriptions />}
        {selectedItem == "organizationGeneralSettings" && <Organization />}{" "}
        {selectedItem == "prospects" && <Prospects />}
        {selectedItem == "leadsPipeline" && <LeadsPipeline />}
        {selectedItem == "relationships" && <Relationships />}
        {selectedItem == "faqs" && <FAQs />}
        {selectedItem == "billboard" && <NoticeBoard />}
        {selectedItem == "adminBillboard" && <ManageNoticeBoard />}
        {selectedItem == "adminStories" && <ManageStories />}
        {selectedItem == "adminAnalytics" && <Analytics />}
        {selectedItem == "manageAds" && <ManageAds />}
        {selectedItem == "setTargetsForPartners" && <SetTragets />}
        {selectedItem == "viewReports" && <Reports />}
        {selectedItem == "coupons" && <Coupons />}
        {selectedItem == "contactSupport" && <ContactSupport />}
        {selectedItem == "adminSupportTickets" && <SupportTickets />}
        {selectedItem == "createNewAgent" && <CreateAgents />}
        {selectedItem == "listOfAgents" && <ListOfAgents />}
        {selectedItem == "agentPrice" && <AgentPrice />}
        {selectedItem == "agentAnalytics" && <AgentAnalytics />}
        {/* <LeadsPipeline /> */}
      </Box>
    );
  };

export default CRMContent;
