import React, { useState, useEffect } from "react";
import { api } from "../../../axios/api";
import { Box, Typography, Button, Input, useTheme } from "@mui/material";

const EditLeadsMilestones = ({ toast }) => {
  const [milestones, setMilestones] = useState([]);
  const theme = useTheme();
  const [organization, setOrganization] = useState({});

  useEffect(() => {
    // Fetch the milestones from the API and set them in the state
    api.get("/organization").then((res) => {
      let org = res?.data;
      setOrganization(org);
      if (org?.leads_milestones) {
        setMilestones(org?.leads_milestones);
      }
    });
  }, []);

  // Function to update a specific milestone in the state
  const handleMilestoneChange = (index, newValue) => {
    const updatedMilestones = [...milestones];
    updatedMilestones[index] = newValue;
    setMilestones(updatedMilestones);
  };

  // Function to update all milestones via API
  const handleUpdateAll = () => {
    // Send the updated milestones to your API
    api
      .post("/organization/edit/" + organization?.id, {
        leads_milestones: milestones,
      })
      .then((res) => {
        toast.success("Milestones Updated Successfully");
        // Handle success or error response
      });
  };

  return (
    <Box sx={{ my: 2, p: 2, background: "#fff" }}>
      <Typography variant="h6">Milestones for Leads</Typography>
      <Box
        sx={{ my: 2, background: theme.palette.selectedMenuBack.main, p: 2 }}
      >
        <Typography
          variant="body2"
          sx={{ fontWeignt: "600", color: theme.palette.primary.main }}
        >
          Keys (keep these in mind):
        </Typography>
        <Typography variant="body2">Level 1. Discovery</Typography>
        <Typography variant="body2">Level 2. Assessment</Typography>
        <Typography variant="body2">Level 3. Negotiation</Typography>
        <Typography variant="body2">Level 4. Validation</Typography>
        <Typography variant="body2">Level 5. Decision/Conversion</Typography>
      </Box>
      <Box>
        {milestones?.map((m, i) => (
          <Box sx={{ mt: 2 }} key={i}>
            <Typography
              variant="body2"
              sx={{ color: theme.palette.primary.main }}
            >
              Level: {i + 1}
            </Typography>
            <Input
              type="text"
              value={m}
              sx={{ width: "100%" }}
              onChange={(e) => handleMilestoneChange(i, e.target.value)}
            />
          </Box>
        ))}
      </Box>
      <Button variant="contained" sx={{ mt: 2 }} onClick={handleUpdateAll}>
        Update All
      </Button>
    </Box>
  );
};

export default EditLeadsMilestones;
