import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  useMediaQuery,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from "@mui/material";

import logo from "../../utils/images/edge.jpg";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import AuthInput from "../InputFields/AuthInput";
import { User, Mail, Briefcase, Lock } from "react-feather";
import GoogleIcon from "../../utils/images/googlIcon.png";
import AES from "crypto-js/aes";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { api, setAuthToken } from "../../axios/api";
import { useDispatch } from "react-redux";
import { setUser, setToken } from "../../actions/userActions";

import PhoneInput from "react-phone-number-input";
import AuthPhoneInput from "../InputFields/AuthPhoneInput";
import { useParams } from "react-router-dom";
import GoogleLogin from "./GoogleLogin";

const AdminLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [mode, setMode] = useState(
    params?.mode == "login" ? "login" : "signup"
  );
  useEffect(() => params?.mode && setMode(params?.mode), [params]);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState("");

  const [isAgreeToTermsAndConditions, setIsAgreeToTermAndConditions] =
    useState(false);

  // const handlePhoneNumberChange = (event) => {
  //   console.log("event", event);
  //   setPhoneNumber(event.target.value);
  //   setPhoneNumberError("");
  // };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setPasswordError("");
  };

  const clearForms = () => {
    setPassword("");
  };
  useEffect(() => clearForms(), [mode]);

  const validatePassword = (password) => {
    if (!password) {
      setPasswordError("Password is required");
      return false;
    }
    if (password.length < 6) {
      setPasswordError("Password must be at least 6 characters long");
      return false;
    }
    setPasswordError("");
    return true;
  };

  const handleSubmit = () => {
    console.log("password", password);
    console.log(
      " process.env.REACT_APP_CRYPTO_JS_KEY",
      process.env.REACT_APP_CRYPTO_JS_KEY
    );

    var encPass = AES.encrypt(
      password,
      process.env.REACT_APP_CRYPTO_JS_KEY
    ).toString();
    console.log("encPass", encPass);

    let obj = {
      email_address: "admin@edge360.co",
      password: encPass,
    };
    api
      .post("/signin", obj)
      .then((res) => {
        console.log("resposne from logging user", res.data);
        const { verified, token } = res?.data;

        setAuthToken(token);

        dispatch(setToken(token));
        // dispatch(setToken(token));
        // setAuthToken(token);
        toast.success("logged in successfully!");
        clearForms();
        setTimeout(() => {
          navigate("/dashboard");
        }, 2000);
      })
      .catch((err) => {
        console.log("error in logging user", err);
        if (err?.response?.status == 404) {
          toast.error("User not found!");
        } else {
          toast.error("Login with correct credentials!");
        }
      });
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    console.log("running handle form submit");

    const isPasswordValid = validatePassword(password);
    if (isPasswordValid) {
      handleSubmit();
    }
  };

  const [credentialResponse, setCredentialResponse] = useState();

  return (
    <Box
      sx={{
        // backgroundColor: theme.palette.background?.main,
        // backgroundColor: "rgba(255, 255, 255, 0.5)", // set a semi-transparent white background color
        // backdropFilter: "blur(6px)", // add a 5px blur filter
        // zIndex: "1000000",
        // height: "100vh",
        // minHeight: "100vh",
        // position: "absolute",

        // position: "fixed",
        // top: "0px",
        // left: 0,
        // width: "100vw",
        display: "flex",
        justifyContent: "center",
        // alignItems: "center",
        overflow: "hidden",
        // backgroundColor: theme.palette.colorthree.main,
        padding: "50px 0",
      }}
    >
      <ToastContainer />
      <Box
        sx={{
          minHeight: "200px",
          // height: "60%",
          // maxHeight: "80%",
          // overflowY: "auto",
          height: "max-content",
          background: theme.palette.background.main,
          width: "40%",
          minWidth: "300px",
          maxWidth: "400px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "40px 40px 40px 40px",
          borderRadius: "20px",
          boxShadow:
            "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",

          // border: "1px solid lightgray",
          //   justifyContent: "center",
          // border: "1px solid black",
        }}
      >
        <Box sx={{ cursor: "pointer" }} onClick={() => navigate("/")}>
          <img style={{ height: "50px" }} src={logo} />
        </Box>

        <Box sx={{ width: "100%", maxWidth: "350px" }}>
          <Typography
            variant="h6"
            sx={{
              mt: 2,
              marginBottom: "30px",
              textAlign: "center",
              color: theme.palette.heading.main,
            }}
          >
            {"Admin Login"}
          </Typography>

          <Box>
            <AuthInput
              isPassword={true}
              label="Password"
              placeholder="password"
              value={password}
              onChange={handlePasswordChange}
              icon={
                <Lock
                  size={17}
                  color={theme.palette.menuHeading.main}
                  strokeWidth={1.3}
                />
              }
              error={passwordError ? true : false}
              helperText={passwordError}
            />
          </Box>

          <Button
            fullWidth
            type="submit"
            variant="contained"
            sx={{
              mt: 3,
              backgroundColor: theme.palette.brandColorOne?.main,
              color: theme.palette.whiteFont?.main,
              height: "36px",
            }}
            onClick={handleFormSubmit}
          >
            Login
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default AdminLogin;
