import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
  IconButton,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useTheme } from "@emotion/react";
import { api } from "../../../../axios/api";

const TransferHistory = ({ selectedLead }) => {
  const theme = useTheme();
  const [notes, setNotes] = useState([]);
  useEffect(() => {
    api
      .get(
        "/transferRelationshipHistory/" + selectedLead?.info_reference_number
      )
      .then((res) => {
        console.log("respose transfer hostory", res?.data);
        setNotes(res?.data);
      })
      .catch((err) => console.log(Error));
  }, []);
  return (
    <Box>
      <Box sx={{ mt: 2, display: "flex", alignItems: "center", gap: "20px" }}>
        <Typography variant="p">Total No. of Notes= </Typography>
        <Typography
          variant="p"
          sx={{
            fontSize: "1.3rem",
            fontWeight: "600",
            color: theme.palette.primary.main,
          }}
        >
          {notes?.length}
        </Typography>
      </Box>
      <Box sx={{ mt: 2, p: 2, background: "white" }}>
        <Typography variant="h6" sx={{ color: theme.palette.heading.main }}>
          Transfer notes for the lead: {selectedLead?.contact_person}
        </Typography>
        <Box sx={{ mt: 3 }}>
          {notes?.map((n, i) => (
            <TransferBox note={n} i={i} />
          ))}
        </Box>
      </Box>
      {/* {notes?.length > 0 ? (
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    // color: theme.palette.colortwo.main,
                    fontSize: "0.9rem",
                  }}
                >
                  Transfer Date
                </TableCell>
                <TableCell
                  sx={{
                    // color: theme.palette.colortwo.main,
                    fontSize: "0.9rem",
                  }}
                >
                  From
                </TableCell>
                <TableCell
                  sx={{
                    // color: theme.palette.colortwo.main,
                    fontSize: "0.9rem",
                  }}
                >
                  To
                </TableCell>
                <TableCell
                  sx={{
                    // color: theme.palette.colortwo.main,
                    fontSize: "0.9rem",
                  }}
                >
                  Note
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {notes?.map((o) => (
                <TransferRow o={o} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography>N/A</Typography>
      )}*/}
    </Box>
  );
};

export default TransferHistory;
const TransferBox = ({ note, i }) => {
  const [from, setFrom] = useState({});
  const [to, setTo] = useState({});
  const user = useSelector((state) => state.user);
  const theme = useTheme();

  useEffect(() => {
    api
      .get("/getUserById/" + note?.transfer_from)
      .then((res) => setFrom(res?.data))
      .catch((err) => console.log(err));
    api
      .get("/getUserById/" + note?.transfer_to)
      .then((res) => setTo(res?.data))
      .catch((err) => console.log(err));
  }, []);
  return (
    <Box
      sx={{
        mb: 2,
        p: 1,
        background: theme.palette.selectedMenuBack.main,
        borderRadius: "3px",
        maxWidth: "500px",
      }}
    >
      <Box>
        <Typography sx={{ color: theme.palette.primary.main }}>
          {note?.note}
        </Typography>
      </Box>
      <Box
        sx={{
          mt: 1,

          gap: "10px",
        }}
      >
        <Typography variant="body2" sx={{ color: theme.palette.para?.main }}>
          Transferred from: {from?.id == user?.id ? "You" : from?.name}
        </Typography>
        <Typography variant="body2" sx={{ color: theme.palette.para?.main }}>
          Transferred to: {to?.id == user?.id ? "You" : to?.name}
        </Typography>
        <Typography
          variant="body2"
          sx={{ color: theme.palette.para?.main, mt: 1.3, textAlign: "right" }}
        >
          {new Date(note?.date_of_transfer)?.toDateString()}
        </Typography>
      </Box>
    </Box>
  );
};

// const TransferRow = ({ o }) => {
//   const [from, setFrom] = useState({});
//   const [to, setTo] = useState({});
//   useEffect(() => {
//     api
//       .get("/getUserById/" + o?.transfer_from)
//       .then((res) => setFrom(res?.data))
//       .catch((err) => console.log(err));
//     api
//       .get("/getUserById/" + o?.transfer_to)
//       .then((res) => setTo(res?.data))
//       .catch((err) => console.log(err));
//   }, []);
//   return (
//     <TableRow>
//       <TableCell>{new Date(o?.date_of_transfer)?.toDateString()}</TableCell>
//       <TableCell>{from?.name}</TableCell>
//       <TableCell>{to?.name}</TableCell>
//       <TableCell>{o?.note}</TableCell>
//     </TableRow>
//   );
// };
