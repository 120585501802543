import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useState, useEffect } from "react";

import logo from "../../utils/images/edge.jpg";
import salesImg from "../../utils/images/sales.png";
import emailImg from "../../utils/images/email.png";
import chatImg from "../../utils/images/chat.png";
import scheduleImg from "../../utils/images/schedule.png";
import scoreImg from "../../utils/images/score.png";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";

const Feature = ({ featureData, direction }) => {
  const { title, image, description } = featureData;
  const isLeftDirection = direction === "left";
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Use react-intersection-observer to detect when the component is in view
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: "-100px 0px",
  });

  // Use state to control the animation
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    if (inView) {
      setAnimate(true);
    }
  }, [inView]);
  return (
    <Grid
      container
      spacing={4}
      sx={{ marginBottom: isMobile ? "60px" : "150px" }}
    >
      <Grid item xs={12} md={6} order={isLeftDirection ? 2 : 1}>
        <motion.div
          ref={ref}
          initial={{
            opacity: 0,
            x: isLeftDirection && !isMobile ? "-50%" : "50%",
          }}
          animate={animate ? { opacity: 1, x: "0%" } : {}}
          transition={{ duration: 0.8, ease: "easeOut" }}
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "0 25px",
            justifyContent: "center",
          }}
        >
          <br /> <br /> <br />
          <Typography
            variant="h5"
            sx={{ fontWeight: "500", color: theme.palette.heading.main }}
          >
            {title}
          </Typography>
          <br />
          <Typography
            variant="p"
            sx={{
              marginTop: "50px",
              fontSize: "1.1rem",
              fontWeight: "300",
              color: theme.palette.para.main,
            }}
          >
            {description}
          </Typography>
        </motion.div>
      </Grid>
      <Grid
        item
        sx={{
          display: "flex",
          justifyContent: isLeftDirection ? "right" : "left",
          marginTop: isMobile ? "30px" : "0",
        }}
        xs={12}
        md={6}
        order={isLeftDirection && !isMobile ? 1 : 2}
      >
        <motion.img
          ref={ref}
          initial={{ opacity: 0, x: isLeftDirection ? "50%" : "-50%" }}
          animate={animate ? { opacity: 1, x: "0%" } : {}}
          transition={{ duration: 0.8, ease: "easeOut" }}
          src={image}
          alt="Sales conversion"
          style={{ width: "100%" }}
        />
      </Grid>
    </Grid>
  );
};

const SystemFeatures = () => {
  const [systemDetails, setSystemDetails] = useState({
    sales: {
      title: "Track Your Progress and Close More Deals",
      image: salesImg,
      description:
        "Effortlessly track your progress towards sales conversion using our advanced system. With intuitive tools and real-time insights, you can close more deals and boost your revenue.",
    },
    email: {
      title: "Streamline Communication and Connect with Prospects",
      image: emailImg,
      description:
        "Our system allows you to communicate with prospects via email, making it easier than ever to connect with potential clients. Say goodbye to cluttered inboxes and hello to streamlined communication.",
    },
    schedule: {
      title: "Stay Organized and Never Miss a Beat",
      image: scheduleImg,
      description:
        "Efficiently manage your schedule and stay on top of your tasks with our easy-to-use system. Stay organized, never miss a deadline, and get more done in less time.",
    },
    chat: {
      title: "Collaborate with Your Team and Boost Productivity",
      image: chatImg,
      description:
        "With our built-in chat feature, you can communicate with team members in real-time, sharing ideas and collaborating on projects seamlessly. Improve productivity and take your business to the next level.",
    },
    score: {
      title: "Track Your Progress and Boost Your Success",
      image: scoreImg,
      description:
        "Score prospects based on progress made, allowing you to identify areas for improvement and boost your success rate. With our advanced tracking system, you'll have all the data you need to succeed.",
    },
  });
  return (
    <Box>
      <Container>
        {/* <SalesConversionEmailScheduleTasks />
      <ChatScore /> */}
        <Box>
          <Feature featureData={systemDetails?.sales} direction="left" />
          <Feature featureData={systemDetails?.email} direction="right" />
          <Feature featureData={systemDetails?.schedule} direction="left" />
          <Feature featureData={systemDetails?.chat} direction="right" />
          <Feature featureData={systemDetails?.score} direction="left" />
        </Box>
      </Container>
    </Box>
  );
};

export default SystemFeatures;
