import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { api } from "../../../axios/api";
import ViewReports from "./ViewReports";

const Reports = () => {
  const reportTypes = [
    "Leads by number of days open",
    // "Leads by star rating",
    "Leads by point rating",
    "Leads by milestone level",
    "Leads by number of engagements/notes",
    // "Leads by employee by type (points, stars, milestone)",
    "Average action days",
    "Average number of engagements/notes",
    "List of won leads",
    "List of dropped leads",

    "Performance tracking week on week",
    "Performance tracking month on month",
    // "Performance tracking this month vs last month",
    "Performance tracking this year vs last year",
    "Performance tracking this month vs last year same month",
    "Performance tracking this week vs last year same week",
    "Performance tracking vs target",
  ];

  const [reportData, setReportData] = useState([]);
  const [selectedReportTypeToView, setSelectedReportTypeToView] = useState("");
  const [showReport, setShowReport] = useState(false);
  const [leads, setLeads] = useState([]);
  const [organizationInformation, setOrganizationInformation] = useState({});

  useEffect(() => {
    console.log("running useeffect");
    api
      .get("/organization")
      .then((res) => {
        console.log("organization information", res?.data);
        setOrganizationInformation(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    api
      .get("/prospects")
      .then((res) => {
        console.log("response from getting prospects", res?.data);
        let leadsAfterAddition = res?.data?.map((data) => {
          let startDate = new Date(data?.date);
          let totalDays = 0;
          let totalHours = 0;
          let totalMinutes = 0;
          let endDate;

          if (data?.end_date && (data?.isDropped || data?.Won)) {
            endDate = new Date(data?.end_date);
          } else {
            endDate = new Date(Date.now());
          }
          console.log("start date", startDate);
          console.log("end date", endDate);
          let timeDifference = endDate.getTime() - startDate.getTime(); // Difference in milliseconds
          totalDays = Math.floor(timeDifference / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
          totalHours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24); // Convert milliseconds to hours and calculate the remaining hours
          totalMinutes = Math.floor((timeDifference / (1000 * 60)) % 60); // Convert milliseconds to minutes and calculate the remaining minutes
          let totalTime =
            totalDays +
            " days " +
            totalHours +
            " hours " +
            totalMinutes +
            " minutes ";

          //no of notes
          let noOfNotes = 0;

          return {
            ...data,
            total_open_days: totalTime,
          };
        });

        console.log("leads after addition", leadsAfterAddition);

        // Fetch notes for each lead and resolve promises before setting state
        Promise.all(
          leadsAfterAddition.map(async (lead) => {
            let noOfNotes = 0;
            await api
              .get("/leadsNotes/" + lead?.lead_serial_number)
              .then((res) => {
                console.log("res", res?.data);
                noOfNotes = res?.data?.length;
              });
            return { ...lead, no_of_notes: noOfNotes };
          })
        ).then((resolvedLeads) => {
          setLeads(resolvedLeads);
        });
      })
      .catch((err) => toast.error("unable o download report"));
  }, []);

  const downloadReport = (reportType) => {
    console.log("report type download", reportType);
  };
  const viewReport = (reportType) => {
    setSelectedReportTypeToView(reportType);
    console.log("report type view", reportType);
    setShowReport(true);
  };

  return (
    <Box>
      <ToastContainer />
      {!organizationInformation?.id ? (
        <Box>
          <Typography sx={{ mt: 3 }}>
            {" "}
            Update your organization details
          </Typography>
        </Box>
      ) : (
        <>
          <Typography variant="h5" sx={{ mb: 2 }}>
            View Reports
          </Typography>
          {showReport ? (
            <ViewReports
              reportType={selectedReportTypeToView}
              leads={leads}
              setShowReport={setShowReport}
            />
          ) : (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Report Type</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reportTypes.map((reportType, index) => (
                    <TableRow key={index}>
                      <TableCell>{reportType}</TableCell>
                      <TableCell>
                        {/* <Button
                      variant="outlined"
                      sx={{ mr: 1 }}
                      onClick={() => downloadReport(reportType)}
                    >
                      Download Report
                    </Button> */}
                        <Button
                          variant="outlined"
                          onClick={() => viewReport(reportType)}
                        >
                          View Report
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </>
      )}
    </Box>
  );
};

export default Reports;
