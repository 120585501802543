import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import { WhatsAppWidget } from "react-whatsapp-widget";
import Modal from "@mui/material/Modal";
import ClearIcon from "@mui/icons-material/Clear";
import { api } from "../axios/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "react-whatsapp-widget/dist/index.css";
import Footer from "../components/Footer";
import {
  Box,
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  useMediaQuery,
  RadioGroup,
  Radio,
  FormControlLabel,
  Input,
  Switch,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import styled from "@mui/material/styles/styled";
import { Icon } from "@mui/material";
import { Check, Close } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";

// import React, { useState } from "react";
import { Card, CardContent, makeStyles } from "@mui/material";
import { useNavigate } from "react-router-dom";

// const SubscriptionOption = ({
//   title,
//   description,
//   price,
//   users,
//   leads,
//   features,
//   onSelect,
// }) => {
//   const user = useSelector((state) => state.user);
//   const theme = useTheme();
//   const optionStyle = {
//     margin: "16px",
//     padding: "16px",
//     transition: "box-shadow 0.3s",
//     cursor: "pointer",
//   };

//   return (
//     <Card
//       style={optionStyle}
//       sx={{
//         "&:hover": {
//           boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
//           backgroundColor: theme.palette.selectedMenuBack.main,
//         },
//       }}
//       onClick={onSelect}
//     >
//       <CardContent>
//         <Box
//           sx={{
//             minHeight: "65px",
//             my: 1,
//             // border: "3px solid green"
//           }}
//         >
//           <Typography
//             variant="h6"
//             component="Box"
//             sx={{
//               color: theme.palette.heading.main,
//               fontWeight: "600",
//               fontSize: "1.1rem",
//             }}
//           >
//             {title}
//           </Typography>
//           <Typography
//             variant="body2"
//             sx={{
//               fontWeight: "400",
//               fontSize: "0.8rem",
//               color: theme.palette.para.main,
//               mt: "-0.1rem",
//               lineHeight: "15px",
//             }}
//           >
//             {description}
//           </Typography>
//         </Box>
//         <Box
//           sx={{
//             height: "60px",
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//             // border: "2px solid red",
//           }}
//         >
//           <Typography
//             variant="h4"
//             sx={{
//               textAlign: "center",
//               fontSize: "1.6rem",
//               fontWeight: "600",
//             }}
//           >
//             Ksh {price}
//             <sub style={{ fontSize: "1rem" }}>/Year</sub>
//           </Typography>
//         </Box>

//         {/* <Typography color="textSecondary">{users} users</Typography>
//         <Typography color="textSecondary">{leads} leads</Typography> */}
//         <Box
//           sx={{ display: "flex", flexDirection: "column", gap: "10px", mt: 2 }}
//         >
//           {features.map((feature, index) => (
//             <Box key={index} sx={{ display: "flex" }}>
//               <Box
//                 sx={{
//                   marginRight: "11px",
//                   color: theme.palette.primary.main,
//                   fontWeight: "600",
//                 }}
//               >
//                 ✓
//               </Box>
//               <Typography variant="p" sx={{ fontSize: "0.9rem" }}>
//                 {feature}
//               </Typography>
//             </Box>
//           ))}
//         </Box>
//         {/* <Button variant="contained" color="primary">
//           Subscribe
//         </Button> */}
//       </CardContent>
//     </Card>
//   );
// };
const SubscriptionOption = ({
  title,
  description,
  price,
  monthly_price,
  users,
  leads,
  features,
  onSelect,
  isSubscribed,
  activeMonthlyBilling,
}) => {
  const theme = useTheme();

  return (
    <Card
      sx={{
        "&:hover": {
          boxShadow:
            !isSubscribed &&
            title != "Free for life" &&
            "0 0 10px rgba(0, 0, 0, 0.2)",
          backgroundColor:
            !isSubscribed &&
            title != "Free for life" &&
            theme.palette.selectedMenuBack.main,
        },
        margin: "16px 10px",
        padding: { xs: "10px", lg: "16px" },
        transition: "box-shadow 0.3s",
        cursor: !isSubscribed && title != "Free for life" && "pointer",
        background: isSubscribed && theme.palette.selectedMenuBack?.main,
      }}
      onClick={() => !isSubscribed && title != "Free for life" && onSelect()}
    >
      <CardContent>
        <Box
          sx={{
            minHeight: "65px",
            my: 2,
            // border: "3px solid green"
          }}
        >
          <Typography
            variant="h6"
            component="Box"
            sx={{
              color: theme.palette.heading.main,
              fontWeight: "600",
              fontSize: "1.1rem",
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontWeight: "400",
              fontSize: "0.8rem",
              color: theme.palette.para.main,
              mt: "-0.1rem",
              lineHeight: "15px",
            }}
          >
            {description}
          </Typography>
        </Box>
        <Box
          sx={{
            height: "60px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // border: "2px solid red",
          }}
        >
          {isSubscribed ? (
            <Typography
              variant="h5"
              sx={{
                textAlign: "center",
                fontSize: "1.6rem",
                fontWeight: "600",
                color: theme.palette.primary.main,
              }}
            >
              Subscribed
            </Typography>
          ) : (
            <Typography
              variant="h5"
              sx={{
                textAlign: "center",
                fontSize: "1.6rem",
                fontWeight: "600",
              }}
            >
              Ksh {activeMonthlyBilling ? monthly_price : price}
              <sub style={{ fontSize: "1rem" }}>
                /{activeMonthlyBilling ? "Month" : "Year"}
              </sub>
            </Typography>
          )}
        </Box>

        {/* <Typography color="textSecondary">{users} users</Typography>
        <Typography color="textSecondary">{leads} leads</Typography> */}
        <Box
          sx={{ display: "flex", flexDirection: "column", gap: "10px", mt: 2 }}
        >
          {features.map((feature, index) => (
            <Box key={index} sx={{ display: "flex" }}>
              <Box
                sx={{
                  marginRight: "11px",
                  color: theme.palette.primary.main,
                  fontWeight: "600",
                }}
              >
                ✓
              </Box>
              <Typography variant="p" sx={{ fontSize: "0.9rem" }}>
                {feature}
              </Typography>
            </Box>
          ))}
        </Box>
        {/* <Button variant="contained" color="primary">
          Subscribe
        </Button> */}
      </CardContent>
    </Card>
  );
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Pricing = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [selectedOption, setSelectedOption] = useState(null);
  const user = useSelector((state) => state.user);
  const handleSelectOption = (option) => {
    if (!user?.id) {
      navigate("/auth/login");
    }
    console.log("selected option", selectedOption);
    setSelectedOption(option);
    handleOpen();
  };

  const [openPaymentModal, setOpenPaymentModal] = React.useState(false);
  const handleOpen = () => setOpenPaymentModal(true);
  const handleClose = () => {
    setCouponCode("");
    setIsCouponApplied(false);
    setTotalOrderValue(0);
    setOpenPaymentModal(false);
  };

  // const subscriptionOptions = [
  //   // {
  //   //   title: "Free for life",
  //   //   description: "Everyone is invited. Don't be left behind",
  //   //   price: 0,
  //   //   //   users: "2 users",
  //   //   //   leads: "10 leads",
  //   //   features: [
  //   //     "2 users",
  //   //     "10 leads",
  //   //     "Earn money/Post on the Notice Board",
  //   //     "View available opportunities",
  //   //     // "Manage leads pipeline",
  //   //   ],
  //   // },
  //   // {
  //   //   title: "Starter Pack",
  //   //   description: "Run your business with expertise.",
  //   //   price: 14400,
  //   //   //   users: "3 users",
  //   //   //   leads: "unlimited leads",
  //   //   features: [
  //   //     "3 users",
  //   //     "unlimited leads",
  //   //     "Earn money/Post on the Notice Board",
  //   //     "View available opportunities",
  //   //     "Manage leads pipeline",
  //   //     "View Leads Performance Reports",
  //   //   ],
  //   // },
  //   // {
  //   //   title: "Booster Pack",
  //   //   description: "Speed up your success. Grab a Booster.",
  //   //   price: 18300,
  //   //   //   users: "5 users",
  //   //   //   leads: "unlimited leads",
  //   //   features: [
  //   //     "5 users",
  //   //     "unlimited leads",
  //   //     "Earn money/Post on the Notice Board",
  //   //     "View available opportunities",
  //   //     "Manage leads pipeline",
  //   //     "View Leads Performance Reports",
  //   //     "View Premium Referrals",
  //   //   ],
  //   // },
  //   // {
  //   //   title: "Power Pack",
  //   //   description: "Amplify your success with added power",
  //   //   price: 25200,
  //   //   //   users: "10 users",
  //   //   //   leads: "unlimited leads",
  //   //   features: [
  //   //     "10 users",
  //   //     "unlimited leads",
  //   //     "Earn money/Post on the Notice Board",
  //   //     "View available opportunities",
  //   //     "Manage leads pipeline",
  //   //     "View Leads Performance Reports",
  //   //     "View Premium Referrals",
  //   //     "Create a Network",
  //   //     "Manage a Network",
  //   //   ],
  //   // },
  //   // Add more subscription options as needed
  // ];
  const [subscriptionOptions, setSubscriptionOptions] = useState([]);
  useEffect(() => {
    api
      .get("/subscriptions/general")
      .then((res) => {
        console.log("subscriptions", res?.data);
        setSubscriptionOptions(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const userSubscriptionData = useSelector((state) => state?.subscription_data);
  console.log("subscription_data at user profile", userSubscriptionData);
  const [subscribedOption, setSubscribedOption] = useState({});
  useEffect(() => {
    if (userSubscriptionData?.offer_purchased) {
      setSubscribedOption(
        subscriptionOptions?.filter(
          (op) =>
            op?.title?.toLowerCase() ==
            userSubscriptionData?.offer_purchased?.toLowerCase()
        )[0]
      );
    } else setSubscribedOption(subscriptionOptions[0]);
  }, [subscriptionOptions, userSubscriptionData]);

  const [totalOrderValue, setTotalOrderValue] = useState(0);
  useEffect(() => {
    if (selectedOption?.price) {
      setTotalOrderValue(selectedOption?.price);
    }
  }, [selectedOption]);
  const [couponCode, setCouponCode] = useState("");
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  const [couponError, setCouponError] = useState("");
  const [activeMonthlyBilling, setActiveMonthlyBilling] = useState(false);
  const dispatch = useDispatch();
  const useCouponCode = () => {
    if (!couponCode) {
      setCouponError("missing field");
      return;
    }
    api
      .put("/coupons/use", { code: couponCode })
      .then((res) => {
        console.log("res", res?.data);
        let c = res?.data;
        console.log("totalOrderValue ", totalOrderValue);
        console.log(" parseInt(c?.value)", parseInt(c?.value));
        console.log(
          "totalOrderValue - parseInt(c?.value)",
          totalOrderValue - parseInt(c?.value)
        );
        setTotalOrderValue(totalOrderValue - parseInt(c?.value));
        setIsCouponApplied(true);
        toast.success("coupon applied!");
      })
      .catch((err) => {
        console.log(err);
        setCouponError("unable to apply this coupon");
      });
  };
  useEffect(() => {
    setCouponError("");
  }, [couponCode]);
  function fetchUserSubscriptionData() {
    api
      .get("/user/subscription")
      .then((res) => {
        console.log("response from getting user subscriptio", res.data);
        dispatch({
          type: "SET_Subscription_Data",
          payload: res?.data,
        });
      })
      .catch((err) => console.log("err from getting user", err));
  }
  const checkoutOffer = () => {
    if (selectedOption?.title) {
      api
        .put(`/users/purchase-subscription/${user?.id}`, {
          title: selectedOption?.title,
        })
        .then((res) => {
          console.log("response form updating offer", res?.data);
          setOpenPaymentModal(false);
          fetchUserSubscriptionData();
        })
        .catch((err) => console.log(err));
    }
  };
  return (
    <Box>
      <Navbar />
      <ToastContainer />
      <Container>
        <Modal
          open={openPaymentModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ zIndex: "1000000000" }}
        >
          <Box sx={style}>
            <Box sx={{}}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6">Order Summary</Typography>
                <IconButton onClick={handleClose}>
                  <ClearIcon />
                </IconButton>
              </Box>

              <Box sx={{ my: 1 }}>
                <Typography variant="body2">Selected Option</Typography>
                <Typography
                  sx={{
                    fontWeight: "500",
                    color: theme.palette.primary.main,
                    fontSize: "1.2rem",
                  }}
                >
                  {selectedOption?.title}
                </Typography>
              </Box>
              <Box sx={{ my: 3, borderBottom: "1px solid lightgray", pb: 5 }}>
                {isCouponApplied ? (
                  <>
                    <Typography>Coupon Applied.</Typography>
                  </>
                ) : (
                  <>
                    <Typography>Coupon Code</Typography>
                    <Box>
                      <Input onChange={(e) => setCouponCode(e.target.value)} />
                      <Button onClick={useCouponCode}>Apply</Button>
                      {couponError && (
                        <Typography sx={{ color: "red" }}>
                          {couponError}
                        </Typography>
                      )}
                    </Box>
                  </>
                )}
              </Box>
              <Box
                sx={{ my: 2, display: "flex", justifyContent: "space-between" }}
              >
                <Typography>Total</Typography>
                <Typography sx={{ fontWeight: "600", fontSize: "1.3rem" }}>
                  Ksh {totalOrderValue}
                </Typography>
              </Box>
              <Button
                variant="contained"
                sx={{ width: "100%", mt: 2 }}
                onClick={checkoutOffer}
              >
                Checkout
              </Button>
            </Box>
          </Box>
        </Modal>
        <Typography
          variant="h4"
          sx={{
            fontWeight: "700",
            color: theme.palette.heading.main,
            textAlign: "center",
          }}
        >
          Plans and Pricing
        </Typography>

        <Box
        //   style={{
        //     display: "flex",
        //     justifyContent: "center",
        //     alignItems: "center",
        //     height: "100vh",
        //   }}
        >
          <Typography
            sx={{ textAlign: "center", mt: 1, color: theme.palette.para.main }}
          >
            Choose annual billing for big discount or go monthly for flexibility
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 2,
            }}
          >
            {" "}
            <Typography>Annual</Typography>
            <Switch
              value={activeMonthlyBilling}
              onChange={() => setActiveMonthlyBilling(!activeMonthlyBilling)}
            />
            <Typography>Monthly</Typography>
          </Box>

          <Box sx={{ my: 4, mt: 2, minHeight: "500px" }}>
            <Box
              style={{
                display: "flex",
                // flexWrap: "wrap"
              }}
            >
              <Grid container>
                {subscriptionOptions.map((option, index) => (
                  <Grid xs={12} sm={6} md={4} lg={3} item>
                    <SubscriptionOption
                      key={index}
                      {...option}
                      onSelect={() =>
                        // handleSelectOption(option)
                        {
                          dispatch({
                            type: "SET_Dashboard_Tab",
                            payload: "subscriptions",
                          });
                          if (user?.id) navigate("/dashboard");
                          else navigate("/auth/login");
                        }
                      }
                      // selected={user?.id && selectedOption === option}
                      isSubscribed={
                        user?.id && subscribedOption?.title == option?.title
                      }
                      activeMonthlyBilling={activeMonthlyBilling}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        </Box>
      </Container>{" "}
      <WhatsAppWidget phoneNumber={process.env.REACT_APP_WHATSAPP_NUMBER} />
      <Footer />
    </Box>
  );
};

export default Pricing;
