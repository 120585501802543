import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
  IconButton,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useTheme } from "@emotion/react";
import { api } from "../../../../axios/api";
import Modal from "@mui/material/Modal";
import { ToastContainer, toast } from "react-toastify";
import Confetti from "react-confetti";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  //   border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const WonIt = ({ selectedLead, setShowIndividualLeadFeature }) => {
  const theme = useTheme();
  const [note, setNote] = useState("");
  const [noteValue, setNoteValue] = useState(0);
  const user = useSelector((state) => state.user);
  const [showConfetti, setShowConfetti] = useState(false);
  const [organizationInformation, setOrganizationInformation] = useState();

  useEffect(() => {
    console.log("running useeffect");
    api
      .get("/organization")
      .then((res) => {
        console.log("res?.data", res?.data);
        setOrganizationInformation(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const cancelWinning = () => {
    setNote("");
    handleClose();
  };
  const confirmWinning = () => {
    console.log("note", note);

    if (note?.length < 1) {
      return toast.error(`field can't be empty`);
    }
    if (noteValue < 1) {
      return toast.error(`Add Value of the lead!`);
    }
    let obj = {
      prospect_id: selectedLead?.lead_serial_number,
      uploaded_by: user?.id,
      note: `${note}\nNote value: ${noteValue}\n(Winning-note)`,
      happiness_rating: 5,
    };
    api
      .post("/addLeadNote", obj)
      .then((res) => {
        console.log("response", res?.data);
        api
          .put("/wonTheLead/" + selectedLead?.lead_serial_number)
          .then((res) => {
            console.log(res?.data);
            let body = {
              prospect_id: selectedLead?.lead_serial_number,
              text: `${note}\nNote value: ${noteValue}\n(Winning-note)`,
              is_general: true,
            };
            api
              .post("/message/add", body)
              .then((res) => {
                setNote("");
                toast.success("Job well done, remember to thanks the client!");
                handleClose();
                setShowConfetti(true);
                setTimeout(() => {
                  setShowIndividualLeadFeature(false);
                }, 3000);
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => toast.error("unable to won the lead at moment"));
      })
      .catch((err) => {
        toast.error("unable to add note");
      });
  };
  return (
    <Box>
      <ToastContainer />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ mb: 2 }}
          >
            Explain in detail including final lead value
          </Typography>
          <textarea
            style={{ padding: "10px", width: "100%", minHeight: "120px" }}
            placeholder="enter here"
            onChange={(e) => setNote(e.target.value)}
          />
          <input
            type="number"
            style={{ padding: "10px", width: "100%" }}
            placeholder={`Value (${organizationInformation?.organization_currency})`}
            onChange={(e) => setNoteValue(e.target.value)}
          />
          <Box sx={{ mt: 3, display: "flex", gap: "10px" }}>
            <Button
              color="primary"
              size="small"
              onClick={() => cancelWinning()}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="samll"
              color="primary"
              onClick={() => confirmWinning()}
            >
              Confirm Win
            </Button>
          </Box>
        </Box>
      </Modal>
      <Button
        color="primary"
        variant="contained"
        onClick={() => handleOpen()}
        sx={{ mt: 2 }}
      >
        Won
      </Button>
      {showConfetti && (
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          recycle={false}
        />
      )}
    </Box>
  );
};

export default WonIt;
