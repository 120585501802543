import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
  IconButton,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  Avatar,
  Input,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useTheme } from "@mui/material";
import { api } from "../../axios/api";
import { useSelector } from "react-redux";
import * as filestack from "filestack-js";
import * as XLSX from "xlsx";
import { useNavigate } from "react-router-dom";

const DashboardContainer = () => {
  const theme = useTheme();
  const user = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(true);
  const [showDownloadButton, setShowDownloadButton] = useState(false);
  const [downloadFileUrl, setDownloadFileUrl] = useState("");
  const [isLoadingReportDownload, setIsLoadingDownloadReport] = useState(false);
  const [data, setData] = useState([
    // {
    //   name: "Admin",
    //   profile:
    //     "https://img.freepik.com/free-photo/portrait-white-man-isolated_53876-40306.jpg",
    //   openLeads: "21",
    //   averageAge: "79",
    //   avActionDays: "12",
    //   hotLeads: "2",
    //   target: "8000",
    //   valueWon: "3489",
    //   contribution: "28%",
    // },
    // {
    //   name: "Raj",
    //   profile:
    //     "https://www.faceapp.com/static/img/content/compare/beard-example-before@3x.jpg",
    //   openLeads: "87",
    //   averageAge: "65",
    //   avActionDays: "9.75",
    //   hotLeads: "14",
    //   target: "20,000",
    //   valueWon: "12,319",
    //   contribution: "100%",
    // },
    // {
    //   name: "Polly",
    //   profile:
    //     "https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8bWFuJTIwZmFjZXxlbnwwfHwwfHw%3D&w=1000&q=80",
    //   openLeads: "21",
    //   averageAge: "79",
    //   avActionDays: "12",
    //   hotLeads: "2",
    //   target: "8000",
    //   valueWon: "3489",
    //   contribution: "28%",
    // },
  ]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  useEffect(() => {
    // Set default values when the component mounts
    const defaultStartDate = new Date();
    defaultStartDate.setMonth(defaultStartDate.getMonth() - 1); // One month behind
    setStartDate(defaultStartDate.toISOString().split("T")[0]); // Format as YYYY-MM-DD

    const defaultEndDate = new Date();
    defaultEndDate.setDate(defaultEndDate.getDate() + 1); // Tomorrow
    setEndDate(defaultEndDate.toISOString().split("T")[0]); // Format as YYYY-MM-DD
  }, []); // Empty dependency array ensures this effect runs only once when the component mounts

  const [organizationInformation, setOrganizationInformation] = useState();

  useEffect(() => {
    api
      .get("/organization")
      .then((res) => {
        console.log("res?.data", res?.data);
        setOrganizationInformation(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);
  const [leads, setLeads] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch prospects data
        const prospectsResponse = await api.get("/prospects");
        console.log("response from getting prospects", prospectsResponse?.data);

        let leadsAfterAddition = prospectsResponse?.data?.map((data) => {
          let startDate = new Date(data?.date);
          let totalDays = 0;
          let totalHours = 0;
          let totalMinutes = 0;
          let endDate;

          if (data?.end_date && (data?.isDropped || data?.Won)) {
            endDate = new Date(data?.end_date);
          } else {
            endDate = new Date(Date.now());
          }

          let timeDifference = endDate.getTime() - startDate.getTime();
          totalDays = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
          totalHours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
          totalMinutes = Math.floor((timeDifference / (1000 * 60)) % 60);
          let totalTime =
            totalDays +
            " days " +
            totalHours +
            " hours " +
            totalMinutes +
            " minutes ";

          let noOfNotes = 0;

          return {
            ...data,
            total_open_days: totalTime,
          };
        });

        // Fetch notes for each lead and resolve promises before setting state
        let resolvedLeads = await Promise.all(
          leadsAfterAddition.map(async (lead) => {
            let noOfNotes = 0;
            await api
              .get("/leadsNotes/" + lead?.lead_serial_number)
              .then((res) => {
                console.log("res", res?.data);
                noOfNotes = res?.data?.length;
              });
            return { ...lead, no_of_notes: noOfNotes };
          })
        );
        resolvedLeads = resolvedLeads?.length > 0 ? resolvedLeads : [];
        // setLeads(resolvedLeads);

        // Fetch all users data
        const usersResponse = await api.get("/getAllUsersByAdmin");
        console.log("response from getting users", usersResponse?.data);

        let allUsers = usersResponse?.data;
        // allUsers?.unshift(user);

        const avgActionDaysPromises = allUsers?.map(async (d) => {
          let avg = 0;

          resolvedLeads
            ?.filter((l) => l?.owner_id == d?.id)
            ?.filter((l) => !l?.is_won && !l?.is_dropped)
            ?.filter((l) =>
              l?.is_won || l?.is_dropped
                ? new Date(l?.end_date) <= new Date(endDate) &&
                  new Date(l?.end_date) >= new Date(startDate)
                : new Date(l?.date) <= new Date(endDate) &&
                  new Date(l?.date) >= new Date(startDate)
            )
            ?.map((lead) => {
              let days;
              if (lead?.end_date) {
                days =
                  (new Date(lead?.end_date) - new Date(lead?.date)) /
                  (1000 * 60 * 60 * 24);
              } else {
                days =
                  (new Date() - new Date(lead?.date)) / (1000 * 60 * 60 * 24);
              }
              console.log("days", days);
              avg = avg + days;
            });

          avg =
            avg /
            resolvedLeads
              ?.filter((l) => l?.owner_id == d?.id)
              ?.filter((l) => !l?.is_won && !l?.is_dropped)?.length;

          let daily_target = 0;
          try {
            daily_target = await api.get("/target/" + d?.id);
          } catch (err) {
            console.log("err in getting target", err);
            daily_target = 0;
          }

          daily_target = daily_target?.data?.daily_target;
          console.log("daily target", daily_target);

          let totalTarget;
          if (daily_target && !isNaN(daily_target)) {
            totalTarget =
              ((new Date(endDate) - new Date(startDate)) /
                (1000 * 60 * 60 * 24)) *
              daily_target;
          } else {
            totalTarget = "N/A";
          }
          console.log("total target", totalTarget);

          console.log("resolved leads", resolvedLeads);
          return {
            name: d?.name,
            profile: d?.profile_image,
            openLeads: resolvedLeads
              ?.filter((l) => l?.owner_id == d?.id)
              ?.filter((l) => !l?.is_won && !l?.is_dropped)
              ?.filter((l) =>
                l?.is_won || l?.is_dropped
                  ? new Date(l?.end_date) <= new Date(endDate) &&
                    new Date(l?.end_date) >= new Date(startDate)
                  : new Date(l?.date) <= new Date(endDate) &&
                    new Date(l?.date) >= new Date(startDate)
              )?.length,
            averageAge: avg > 1 ? parseInt(avg) : 0,
            hotLeads: resolvedLeads
              ?.filter((l) => !l?.is_won && !l?.is_dropped)
              ?.filter((l) => l?.owner_id == d?.id)
              ?.filter((l) =>
                l?.is_won || l?.is_dropped
                  ? new Date(l?.end_date) <= new Date(endDate) &&
                    new Date(l?.end_date) >= new Date(startDate)
                  : new Date(l?.date) <= new Date(endDate) &&
                    new Date(l?.date) >= new Date(startDate)
              )
              ?.filter((l) => parseInt(l?.points) >= 16)?.length,
            warmLeads: resolvedLeads
              ?.filter((l) => !l?.is_won && !l?.is_dropped)
              ?.filter((l) => l?.owner_id == d?.id)
              ?.filter((l) =>
                l?.is_won || l?.is_dropped
                  ? new Date(l?.end_date) <= new Date(endDate) &&
                    new Date(l?.end_date) >= new Date(startDate)
                  : new Date(l?.date) <= new Date(endDate) &&
                    new Date(l?.date) >= new Date(startDate)
              )
              ?.filter(
                (l) => parseInt(l?.points) >= 10 && parseInt(l?.points) <= 15
              )?.length,
            coldLeads: resolvedLeads
              ?.filter((l) => !l?.is_won && !l?.is_dropped)
              ?.filter((l) => l?.owner_id == d?.id)
              ?.filter((l) =>
                l?.is_won || l?.is_dropped
                  ? new Date(l?.end_date) <= new Date(endDate) &&
                    new Date(l?.end_date) >= new Date(startDate)
                  : new Date(l?.date) <= new Date(endDate) &&
                    new Date(l?.date) >= new Date(startDate)
              )
              ?.filter((l) => parseInt(l?.points) <= 9)?.length,
            target: totalTarget ? `${totalTarget} ` : "N/A",
            valueWon: `${resolvedLeads
              ?.filter((l) => l?.owner_id == d?.id)
              ?.filter((l) =>
                l?.is_won || l?.is_dropped
                  ? new Date(l?.end_date) <= new Date(endDate) &&
                    new Date(l?.end_date) >= new Date(startDate)
                  : new Date(l?.date) <= new Date(endDate) &&
                    new Date(l?.date) >= new Date(startDate)
              )
              ?.filter((l) => l?.is_won)
              .reduce(
                (accumulator, currentValue) =>
                  accumulator + parseInt(currentValue?.value_of_lead),
                0
              )}`,
            contribution: `${
              isNaN(
                parseFloat(
                  (resolvedLeads
                    ?.filter((l) =>
                      l?.is_won || l?.is_dropped
                        ? new Date(l?.end_date) <= new Date(endDate) &&
                          new Date(l?.end_date) >= new Date(startDate)
                        : new Date(l?.date) <= new Date(endDate) &&
                          new Date(l?.date) >= new Date(startDate)
                    )
                    ?.filter((l) => l?.owner_id == d?.id)?.length /
                    resolvedLeads?.filter((l) =>
                      l?.is_won || l?.is_dropped
                        ? new Date(l?.end_date) <= new Date(endDate) &&
                          new Date(l?.end_date) >= new Date(startDate)
                        : new Date(l?.date) <= new Date(endDate) &&
                          new Date(l?.date) >= new Date(startDate)
                    )?.length) *
                    100
                ).toFixed(2)
              )
                ? "0"
                : parseFloat(
                    (resolvedLeads
                      ?.filter((l) =>
                        l?.is_won || l?.is_dropped
                          ? new Date(l?.end_date) <= new Date(endDate) &&
                            new Date(l?.end_date) >= new Date(startDate)
                          : new Date(l?.date) <= new Date(endDate) &&
                            new Date(l?.date) >= new Date(startDate)
                      )
                      ?.filter((l) => l?.owner_id == d?.id)?.length /
                      resolvedLeads?.filter((l) =>
                        l?.is_won || l?.is_dropped
                          ? new Date(l?.end_date) <= new Date(endDate) &&
                            new Date(l?.end_date) >= new Date(startDate)
                          : new Date(l?.date) <= new Date(endDate) &&
                            new Date(l?.date) >= new Date(startDate)
                      )?.length) *
                      100
                  ).toFixed(2)
            } %`,
          };
        });

        const allUsersDashboardInfo = await Promise.all(avgActionDaysPromises);
        console.log("allUsersDashboardInfo", allUsersDashboardInfo);

        let arrayOfUsers = [
          {
            name: "Total",
            profile:
              "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e2/Republic_Of_Korea_Broadcasting-TV_Rating_System%28ALL%29.svg/1200px-Republic_Of_Korea_Broadcasting-TV_Rating_System%28ALL%29.svg.png",
            openLeads: allUsersDashboardInfo?.reduce(
              (accumulator, currentValue) =>
                accumulator + parseInt(currentValue?.openLeads),
              0
            ),
            // allUsersDashboardInfo?.length,
            averageAge:
              allUsersDashboardInfo?.length < 1
                ? "0"
                : allUsersDashboardInfo?.reduce(
                    (accumulator, currentValue) =>
                      accumulator + parseInt(currentValue?.averageAge),
                    0
                  ) / allUsersDashboardInfo?.length,
            hotLeads: allUsersDashboardInfo?.reduce(
              (accumulator, currentValue) =>
                accumulator + parseInt(currentValue?.hotLeads),
              0
            ),
            warmLeads: allUsersDashboardInfo?.reduce(
              (accumulator, currentValue) =>
                accumulator + parseInt(currentValue?.warmLeads),
              0
            ),
            coldLeads: allUsersDashboardInfo?.reduce(
              (accumulator, currentValue) =>
                accumulator + parseInt(currentValue?.coldLeads),
              0
            ),
            target: allUsersDashboardInfo?.reduce(
              (accumulator, currentValue) =>
                accumulator +
                parseInt(
                  currentValue?.target && !isNaN(currentValue?.target)
                    ? currentValue?.target
                    : 0
                ),
              0
            ),
            valueWon: `${allUsersDashboardInfo?.reduce(
              (accumulator, currentValue) =>
                accumulator +
                parseInt(
                  currentValue?.valueWon && !isNaN(currentValue?.valueWon)
                    ? currentValue?.valueWon
                    : 0
                ),
              0
            )}`,
            contribution:
              parseInt(
                `${allUsersDashboardInfo?.reduce(
                  (accumulator, currentValue) =>
                    accumulator +
                    parseInt(
                      currentValue?.valueWon && !isNaN(currentValue?.valueWon)
                        ? currentValue?.valueWon
                        : 0
                    ),
                  0
                )}`
              ) == 0
                ? "0 %"
                : "100%",
          },
        ];
        const totalValueWon = parseInt(
          `${allUsersDashboardInfo?.reduce(
            (accumulator, currentValue) =>
              accumulator +
              parseInt(
                currentValue?.valueWon && !isNaN(currentValue?.valueWon)
                  ? currentValue?.valueWon
                  : 0
              ),
            0
          )}`
        );
        console.log("total value won", totalValueWon);
        console.log("allUsersDashboardInfo", allUsersDashboardInfo);
        /******update contribution in all users dashboard */
        let updatedUsersDashboardInfo = allUsersDashboardInfo?.map((info) => {
          console.log("info", info);
          return {
            ...info,
            contribution:
              totalValueWon == 0
                ? "0%"
                : `${(
                    (parseFloat(info?.valueWon) * 100) /
                    totalValueWon
                  )?.toFixed(2)} %`,
          };
        });

        /************************************************** */
        setData([...arrayOfUsers, ...updatedUsersDashboardInfo]);
        setIsLoading(false);
        console.log("array of users", arrayOfUsers);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [startDate, endDate, organizationInformation]); // Empty dependency array means this effect runs once after the initial render
  const uploadFileToBackend = async (file, isJSON) => {
    const formData = new FormData();
    isJSON
      ? formData.append(
          "files",
          new Blob([file], { type: "application/json" }),
          "data.json"
        )
      : formData.append("files", file);

    try {
      const response = await api.post("/files/upload/space", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("url", response?.data?.url);
      return response.data.url; // Assuming the response contains the S3 URL
    } catch (error) {
      // console.error(error);
      setIsLoading(false);
      // toast.error("unable to cntinue at the moment!");
      console.log(error);
      return null;
    }
  };
  const downloadReport = async () => {
    // setIsLoading(true);
    setIsLoadingDownloadReport(true);
    const fileName = `dashboard_Report.xlsx`;

    // Create a new workbook and add a worksheet
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Generate an XLSX file
    const wbout = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array",
    });

    // const client = filestack.init(process.env.REACT_APP_FILESTACK_API);
    const file = new File([wbout], fileName, {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    try {
      const url = await uploadFileToBackend(file);
      setIsLoading(false);
      setShowDownloadButton(true);
      setDownloadFileUrl(url);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
    // client
    //   .upload(file, {})
    //   .then((res) => {
    //     console.log("Uploaded successfully", res);
    //     // window.open(res.url, "_blank");

    //     setIsLoading(false);
    //     setShowDownloadButton(true);
    //     setDownloadFileUrl(res?.url);
    //   })
    //   .catch((error) => {
    //     console.error("Error uploading file: ", error);
    //     setIsLoading(false);
    //   });
  };
  // useEffect(() => {
  //   api
  //     .get("/prospects")
  //     .then((res) => {
  //       console.log("response from getting prospects", res?.data);
  //       let leadsAfterAddition = res?.data?.map((data) => {
  //         let startDate = new Date(data?.date);
  //         let totalDays = 0;
  //         let totalHours = 0;
  //         let totalMinutes = 0;
  //         let endDate;

  //         if (data?.end_date && (data?.isDropped || data?.Won)) {
  //           endDate = new Date(data?.end_date);
  //         } else {
  //           endDate = new Date(Date.now());
  //         }
  //         console.log("start date", startDate);
  //         console.log("end date", endDate);
  //         let timeDifference = endDate.getTime() - startDate.getTime(); // Difference in milliseconds
  //         totalDays = Math.floor(timeDifference / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
  //         totalHours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24); // Convert milliseconds to hours and calculate the remaining hours
  //         totalMinutes = Math.floor((timeDifference / (1000 * 60)) % 60); // Convert milliseconds to minutes and calculate the remaining minutes
  //         let totalTime =
  //           totalDays +
  //           " days " +
  //           totalHours +
  //           " hours " +
  //           totalMinutes +
  //           " minutes ";

  //         //no of notes
  //         let noOfNotes = 0;

  //         return {
  //           ...data,
  //           total_open_days: totalTime,
  //         };
  //       });

  //       console.log("leads after addition", leadsAfterAddition);

  //       // Fetch notes for each lead and resolve promises before setting state
  //       Promise.all(
  //         leadsAfterAddition.map(async (lead) => {
  //           let noOfNotes = 0;
  //           await api
  //             .get("/leadsNotes/" + lead?.lead_serial_number)
  //             .then((res) => {
  //               console.log("res", res?.data);
  //               noOfNotes = res?.data?.length;
  //             });
  //           return { ...lead, no_of_notes: noOfNotes };
  //         })
  //       ).then((resolvedLeads) => {
  //         setLeads(resolvedLeads);
  //       });
  //     })
  //     .catch((err) => console.error("unable o download report"));
  // }, []);

  // useEffect(() => {
  //   const fetchALLUsersByAdmin = async () => {
  //     api
  //       .get("/getAllUsersByAdmin")
  //       .then((res) => {
  //         console.log("response from getting users", res?.data);
  //         let arrayOfUsers = [
  //           {
  //             name: "Total",
  //             profile:
  //               "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e2/Republic_Of_Korea_Broadcasting-TV_Rating_System%28ALL%29.svg/1200px-Republic_Of_Korea_Broadcasting-TV_Rating_System%28ALL%29.svg.png",
  //             openLeads: leads?.length,
  //             averageAge: "65",
  //             // avActionDays: "9.75",
  //             hotLeads: leads?.filter((l) => !l?.is_won && !l?.is_dropped)
  //               ?.length,
  //             target: "20,000",
  //             valueWon: `
  //           ${leads?.reduce(
  //             (accumulator, currentValue) =>
  //               accumulator + parseInt(currentValue?.value_of_lead),
  //             0
  //           )} ${organizationInformation?.organization_currency}`,
  //             contribution: "100%",
  //           },
  //         ];
  //         let allUsers = res?.data;
  //         allUsers?.push(user);
  //         allUsers?.forEach(async (d) => {
  //           /*** avgActionDays */
  //           let avg = 0; // Initialize avg to zero
  //           leads?.map((lead) => {
  //             let days;
  //             if (lead?.end_date) {
  //               days =
  //                 (new Date(lead?.end_date) - new Date(lead?.date)) /
  //                 (1000 * 60 * 60 * 24); // Convert milliseconds to days
  //             } else {
  //               days =
  //                 (new Date() - new Date(lead?.date)) / (1000 * 60 * 60 * 24); // Convert milliseconds to days
  //             }
  //             console.log("days", days);
  //             avg = avg + days;
  //           });
  //           avg = avg / leads?.length;

  //           /**** */
  //           /***** */
  //           let daily_target = await api
  //             .get("/target/" + d?.id)
  //             .then((res) => res?.data)
  //             .catch((err) => console.log);
  //           console.log("daily target", daily_target);

  //           let totalTraget;
  //           if (daily_target && !isNaN(daily_target)) {
  //             totalTraget =
  //               (new Date(endDate) - new Date(startDate)) * daily_target;
  //           } else {
  //             totalTraget = "N/A";
  //           }
  //           console.log("total target", totalTraget);
  //           /**** */
  //           arrayOfUsers.push({
  //             name: d?.name,
  //             profile: d?.profile_image,
  //             openLeads: leads?.filter((l) => l?.owner_id == d?.id)?.length,
  //             averageAge: parseInt(avg),
  //             // avActionDays: "12",
  //             hotLeads: leads
  //               ?.filter((l) => !l?.is_won && !l?.is_dropped)
  //               ?.filter((l) => l?.owner_id == d?.id)?.length,
  //             target: totalTraget,
  //             valueWon: `
  //             ${leads
  //               ?.filter((l) => l?.owner_id == d?.id)
  //               ?.filter((l) => l?.is_won)
  //               .reduce(
  //                 (accumulator, currentValue) =>
  //                   accumulator + parseInt(currentValue?.value_of_lead),
  //                 0
  //               )} ${organizationInformation?.organization_currency}`,
  //             contribution: `${parseFloat(
  //               (leads?.filter((l) => l?.owner_id == d?.id)?.length /
  //                 leads?.length) *
  //                 100
  //             ).toFixed(2)} %`,
  //           });
  //         });
  //         setData(arrayOfUsers);
  //         setIsLoading(false);
  //         console.log("array of users", arrayOfUsers);
  //       })
  //       .catch((err) => console.log("err", err));
  //   };
  //   fetchALLUsersByAdmin();
  // }, [leads, startDate, endDate]);
  const navigate = useNavigate();
  return (
    <Box>
      {!organizationInformation?.id ? (
        // true
        <Box>
          <Typography sx={{ mt: 3 }}>
            {" "}
            Update your organization details
          </Typography>
          <Button
            variant="outlined"
            sx={{ mt: 2 }}
            onClick={() => navigate("/dashboard/organizationGeneralSettings")}
          >
            Go to Organization tab
          </Button>
        </Box>
      ) : isLoading ? (
        <Box sx={{ p: 3, display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {" "}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",

                alignItems: "flex-end",
                my: 1,
              }}
            >
              {/* <input
                type="text"
                placeholder="Select period"
                style={{ padding: "5px " }}
              /> */}
              <Box
                sx={{
                  display: "flex",
                  gap: "15px",
                  alignItems: "center",
                  my: 1,
                }}
              >
                <Typography>From</Typography>
                <Box>
                  {/* <Typography
                  sx={{
                    fontSize: "0.8rem",
                    color: theme.palette.primary.main,
                  }}
                >
                  Start Date
                </Typography> */}
                  <Input
                    sx={{ background: "#fff", p: 1 }}
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </Box>
                <Typography>To</Typography>
                <Box>
                  {/* <Typography
                  sx={{
                    fontSize: "0.8rem",
                    color: theme.palette.primary.main,
                  }}
                >
                  End Date
                </Typography> */}
                  <Input
                    sx={{ background: "#fff", p: 1 }}
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </Box>
              </Box>
              <Button
                variant="contained"
                size="small"
                sx={{ width: "90px" }}
                onClick={() => downloadReport()}
                disabled={isLoadingReportDownload ? true : false}
              >
                Download
              </Button>
              {showDownloadButton && (
                <a
                  href={downloadFileUrl}
                  onClick={() => {
                    setTimeout(() => {
                      setShowDownloadButton(false);
                      setDownloadFileUrl("");
                      setIsLoadingDownloadReport(false);
                    }, 5000);
                  }}
                >
                  <Typography sx={{ textDecoration: "underline" }}>
                    View File
                  </Typography>
                </a>
              )}
            </Box>
          </Box>
          <Box>
            <TableContainer
              component={Paper}
              spacing={2}
              sx={{ marginTop: "10px" }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        color: theme.palette.heading.main,
                        textAlign: "center",
                        borderRight: "2px solid white",
                      }}
                    >
                      User
                    </TableCell>
                    {/* <TableCell>Customer ID</TableCell> */}
                    <TableCell
                      sx={{
                        color: theme.palette.heading.main,
                        textAlign: "center",
                        borderRight: "2px solid white",
                      }}
                    >
                      <Box>
                        {" "}
                        <Typography sx={{ marginBottom: "10px" }}>
                          {" "}
                          Open Leads
                        </Typography>
                        <img
                          src="https://www.pngmart.com/files/7/Lead-Generation-PNG-Transparent-Picture.png"
                          style={{ width: "auto", height: "50px" }}
                        />
                      </Box>
                    </TableCell>
                    {/* <TableCell
                      sx={{
                        color: theme.palette.heading.main,
                        textAlign: "center",
                        borderRight: "2px solid white",
                      }}
                    >
                      <Box>
                        {" "}
                        <Typography sx={{ marginBottom: "10px" }}>
                          {" "}
                          Average age
                        </Typography>
                        <img
                          src="https://www.iconpacks.net/icons/2/free-sand-clock-icon-2314-thumb.png"
                          style={{ width: "auto", height: "50px" }}
                        />
                      </Box>
                    </TableCell> */}
                    {/* <TableCell
                      sx={{
                        color: theme.palette.heading.main,
                        textAlign: "center",
                        borderRight: "2px solid white",
                      }}
                    >
                      <Box>
                        {" "}
                        <Typography sx={{ marginBottom: "10px" }}>
                          {" "}
                          Av. action days
                        </Typography>
                        <img
                          src="https://e7.pngegg.com/pngimages/1012/621/png-clipart-action-item-task-computer-icons-project-meeting-room-miscellaneous-angle-thumbnail.png"
                          style={{ width: "auto", height: "50px" }}
                        />
                      </Box>
                    </TableCell> */}
                    <TableCell
                      sx={{
                        color: theme.palette.heading.main,
                        textAlign: "center",
                        borderRight: "2px solid white",
                      }}
                    >
                      <Box>
                        {" "}
                        <Typography sx={{ marginBottom: "10px" }}>
                          {" "}
                          Hot leads
                        </Typography>
                        <img
                          src="https://manufacturingadvertising.com/wp-content/uploads/2020/06/flame-icon.png"
                          style={{ width: "auto", height: "50px" }}
                        />
                      </Box>
                    </TableCell>
                    <TableCell
                      sx={{
                        color: theme.palette.heading.main,
                        textAlign: "center",
                        borderRight: "2px solid white",
                      }}
                    >
                      <Box>
                        {" "}
                        <Typography sx={{ marginBottom: "10px" }}>
                          {" "}
                          Warm leads
                        </Typography>
                        <img
                          src="https://cdn.pixabay.com/photo/2016/04/07/22/08/fire-1314935_1280.png"
                          style={{ width: "auto", height: "50px" }}
                        />
                      </Box>
                    </TableCell>
                    <TableCell
                      sx={{
                        color: theme.palette.heading.main,
                        textAlign: "center",
                        borderRight: "2px solid white",
                      }}
                    >
                      <Box>
                        {" "}
                        <Typography sx={{ marginBottom: "10px" }}>
                          {" "}
                          Cold leads
                        </Typography>
                        <img
                          src="https://icons.veryicon.com/png/o/food--drinks/bakery/cold-4.png"
                          style={{ width: "auto", height: "50px" }}
                        />
                      </Box>
                    </TableCell>
                    <TableCell
                      sx={{
                        color: theme.palette.heading.main,
                        textAlign: "center",
                        borderRight: "2px solid white",
                      }}
                    >
                      <Box>
                        {" "}
                        <Typography sx={{ marginBottom: "10px" }}>
                          {" "}
                          Target
                        </Typography>
                        <img
                          src="https://www.iconpacks.net/icons/1/free-target-icon-777-thumb.png"
                          style={{ width: "auto", height: "50px" }}
                        />
                      </Box>
                    </TableCell>
                    <TableCell
                      sx={{
                        color: theme.palette.heading.main,
                        textAlign: "center",
                        borderRight: "2px solid white",
                      }}
                    >
                      <Box>
                        {" "}
                        <Typography sx={{ marginBottom: "10px" }}>
                          {" "}
                          Value won
                        </Typography>
                        <img
                          src="https://cdn-icons-png.flaticon.com/512/562/562816.png"
                          style={{ width: "auto", height: "50px" }}
                        />
                      </Box>
                    </TableCell>

                    <TableCell
                      sx={{
                        color: theme.palette.heading.main,
                        textAlign: "center",
                        borderRight: "2px solid white",
                      }}
                    >
                      <Box>
                        {" "}
                        <Typography sx={{ marginBottom: "10px" }}>
                          Contribution
                        </Typography>
                        <img
                          src="https://cdn-icons-png.flaticon.com/512/5520/5520859.png"
                          style={{ width: "auto", height: "50px" }}
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.map((d, i) => (
                    <TableRow
                      key={i}
                      style={{
                        backgroundColor:
                          i % 2 == 0
                            ? theme.palette.colorthree.main
                            : "transparent",
                        border: "0px solid transparent",
                      }}
                    >
                      <TableCell
                        sx={{
                          textAlign: "center",
                          borderRight: "2px solid white",
                          padding: "20px 10px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            gap: "10px",
                            alignItems: "center",
                          }}
                        >
                          {/* <img
                        src={d?.profile}
                        style={{
                          width: "30px",
                          height: "30px",
                          borderRadius: "50%",
                        }}
                      /> */}
                          {d?.profile ? (
                            <Avatar src={d?.profile} />
                          ) : (
                            <Avatar
                              color="primary"
                              sx={{
                                backgroundColor: theme.palette.primary.main,
                              }}
                            >
                              {d?.name[0]?.toLocaleUpperCase()}
                            </Avatar>
                          )}
                          <Typography variant="body2"> {d?.name}</Typography>
                          {/* <Avatar  src={d?.profile} /> */}
                        </Box>
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          borderRight: "2px solid white",
                        }}
                      >
                        {d?.openLeads}
                      </TableCell>
                      {/* <TableCell
                        sx={{
                          textAlign: "center",
                          borderRight: "2px solid white",
                        }}
                      >
                        {d?.averageAge}
                      </TableCell> */}
                      {/* <TableCell
                        sx={{
                          textAlign: "center",
                          borderRight: "2px solid white",
                        }}
                      >
                        {d?.avActionDays}
                      </TableCell> */}
                      <TableCell
                        sx={{
                          textAlign: "center",
                          borderRight: "2px solid white",
                        }}
                      >
                        {d?.hotLeads}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          borderRight: "2px solid white",
                        }}
                      >
                        {d?.warmLeads}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          borderRight: "2px solid white",
                        }}
                      >
                        {d?.coldLeads}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          borderRight: "2px solid white",
                        }}
                      >
                        {d?.target}{" "}
                        {organizationInformation?.organization_currency}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          borderRight: "2px solid white",
                        }}
                      >
                        {d?.valueWon}
                        {`  `}
                        {organizationInformation?.organization_currency}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          borderRight: "2px solid white",
                        }}
                      >
                        {d?.contribution}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </>
      )}
    </Box>
  );
};

export default DashboardContainer;
