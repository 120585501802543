import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
  IconButton,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

import { api } from "../../../axios/api";
import { useSelector } from "react-redux";
import ArrowUpwardIcon from "@mui/icons-material/ExpandLess";
import ArrowDownwardIcon from "@mui/icons-material/ExpandMore";
import { useTheme } from "@mui/material/styles";
import { Edit, Trash2 } from "react-feather";
import { ToastContainer, toast } from "react-toastify";
import AddRelationshipForm from "./AddRelationshipForm";
import IndividualLeadFeatures from "./IndividualLeadFeatures";

const Relationships = () => {
  const theme = useTheme();
  const user = useSelector((state) => state.user);
  const [prospects, setProspects] = useState([]);
  const userSubscriptionData = useSelector((state) => state?.subscription_data);
  console.log("user subscription data", userSubscriptionData);

  const [organizationInformation, setOrganizationInformation] = useState({});
  const [showAddForm, setShowAddForm] = useState(false);
  const [editOptions, setEditOptions] = useState({});
  const [showIndividualLeadFeature, setShowIndividualLeadFeature] =
    useState(false);
  const [selectedLead, setSelectedLead] = useState({});

  useEffect(() => {
    console.log("running useeffect");
    api
      .get("/organization")
      .then((res) => {
        console.log("organization information", res?.data);
        setOrganizationInformation(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    console.log("user at show contact", user);
  }, [user]);
  useEffect(() => {
    api
      .get("/relationships")
      .then((res) => {
        console.log("response from getting prospects", res?.data);
        setProspects(res?.data);
      })
      .catch((err) => console.log("err", err));
    if (!showAddForm) {
      setEditOptions({});
    }
  }, [user, showAddForm, showIndividualLeadFeature]);
  console.log("showIndividualLeadFeature", showIndividualLeadFeature);

  const [sorting, setSorting] = useState({
    field: "info_reference_number", // default sorting field
    order: "asc", // default sorting order
  });

  const handleSort = (field) => {
    const isAsc = sorting.field === field && sorting.order === "asc";
    setSorting({ field, order: isAsc ? "desc" : "asc" });
  };

  const sortedData = prospects.sort((a, b) => {
    const order = sorting.order === "asc" ? 1 : -1;
    if (a[sorting.field] < b[sorting.field]) {
      return -1 * order;
    }
    if (a[sorting.field] > b[sorting.field]) {
      return 1 * order;
    }
    return 0;
  });
  return (
    <Box sx={{ padding: "0" }}>
      <ToastContainer />
      {userSubscriptionData?.offer_purchased != "Power Pack" ? (
        <Box>
          <Typography variant="h6" sx={{ color: theme.palette.primary.main }}>
            Upgrade your account to access extra features and capabilities
          </Typography>
        </Box>
      ) : (
        <Box>
          {" "}
          {showIndividualLeadFeature ? (
            <>
              <IndividualLeadFeatures
                selectedLead={selectedLead}
                setShowIndividualLeadFeature={setShowIndividualLeadFeature}
              />
            </>
          ) : (
            <>
              {" "}
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  variant="h6"
                  sx={{
                    color: theme.palette.heading.main,
                    fontSize: "1.3rem",
                    fontWeight: "600",
                  }}
                >
                  {"Network"}
                </Typography>
                {organizationInformation?.id && (
                  <Button
                    variant="contained"
                    onClick={() => setShowAddForm(!showAddForm)}
                    sx={{
                      backgroundColor: theme.palette.colortwo.main,
                      color: "#fff",
                    }}
                    size="small"
                  >
                    {showAddForm ? "View network" : "Add network"}
                  </Button>
                )}
              </Box>
              <p>
                The individuals in your network are not your potential clients.
                Your network should be made of highly impactful persons who can
                lead you to multiple referrals. For example, if you are a
                photographer, you want to build a network relationship with a
                celebrity events organizer who can help you access multiple
                referrals from her celebrity clients. Ensure to keep your
                network engaged, you can find tips on highly engaged networks in
                the stories section of this website.
              </p>
              {!organizationInformation?.id ? (
                <Box>
                  <Typography sx={{ mt: 3 }}>
                    {" "}
                    Update your organization details
                  </Typography>
                </Box>
              ) : showAddForm ? (
                <AddRelationshipForm
                  editOptions={editOptions}
                  setShowAddForm={setShowAddForm}
                  organizationInformation={organizationInformation}
                />
              ) : prospects?.length < 1 ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mt: 2,
                    gap: "10px",
                  }}
                >
                  <InfoIcon />
                  <Typography sx={{}}>
                    {" "}
                    No Relationship is added yet.
                  </Typography>
                </Box>
              ) : (
                <>
                  <Box sx={{ marginTop: "40px" }}>
                    <Grid container spacing={2} justifyContent="center">
                      <Grid item xs={12}>
                        <TableContainer
                          component={Paper}
                          style={{ maxHeight: "70vh", overflowY: "scroll" }}
                        >
                          <Table>
                            <TableHead
                              sx={{
                                position: "sticky",
                                top: 0,
                                backgroundColor: "white",
                              }}
                            >
                              <TableRow>
                                <TableCell
                                  sx={{
                                    width: "80px",
                                    // color: theme.palette.colortwo.main,
                                    fontSize: "0.9rem",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      gap: "4px",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        gap: "-2px",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                      }}
                                    >
                                      {" "}
                                      <IconButton
                                        size="small"
                                        onClick={() =>
                                          setSorting({
                                            field: "info_reference_number",
                                            order: "asc",
                                          })
                                        }
                                      >
                                        <ArrowUpwardIcon
                                          size="small"
                                          sx={{ fontSize: "0.85rem" }}
                                        />
                                      </IconButton>
                                      <IconButton
                                        size="small"
                                        onClick={() =>
                                          setSorting({
                                            field: "info_reference_number",
                                            order: "desc",
                                          })
                                        }
                                      >
                                        <ArrowDownwardIcon
                                          size="small"
                                          sx={{ fontSize: "0.85rem" }}
                                        />
                                      </IconButton>
                                    </Box>
                                    Ref. No
                                  </Box>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    // color: theme.palette.colortwo.main,
                                    fontSize: "0.9rem",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      gap: "4px",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        gap: "-2px",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                      }}
                                    >
                                      {" "}
                                      <IconButton
                                        size="small"
                                        onClick={() =>
                                          setSorting({
                                            field: "date_created",
                                            order: "asc",
                                          })
                                        }
                                      >
                                        <ArrowUpwardIcon
                                          size="small"
                                          sx={{ fontSize: "0.85rem" }}
                                        />
                                      </IconButton>
                                      <IconButton
                                        size="small"
                                        onClick={() =>
                                          setSorting({
                                            field: "date_created",
                                            order: "desc",
                                          })
                                        }
                                      >
                                        <ArrowDownwardIcon
                                          size="small"
                                          sx={{ fontSize: "0.85rem" }}
                                        />
                                      </IconButton>
                                    </Box>
                                    Date created
                                  </Box>
                                </TableCell>
                                {/* <TableCell>Customer ID</TableCell> */}
                                <TableCell
                                  sx={{
                                    // color: theme.palette.colortwo.main,
                                    fontSize: "0.9rem",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  Organization
                                </TableCell>

                                <TableCell
                                  sx={{
                                    // color: theme.palette.colortwo.main,
                                    fontSize: "0.9rem",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  Contact person
                                </TableCell>
                                <TableCell
                                  sx={{
                                    // color: theme.palette.colortwo.main,
                                    fontSize: "0.9rem",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  Phone number
                                </TableCell>
                                <TableCell
                                  sx={{
                                    // color: theme.palette.colortwo.main,
                                    fontSize: "0.9rem",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  Email
                                </TableCell>

                                <TableCell
                                  sx={{
                                    // color: theme.palette.colortwo.main,
                                    fontSize: "0.9rem",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  Owner
                                </TableCell>
                                <TableCell
                                  sx={{
                                    // color: theme.palette.colortwo.main,
                                    fontSize: "0.9rem",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  Actions
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {prospects?.map((contact, i) => (
                                <NormalProspect
                                  contact={contact}
                                  i={i}
                                  prospects={prospects}
                                  setProspects={setProspects}
                                  setShowAddForm={setShowAddForm}
                                  setEditOptions={setEditOptions}
                                  setShowIndividualLeadFeature={
                                    setShowIndividualLeadFeature
                                  }
                                  setSelectedLead={setSelectedLead}
                                />
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                  </Box>
                </>
              )}
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

const NormalProspect = ({
  contact,
  i,
  prospects,
  setProspects,
  setEditOptions,
  setShowAddForm,
  setShowIndividualLeadFeature,
  setSelectedLead,
}) => {
  const theme = useTheme();
  const user = useSelector((state) => state.user);

  const edit = () => {
    setEditOptions(contact);
    setShowAddForm(true);
    setShowIndividualLeadFeature(false);
  };

  return (
    <TableRow
      key={contact?.info_reference_number}
      sx={{
        backgroundColor:
          i % 2 == 0 ? theme.palette.colorthree.main : "transparent",
        cursor: "pointer",
        "&:hover": {
          boxShadow:
            "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
          boxShadow:
            "rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px",
        },
      }}
    >
      <TableCell
        sx={{
          padding: "4px 20px",
          whiteSpace: "nowrap",
          width: "50px",
        }}
        onClick={() => {
          setSelectedLead(contact);
          setShowIndividualLeadFeature(true);
        }}
      >
        {contact?.organizational_identifier}
      </TableCell>
      <TableCell
        sx={{ padding: "4px 20px", whiteSpace: "nowrap" }}
        onClick={() => {
          setSelectedLead(contact);
          setShowIndividualLeadFeature(true);
        }}
      >
        {new Date(contact?.date_created)?.toDateString()}
      </TableCell>
      {/* <TableCell sx={{whiteSpace:'nowrap'}}>{contact?.customer_id}</TableCell> */}
      <TableCell
        sx={{ whiteSpace: "nowrap", padding: "4px 20px" }}
        onClick={() => {
          setSelectedLead(contact);
          setShowIndividualLeadFeature(true);
        }}
      >
        {contact?.organization_name}
      </TableCell>

      <TableCell
        sx={{ whiteSpace: "nowrap", padding: "4px 20px" }}
        onClick={() => {
          setSelectedLead(contact);
          setShowIndividualLeadFeature(true);
        }}
      >
        {contact?.contact_person}
      </TableCell>
      <TableCell
        sx={{ whiteSpace: "nowrap", padding: "4px 20px" }}
        onClick={() => {
          setSelectedLead(contact);
          setShowIndividualLeadFeature(true);
        }}
      >
        {contact?.primary_phone_number}
      </TableCell>
      <TableCell
        sx={{ whiteSpace: "nowrap", padding: "4px 20px" }}
        onClick={() => {
          setSelectedLead(contact);
          setShowIndividualLeadFeature(true);
        }}
      >
        <a href={`mailto:${contact?.email}`} style={{ textDecoration: "none" }}>
          <Typography
            sx={{
              color: "#000c",
            }}
          >
            {contact?.email}
          </Typography>
        </a>
      </TableCell>

      <TableCell sx={{ whiteSpace: "nowrap" }}>{contact?.owner_name}</TableCell>
      {user?.id == contact?.owner_id && (
        <TableCell>
          <Box sx={{ display: "flex", gap: "5px" }}>
            <IconButton sx={{ zIndex: "10000" }} onClick={() => edit()}>
              <Edit size="18" color={theme.palette.colortwo.main} />
            </IconButton>
          </Box>
        </TableCell>
      )}
    </TableRow>
  );
};

export default Relationships;
