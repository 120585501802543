// import PhoneInput from "react-phone-number-input";
// import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import React, { useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
} from "@mui/material";

const AuthPhoneInput = ({
  label,
  placeholder,
  value,
  onChange,
  icon,
  isPassword,
  error,
  helperText,
  setPhoneNumber,
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        margin: "15px 0 0px 0",
      }}
    >
      {/* <Box
        sx={{
          display: "flex",
          borderRadius: "4px",
          border: "1px solid lightgray",
          padding: "10px 12px",
        }}
      > */}
      <PhoneInput
        country={"us"}
        value={value}
        placeholder={placeholder}
        onChange={(phone) => setPhoneNumber(phone)}
        error={error}
        // inputProps={{
        //   style: {
        //     //   flex: "1",
        //     //   height: "45px",
        //     //   boxSizing: "border-box",
        //     //   overflow: "hidden",
        //     //   fontSize: "14px",
        //     //   padding: "0 12px",
        //     //   border: error ? "1px solid red" : "1px solid #ced4da",
        //     //   borderRadius: "4px",
        //     fontWeight: "300",

        //     color: theme.palette.para.main,
        //     fontFamily: "inherit",
        //   },
        // }}

        inputStyle={{
          border: "none",
          outline: "none",

          paddingLeft: "47px",
          fontWeight: "300",
          fontFamily: "inherit",
        }}
        searchStyle={{
          border: "1px solid black",
          fontWeight: "300",
          fontFamily: "inherit",
        }}
        containerStyle={{
          flex: "1",
          border: "1px solid lightgray",
          borderRadius: "4px",
          padding: "4px 4px",
        }}
        buttonStyle={{
          // height: "45px",
          borderRadius: "4px",

          padding: "0 5px",
          backgroundColor: "white",
          border: "none",
          outline: "none",
        }}
        dropdownStyle={{
          marginTop: "0",
          borderRadius: "4px",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          border: "1px solid #ced4da",
        }}
      />
      {/* </Box> */}
      {helperText && (
        <Typography variant="body2" sx={{ color: "red", fontSize: "0.8rem" }}>
          {helperText}
        </Typography>
      )}
    </Box>
  );
};

export default AuthPhoneInput;
