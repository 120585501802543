import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { WhatsAppWidget } from "react-whatsapp-widget";

import "react-whatsapp-widget/dist/index.css";
import { api } from "../axios/api";
import {
  Box,
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  useMediaQuery,
  RadioGroup,
  Radio,
  FormControlLabel,
  Input,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import storyOneImg from "../utils/images/story1.jpg";
import storyTwoImg from "../utils/images/story2.jpg";
import storyThreeImg from "../utils/images/story3.jpg";
import storyFourImg from "../utils/images/story4.jpg";
import storyFiveImg from "../utils/images/story5.jpg";
import storySixImg from "../utils/images/story6.jpg";
import parse from "html-react-parser"; // Import html-react-parser

const Story = ({ story }) => {
  const theme = useTheme();
  return (
    <Box>
      <img
        src={story?.cover_photo}
        width="100%"
        alt="image"
        sx={{ height: { sm: "200px", xs: "250px" } }}
      />
      <Box>
        <Typography
          variant="body2"
          sx={{
            color: theme.palette.primary.main,
            margin: "10px 0 6px 0",
            fontSize: "1.1rem",
            fontWeight: "500",
          }}
        >
          {story?.title}
        </Typography>
        <Typography
          variant="p"
          component="div"
          sx={{
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 2,
            overflow: "hidden",
            textOverflow: "ellipsis",
            color: theme.palette.para.main,
          }}
        >
          {parse(story?.description)}
        </Typography>
      </Box>
    </Box>
  );
};

const Stories = () => {
  const [thisWeekStories, setThisWeekStories] = useState([]);
  useEffect(() => {
    api
      .get("/stories")
      .then((res) => {
        console?.log("response stories ", res?.data);
        setThisWeekStories(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);
  const theme = useTheme();
  const [showSpecificStory, setShowSpecificStory] = useState(false);
  const [specificStory, setSpecificStory] = useState({
    name: "Sarah's Success Story",
    image: storyOneImg,
    description:
      "Sarah was struggling to close sales, but then she started using our lead management software. With our software, she was able to organize her leads, track her progress, and identify areas where she needed to improve. She started closing more sales than ever before and even exceeded her sales goals for the month. Now, Sarah is a top performer at her company and continues to use our software to manage her leads and stay on top of her game.",
  });
  const [topStory, setTopStory] = useState({});
  useEffect(() => {
    setTopStory(thisWeekStories?.filter((s) => s?.is_top_story)[0]);
  }, [thisWeekStories]);

  const splitParagraphIntoChunks = (text, maxWordCount) => {
    console.log("running splitParagraphIntoChunks");
    const sentences = text.split(/[.!?]/);

    let currentChunk = "";
    const chunks = [];

    for (const sentence of sentences) {
      if ((currentChunk + sentence).split(" ").length <= maxWordCount) {
        currentChunk += sentence + ".";
      } else {
        chunks.push(currentChunk);
        currentChunk = sentence + ".";
      }
    }

    if (currentChunk.trim() !== "") {
      chunks.push(currentChunk);
    }

    console.log("chunks", chunks);

    return chunks;
  };
  return (
    <Box>
      <Navbar />
      {showSpecificStory ? (
        <>
          {" "}
          <Container>
            <Box sx={{ margin: "70px 0", minHeight: "400px" }}>
              <Typography
                variant="h4"
                sx={{
                  margin: "10px 0 30px 0",
                  fontSize: "1rem",
                  color: theme.palette.primary.main,
                }}
                onClick={() => {
                  setShowSpecificStory(false);
                }}
              >
                stories
              </Typography>
              <Grid
                container
                spacing={3}
                sx={{ justifyContent: "space-between", columnGap: "20px" }}
              >
                <Grid item xs={12} sm={8} sx={{ minHeight: "200px" }}>
                  <Typography variant="h4" sx={{ margin: "10px 0 10px 0" }}>
                    {specificStory?.title}
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{
                      margin: "0px 0 30px 0",
                      color: theme.palette.para.main,
                    }}
                  >
                    {specificStory?.description}
                  </Typography>
                  <Box sx={{ my: 1 }}>
                    <img
                      src={specificStory?.cover_photo}
                      maxWidth="100%"
                      alt="image"
                      style={{ height: "auto", maxHeight: "300px" }}
                    />
                  </Box>
                  <Box>{parse(specificStory?.story_content)}</Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={3.5}
                  sx={{
                    minHeight: "200px",
                    background: theme.palette.colorthree.main,
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      padding: "20px 0px",
                      color: theme.palette.primary.main,
                    }}
                  >
                    Trending Stories
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "20px",
                    }}
                  >
                    {thisWeekStories?.map((story) => (
                      <>
                        <Grid
                          container
                          onClick={() => {
                            setShowSpecificStory(true);
                            setSpecificStory(story);
                          }}
                        >
                          <Grid item xs={4}>
                            <img
                              src={story?.cover_photo}
                              width="100%"
                              alt="image"
                              sx={{ height: "auto" }}
                            />
                          </Grid>
                          <Grid item xs={8} sx={{ padding: "0px 10px" }}>
                            <Typography
                              variant="body2"
                              sx={{
                                color: theme.palette.primary.main,
                                margin: "0px 0 0px 0",
                                fontSize: "0.9rem",
                                fontWeight: "500",
                              }}
                            >
                              {story?.title}
                            </Typography>
                            <Typography
                              variant="p"
                              component="div"
                              sx={{
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                WebkitLineClamp: 2,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                color: theme.palette.para.main,
                                fontSize: "0.7rem",
                              }}
                            >
                              {story?.description}
                            </Typography>
                          </Grid>
                        </Grid>
                      </>
                    ))}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </>
      ) : (
        <>
          <Box
            sx={{
              backgroundColor: theme.palette.colorthree.main,
              padding: { sm: "100px", xs: "60px 20px" },
            }}
          >
            <Container
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Grid container sx={{ backgroundColor: "#fff" }}>
                <Grid
                  item
                  xs={12}
                  md={5}
                  sx={{
                    padding: { sm: "30px 45px", xs: "25px" },
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{
                      fontSize: "700",
                      color: theme.palette.heading.main,
                      color: "black",
                      fontSize: { xs: "1.4rem", sm: "1.8rem" },
                    }}
                  >
                    {topStory?.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      margin: "20px 0",
                      fontSize: "1.2rem",
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 2,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      color: theme.palette.para.main,
                    }}
                  >
                    {topStory?.description}
                  </Typography>
                  <Typography
                    sx={{ color: theme.palette.primary.main }}
                    onClick={() => {
                      setShowSpecificStory(true);
                      setSpecificStory(thisWeekStories[0]);
                    }}
                  >
                    Read More
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={7}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <img src={topStory?.cover_photo} style={{ width: "100%" }} />
                </Grid>
              </Grid>
            </Container>
          </Box>
          <Container>
            <Box sx={{ margin: "70px 0", minHeight: "400px" }}>
              <Box>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: "700",
                    color: theme.palette.heading.main,
                    textAlign: "center",
                    marginBottom: "50px",
                  }}
                >
                  Recent stories
                </Typography>
                <Grid
                  container
                  sx={{ justifyContent: "space-between", rowGap: "40px" }}
                >
                  {thisWeekStories?.map((story) => (
                    <Grid
                      item
                      xs={12}
                      sm={3.5}
                      onClick={() => {
                        setShowSpecificStory(true);
                        setSpecificStory(story);
                      }}
                    >
                      <Story story={story} />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>
            {/* <Box
              sx={{
                border: "1px solid lightgray",
                minHeight: "200px",
                margin: "50px 0 50px 0",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Ads
            </Box> */}
            {/* <Box sx={{ margin: "70px 0", minHeight: "400px" }}>
              <Box>
               
                <Grid
                  container
                  sx={{ justifyContent: "space-between", rowGap: "40px" }}
                >
                  {thisWeekStories?.map((story) => (
                    <Grid
                      item
                      xs={12}
                      sm={3.5}
                      onClick={() => {
                        setShowSpecificStory(true);
                        setSpecificStory(story);
                      }}
                    >
                      <Story story={story} />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box> */}
          </Container>
        </>
      )}
      {/* WhatsApp floating icon */}{" "}
      <WhatsAppWidget phoneNumber={process.env.REACT_APP_WHATSAPP_NUMBER} />
      <Footer />
    </Box>
  );
};

export default Stories;
