import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  TextareaAutosize,
  IconButton,
  Input,
} from "@mui/material";
import { api } from "../../axios/api";
import { ToastContainer, toast } from "react-toastify";

import ClearIcon from "@mui/icons-material/Clear";
import { useTheme } from "@mui/material";
import Modal from "@mui/material/Modal";

const generateRandomCode = () => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let code = "";
  for (let i = 0; i < 10; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    code += characters[randomIndex];
  }
  return code;
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const Coupons = () => {
  const [coupons, setCoupons] = useState([]);
  const [showAddStoryForm, setShowAddStoryForm] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [coverPhoto, setCoverPhoto] = useState("");
  const [editStory, setEditStory] = useState(null);
  const [fileUrlAtEdit, setFileUrlAtEdit] = useState("");
  const theme = useTheme();
  const [selectedCoupon, setSelectedCoupon] = useState({});
  const [showSendModal, setShowSendModal] = useState(false);
  const handleModalOpen = () => setShowSendModal(true);
  const handleModalClose = () => {
    setSelectedCoupon({});
    setShowSendModal(false);
  };
  const [recEmail, setRecEmail] = useState("");

  const fetchcoupons = () => {
    api
      .get("/coupons")
      .then((res) => {
        console.log("response coupons ", res?.data);
        setCoupons(res?.data);
      })
      .catch((err) => console.log(err));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!couponCode || !couponValue) {
      toast.error("missing fields");
      return;
    }
    // try {
    //   api
    //     .post("/coupons/add", { code: couponCode, value: couponValue })
    //     .then((res) => {
    //       console.log("res", res?.data);
    //       toast?.success("Coupon added successfully");
    //       fetchcoupons();
    //       // Reset form values
    //       setTitle("");
    //       setDescription("");
    //       setCoverPhoto("");
    //       setShowAddStoryForm(false);
    //     })
    //     .catch((err) => {
    //       console.log("error", err);
    //       toast.error("unable to upload story");
    //     });
    // } catch (err) {
    //   toast.error("unable to upload story");
    // }
    try {
      api
        .post("/coupons/add", { code: couponCode, value: couponValue })
        .then((res) => {
          console.log("res", res?.data);
          toast?.success("Coupon added successfully");
          fetchcoupons();
          // Reset form values
          setTitle("");
          setDescription("");
          setCoverPhoto("");
          setShowAddStoryForm(false);
        })
        .catch((err) => {
          console.error("error", err);

          if (err.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            if (err.response.status === 409) {
              toast.error("Coupon with the same code already exists.");
            } else if (err.response.status === 500) {
              toast.error("Server error. Please try again later.");
            } else {
              toast.error("Unable to add coupon. Please try again.");
            }
          } else if (err.request) {
            // The request was made but no response was received
            toast.error("No response from the server. Please try again.");
          } else {
            // Something happened in setting up the request that triggered an Error
            toast.error(
              "An unexpected error occurred. Please try again later."
            );
          }
        });
    } catch (err) {
      console.error("error", err);
      toast.error("An unexpected error occurred. Please try again later.");
    }
  };

  const deleteStory = (story) => {
    // Make a DELETE request to delete the story
    api
      .delete("/coupons/" + story.id)
      .then((res) => {
        setCoupons((prevcoupons) =>
          prevcoupons.filter((s) => s.id !== story.id)
        );
        toast.success("Story deleted successfully!");
      })
      .catch((err) => toast.error("Unable to delete story"));
  };

  useEffect(() => {
    fetchcoupons();
  }, []);

  const [couponCode, setCouponCode] = useState("");
  const [couponValue, setCouponValue] = useState("");

  const handleGenerateCode = () => {
    const generatedCode = generateRandomCode();
    setCouponCode(generatedCode);
  };
  const [error, setError] = useState("");
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const sendCoupon = (coupon) => {
    console.log("send coupon", selectedCoupon);
    if (!recEmail) {
      setError("email required");
      return;
    }
    // Validate email format
    if (!isValidEmail(recEmail)) {
      setError("Invalid email format");
      return;
    }
    api
      .post("/coupons/send", {
        couponId: selectedCoupon?.id,
        receiverEmail: recEmail,
      })
      .then((res) => {
        console.log("res data", res?.data);
        toast?.success("Sent Successfully");
        handleModalClose();
      })
      .catch((err) => {
        console.log(err);
        setError("unable to send");
      });
  };

  return (
    <Box>
      <ToastContainer />
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
        <Modal
          open={showSendModal}
          onClose={handleModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ zIndex: "1000000000" }}
        >
          <Box sx={style}>
            <Box sx={{}}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6">
                  Couon Code: {selectedCoupon?.code}
                </Typography>
                <IconButton onClick={handleModalClose}>
                  <ClearIcon />
                </IconButton>
              </Box>
              <Box sx={{ my: 3 }}>
                <Typography>Send to</Typography>
                <Input onChange={(e) => setRecEmail(e.target.value)} />
                {error && (
                  <Typography sx={{ color: "red" }}>{error}</Typography>
                )}
              </Box>

              <Button
                variant="contained"
                sx={{ width: "100%", mt: 2 }}
                onClick={() => sendCoupon()}
              >
                Send
              </Button>
            </Box>
          </Box>
        </Modal>
        <Typography variant="h6" gutterBottom>
          Coupons
        </Typography>
        {showAddStoryForm ? (
          <Button
            onClick={() => setShowAddStoryForm(false)}
            variant="contained"
            color="primary"
          >
            See Coupons
          </Button>
        ) : (
          <Button
            onClick={() => setShowAddStoryForm(true)}
            variant="contained"
            color="primary"
          >
            Add Coupon
          </Button>
        )}
      </Box>
      {showAddStoryForm ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            maxWidth: "350px",
          }}
        >
          {/* ... existing code for add story form */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              maxWidth: "350px",
              background: "#fff",
              p: 3,
            }}
            component="form"
            onSubmit={handleFormSubmit}
          >
            <Typography variant="body2" sx={{ fontWeight: 500, mt: 2 }}>
              Coupon code
            </Typography>
            <TextField
              disabled
              // label="Title"
              value={couponCode}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
            <Button onClick={() => handleGenerateCode()}>Generate Code</Button>
            <Typography variant="body2" sx={{ fontWeight: 500, mt: 2 }}>
              Coupen value (Ksh)
            </Typography>
            <TextField
              type="number"
              // label="Title"
              value={couponValue}
              onChange={(e) => setCouponValue(e.target.value)}
              required
            />

            <Button
              size="small"
              sx={{ width: "max-content", mt: 2 }}
              onClick={(e) => {
                handleFormSubmit(e);
              }}
              variant="contained"
              color="primary"
            >
              save
            </Button>
          </Box>
        </Box>
      ) : (
        <Box>
          {coupons.length > 0 && (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Id</TableCell>
                    <TableCell>Coupon Code</TableCell>
                    <TableCell>Coupon Value</TableCell>
                    <TableCell>Coupon Used</TableCell>
                    <TableCell></TableCell>
                    {/* <TableCell>Actions</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {coupons
                    .sort((a, b) => a.id - b.id)
                    .map((coupon, i) => (
                      <TableRow key={i + 1}>
                        {/* ... existing columns */}
                        <TableCell>{i + 1}</TableCell>

                        <TableCell>
                          <Typography variant="body2" sx={{ mb: 0.5 }}>
                            {" "}
                            {coupon?.code}{" "}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2" sx={{ mb: 0.5 }}>
                            {" "}
                            {coupon?.value}{" "}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2" sx={{ mb: 0.5 }}>
                            {" "}
                            {coupon?.is_used ? "true" : "false"}{" "}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {" "}
                          {!coupon?.is_used ? (
                            <Button
                              variant="outlined"
                              size="small"
                              onClick={() => {
                                setSelectedCoupon(coupon);
                                setShowSendModal(true);
                              }}
                              sx={{ ml: 1 }}
                            >
                              Send Coupon
                            </Button>
                          ) : (
                            <></>
                          )}
                        </TableCell>
                        {/* <Button
                            variant="contained"
                            size="small"
                            onClick={() => deleteStory(story)}
                            sx={{ ml: 1 }}
                          >
                            Delete
                          </Button> */}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      )}
    </Box>
  );
};

export default Coupons;
