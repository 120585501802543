import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Box,
  Button,
  IconButton,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { api } from "../../../../axios/api";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material";

const Points = ({ selectedLeadId, setSelectedFeature }) => {
  const [points, setPoints] = useState(0);
  const [happinessRating, setLeadHappinessRating] = useState(0);
  const theme = useTheme();
  const [selectedLead, setSelectedLead] = useState({});
  useEffect(() => {
    if (selectedLeadId) {
      api.get("/prospects/bySerialNumber/" + selectedLeadId).then((res) => {
        console.log("serial contact", res?.data);
        setSelectedLead(res?.data);
      });
    }
  }, [selectedLeadId]);
  useEffect(() => {
    console.log("selected lead", selectedLead);
    if (selectedLead) {
      api
        .get("/leadHappinessRating/" + selectedLeadId)
        .then((res) => {
          console.log("res?.data", res?.data);
          let hr = res?.data?.happiness_rating;
          setLeadHappinessRating(hr);
          if (hr < 1 || hr > 5) {
            setPoints(selectedLead?.milestone_level);
          } else {
            setPoints(hr * selectedLead?.milestone_level);
          }
        })
        .catch((err) => {
          console.log(err);
          setPoints(selectedLead?.milestone_level);
        });
    }
  }, [selectedLeadId, selectedLead]);
  return (
    <Box sx={{ background: "#fff", p: 2, mt: 1 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          // alignItems: "center",
          mt: 3,
          mb: 3,
        }}
      >
        <Typography variant="h6" sx={{ mb: 2 }}>
          Lead :{selectedLead?.contact_person}
        </Typography>
        <Typography
          variant="p"
          onClick={() => setSelectedFeature("Milestone")}
          sx={{
            cursor: "pointer",
            "&:hover": {
              color: theme.palette.primary.main,
              textDecoration: "underline",
            },
          }}
        >
          Milestone level: {selectedLead?.milestone_level}
        </Typography>
        <Typography
          variant="p"
          onClick={() => setSelectedFeature("Add a note")}
          sx={{
            cursor: "pointer",
            "&:hover": {
              color: theme.palette.primary.main,
              textDecoration: "underline",
            },
          }}
        >
          Happiness rating: {happinessRating}
        </Typography>
      </Box>
      <Box
        sx={{
          mt: 3,
          mb: 3,
          backgroundColor: theme.palette.colorthree.main,
          p: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "20px",
            alignItems: "center",
            mt: 1,
            mb: 3,
          }}
        >
          <Typography>Total Points</Typography>
          <Box
            sx={{
              width: "15px",
              height: "15px",
              background:
                points <= 8
                  ? "red"
                  : points > 8 && points < 16
                  ? "yellow"
                  : "green",
              borderRadius: "50%",
            }}
          ></Box>
        </Box>
        <Typography variant="h3" sx={{ color: theme.palette.primary.main }}>
          {points}
        </Typography>
      </Box>
    </Box>
  );
};

export default Points;
