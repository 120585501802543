import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  Typography,
  useTheme,
} from "@mui/material";
import { api } from "../../axios/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SupportTickets = () => {
  const [tickets, setTickets] = useState([]);
  const theme = useTheme();

  useEffect(() => {
    // Fetch tickets when the component mounts
    fetchTickets();
  }, []);

  const fetchTickets = () => {
    // Make an API call to get all tickets
    api
      .get("/tickets")
      .then((response) => {
        setTickets(response.data);
      })
      .catch((error) => {
        console.error("Error fetching tickets:", error.message);
      });
  };

  //   const handleViewTicket = (ticketNumber) => {
  //     // Implement the logic to view a specific ticket
  //     console.log("View ticket with number:", ticketNumber);
  //   };

  return (
    <Box sx={{ p: 2 }}>
      <ToastContainer />
      <Typography variant="h6">Support Tickets</Typography>
      <TableContainer component={Paper} style={{ marginTop: "20px" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: theme.palette.primary.main }}>
                Ticket number
              </TableCell>
              <TableCell sx={{ color: theme.palette.primary.main }}>
                Date raised
              </TableCell>
              <TableCell sx={{ color: theme.palette.primary.main }}>
                Subject
              </TableCell>
              <TableCell sx={{ color: theme.palette.primary.main }}>
                Priority
              </TableCell>
              <TableCell sx={{ color: theme.palette.primary.main }}>
                User
              </TableCell>
              <TableCell sx={{ color: theme.palette.primary.main }}>
                Company
              </TableCell>
              <TableCell sx={{ color: theme.palette.primary.main }}>
                Attachment
              </TableCell>
              <TableCell sx={{ color: theme.palette.primary.main }}>
                Status
              </TableCell>
              <TableCell sx={{ color: theme.palette.primary.main }}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tickets.map((ticket) => (
              <RowOfTable
                ticket={ticket}
                setTickets={setTickets}
                tickets={tickets}
                toast={toast}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default SupportTickets;

const RowOfTable = ({ ticket, setTickets, tickets, toast }) => {
  const [user, setUser] = useState({});
  const [organization, setOrganization] = useState({});
  const theme = useTheme();
  useEffect(() => {
    if (ticket?.user_id) {
      api
        .get("/getUserById/" + ticket?.user_id)
        .then((res) => {
          console.log("response from getting user", res?.data);
          setUser(res?.data);
          if (res?.data?.organization_id) {
            api
              .get("/organization/byId/" + res?.data?.organization_id)
              .then((res2) => {
                console.log(
                  "response of getting organization details",
                  res2?.data
                );
                setOrganization(res2?.data);
              })
              .catch((err) => console.log(err));
          }
        })
        .catch((err) => console.log(err));
    }
  }, []);
  const handleClose = (ticket) => {
    api
      .put("/tickets/close/" + ticket?.ticket_number)
      .then((res) => {
        console.log("updated successfully", res?.data);
        toast?.success("closed successfully");
        let ticks = tickets?.filter(
          (t) => t?.ticket_number != ticket?.ticket_number
        );
        let closeTick = { ...ticket, is_closed: true };
        setTickets([...ticks, closeTick]);
      })
      .catch((err) => console.log(err));
  };
  return (
    <TableRow key={ticket.ticket_number}>
      <TableCell>{ticket.ticket_number}</TableCell>
      <TableCell>{new Date(ticket.date_raised)?.toDateString()}</TableCell>
      <TableCell>
        <Box>
          <Typography variant="body2" sx={{ fontSize: "1rem" }}>
            {ticket.subject}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: theme.palette.para.main,
              display: { xs: "none", sm: "block" },
            }}
          >
            {ticket.description}
          </Typography>
        </Box>
      </TableCell>
      <TableCell>{ticket.priority}</TableCell>
      <TableCell>{user?.name}</TableCell>
      <TableCell>{organization?.organization_name}</TableCell>
      <TableCell>
        {/* <Button
          variant="outlined"
          onClick={() => handleViewTicket(ticket.ticket_number)}
        >
          View
        </Button> */}
        {ticket?.attachment ? (
          <a href={ticket?.attachment} target="_blank">
            View
          </a>
        ) : (
          <Typography>N/A</Typography>
        )}
      </TableCell>
      <TableCell>{ticket.is_closed ? "Closed" : "Open"}</TableCell>
      <TableCell>
        {!ticket?.is_closed && (
          <Button onClick={() => handleClose(ticket)}>Close Ticket</Button>
        )}
      </TableCell>
    </TableRow>
  );
};
